import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleFallbackConnected = () => {
  const filter1 = useId();
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const clipPath1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <g clipPath={`url(#${clipPath1})`}>
        <g filter={`url(#${filter1})`}>
          <rect x="4" y="4" width="32" height="32" rx="4" fill="white" />
          <rect x="5" y="5" width="30" height="30" rx="3" stroke={`url(#${linearGradient1})`} strokeWidth="2" />
        </g>
        <path
          d="M25.9203 20.9733C25.9203 20.9733 25.8986 20.9299 25.8986 20.843C25.7031 19.9091 25.4859 17.824 26.8325 16.9117C28.1574 16.0212 29.4823 17.0638 29.6778 17.6068C29.9602 18.4104 29.3086 18.7796 28.1574 19.0837C26.3547 19.5615 25.942 20.7344 25.9203 20.9733Z"
          fill="#0E6DBF"
        />
        <path
          d="M26.3114 22.4065C23.6182 21.1033 23.9222 18.9314 23.5747 17.8454C21.6634 11.1556 17.8624 10.6778 17.4063 10.6561C16.3637 10.6344 14.4958 11.1122 14.3655 13.2408C14.17 16.2598 18.4271 16.0643 20.0127 17.3024C21.0987 18.1494 21.8371 20.0173 19.8606 19.6047C17.4714 18.7142 14.6696 18.9748 13.4967 19.4526C11.5202 20.278 10.8469 21.3423 10.6514 21.5595C10.4559 21.7767 8.0233 25.165 10.9772 27.9451C13.3012 30.1388 16.4723 28.575 17.7104 26.8374C18.4488 25.7948 18.9918 24.7523 19.5783 23.84C21.9023 20.9947 24.6824 22.8626 25.4209 24.1658C26.5503 26.1858 27.3323 27.2066 28.3314 26.5333C29.3305 25.8817 29.2002 23.8183 26.3114 22.4065Z"
          fill="#0E6DBF"
        />
        <circle cx="31" cy="31" r="7" fill={`url(#${linearGradient2})`} stroke="white" strokeWidth="2" />
        <rect x="29" y="30.25" width="4" height="1.5" fill="white" />
        <path
          d="M33 28.4828V33.5172C33 33.6953 33.2154 33.7846 33.3414 33.6586L35.8586 31.1414C35.9367 31.0633 35.9367 30.9367 35.8586 30.8586L33.3414 28.3414C33.2154 28.2154 33 28.3047 33 28.4828Z"
          fill="white"
        />
        <path
          d="M29 28.4828V33.5172C29 33.6953 28.7846 33.7846 28.6586 33.6586L26.1414 31.1414C26.0633 31.0633 26.0633 30.9367 26.1414 30.8586L28.6586 28.3414C28.7846 28.2154 29 28.3047 29 28.4828Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id={filter1} x="-1" y="-1" width="42" height="42" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9874_75727" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.572549 0 0 0 0 0.92549 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9874_75727" result="effect2_dropShadow_9874_75727" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9874_75727" result="shape" />
        </filter>
        <linearGradient id={linearGradient1} x1="4" y1="11" x2="32" y2="36" gradientUnits="userSpaceOnUse">
          <stop offset="0.208275" stopColor="#AC59FF" />
          <stop offset="1" stopColor="#036DEA" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="37.4167" y1="38" x2="25.1663" y2="24.8754" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset="1" stopColor="#5594EB" />
        </linearGradient>
        <clipPath id={clipPath1}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export const RippleFallbackOffline = () => {
  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" />
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" stroke="#DDDDDD" />
      <path
        d="M25.9203 20.9733C25.9203 20.9733 25.8986 20.9299 25.8986 20.843C25.7031 19.9091 25.4859 17.824 26.8325 16.9117C28.1574 16.0212 29.4823 17.0638 29.6778 17.6068C29.9602 18.4104 29.3086 18.7796 28.1574 19.0837C26.3547 19.5615 25.942 20.7344 25.9203 20.9733Z"
        fill="#B0B0B0"
      />
      <path
        d="M26.3114 22.4065C23.6182 21.1033 23.9222 18.9314 23.5747 17.8454C21.6634 11.1556 17.8624 10.6778 17.4063 10.6561C16.3637 10.6344 14.4958 11.1122 14.3655 13.2408C14.17 16.2598 18.4271 16.0643 20.0127 17.3024C21.0987 18.1494 21.8371 20.0173 19.8606 19.6047C17.4714 18.7142 14.6696 18.9748 13.4967 19.4526C11.5202 20.278 10.8469 21.3423 10.6514 21.5595C10.4559 21.7767 8.0233 25.165 10.9772 27.9451C13.3012 30.1388 16.4723 28.575 17.7104 26.8374C18.4488 25.7948 18.9918 24.7523 19.5783 23.84C21.9023 20.9947 24.6824 22.8626 25.4209 24.1658C26.5503 26.1858 27.3323 27.2066 28.3314 26.5333C29.3305 25.8817 29.2002 23.8183 26.3114 22.4065Z"
        fill="#B0B0B0"
      />
    </Icon>
  );
};

export const RippleFallbackOnline = () => {
  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" />
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" stroke="#DFE4E9" />
      <path
        d="M25.9203 20.9733C25.9203 20.9733 25.8986 20.9299 25.8986 20.843C25.7031 19.9091 25.4859 17.824 26.8325 16.9117C28.1574 16.0212 29.4823 17.0638 29.6778 17.6068C29.9602 18.4104 29.3086 18.7796 28.1574 19.0837C26.3547 19.5615 25.942 20.7344 25.9203 20.9733Z"
        fill="#0E6DBF"
      />
      <path
        d="M26.3114 22.4065C23.6182 21.1033 23.9222 18.9314 23.5747 17.8454C21.6634 11.1556 17.8624 10.6778 17.4063 10.6561C16.3637 10.6344 14.4958 11.1122 14.3655 13.2408C14.17 16.2598 18.4271 16.0643 20.0127 17.3024C21.0987 18.1494 21.8371 20.0173 19.8606 19.6047C17.4714 18.7142 14.6696 18.9748 13.4967 19.4526C11.5202 20.278 10.8469 21.3423 10.6514 21.5595C10.4559 21.7767 8.0233 25.165 10.9772 27.9451C13.3012 30.1388 16.4723 28.575 17.7104 26.8374C18.4488 25.7948 18.9918 24.7523 19.5783 23.84C21.9023 20.9947 24.6824 22.8626 25.4209 24.1658C26.5503 26.1858 27.3323 27.2066 28.3314 26.5333C29.3305 25.8817 29.2002 23.8183 26.3114 22.4065Z"
        fill="#0E6DBF"
      />
    </Icon>
  );
};
