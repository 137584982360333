import { ReactNode } from 'react';

import { Box, InputGroup, InputLeftElement, InputRightElement, forwardRef } from '@chakra-ui/react';

import { RippleInput, RippleInputProps } from '../RippleInput';
import { RippleTypography } from '../RippleTypography';

export type RippleTextFieldProps = RippleInputProps & {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  helperText?: string;
};

export const RippleTextField = forwardRef<RippleTextFieldProps, 'input'>((props, ref) => {
  const { leadingIcon, trailingIcon, helperText, isInvalid, isDisabled, sx, children: _children, ...otherProps } = props; // Memo: Here will cause runtime error if children is not destructed, not sure the reason currently

  return (
    <Box sx={sx}>
      <InputGroup>
        {leadingIcon && <InputLeftElement sx={{ h: '100%', w: '24px', marginLeft: '8px' }}>{leadingIcon}</InputLeftElement>}
        <RippleInput
          ref={ref}
          sx={{ ...(leadingIcon && { paddingStart: '40px' }), ...(trailingIcon && { paddingEnd: '40px' }) }}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          {...otherProps}
        />
        {trailingIcon && <InputRightElement sx={{ h: '100%', w: '24px', marginRight: '8px' }}>{trailingIcon}</InputRightElement>}
      </InputGroup>
      {helperText && (
        <RippleTypography className="helperText" variant="body03" pt="4px" color={isInvalid ? 'red.100' : 'neutral.300'}>
          {helperText}
        </RippleTypography>
      )}
    </Box>
  );
});
