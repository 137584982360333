import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    backgroundColor: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.neutral[60],
    borderRadius: '8px',
    boxShadow: 'none',
  },
  header: {
    padding: '16px 24px',
  },
  body: {
    padding: '0 24px 24px',
  },
  footer: {
    padding: '0px 24px 24px',
  },
});

export const rippleCard = defineMultiStyleConfig({ baseStyle });
