import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export const useIsomorphicEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useLatestValue<T>(value: T) {
  const cache = useRef(value);

  useIsomorphicEffect(() => {
    cache.current = value;
  }, [value]);

  return cache;
}

// fork from: https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/hooks/use-event.ts
export const useEvent = function useEvent<F extends (...args: Array<any>) => any, P extends Array<any> = Parameters<F>, R = ReturnType<F>>(
  cb: (...args: P) => R,
) {
  const cache = useLatestValue(cb);
  return useCallback((...args: P) => cache.current(...args), [cache]);
};
