import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleRdpIcon({ color = '#DA5E39' }: RippleIconProps) {
  const clipPath1 = useId();
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <g clipPath={`url(#${clipPath1})`}>
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DFE4E9" />
        <path
          d="M7.85006 9.33199C8.0294 9.1303 8.32017 9.1303 8.49952 9.33199L12.7786 14.1445C12.958 14.3461 12.958 14.6732 12.7786 14.8749L8.35593 19.8487C8.17659 20.0504 7.88582 20.0504 7.70648 19.8487L6.38449 18.362C6.20518 18.1603 6.20517 17.8334 6.38447 17.6316L9.2297 14.4314L6.59745 11.4711C6.41811 11.2694 6.41811 10.9424 6.59745 10.7407L7.85006 9.33199ZM16.0785 4.15127L17.3312 5.56C17.5105 5.76168 17.5105 6.0887 17.3312 6.29039L14.6989 9.25068L17.5442 12.4509C17.7234 12.6526 17.7234 12.9796 17.5441 13.1813L16.2221 14.668C16.0428 14.8697 15.752 14.8697 15.5727 14.668L11.1499 9.69411C10.9706 9.49242 10.9706 9.1654 11.1499 8.96372L15.429 4.15127C15.6084 3.94958 15.8992 3.94958 16.0785 4.15127Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipPath1}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
