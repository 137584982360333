import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleViewToggleList({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="3" y="3" width="4" height="4" rx="1" fill="currentColor" />
      <rect x="9" y="4" width="11" height="2" rx="1" fill="currentColor" />
      <rect x="3" y="10" width="4" height="4" rx="1" fill="currentColor" />
      <rect x="9" y="11" width="11" height="2" rx="1" fill="currentColor" />
      <rect x="3" y="17" width="4" height="4" rx="1" fill="currentColor" />
      <rect x="9" y="18" width="11" height="2" rx="1" fill="currentColor" />
    </Icon>
  );
}
