import type { ReactNode } from 'react';

import { Box, forwardRef, useStyleConfig } from '@chakra-ui/react';

type RippleSubscribeBannerProps = {
  variant?: 'warn' | 'default';
  children: ReactNode;
};

export const RippleSubscribeBanner = forwardRef(({ children, variant = 'default', ...otherProps }: RippleSubscribeBannerProps, ref) => {
  const styles = useStyleConfig('rippleSubscribeBanner', { variant });

  return (
    <Box __css={styles} ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});
