import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleTerminalSession({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 6H16V8H8V6Z" fill="currentColor" />
      <path
        d="M7 4V20H8V22H6C5.44772 22 5 21.5523 5 21V3C5 2.44772 5.44772 2 6 2H18C18.5523 2 19 2.44772 19 3V12H17V4H7Z"
        fill="currentColor"
      />
      <path d="M8 9H16V11H8V9Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13C12.4477 13 12 13.4477 12 14V21C12 21.5523 12.4477 22 13 22H18C18.5523 22 19 21.5523 19 21V14C19 13.4477 18.5523 13 18 13H13ZM14 15V20H17V15H14Z"
        fill="currentColor"
      />
      <path
        d="M21 14C20.4477 14 20 14.4477 20 15V20C20 20.5523 20.4477 21 21 21C21.5523 21 22 20.5523 22 20V15C22 14.4477 21.5523 14 21 14Z"
        fill="currentColor"
      />
      <path
        d="M9 15C9 14.4477 9.44772 14 10 14C10.5523 14 11 14.4477 11 15V20C11 20.5523 10.5523 21 10 21C9.44772 21 9 20.5523 9 20V15Z"
        fill="currentColor"
      />
    </Icon>
  );
}
