import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleAndroidOverview({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" color={color}>
      <rect x="6" y="6" width="12" height="12" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    </Icon>
  );
}
