import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleShrink({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M4 4L8 8" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path d="M16 16L20 20" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path d="M19 15L15 15L15 19" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M5 9L9 9L9 5" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <rect x="11" y="11" width="2" height="2" fill="currentColor" />
    </Icon>
  );
}
