import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleInfo({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="13" y="11" width="6" height="2" rx="1" transform="rotate(90 13 11)" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.20005C11.3376 9.20005 10.8 8.66365 10.8 8.00005C10.8 7.33645 11.3376 6.80005 12 6.80005C12.6624 6.80005 13.2 7.33645 13.2 8.00005C13.2 8.66365 12.6624 9.20005 12 9.20005Z"
        fill="currentColor"
      />
    </Icon>
  );
}
