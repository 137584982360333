import { Fragment } from 'react';

import { SimpleGrid, Stack, forwardRef } from '@chakra-ui/react';
import type { BoxProps, SimpleGridProps, TextProps } from '@chakra-ui/react';

import { RippleBodyText02, RippleHeading07, RippleHeading09 } from '../RippleText';

export const RippleDS = forwardRef<BoxProps, 'section'>((props, ref) => (
  <Stack ref={ref} as="section" direction="column" spacing="16px" {...props} />
));

export const RippleDH = forwardRef<SimpleGridProps, 'h6'>((props, ref) => <RippleHeading07 ref={ref} lineHeight="20px" {...props} />);

export const RippleDL = forwardRef<SimpleGridProps, 'dl'>((props, ref) => (
  <SimpleGrid ref={ref} as="dl" columns={2} templateColumns="7fr 13fr" spacing="16px" {...props} />
));

export const RippleDT = forwardRef<TextProps, 'dt'>((props, ref) => (
  <RippleHeading09 ref={ref} as="dt" color="neutral.100" lineHeight="20px" {...props} />
));

export const RippleDD = forwardRef<TextProps, 'dd'>((props, ref) => <RippleBodyText02 ref={ref} as="dd" lineHeight="20px" {...props} />);

export type RippleDescriptionListProps = BoxProps & {
  title?: React.ReactNode;
  items: Array<{
    name: React.ReactNode;
    content: React.ReactNode;
  }>;
};

export const RippleDescriptionList = ({ title, items, ...otherProps }: RippleDescriptionListProps) => {
  return (
    <RippleDS {...otherProps}>
      {title && <RippleDH>{title}</RippleDH>}
      <RippleDL>
        {items.map((item, index) => {
          return (
            <Fragment key={index}>
              <RippleDT>{item.name}</RippleDT>
              <RippleDD as="dd" lineHeight="20px">
                {item.content}
              </RippleDD>
            </Fragment>
          );
        })}
      </RippleDL>
    </RippleDS>
  );
};
