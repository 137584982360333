import NextImage from 'next/image';
import type { ImageProps as NextImageProps } from 'next/image';
import React from 'react';

import { Flex } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';

import { RippleBodyText02, RippleHeading04, RippleHeading05 } from '../RippleText';

export type RippleEmptyStateProps = {
  size?: 'xl' | 'lg' | 'md' | 'sm';
  /** static file url */
  image: NextImageProps['src'];
  title?: string;
  description: React.ReactNode;
  button?: React.ReactNode;
};

export function RippleEmptyState(props: RippleEmptyStateProps): React.JSX.Element {
  const { size = 'lg', image, title, description, button } = props;

  const containerStyle = computeContainerStyle();
  function computeContainerStyle(): StyleProps {
    switch (size) {
      case 'xl': {
        return {
          w: '640px',
        };
      }
      case 'lg': {
        return {
          w: '480px',
        };
      }
      case 'md': {
        return {
          w: '400px',
        };
      }
      case 'sm': {
        return {
          w: '320px',
        };
      }
    }
  }

  const imageContainerStyle = computeImageContainerStyle();
  function computeImageContainerStyle(): StyleProps {
    switch (size) {
      case 'xl':
      case 'lg':
      case 'md': {
        return {
          h: '252px',
        };
      }
      case 'sm': {
        return {
          h: '192px',
        };
      }
    }
  }

  const imageStyle = computeImageStyle();
  function computeImageStyle(): Partial<NextImageProps> {
    switch (size) {
      case 'xl':
      case 'lg':
      case 'md': {
        return {
          width: 368,
          height: 228,
        };
      }
      case 'sm': {
        return {
          width: 272,
          height: 168,
        };
      }
    }
  }

  const TitleComponent = size === 'sm' ? RippleHeading05 : RippleHeading04;

  return (
    <Flex direction="column" alignItems="center" {...containerStyle}>
      <Flex w="100%" mb="32px" alignItems="end" justifyContent="center" {...imageContainerStyle}>
        <NextImage src={image} unoptimized {...imageStyle} alt="Empty state" />
      </Flex>
      {title && (
        <TitleComponent mb="16px" textAlign="center">
          {title}
        </TitleComponent>
      )}
      <RippleBodyText02 mb="32px" textAlign="center" as="div">
        {description}
      </RippleBodyText02>
      {button}
    </Flex>
  );
}
