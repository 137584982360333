import router from 'next/router';

import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import type { AxiosResponse, Canceler } from 'axios';
import { addSeconds } from 'date-fns';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { LS_KEY_PCP_VALIDATION_TOKEN, clearTokens, logout } from '@/authentication';
import { featureControl } from '@/feature/toggle';

import type { ResponseData } from './common/types';

axios.defaults.withCredentials = true;

type InterceptorData = {
  data: {
    error?: string;
    redirect_to?: string;
    key?: string;
  };
};

axios.interceptors.response.use(
  (response: AxiosResponse<ResponseData & InterceptorData>) => {
    const {
      data: { result, data, commands, messages },
    } = response;

    if (result === 40401 && data.error === 'required_2sv' && data.redirect_to && typeof window !== 'undefined') {
      if (window.top) window.top.location.href = data.redirect_to;
      else window.location.href = data.redirect_to;
    }

    if (result === 41401 && data.error === 'password_changed' && typeof window !== 'undefined') {
      clearTokens();

      if (window.top) window.top.location.href = '/';
      else window.location.href = '/';
    }

    if (commands?.force_reload && typeof window !== 'undefined') {
      if (window.top) window.top.location.reload();
      else window.location.reload();
    }

    if (result === 41403 && data.error === 'ip_address_blocked_by_admin') {
      // IP Whitelist block
      Cookie.set('device_auth_for_admins_message', messages.join(''), { expires: addSeconds(new Date(), 15), secure: true });
      logout();
    }

    // For preference policy api, force user to enable 2sv when needed.
    if (result === 40403 && data.error === 'team_permissions' && data.redirect_to) {
      window.location.href = data.redirect_to;
    }

    return response;
  },
  (error: any) => Promise.reject(error),
);

let reqInterceptor: number | null = null;
export function appendTokenToRequest(token: string) {
  if (reqInterceptor !== null) {
    axios.interceptors.request.eject(reqInterceptor);
  }

  reqInterceptor = axios.interceptors.request.use(
    (config) => {
      try {
        const decodeToken: { spid: string; user_id: number } = jwtDecode(token);
        const validationToken = localStorage.getItem(LS_KEY_PCP_VALIDATION_TOKEN);

        if (process.env.NEXT_PUBLIC_APP_VERSION) {
          config.headers['App-Version'] = process.env.NEXT_PUBLIC_APP_VERSION;
        }

        if (/clean_pcp_storage/.exec(document.cookie)) {
          Cookie.remove('clean_pcp_storage');
        } else if (
          window.btoa(
            unescape(
              encodeURIComponent(
                JSON.stringify({
                  email: decodeToken.spid,
                  userId: decodeToken.user_id,
                }),
              ),
            ),
          ) === validationToken
        ) {
          const devUUID = localStorage.getItem('pcp_dev_uuid');
          config.headers['Dev-UUID'] = devUUID;
          config.headers.Authorization = `Bearer ${token}`;

          // Since the EMM API is a separate API, the Authorization header will contain the EMM authorization token. However, we still need the Web API authorization, so we’ll move it to x-web-Authorization instead.
          if (featureControl.getToggle('PCP_1489__EMM_auth_header') && config.headers['x-emm-Authorization']) {
            const { Authorization, 'x-emm-Authorization': emmAuth, ...otherHeaders } = config.headers;
            config.headers = {
              ...otherHeaders,
              'x-web-Authorization': Authorization,
              Authorization: emmAuth,
            };
          }

          return config;
        }

        Object.keys(localStorage).forEach((key) => {
          if (/^pcp_.+/.exec(key)) localStorage.removeItem(key);
        });
        router.reload();
        return config;
      } catch (error) {
        // NOTE: [PCP-1441] Seems that sometimes the `config` object will be undefined but it's incompatible to the type definition
        Sentry.captureException(error, { extra: { info: 'axios interceptor', config: JSON.stringify(config) } });
        return Promise.reject(error);
      }
    },
    (error) => Promise.reject(error),
  );
}

let cancelRequestList: Array<Canceler> = [];

export const cancelRequests = () => {
  cancelRequestList.forEach((cancel) => {
    cancel();
  });
  cancelRequestList = [];
};

export async function appendCancelTokenToRequest() {
  axios.interceptors.request.use((config) => {
    config.cancelToken = new axios.CancelToken((cancelFunc) => {
      cancelRequestList.push(cancelFunc);
    });
    return config;
  });
}
