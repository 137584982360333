export const VENDOR_TEAMS_APP_ID = 7;

type MakeMsTeamsLoginUrlParams = { serverHost: string; vendorId?: string | number };

export const makeMSTeamsLoginUrl = ({ serverHost, vendorId = VENDOR_TEAMS_APP_ID }: MakeMsTeamsLoginUrlParams) =>
  `${serverHost}/login?select_region=false&vendor_id=${vendorId}`;

export const MS_TEAMS_TABS_ID_SETTING = 'setting';

export const SALES_EMAIL = 'sales@splashtop.com';
export const CUSTOMER_SUCCESS_EMAIL = 'customer-success@splashtop.com';

/** Chunk size with the computer list length */
export const CHUNK_SIZE_SMALL = 1000;
export const CHUNK_SIZE = 5000;
export const COMPUTER_LIST_THRESHOLD = 100000;

export const HELP_BUTTON_HEIGHT = 70;

export const MAXIMUM_NUMBER_OF_GROUPS = 8000;

export const ANDROID_SOS_APP_URL = 'https://play.google.com/store/apps/details?id=com.splashtop.sos';
export const ANDROID_SOS_APP_URL_SCHEME = 'market://details?id=com.splashtop.sos';
export const IOS_SOS_APP_URL = 'https://apps.apple.com/app/id1230853703';
export const IOS_SOS_APP_URL_SCHEME = 'itms-apps://apps.apple.com/app/id1230853703';
