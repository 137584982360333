import colors from '../colors';
import { textStyles } from '../text';

const styles = {
  baseStyle: {
    // TODO: Should use typography?
    ...textStyles.h9,
    textAlign: 'center',
    minW: '55px',
    height: 'initial',
    padding: '3px 7px',
    border: '1px solid',
    borderRadius: '4px',
    _focus: {
      // TODO: Chakra token invalid in box-shadow property
      boxShadow: `0 0 0 2px ${colors.blue[60]}`,
    },
    _disabled: {
      cursor: 'not-allowed',
    },
  },
  variants: {
    primary: {
      color: 'white',
      borderColor: 'blue.100',
      backgroundColor: 'blue.100',
      _hover: {
        borderColor: 'blue.80',
        backgroundColor: 'blue.80',
        _disabled: {
          borderColor: 'blue.40',
          backgroundColor: 'blue.40',
        },
      },
      _active: {
        borderColor: 'blue.200',
        backgroundColor: 'blue.200',
      },
      _disabled: {
        color: 'white',
        borderColor: 'blue.40',
        backgroundColor: 'blue.40',
      },
    },
    secondary: {
      color: 'blue.100',
      borderColor: 'blue.100',
      backgroundColor: 'white',
      _hover: {
        backgroundColor: 'blue.10',
        _disabled: {
          color: 'blue.40',
          borderColor: 'blue.40',
          backgroundColor: 'white',
        },
      },
      _active: {
        borderColor: 'blue.100',
        backgroundColor: 'blue.20',
      },
      _disabled: {
        color: 'blue.40',
        borderColor: 'blue.40',
        backgroundColor: 'white',
      },
    },
    tertiary: {
      color: 'black',
      borderColor: 'white',
      backgroundColor: 'white',
      _hover: {
        borderColor: 'blue.10',
        backgroundColor: 'blue.10',
        _disabled: {
          color: 'dark.40',
          borderColor: 'white',
          backgroundColor: 'white',
        },
      },
      _active: {
        borderColor: 'blue.20',
        backgroundColor: 'blue.20',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'white',
        backgroundColor: 'white',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export const rippleInlineButton = styles;
