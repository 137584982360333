import { BoxProps, Flex, forwardRef, useMenuContext, useStyleConfig } from '@chakra-ui/react';

import { RippleInlineArrowDown, RippleInlineArrowUp } from '../RippleIcon';

type Variant = 'standard' | 'grayScale';

export type RippleInlineSelectButtonWithMenuContextProps = BoxProps & {
  isDisabled?: boolean;
  variant?: Variant;
  size?: 'md' | 'sm';
};

const InlineButtonWithMenuContext = forwardRef<RippleInlineSelectButtonWithMenuContextProps, 'button'>((props, ref) => {
  const { isOpen } = useMenuContext();

  return <RippleInlineSelectButton ref={ref} isOpen={isOpen} {...props} />;
});

type RippleInlineSelectButtonProps = { isOpen: boolean } & RippleInlineSelectButtonWithMenuContextProps;
export const RippleInlineSelectButton = forwardRef<RippleInlineSelectButtonProps, 'button'>(
  ({ isOpen, children, variant = 'standard', size = 'md', ...otherProps }, ref) => {
    const styles = useStyleConfig('rippleInlineSelectButton', { variant, size });

    return (
      <Flex
        as="button"
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        gap="4px"
        ref={ref}
        __css={styles}
        {...otherProps}
      >
        {children}
        {isOpen ? <RippleInlineArrowUp color="inherit" /> : <RippleInlineArrowDown color="inherit" />}
      </Flex>
    );
  },
);

export const RippleInlineSelectButtonWithMenuContext = InlineButtonWithMenuContext;
