import { ReactNode, useMemo } from 'react';

import { ToastPosition, useToast } from '@chakra-ui/react';

import { FlexMessageBox } from './FlexMessageBox';
import { FlashMessageVariant } from './types';

type RippleFlashMessageOptions = {
  title: ReactNode;
  variant: FlashMessageVariant;
  id?: string;
  position?: ToastPosition;
};

export const useRippleFlashMessage = () => {
  const toast = useToast();

  return useMemo(() => {
    return {
      isFlashMessageActive: toast.isActive,
      flashMessage: (option: RippleFlashMessageOptions) => {
        const { id, variant, title, position } = option;

        toast({
          id,
          position: position ?? 'bottom',
          duration: 5000,
          render: ({ onClose }) => {
            return (
              <FlexMessageBox variant={variant} onClose={onClose}>
                {title}
              </FlexMessageBox>
            );
          },
        });
      },
      closeAll: toast.closeAll,
    };
  }, [toast]);
};
