import { Text, TextProps, forwardRef } from '@chakra-ui/react';

export const RippleHeading01 = forwardRef<TextProps, 'h1'>((props, ref) => <Text ref={ref} as="h1" textStyle="h1" {...props} />);
export const RippleHeading02 = forwardRef<TextProps, 'h2'>((props, ref) => <Text ref={ref} as="h2" textStyle="h2" {...props} />);
export const RippleHeading03 = forwardRef<TextProps, 'h3'>((props, ref) => <Text ref={ref} as="h3" textStyle="h3" {...props} />);
export const RippleHeading04 = forwardRef<TextProps, 'h4'>((props, ref) => <Text ref={ref} as="h4" textStyle="h4" {...props} />);
export const RippleHeading05 = forwardRef<TextProps, 'h5'>((props, ref) => <Text ref={ref} as="h5" textStyle="h5" {...props} />);
export const RippleHeading06 = forwardRef<TextProps, 'h6'>((props, ref) => <Text ref={ref} as="h6" textStyle="h6" {...props} />);
export const RippleHeading07 = forwardRef<TextProps, 'h6'>((props, ref) => <Text ref={ref} as="h6" textStyle="h7" {...props} />);
export const RippleHeading08 = forwardRef<TextProps, 'h6'>((props, ref) => <Text ref={ref} as="h6" textStyle="h8" {...props} />);
export const RippleHeading09 = forwardRef<TextProps, 'h6'>((props, ref) => <Text ref={ref} as="h6" textStyle="h9" {...props} />);

export const RippleBodyText01 = forwardRef<TextProps, 'p' | 'span'>((props, ref) => <Text ref={ref} as="p" textStyle="body1" {...props} />);
export const RippleBodyText02 = forwardRef<TextProps, 'p' | 'span'>((props, ref) => <Text ref={ref} as="p" textStyle="body2" {...props} />);
export const RippleBodyText03 = forwardRef<TextProps, 'p' | 'span'>((props, ref) => <Text ref={ref} as="p" textStyle="body3" {...props} />);

export const RippleButtonText = forwardRef<TextProps, 'p' | 'span'>((props, ref) => (
  <Text ref={ref} as="p" textStyle="buttonText" {...props} />
));
