import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleViewToggleGroup({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V17.4V19C21 20.1046 20.1046 21 19 21H17H5C3.89543 21 3 20.1046 3 19V5Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="6" y="7" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="10" y="7" width="8" height="2" rx="1" fill="currentColor" />
      <rect x="6" y="11" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="10" y="11" width="8" height="2" rx="1" fill="currentColor" />
      <rect x="6" y="15" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="10" y="15" width="8" height="2" rx="1" fill="currentColor" />
    </Icon>
  );
}
