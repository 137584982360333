import React, { useEffect, useRef, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { SerializedStyles } from '@emotion/react';
import { useTranslation } from 'next-i18next';

import { RippleClose16, RippleSearch } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleInput } from '../RippleInput';
import { RippleTooltip } from '../RippleTooltip';

export type RippleComputerSearchBarProps = {
  onSearch: (keyword: string) => void;
  initKeyword?: string;
  placeholder?: string;
  isDisabled?: boolean;
  showShortcut?: boolean;
  cssStyles?: {
    box?: SerializedStyles;
  };
};

export function RippleComputerSearchBar({
  onSearch,
  cssStyles,
  showShortcut,
  initKeyword = '',
  placeholder = '',
  isDisabled = false,
}: RippleComputerSearchBarProps) {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState<boolean>(Boolean(initKeyword));
  const [searchInput, setSearchInput] = useState<string>(initKeyword);
  const isClosedRef = useRef<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
        return;
      }

      if (event.code === 'Slash') {
        setIsShow((isShow) => {
          if (!isShow) {
            event.preventDefault();
          }

          return true;
        });
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleSearchClick = () => {
    onSearch(searchInput);
    inputRef.current?.blur();
  };

  const handleSearchEnterClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick();
      inputRef.current?.blur();
    }
  };

  const handleShowSearchClick = () => {
    isClosedRef.current = false;
    setIsShow(true);
  };
  const handleCloseSearchClick = () => {
    isClosedRef.current = true;
    setSearchInput('');
    setIsShow(false);

    onSearch('');
  };

  const blurEventHandler = () => {
    if (!isClosedRef.current) {
      onSearch(searchInput);
    }

    if (!searchInput) {
      setIsShow(false);
    }
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      blurEventHandler();
    }, 100);
  };

  const label = t('common:table.search');

  return (
    <Box css={cssStyles?.box}>
      {!isShow ? (
        <RippleTooltip aria-label={label} label={label} shortcut={showShortcut ? '/' : undefined} placement="bottom">
          <RippleIconButton
            aria-label="Search"
            data-testid="show-search-btn"
            icon={<RippleSearch />}
            isDisabled={isDisabled}
            onClick={handleShowSearchClick}
          />
        </RippleTooltip>
      ) : (
        <Flex align="center">
          <Box position="relative">
            <RippleInput
              ref={inputRef}
              data-testid="global-search-input"
              py="0"
              pr="30px"
              height="32px"
              color="dark.90"
              fontSize="0.875rem"
              borderColor="blue.100"
              borderTopLeftRadius="4px"
              borderTopRightRadius="0"
              borderBottomRightRadius="0"
              borderBottomLeftRadius="4px"
              backgroundColor="#FFFFFF"
              value={searchInput || ''}
              isDisabled={isDisabled}
              placeholder={placeholder}
              onChange={handleInputChange}
              onKeyDown={handleSearchEnterClick}
              onBlur={handleInputBlur}
              autoFocus
            />
            {searchInput && (
              <RippleIconButton
                aria-label="clear"
                data-testid="close-search-btn"
                zIndex={2}
                p="1px"
                position="absolute"
                top="50%"
                right="8px"
                width="18px"
                height="18px"
                transform="translateY(-50%)"
                borderRadius="50%"
                backgroundColor="neutral.80"
                _hover={{
                  backgroundColor: 'neutral.300',
                }}
                isDisabled={isDisabled}
                onClick={handleCloseSearchClick}
                icon={<RippleClose16 color="#FFFFFF" />}
              />
            )}
          </Box>
          <RippleIconButton
            aria-label="Search"
            data-testid="global-search-btn"
            bg="blue.100"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            _hover={{ bg: 'blue.100' }}
            icon={<RippleSearch color="white" />}
            isDisabled={isDisabled}
            onClick={handleSearchClick}
          />
        </Flex>
      )}
    </Box>
  );
}
