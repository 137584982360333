import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDomain({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
      <ellipse cx="12" cy="12" rx="4" ry="9" stroke="currentColor" strokeWidth="2" />
      <path d="M20 8C17.8002 8.62621 15.0213 9 12 9C8.97872 9 6.19976 8.62621 4 8" stroke="currentColor" strokeWidth="2" />
      <path d="M20 16C17.8002 15.3738 15.0213 15 12 15C8.97872 15 6.19976 15.3738 4 16" stroke="currentColor" strokeWidth="2" />
    </Icon>
  );
}
