import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { computerDetailSchema } from '../schemas';

const getUrl = (teamId: string, computerId: string) => `/api/web/v1/teams/${teamId}/computers/${computerId}`;

const schema = computerDetailSchema;

const service = (teamId: string, computerId: string) => checkResponse(axios.get(getUrl(teamId, computerId)), schema);

export const getTeamComputerDetail = {
  service,
  getUrl,
};
