import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { computerDetailSchema } from '../schemas';

const getUrl = (computerId: string) => `/api/web/v1/users/computers/${computerId}`;

const schema = computerDetailSchema;

const service = (computerId: string) => checkResponse(axios.get(getUrl(computerId)), schema);

export const getUserComputerDetail = {
  service,
  getUrl,
};
