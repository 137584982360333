import { type FillerRowProps } from 'react-virtuoso';

/**
 * Fix warning for <tr> cannot appear as a child of <div>.
 * 1. copy from https://github.com/petyosi/react-virtuoso/blob/274731d5eb027859224e12931d6c63b9a6ec5f77/src/Table.tsx#L63
 * 2. replace tr and td with div
 */
export function FillerRow({ height }: FillerRowProps) {
  return (
    <div>
      <div style={{ height: height, padding: 0, border: 0 }}></div>
    </div>
  );
}
