import React, { ChangeEvent, Fragment, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { ALL_GROUP_ID, DEFAULT_GROUP_ID, FROM_OTHER_GROUP_ID } from '@/components/ComputerList/utils';

import { RippleAlertSpecificColor, RippleClose16, RippleSearch } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleInput } from '../RippleInput';
import {
  RippleMenu,
  RippleMenuButtonGhost,
  RippleMenuDivider,
  RippleMenuGroup,
  RippleMenuItemSingleSelectCustom,
  RippleMenuList,
} from '../RippleMenu';
import { RippleTypography } from '../RippleTypography';

function isContainFilterValue(upperFilterValue: string, groupName: string) {
  const upperGroupName = groupName.trim().toLocaleUpperCase();

  return upperGroupName.includes(upperFilterValue);
}

export type RippleComputerGroupDropdownData = {
  id: string;
  name: string;
};

type AdditionalGroupHashMap = {
  allGroup?: RippleComputerGroupDropdownData;
  defaultGroup?: RippleComputerGroupDropdownData;
  fromOtherGroup?: RippleComputerGroupDropdownData;
};

export type RippleComputerGroupDropdownProps = {
  showAllGroup: boolean;
  showDefaultGroup: boolean;
  showFromOtherGroup: boolean;
  groups: Array<RippleComputerGroupDropdownData>;
  maxHeight: number;
  onSelect: (selectedGroup: RippleComputerGroupDropdownData) => void;
  initSelectedGroupId?: string | null;
};

export function RippleComputerGroupDropdown(props: RippleComputerGroupDropdownProps) {
  const { t } = useTranslation();
  const { showAllGroup, showDefaultGroup, showFromOtherGroup, groups, maxHeight, onSelect, initSelectedGroupId } = props;
  const alreadyRunOnce = useRef<boolean>(false);

  const sortedGroups = useMemo(() => {
    const filteredGroups = groups.filter((group) => group.id !== DEFAULT_GROUP_ID && group.id !== FROM_OTHER_GROUP_ID);

    return filteredGroups.sort((aGroup, bGroup) => {
      if (aGroup.name.toLocaleUpperCase() > bGroup.name.toLocaleUpperCase()) {
        return 1;
      } else {
        return -1;
      }
    });
  }, [groups]);

  const allAdditionalGroupHashMap: Required<AdditionalGroupHashMap> = useMemo(() => {
    return {
      allGroup: {
        id: ALL_GROUP_ID,
        name: t('computer:allGroups'),
      },
      defaultGroup: {
        id: DEFAULT_GROUP_ID,
        name: t('computer:defaultGroup'),
      },
      fromOtherGroup: {
        id: FROM_OTHER_GROUP_ID,
        name: t('computer:selector.fromOtherGroup'),
      },
    };
  }, [t]);

  const [inputValue, setInputValue] = useState<string>('');
  const [filteredGroups, setFilteredGroups] = useState(sortedGroups);
  const [selectedGroup, setSelectedGroup] = useState<RippleComputerGroupDropdownData>(allAdditionalGroupHashMap.allGroup);

  useEffect(() => {
    if (alreadyRunOnce.current) {
      return;
    }

    if (initSelectedGroupId === allAdditionalGroupHashMap.defaultGroup.id) {
      setSelectedGroup(allAdditionalGroupHashMap.defaultGroup);
      alreadyRunOnce.current = true;
    } else if (initSelectedGroupId === allAdditionalGroupHashMap.fromOtherGroup.id) {
      setSelectedGroup(allAdditionalGroupHashMap.fromOtherGroup);
      alreadyRunOnce.current = true;
    } else if (initSelectedGroupId && sortedGroups.length > 0) {
      const selectedGroup = sortedGroups.find((group) => group.id === initSelectedGroupId);
      selectedGroup && setSelectedGroup(selectedGroup);
      alreadyRunOnce.current = true;
    }
  }, [initSelectedGroupId, allAdditionalGroupHashMap, sortedGroups]);

  const filteredAdditionalGroupHashMap = useMemo(() => {
    const additional: AdditionalGroupHashMap = {};

    const filterValue = inputValue?.trim()?.toLocaleUpperCase() ?? '';

    if (showAllGroup && (!filterValue || isContainFilterValue(filterValue, allAdditionalGroupHashMap.allGroup.name.toLocaleUpperCase()))) {
      additional.allGroup = allAdditionalGroupHashMap.allGroup;
    }
    if (
      showDefaultGroup &&
      (!filterValue || isContainFilterValue(filterValue, allAdditionalGroupHashMap.defaultGroup.name.toLocaleUpperCase()))
    ) {
      additional.defaultGroup = allAdditionalGroupHashMap.defaultGroup;
    }
    if (
      showFromOtherGroup &&
      (!filterValue || isContainFilterValue(filterValue, allAdditionalGroupHashMap.fromOtherGroup.name.toLocaleUpperCase()))
    ) {
      additional.fromOtherGroup = allAdditionalGroupHashMap.fromOtherGroup;
    }

    return additional;
  }, [allAdditionalGroupHashMap, showAllGroup, showDefaultGroup, showFromOtherGroup, inputValue]);

  useEffect(() => {
    if (inputValue === '') {
      setFilteredGroups(sortedGroups);
    } else {
      const filterValue = inputValue.trim().toLocaleUpperCase();
      const nextFilteredGroups = sortedGroups.filter((group) => isContainFilterValue(filterValue, group.name));

      setFilteredGroups(nextFilteredGroups);
    }
  }, [setInputValue, sortedGroups, inputValue]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
    },
    [setInputValue],
  );

  const handleRemoveInput = useCallback(
    (event: UIEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setInputValue('');
    },
    [setInputValue],
  );

  const isNoAdditional = Object.keys(filteredAdditionalGroupHashMap).length === 0;
  const isNoFilteredGroups = filteredGroups.length === 0;
  const isNoResult = isNoFilteredGroups && isNoAdditional;

  return (
    <RippleMenu placement="bottom-end" closeOnBlur>
      {({ isOpen, onClose }) => {
        const makeHandleSelect = (nextSelectGroup: RippleComputerGroupDropdownData) => {
          return () => {
            if (nextSelectGroup.id !== selectedGroup.id) {
              setSelectedGroup(nextSelectGroup);
              onSelect(nextSelectGroup);
            }

            setInputValue('');
            onClose?.();
          };
        };

        return (
          <Fragment>
            <RippleMenuButtonGhost isOpen={isOpen}>{selectedGroup.name}</RippleMenuButtonGhost>
            <RippleMenuList zIndex={5} width="352px" overflowY="auto" maxHeight={`${maxHeight}px`}>
              <Box position="relative" padding="0 12px" mt="12px" mb="6px">
                <RippleInput
                  height="32px"
                  autoFocus
                  pr="56px"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (!['ArrowUp', 'ArrowDown', 'Escape'].includes(e.key)) {
                      e.stopPropagation();
                    }
                  }}
                />
                {inputValue && (
                  <RippleIconButton
                    aria-label="clear"
                    data-testid="group-dropdown-remove-btn"
                    zIndex={2}
                    p="1px"
                    minW="initial"
                    width="18px"
                    height="18px"
                    position="absolute"
                    top="50%"
                    right="44px"
                    transform="translateY(-50%)"
                    borderRadius="50%"
                    backgroundColor="neutral.80"
                    _hover={{
                      backgroundColor: 'neutral.300',
                    }}
                    onClick={handleRemoveInput}
                    icon={<RippleClose16 color="#FFFFFF" />}
                  />
                )}
                <Box zIndex={2} position="absolute" top="50%" right="20px" transform="translateY(-50%)">
                  <RippleSearch />
                </Box>
              </Box>
              {filteredAdditionalGroupHashMap.allGroup &&
                (() => {
                  const isSelectedAllGroup = allAdditionalGroupHashMap.allGroup.id === selectedGroup.id;

                  return (
                    <RippleMenuItemSingleSelectCustom
                      CustomComponent={Box}
                      data-testid="group-filtering-all-group"
                      isSelected={isSelectedAllGroup}
                      onClick={makeHandleSelect(allAdditionalGroupHashMap.allGroup)}
                    >
                      {allAdditionalGroupHashMap.allGroup.name}
                    </RippleMenuItemSingleSelectCustom>
                  );
                })()}
              {filteredAdditionalGroupHashMap.defaultGroup &&
                (() => {
                  const isSelectedDefaultGroup = allAdditionalGroupHashMap.defaultGroup.id === selectedGroup.id;
                  return (
                    <RippleMenuItemSingleSelectCustom
                      CustomComponent={Box}
                      data-testid="group-filtering-default-group"
                      isSelected={isSelectedDefaultGroup}
                      onClick={makeHandleSelect(allAdditionalGroupHashMap.defaultGroup)}
                    >
                      {allAdditionalGroupHashMap.defaultGroup.name}
                    </RippleMenuItemSingleSelectCustom>
                  );
                })()}
              {filteredAdditionalGroupHashMap.fromOtherGroup &&
                (() => {
                  const isSelectedFromOtherGroup = allAdditionalGroupHashMap.fromOtherGroup.id === selectedGroup.id;
                  return (
                    <RippleMenuItemSingleSelectCustom
                      CustomComponent={Box}
                      data-testid="group-filtering-from-other-group"
                      isSelected={isSelectedFromOtherGroup}
                      onClick={makeHandleSelect(allAdditionalGroupHashMap.fromOtherGroup)}
                    >
                      {allAdditionalGroupHashMap.fromOtherGroup.name}
                    </RippleMenuItemSingleSelectCustom>
                  );
                })()}
              {!isNoAdditional && !isNoFilteredGroups && <RippleMenuDivider my="6px" borderColor="neutral.60" />}
              {filteredGroups && filteredGroups.length > 0 && (
                <RippleMenuGroup title={!inputValue ? t('computer:group') : undefined}>
                  {filteredGroups.map((inputGroup) => {
                    const { id, name } = inputGroup;
                    const isGroupSelected = selectedGroup.id === id;

                    return (
                      <RippleMenuItemSingleSelectCustom
                        CustomComponent={Box}
                        data-testid={`group-filtering-${id}`}
                        key={id}
                        onClick={makeHandleSelect(inputGroup)}
                        isSelected={isGroupSelected}
                      >
                        {name}
                      </RippleMenuItemSingleSelectCustom>
                    );
                  })}
                </RippleMenuGroup>
              )}
              {isNoResult && (
                <Flex padding="4px 12px" alignItems="center">
                  <RippleAlertSpecificColor />
                  <RippleTypography as="span" variant="body02" ml="11px" color="dark.90">
                    {t('common:noResult')}
                  </RippleTypography>
                </Flex>
              )}
            </RippleMenuList>
          </Fragment>
        );
      }}
    </RippleMenu>
  );
}
