import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleModify16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <rect x="1" y="3.19995" width="4" height="1.6" rx="0.8" fill="currentColor" />
      <rect x="9" y="3.19995" width="6" height="1.6" rx="0.8" fill="currentColor" />
      <rect x="4.8" y="1.80005" width="4.4" height="4.4" rx="0.8" stroke="currentColor" strokeWidth="1.6" />
      <rect x="1" y="11.2" width="7" height="1.6" rx="0.8" fill="currentColor" />
      <rect x="12" y="11.2" width="3" height="1.6" rx="0.8" fill="currentColor" />
      <rect x="7.8" y="9.80005" width="4.4" height="4.4" rx="0.8" stroke="currentColor" strokeWidth="1.6" />
    </Icon>
  );
}
