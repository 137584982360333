export type HashTable<T> = Record<string, T>;

export type Options<MK, VK> = {
  mainKey: MK;
  valueKey: VK;
};

export function arrayToHashTable<T extends Record<string, any>>(
  sourceArray: Array<T>,
  options: {
    mainKey: keyof T;
  },
) {
  const { mainKey } = options;
  const hashTable: HashTable<T> = {};
  for (let index = 0; index < sourceArray.length; index++) {
    const item = sourceArray[index];
    hashTable[item[mainKey]] = item;
  }
  return hashTable;
}

export function arrayToExistence<T extends Record<string, any>>(
  sourceArray: Array<T>,
  options: {
    mainKey: keyof T;
  },
) {
  const { mainKey } = options;
  const hashTable: HashTable<boolean> = {};
  for (let index = 0; index < sourceArray.length; index++) {
    const item = sourceArray[index];
    hashTable[item[mainKey]] = true;
  }
  return hashTable;
}

export function arrayToHashPair<T extends Record<string, any>, MK extends keyof T, VK extends keyof T>(
  sourceArray: Array<T>,
  options: {
    mainKey: MK;
    valueKey: VK;
  },
) {
  const { mainKey, valueKey } = options;
  const hashTable: HashTable<T[VK]> = {};
  for (let index = 0; index < sourceArray.length; index++) {
    const item = sourceArray[index];
    hashTable[item[mainKey]] = item[valueKey];
  }
  return hashTable;
}

export function arrayToCounting<T extends Record<string, any>>(
  sourceArray: Array<T>,
  options: {
    mainKey: keyof T;
  },
) {
  const { mainKey } = options;
  const hashTable: HashTable<number> = {};
  for (let index = 0; index < sourceArray.length; index++) {
    const item = sourceArray[index];
    hashTable[item[mainKey]] = -~hashTable[item[mainKey]];
  }
  return hashTable;
}

export function arrayToIndexing<T extends Record<string, any>>(
  sourceArray: Array<T>,
  options: {
    mainKey: keyof T;
  },
) {
  const { mainKey } = options;
  const hashTable: HashTable<number> = {};
  for (let index = 0; index < sourceArray.length; index++) {
    const item = sourceArray[index];
    hashTable[item[mainKey]] = index;
  }
  return hashTable;
}
