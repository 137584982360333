import { addDays, getMonth, getYear, isSameDay, set, subDays, subYears } from 'date-fns';
import { useTranslation } from 'next-i18next';

export const MONTHS = [
  { index: 0, name: 'Jan' },
  { index: 1, name: 'Feb' },
  { index: 2, name: 'Mar' },
  { index: 3, name: 'Apr' },
  { index: 4, name: 'May' },
  { index: 5, name: 'Jun' },
  { index: 6, name: 'Jul' },
  { index: 7, name: 'Aug' },
  { index: 8, name: 'Sep' },
  { index: 9, name: 'Oct' },
  { index: 10, name: 'Nov' },
  { index: 11, name: 'Dec' },
] as const;

export const getCalendarName = (date: Date) =>
  `${MONTHS.find((month) => month.index === getMonth(date))?.name ?? getMonth(date) + 1} ${getYear(date)}`;

export function zeroPadding(value: number) {
  if (value < 10) {
    return `0${value}`;
  }
  return String(value);
}

export const hideScrollbarStyle = {
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  /* Hide scrollbar for IE, Edge and Firefox */
  hideScrollbarStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
};

export type Range = '1d' | '7d' | '30d' | '90d' | '1y';

export type Shortcut = { label: string; value: Range };
/**
 * TODO: Consider to pass shortcut from props
 */
export const useShortcuts = (): Array<Shortcut> => {
  const { t } = useTranslation();
  return [
    { label: t('common:today'), value: '1d' },
    { label: t('common:last_count_days', { count: 7 }), value: '7d' },
    { label: t('common:last_count_days', { count: 30 }), value: '30d' },
    { label: t('common:last_count_days', { count: 90 }), value: '90d' },
    { label: t('common:last_count_months', { count: 12 }), value: '1y' },
  ];
};

export const getDateRangeForShortcut = (range: string) => {
  const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  let start = today;
  switch (range) {
    case '1d': {
      start = today;
      break;
    }
    case '7d': {
      start = subDays(today, 6);
      break;
    }
    case '30d': {
      start = subDays(today, 29);
      break;
    }
    case '90d': {
      start = subDays(today, 89);
      break;
    }
    case '1y': {
      start = addDays(subYears(today, 1), 1);
      break;
    }
  }
  return {
    start,
    end: today,
  };
};

export const getCurrentShortcut = (shortcuts: Array<Shortcut>, startDate: Date, endDate: Date) => {
  return shortcuts.find(({ value }) => {
    const { start, end } = getDateRangeForShortcut(value);
    return isSameDay(start, startDate) && isSameDay(end, endDate);
  });
};
