import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleRdsIcon({ color = '#DA5E39' }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="7.25" y="1.25" width="15.5" height="15.5" rx="1.75" fill="white" />
      <rect x="7.25" y="1.25" width="15.5" height="15.5" rx="1.75" stroke="#DFE4E9" strokeWidth="0.5" />
      <path
        d="M11.9301 6.999C12.0646 6.84773 12.2827 6.84773 12.4172 6.999L15.6265 10.6083C15.761 10.7596 15.761 11.0048 15.6265 11.1561L12.3095 14.8865C12.175 15.0378 11.9569 15.0378 11.8224 14.8865L10.8309 13.7715C10.6964 13.6202 10.6964 13.375 10.8309 13.2237L12.9648 10.8235L10.9906 8.60333C10.8561 8.45206 10.8561 8.20681 10.9906 8.05554L11.9301 6.999ZM18.1014 3.11345L19.0409 4.16999C19.1754 4.32126 19.1754 4.56653 19.0409 4.7178L17.0667 6.93802L19.2006 9.33816C19.3351 9.48945 19.3351 9.73471 19.2006 9.88601L18.2091 11.001C18.0746 11.1523 17.8565 11.1523 17.722 11.001L14.405 7.27058C14.2705 7.11931 14.2705 6.87406 14.405 6.72279L17.6143 3.11345C17.7488 2.96218 17.9669 2.96218 18.1014 3.11345Z"
        fill="#DA5E39"
      />
      <rect x="1.25" y="7.25" width="15.5" height="15.5" rx="1.75" fill="white" />
      <rect x="1.25" y="7.25" width="15.5" height="15.5" rx="1.75" stroke="#DFE4E9" strokeWidth="0.5" />
      <path
        d="M5.9 12.999C6.0345 12.8477 6.25258 12.8477 6.38709 12.999L9.59642 16.6083C9.73092 16.7596 9.73092 17.0048 9.59642 17.1561L6.2794 20.8865C6.14489 21.0378 5.92681 21.0378 5.79231 20.8865L4.80082 19.7715C4.66634 19.6202 4.66633 19.375 4.80081 19.2237L6.93472 16.8235L4.96054 14.6033C4.82603 14.4521 4.82603 14.2068 4.96054 14.0555L5.9 12.999ZM12.0713 9.11345L13.0108 10.17C13.1453 10.3213 13.1453 10.5665 13.0108 10.7178L11.0366 12.938L13.1705 15.3381C13.305 15.4894 13.305 15.7347 13.1705 15.886L12.179 17.001C12.0445 17.1523 11.8264 17.1523 11.6919 17.001L8.37491 13.2706C8.2404 13.1193 8.2404 12.874 8.37491 12.7228L11.5842 9.11345C11.7187 8.96218 11.9368 8.96218 12.0713 9.11345Z"
        fill="#DA5E39"
      />
    </Icon>
  );
}
