import React, { useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { atomWithReset } from 'jotai/utils';
import { Trans, useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleHyperLink,
  RippleListItem,
  RippleModal,
  RippleModalBody,
  RippleModalCloseButton,
  RippleModalContent,
  RippleModalHeader,
  RippleModalOverlay,
  RippleOrderedList,
  RippleStrong,
  RippleTypography,
  type TypographyVariant,
} from '@/design';
import downloadSRC from '@/utils/downloadSRC';

type DownloadModalProps = {
  isInIframe: boolean;
};

export const downloadModalAtom = atomWithReset({ isOpen: false });

export function useDownloadModal() {
  const set = useSetAtom(downloadModalAtom);

  return {
    open: () => {
      set({ isOpen: true });
    },
  };
}

export const DownloadModal = ({ isInIframe }: DownloadModalProps) => {
  const { t } = useTranslation();

  const { isOpen } = useAtomValue(downloadModalAtom);
  const resetModal = useResetAtom(downloadModalAtom);

  useEffect(() => {
    if (isOpen) downloadSRC({ isInIframe });
  }, [isOpen, isInIframe]);

  const headerFontVariant = useBreakpointValue<TypographyVariant>({ base: 'heading06', md: 'heading05' }) ?? 'heading06';

  return (
    <RippleModal isOpen={isOpen} onClose={resetModal}>
      <RippleModalOverlay />
      <RippleModalContent w="95%">
        <RippleModalHeader borderBottom="1px solid" borderColor="neutral.60" color="dark.100">
          <RippleTypography variant={headerFontVariant}>
            {t('common:downloadBusinessAppModal_title')}
            <RippleStrong as="p" variant="strong02" mt="8px">
              {t('common:downloadBusinessAppModal_description')}
            </RippleStrong>
          </RippleTypography>
        </RippleModalHeader>
        <RippleModalCloseButton w="24px" h="24px" />
        <RippleModalBody p="24px">
          <Box fontSize="14px">
            <RippleOrderedList color="dark.100">
              <RippleListItem>{t('common:downloadBusinessAppModal_runBusinessApp')}</RippleListItem>
              <RippleListItem>{t('common:downloadBusinessAppModal_loginAndConnect')}</RippleListItem>
            </RippleOrderedList>
            <RippleBodyText02 mt="16px">
              <Trans
                t={t}
                i18nKey="common:downloadBusinessAppModal_downloadNotBeginTryAgain"
                components={{
                  Link: (
                    <RippleHyperLink
                      variant="hyperlink02"
                      href="#"
                      onClick={() => {
                        downloadSRC({ isInIframe });
                      }}
                    />
                  ),
                }}
              />
            </RippleBodyText02>
          </Box>
        </RippleModalBody>
      </RippleModalContent>
    </RippleModal>
  );
};
