import React from 'react';

import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02 } from '@/design';
import { formatIntlNumber } from '@/utils/formatNumber';

import useLocalPageSize, { UseLocalPageSizeProps } from './useLocalPageSize';

// Copy and modify from @/components/SimpleTable/Pagination/PageSize

export type RipplePaginationStateProps = UseLocalPageSizeProps;

const formatTotal = formatIntlNumber();

export function RipplePaginationState(props: RipplePaginationStateProps) {
  const { total } = props;

  const { t } = useTranslation(['common']);
  const { startItem, endItem } = useLocalPageSize(props);

  return (
    <Flex alignItems="center" mx="8px">
      <RippleBodyText02 data-testid="pagination-size-total">
        {t('common:table.ofPage', {
          start: formatTotal(startItem),
          end: formatTotal(endItem),
          total: formatTotal(total),
        })}
      </RippleBodyText02>
    </Flex>
  );
}
