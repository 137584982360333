import { ReactNode } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

import { RippleTypography } from '../../RippleTypography';

type DatePickerButtonProps = FlexProps & {
  children: ReactNode;
  isActive: boolean;
  isDisabled: boolean;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
};

export const DatePickerButton = ({
  children,
  isActive,
  isDisabled,
  onClick,
  type,
  onMouseEnter,
  onPointerEnter,
  ...flexProps
}: DatePickerButtonProps) => {
  let textColor = 'dark.90';

  if (isDisabled) {
    textColor = 'neutral.100';
  } else if (isActive) {
    textColor = 'white';
  }

  let bgHoverColor = 'blue.10';
  if (isDisabled) {
    bgHoverColor = 'transparent';
  } else if (isActive) {
    bgHoverColor = 'blue.200';
  }

  return (
    <Flex
      as="button"
      type={type}
      justifyContent="center"
      alignItems="center"
      width="24px"
      height="24px"
      borderRadius="4px"
      bg={!isDisabled && isActive ? 'blue.200' : 'transparent'}
      _hover={{
        bg: bgHoverColor,
      }}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={!isDisabled ? onClick : undefined}
      onMouseEnter={!isDisabled ? onMouseEnter : undefined}
      onPointerEnter={!isDisabled ? onPointerEnter : undefined}
      transition="100ms ease"
      {...flexProps}
    >
      <RippleTypography variant="heading09" color={textColor}>
        {children}
      </RippleTypography>
    </Flex>
  );
};
