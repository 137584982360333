import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { i18n } from 'next-i18next';
import { z } from 'zod';

import { getAuthTokens, refreshAccessToken as refreshAccessTokenAPI } from '@/services/auth';
import { appendTokenToRequest } from '@/services/interceptor';
import { postMessageToRails } from '@/utils/postMessageToRails';

import {
  COOKIE_KEY_PCP_SOS_TEAM_ID,
  COOKIE_KEY_PCP_STA_TEAM_ID,
  COOKIE_KEY_PCP_STB_TEAM_ID,
  COOKIE_KEY_PCP_USED_TEAM_AT,
  LS_KEY_PCP_ACCESS_TOKEN_EXP,
  LS_KEY_PCP_CSRF_TOKEN,
  LS_KEY_PCP_DEV_UUID,
  LS_KEY_PCP_REFRESH_TOKEN,
  LS_KEY_PCP_REFRESH_TOKEN_EXP,
  LS_KEY_PCP_VALIDATION_TOKEN,
} from './constants';

/**
 * Latest get auth token (access token or refresh token) time
 */
let latestAuthTokenTime: number = 0;

function refreshLatestAuthTokenTime() {
  latestAuthTokenTime = new Date().getTime();
}

/**
 * Check `pcp_used_team_at` should before the latest get auth token time
 */
function checkUsedTeamTimestamp(): boolean {
  const usedTeamTimestamp = Cookie.get(COOKIE_KEY_PCP_USED_TEAM_AT);

  if (usedTeamTimestamp === undefined) return false;

  const usedTeamTime = Number(usedTeamTimestamp) * 1000;

  return usedTeamTime < latestAuthTokenTime;
}

let alerted = false;
function alertUsedTeamExpired() {
  if (alerted === false) {
    window.alert(
      i18n?.t('common:your_session_has_expired_please_refresh_the_page_and_try_again') ??
        'Your session has expired. Please refresh the page and try again.',
    );
    alerted = true;
  }
}

let checkUsedTeamInterceptor: number | null = null;
function handleVisibilityChange() {
  if (document.visibilityState === 'visible') {
    // When the tab is being focus again
    if (checkUsedTeamInterceptor !== null) {
      axios.interceptors.request.eject(checkUsedTeamInterceptor);
    }

    checkUsedTeamInterceptor = axios.interceptors.request.use((request) => {
      if (checkUsedTeamTimestamp() === false) {
        alertUsedTeamExpired();
        return; // Block all the requests
      }

      return request;
    });
  }
}

export async function validateAuthTokens() {
  const refreshToken = localStorage.getItem(LS_KEY_PCP_REFRESH_TOKEN);
  const refreshTokenExp = localStorage.getItem(LS_KEY_PCP_REFRESH_TOKEN_EXP) || 0;
  const tokenIsExpired = new Date().getTime() > Number(refreshTokenExp);
  const usedTeamIsExpired = checkUsedTeamTimestamp() === false;

  const tokenIsAvailable = refreshToken && !tokenIsExpired && !usedTeamIsExpired;

  let accessToken: string | null = null;
  if (tokenIsAvailable) {
    accessToken = await refreshAccessToken(refreshToken).catch(getAndStoreAuthTokens);
  } else {
    accessToken = await getAndStoreAuthTokens();
  }

  document.removeEventListener('visibilitychange', handleVisibilityChange);
  document.addEventListener('visibilitychange', handleVisibilityChange);

  if (accessToken) {
    setSentryUser(accessToken);
  }
}

export function clearTokens() {
  localStorage.removeItem(LS_KEY_PCP_ACCESS_TOKEN_EXP);
  localStorage.removeItem(LS_KEY_PCP_REFRESH_TOKEN);
  localStorage.removeItem(LS_KEY_PCP_VALIDATION_TOKEN);
  localStorage.removeItem(LS_KEY_PCP_CSRF_TOKEN);
}

export const logout = () => {
  clearSentryUser();

  if (window.self !== window.top) {
    // in iframe
    postMessageToRails({ type: 'logout' });
    return;
  }

  const csrfToken = localStorage.getItem(LS_KEY_PCP_CSRF_TOKEN);

  if (csrfToken) {
    const form = document.createElement('form');
    form.name = 'logout-form';
    form.method = 'POST';
    form.action = '/logout';
    form.style.display = 'none';

    const methodEl = document.createElement('input');
    methodEl.name = '_method';
    methodEl.value = 'delete';
    form.appendChild(methodEl);

    const csrfTokenEl = document.createElement('input');
    csrfTokenEl.name = 'authenticity_token';
    csrfTokenEl.value = csrfToken ?? '';
    form.appendChild(csrfTokenEl);

    document.body.appendChild(form);

    form.submit();
  } else {
    window.location.href = '/login';
  }
};

async function getAndStoreAuthTokens(): Promise<string> {
  const { access_token, access_token_ttl, csrf_token, dev_uuid, refresh_token, refresh_token_ttl } = await getAuthTokens();

  refreshLatestAuthTokenTime();

  localStorage.setItem(LS_KEY_PCP_ACCESS_TOKEN_EXP, `${access_token_ttl * 1000 + new Date().getTime()}`);
  localStorage.setItem(LS_KEY_PCP_DEV_UUID, dev_uuid);
  localStorage.setItem(LS_KEY_PCP_REFRESH_TOKEN, refresh_token);
  localStorage.setItem(LS_KEY_PCP_REFRESH_TOKEN_EXP, `${refresh_token_ttl * 1000 + new Date().getTime()}`);
  localStorage.setItem(LS_KEY_PCP_CSRF_TOKEN, csrf_token);

  const decodeToken: { spid: string; user_id: number } = jwtDecode(access_token);

  localStorage.setItem(
    LS_KEY_PCP_VALIDATION_TOKEN,
    window.btoa(
      unescape(
        encodeURIComponent(
          JSON.stringify({
            email: decodeToken.spid,
            userId: decodeToken.user_id,
          }),
        ),
      ),
    ),
  );

  appendTokenToRequest(access_token);

  return access_token;
}

async function refreshAccessToken(refreshToken: string | null): Promise<string> {
  const devUUID = localStorage.getItem(LS_KEY_PCP_DEV_UUID);

  const userId = getUserId();

  if (devUUID) {
    const { access_token, access_token_ttl } = await refreshAccessTokenAPI(refreshToken ?? '', devUUID, userId);

    refreshLatestAuthTokenTime();

    localStorage.setItem(LS_KEY_PCP_REFRESH_TOKEN_EXP, `${access_token_ttl * 1000 + new Date().getTime()}`);
    appendTokenToRequest(access_token);

    return access_token;
  } else {
    return Promise.reject('Unexpected error');
  }
}

function getUserId() {
  const validationToken = localStorage.getItem(LS_KEY_PCP_VALIDATION_TOKEN);
  if (validationToken) {
    const validationInfo = JSON.parse(decodeURIComponent(escape(window.atob(validationToken))));
    return validationInfo.userId as number;
  }
  return undefined;
}

function setSentryUser(accessToken: string) {
  const accessTokenPayloadRaw = jwtDecode(accessToken);

  const accessTokenPayloadSchema = z.object({
    user_id: z.number(),
    stb_team_id: z.number(),
    sos_team_id: z.number(),
    sta_team_id: z.number(),
  });

  accessTokenPayloadSchema.safeParseAsync(accessTokenPayloadRaw).then((result) => {
    if (result.success) {
      const { user_id, stb_team_id, sos_team_id, sta_team_id } = result.data;

      Sentry.setUser({ user_id, stb_team_id, sos_team_id, sta_team_id });
    }
  });
}

function clearSentryUser() {
  Sentry.setUser(null);
}

export function checkValidSTPTeam(): boolean {
  const sosTeamId = Cookie.get(COOKIE_KEY_PCP_SOS_TEAM_ID);
  const staTeamId = Cookie.get(COOKIE_KEY_PCP_STA_TEAM_ID);
  const stbTeamId = Cookie.get(COOKIE_KEY_PCP_STB_TEAM_ID);

  return sosTeamId === '0' && staTeamId === '0' && stbTeamId === '0';
}
