import { defineStyleConfig } from '@chakra-ui/react';

export const rippleSubscribeBanner = defineStyleConfig({
  baseStyle: {
    borderRadius: '4px',
    border: '1px solid',
    p: '11px 10px',
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
  },
  variants: {
    default: {
      bg: 'green.10',
      borderColor: 'green.100',
    },
    warn: {
      bg: 'yellow.10',
      borderColor: 'yellow.100',
    },
  },
});
