import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDownload({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M16 16C18.8 16 21 13.8 21 11C21 8.5 19.2 6.4 16.8 6.1C15.6 4.8 13.9 4 12 4C9.2 4 6.9 5.7 5.9 8.2C4.2 8.6 3 10.2 3 12C3 14.2 4.8 16 7 16H8"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path d="M12 9.5V21.5" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M9 19L12 21.5L15 19" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
    </Icon>
  );
}
