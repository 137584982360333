import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleClose16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5656 4.5657C12.8781 4.25328 12.8781 3.74675 12.5656 3.43433C12.2532 3.12191 11.7467 3.12191 11.4343 3.43433L7.99995 6.86864L4.56564 3.43433C4.25322 3.12191 3.74669 3.12191 3.43427 3.43433C3.12185 3.74675 3.12185 4.25328 3.43427 4.5657L6.86858 8.00001L3.43427 11.4343C3.12185 11.7467 3.12185 12.2533 3.43427 12.5657C3.74669 12.8781 4.25322 12.8781 4.56564 12.5657L7.99995 9.13138L11.4343 12.5657C11.7467 12.8781 12.2532 12.8781 12.5656 12.5657C12.8781 12.2533 12.8781 11.7467 12.5656 11.4343L9.13132 8.00001L12.5656 4.5657Z"
        fill="currentColor"
      />
    </Icon>
  );
}
