import React from 'react';

import { forwardRef } from '@chakra-ui/react';

import { RippleRefresh, RippleRefresh16 } from '../RippleIcon';
import { RippleIconButton, RippleIconButtonProps } from '../RippleIconButton';

export const RippleRefreshIconButton = forwardRef<
  Omit<RippleIconButtonProps, 'aria-label'> & { isLoading: boolean; isSize16?: boolean },
  'button'
>((props, ref) => {
  const { isLoading, isSize16, color, ...otherProps } = props;
  return (
    <RippleIconButton
      data-testid="refresh"
      aria-label="Refresh"
      boxSize={isSize16 ? '24px' : '32px'}
      icon={isSize16 ? <RippleRefresh16 isLoading={isLoading} color={color} /> : <RippleRefresh isLoading={isLoading} color={color} />}
      isDisabled={isLoading}
      ref={ref}
      color={color}
      {...otherProps}
    />
  );
});
