import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCopyAndPaste({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M7 19L7 20C7 20.5523 7.44772 21 8 21L10 21" stroke="currentColor" strokeWidth="2" strokeMiterlimit="16" />
      <rect x="12" y="20" width="4" height="2" fill="currentColor" />
      <path d="M18 21H20C20.5523 21 21 20.5523 21 20V18" stroke="currentColor" strokeWidth="2" strokeMiterlimit="16" />
      <rect x="20" y="12" width="2" height="4" fill="currentColor" />
      <path d="M21 10L21 8C21 7.44772 20.5523 7 20 7L19 7" stroke="currentColor" strokeWidth="2" strokeMiterlimit="16" />
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V13.1716C17 13.4368 16.8946 13.6911 16.7071 13.8787L15.2929 15.2929L13.8787 16.7071C13.6911 16.8946 13.4368 17 13.1716 17H4C3.44772 17 3 16.5523 3 16V4Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M17 12H14C12.8954 12 12 12.8954 12 14V17" stroke="currentColor" strokeWidth="2" />
      <rect x="6" y="7" width="8" height="2" rx="1" fill="currentColor" />
      <rect x="6" y="11" width="4" height="2" rx="1" fill="currentColor" />
    </Icon>
  );
}
