export function featureToggleControl<T extends Record<string, boolean>>(defaultFeatureToggle: T) {
  let featureToggle = { ...defaultFeatureToggle };

  return {
    getToggle(featureName: keyof T): boolean {
      return featureToggle[featureName];
    },
    setToggle(featureName: keyof T, value: boolean): void {
      featureToggle[featureName] = value as T[keyof T];
    },
    reset(): void {
      featureToggle = { ...defaultFeatureToggle };
    },
  };
}
