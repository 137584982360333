import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const popple = keyframes`
    0% {
    top: 45px;
    left: 45px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -2.5px;
    left: -2.5px;
    width: 95px;
    height: 95px;
    opacity: 0;
  }
`;

const PoppleWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  transform: translateZ(0) scale(1);
  transform-origin: 0 0;
  backface-visibility: hidden;

  & div {
    position: absolute;
    border-style: solid;
    border-width: 5px;
    border-radius: 50%;
    opacity: 1;
    animation: ${popple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  & div:nth-of-type(1) {
    border-color: #d7e5fa;
  }

  & div:nth-of-type(2) {
    border-color: #036dea;
    animation-delay: -0.5s;
  }
`;

const Popple = () => (
  <PoppleWrapper>
    <div></div>
    <div></div>
  </PoppleWrapper>
);

export default Popple;
