import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleGranularControlEventLogsIcon({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 20C8 20.5523 7.55229 21 7 21H4C2.89543 21 2 20.1046 2 19V4C2 2.89543 2.89543 2 4 2H6H17C18.1046 2 19 2.89543 19 4V8C19 8.55229 18.5523 9 18 9C17.4477 9 17 8.55229 17 8V4H6H4V19H7C7.55229 19 8 19.4477 8 20ZM7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8H14C14.5523 8 15 7.55228 15 7C15 6.44772 14.5523 6 14 6H7ZM7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H10C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10H7ZM6 15C6 14.4477 6.44772 14 7 14H8C8.55228 14 9 14.4477 9 15C9 15.5523 8.55228 16 8 16H7C6.44772 16 6 15.5523 6 15ZM12.2297 16C12.2297 13.7909 14.0206 12 16.2297 12C17.7133 12 19.0083 12.8077 19.6989 14.0074C19.7986 14.0025 19.899 14 20 14C20.7002 14 21.3725 14.12 21.9972 14.3404C21.2773 11.8339 18.9677 10 16.2297 10C12.916 10 10.2297 12.6863 10.2297 16C10.2297 16.6107 10.3212 17.2015 10.4919 17.7588L9.13932 20.4493C8.63391 21.4547 9.55781 22.5838 10.6433 22.2873L13.7143 21.4486C13.8829 21.5265 14.0558 21.5969 14.2324 21.6592C14.0811 21.1323 14 20.5756 14 20C14 19.8068 14.0091 19.6157 14.027 19.4272C13.8379 19.3913 13.6378 19.3962 13.4405 19.4501L11.6318 19.9441L12.4085 18.3991C12.5767 18.0645 12.5858 17.6889 12.4679 17.3638C12.3141 16.9395 12.2297 16.4807 12.2297 16ZM17.25 14.6659C16.5595 15.0226 15.9472 15.5094 15.4454 16.094C15.3226 15.9279 15.25 15.7224 15.25 15.5V14C15.25 13.4477 15.6977 13 16.25 13C16.8023 13 17.25 13.4477 17.25 14V14.6659Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.5L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <rect x="6" y="6" width="9" height="2" rx="1" fill="currentColor" />
          <rect x="6" y="10" width="5" height="2" rx="1" fill="currentColor" />
          <rect x="6" y="14" width="3" height="2" rx="1" fill="currentColor" />
          <path
            d="M13.7039 20.4148L13.9674 21.3794L13.7039 20.4148ZM13.9284 20.4401L14.3893 19.5526L13.9284 20.4401ZM10.0328 20.8985L10.9262 21.3476L10.0328 20.8985ZM10.3799 21.3226L10.1164 20.358L10.3799 21.3226ZM11.5278 17.7047L12.4679 17.3638L11.5278 17.7047ZM11.515 17.95L10.6216 17.5008L11.515 17.95ZM20.2297 16C20.2297 18.2091 18.4388 20 16.2297 20V22C19.5434 22 22.2297 19.3137 22.2297 16H20.2297ZM16.2297 12C18.4388 12 20.2297 13.7909 20.2297 16H22.2297C22.2297 12.6863 19.5434 10 16.2297 10V12ZM12.2297 16C12.2297 13.7909 14.0206 12 16.2297 12V10C12.916 10 10.2297 12.6863 10.2297 16H12.2297ZM12.4679 17.3638C12.3141 16.9395 12.2297 16.4807 12.2297 16H10.2297C10.2297 16.7166 10.3558 17.4059 10.5878 18.0456L12.4679 17.3638ZM10.6216 17.5008L9.13932 20.4493L10.9262 21.3476L12.4085 18.3991L10.6216 17.5008ZM10.6433 22.2873L13.9674 21.3794L13.4405 19.4501L10.1164 20.358L10.6433 22.2873ZM16.2297 20C15.5641 20 14.9391 19.8382 14.3893 19.5526L13.4675 21.3275C14.2953 21.7575 15.2357 22 16.2297 22V20ZM13.9674 21.3794C13.7878 21.4285 13.6099 21.4015 13.4675 21.3275L14.3893 19.5526C14.1084 19.4068 13.7705 19.36 13.4405 19.4501L13.9674 21.3794ZM9.13932 20.4493C8.63391 21.4547 9.55781 22.5838 10.6433 22.2873L10.1164 20.358C10.7009 20.1983 11.1984 20.8063 10.9262 21.3476L9.13932 20.4493ZM10.5878 18.0456C10.528 17.881 10.53 17.683 10.6216 17.5008L12.4085 18.3991C12.5767 18.0645 12.5858 17.6889 12.4679 17.3638L10.5878 18.0456Z"
            fill="currentColor"
          />
          <rect x="15.25" y="13" width="2" height="3.5" rx="1" fill="currentColor" />
          <circle cx="16.25" cy="18.25" r="1" fill="currentColor" />
          <path
            d="M7 20H4C3.44772 20 3 19.5523 3 19L3 4C3 3.44772 3.44772 3 4 3H6H17C17.5523 3 18 3.44772 18 4L18 8"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </>
      )}
    </Icon>
  );
}
