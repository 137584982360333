import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleInfo16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <circle cx="8" cy="8" r="6.2" stroke="currentColor" strokeWidth="1.6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80001 11.1667C8.80001 11.6275 8.44161 12 8.00001 12C7.55841 12 7.20001 11.6275 7.20001 11.1667V7.83333C7.20001 7.37333 7.55841 7 8.00001 7C8.44161 7 8.80001 7.37333 8.80001 7.83333V11.1667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C7.448 6 7 5.553 7 5C7 4.447 7.448 4 8 4C8.552 4 9 4.447 9 5C9 5.553 8.552 6 8 6Z"
        fill="currentColor"
      />
    </Icon>
  );
}
