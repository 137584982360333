import React from 'react';

import { InputGroup, InputRightElement, forwardRef } from '@chakra-ui/react';
import type { InputElementProps, StyleProps } from '@chakra-ui/react';

import { RippleButton } from '@/design';
import type { RippleButtonProps } from '@/design';

export const RippleInputGroup = InputGroup;

export const RippleInputRightElement = forwardRef<InputElementProps, 'div'>((props, ref) => {
  return <InputRightElement ref={ref} w="auto" h="auto" {...props} />;
});

type Size = 'sm' | 'xs';
export type RippleInputGroupButtonProps = Omit<RippleButtonProps, 'variant' | 'size'> & { variant?: 'label' | 'icon'; size?: Size };
export const RippleInputGroupButton = forwardRef<RippleInputGroupButtonProps, 'button'>((props, ref) => {
  const { variant = 'label', size = 'sm', ...otherProps } = props;

  const styles = getStyles();

  return (
    <RippleButton
      ref={ref}
      variant="primary"
      borderTopLeftRadius="none"
      borderBottomLeftRadius="none"
      p="10px 8px"
      minW="0"
      size={size}
      sx={{ svg: { color: 'white' } }}
      {...styles}
      {...otherProps}
    />
  );

  function getStyles(): StyleProps {
    switch (variant) {
      case 'label': {
        const styleMap: Record<Size, StyleProps> = {
          sm: {
            p: '10px 8px',
          },
          xs: {
            p: '8px',
          },
        };

        return styleMap[size];
      }
      case 'icon': {
        const styleMap: Record<Size, StyleProps> = {
          sm: {
            boxSize: '40px',
            p: '8px',
          },
          xs: {
            boxSize: '32px',
            p: '4px',
          },
        };

        return styleMap[size];
      }
    }
  }
});
