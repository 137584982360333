import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCtrlAltDel({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2C13.4477 2 13 2.44772 13 3V10C13 10.5523 13.4477 11 14 11H21C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14ZM18.1879 4.07829L19.9245 8.29808C19.9748 8.42392 20 8.54976 20 8.65043C20 9.04473 19.698 9.33835 19.2869 9.33835C18.9765 9.33835 18.7416 9.16218 18.6074 8.81822L18.4899 8.52459H16.5101L16.3926 8.81822C16.2584 9.16218 16.0235 9.33835 15.7131 9.33835C15.302 9.33835 15 9.04473 15 8.65043C15 8.54976 15.0252 8.42392 15.0755 8.29808L16.8121 4.07829C16.9379 3.76788 17.1728 3.6001 17.5 3.6001C17.8272 3.6001 18.0621 3.76788 18.1879 4.07829ZM18.0621 7.36688L17.5 5.74775L16.9379 7.36688H18.0621ZM3 7C2.44772 7 2 7.44772 2 8V15C2 15.5523 2.44772 16 3 16H10C10.5523 16 11 15.5523 11 15V8C11 7.44772 10.5523 7 10 7H3ZM8.85088 13.5351C8.3421 14.1404 7.50877 14.5 6.62281 14.5C4.85965 14.5 3.5 13.193 3.5 11.5C3.5 9.80702 4.86842 8.5 6.63158 8.5C7.51754 8.5 8.35088 8.85965 8.85965 9.46491C9 9.62281 9.07895 9.80702 9.07895 10C9.07895 10.3772 8.77193 10.6842 8.38596 10.6842C8.17544 10.6842 7.98246 10.5877 7.80702 10.4035C7.48246 10.0526 7.09649 9.88597 6.62281 9.88597C5.69298 9.88597 5.04386 10.5526 5.04386 11.5088C5.04386 12.4649 5.68421 13.114 6.62281 13.114C7.07895 13.114 7.45614 12.9474 7.79824 12.5965C7.97368 12.4123 8.16667 12.3158 8.37719 12.3158C8.76316 12.3158 9.07018 12.6228 9.07018 13C9.07018 13.1667 9 13.3421 8.85088 13.5351ZM13 14C13 13.4477 13.4477 13 14 13H21C21.5523 13 22 13.4477 22 14V21C22 21.5523 21.5523 22 21 22H14C13.4477 22 13 21.5523 13 21V14ZM16.9492 14.7H15.7373C15.3136 14.7 15 15.0135 15 15.4372V19.5559C15 19.9796 15.3136 20.2932 15.7373 20.2932H16.9492C18.7288 20.2932 20 19.1237 20 17.4796C20 15.8355 18.7542 14.7 16.9492 14.7ZM16.9407 16.022C17.9153 16.022 18.5085 16.5728 18.5085 17.4796C18.5085 18.3779 17.8814 18.9796 16.9407 18.9796H16.4576V16.022H16.9407Z"
        fill="currentColor"
      />
    </Icon>
  );
}
