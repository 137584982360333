import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleClock16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        d="M14.2 8C14.2 11.4242 11.4242 14.2 8 14.2C4.57583 14.2 1.8 11.4242 1.8 8C1.8 4.57583 4.57583 1.8 8 1.8C11.4242 1.8 14.2 4.57583 14.2 8Z"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path d="M8 5V8L10 9" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
