import React from 'react';

import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export const RippleMinus = ({ color = DEFAULT_ICON_COLOR }: RippleIconProps): React.JSX.Element => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
    <rect x="4" y="11" width="16" height="2" rx="1" fill="currentColor" />
  </Icon>
);
