import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleFullScreen({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M5 5L9 9" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path d="M15 15L19 19" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path d="M5 19L9 15" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path d="M15 9L19 5" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path d="M4 16V20H8" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M20 8L20 4L16 4" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M8 4L4 4L4 8" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M16 20L20 20L20 16" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
    </Icon>
  );
}
