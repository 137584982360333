import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleSquareCrossIcon({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="3.5" y="3.5" width="8" height="8" fill="currentColor" />
      <rect x="3.5" y="12.5" width="8" height="8" fill="currentColor" />
      <rect x="12.5" y="3.5" width="8" height="8" fill="currentColor" />
      <rect x="12.5" y="12.5" width="8" height="8" fill="currentColor" />
    </Icon>
  );
}
