import React from 'react';

import { useTranslation } from 'next-i18next';

import useActivatedButton from '@/components/ComputerList/useActivatedButton';

import { RippleCollapse, RippleExpand } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleTooltip } from '../RippleTooltip';

type RippleComputerAccordionButtonProps = {
  isEnabled: boolean;
  showShortcut?: boolean;
  onClick: () => void;
};

export function RippleComputerAccordionButton({ isEnabled, showShortcut, onClick }: RippleComputerAccordionButtonProps) {
  const { t } = useTranslation();

  const label = isEnabled ? t('common:collapse_all') : t('common:expand_all');
  const isActivated = useActivatedButton(isEnabled);

  return (
    <RippleTooltip aria-label={label} label={label} shortcut={showShortcut ? 'G' : undefined} placement="bottom">
      <RippleIconButton
        aria-label={label}
        aria-expanded={isEnabled}
        data-testid="accordion-btn"
        backgroundColor={isActivated ? 'blue.20' : 'transparent'}
        _hover={{ bg: 'blue.10' }}
        p="4px"
        minWidth="initial"
        onClick={onClick}
        icon={isEnabled ? <RippleCollapse /> : <RippleExpand />}
      />
    </RippleTooltip>
  );
}
