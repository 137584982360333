import React from 'react';

import { ListItem, SystemStyleObject } from '@chakra-ui/react';

import { RippleBodyText02 } from '@/design';

import { NumberedTag } from './NumberedTag';

export type RippleNumberedStepProps = {
  tag: React.ReactNode;
  children: React.ReactNode;
  tagSx?: SystemStyleObject;
};

export const RippleNumberedStep = ({ children, tag, tagSx }: RippleNumberedStepProps) => {
  return (
    <ListItem fontSize="14px" display="flex">
      <NumberedTag sx={tagSx}>{tag}</NumberedTag>
      <RippleBodyText02
        as="div"
        pl="8px"
        pt="4px"
        sx={{
          // Make icon as inline element to wrap with text normally
          svg: {
            display: 'inline-block',
            verticalAlign: 'top',
          },
        }}
      >
        {children}
      </RippleBodyText02>
    </ListItem>
  );
};
