import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleRequestPermission({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.5C14.7614 16.5 17 14.2614 17 11.5C17 8.73858 14.7614 6.5 12 6.5C9.23858 6.5 7 8.73858 7 11.5C7 14.2614 9.23858 16.5 12 16.5ZM14.7593 10.6508C15.1187 10.2315 15.0701 9.60017 14.6508 9.24074C14.2315 8.88132 13.6002 8.92988 13.2407 9.34921L10.9435 12.0293L10.2071 11.2929C9.81658 10.9024 9.18342 10.9024 8.79289 11.2929C8.40237 11.6834 8.40237 12.3166 8.79289 12.7071L10.2929 14.2071C10.4899 14.4041 10.76 14.51 11.0384 14.4993C11.3167 14.4886 11.578 14.3623 11.7593 14.1508L14.7593 10.6508Z"
        fill="currentColor"
      />
      <path
        d="M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5V17C21 17.5523 20.5523 18 20 18H15.4599C15.168 18 14.8907 18.1276 14.7007 18.3492L12.7593 20.6142C12.3602 21.0798 11.6398 21.0798 11.2407 20.6142L9.29932 18.3492C9.10934 18.1276 8.83199 18 8.54007 18H4C3.44772 18 3 17.5523 3 17V5Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </Icon>
  );
}
