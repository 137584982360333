import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconActiveProps } from './types';

export function RippleTaskManager({ color = DEFAULT_ICON_COLOR, isActive }: RippleIconActiveProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isActive ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5V19H14.083C14.0284 19.3252 14 19.6593 14 20C14 20.3407 14.0284 20.6748 14.083 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V14.3414C21.3744 14.1203 20.7013 14 20 14V5H4ZM10.0109 7.00006C10.4237 7.00455 10.7914 7.26229 10.9363 7.64888L13.2509 13.8209L14.1679 12.4453C14.3534 12.1671 14.6656 12 15 12H18C18.5523 12 19 12.4477 19 13C19 13.5523 18.5523 14 18 14H15.5352L13.8321 16.5547C13.6239 16.8669 13.2584 17.0364 12.8856 16.9934C12.5128 16.9505 12.1954 16.7025 12.0637 16.3511L9.96992 10.7678L8.92848 13.3714C8.77661 13.751 8.4089 14 8 14H6C5.44772 14 5 13.5523 5 13C5 12.4477 5.44772 12 6 12H7.32297L9.07152 7.62861C9.22486 7.24527 9.59802 6.99557 10.0109 7.00006ZM18 7C17.4477 7 17 7.44772 17 8C17 8.55228 17.4477 9 18 9C18.5523 9 19 8.55228 19 8C19 7.44772 18.5523 7 18 7ZM14 8C14 7.44772 14.4477 7 15 7C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9C14.4477 9 14 8.55228 14 8Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 20C24 22.2091 22.2091 24 20 24C17.7909 24 16 22.2091 16 20C16 17.7909 17.7909 16 20 16C22.2091 16 24 17.7909 24 20ZM22.28 19.7085C22.5924 19.3961 22.5924 18.8896 22.28 18.5772C21.9676 18.2648 21.461 18.2648 21.1486 18.5772L19.4286 20.2972L18.8514 19.72C18.539 19.4076 18.0324 19.4076 17.72 19.72C17.4076 20.0324 17.4076 20.539 17.72 20.8514L18.8629 21.9943C19.1753 22.3067 19.6818 22.3067 19.9943 21.9943L22.28 19.7085Z"
            fill="#14C02F"
          />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 19V5H20V19H4ZM2 4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4ZM10.9363 7.64888C10.7914 7.26229 10.4237 7.00455 10.0109 7.00006C9.59802 6.99557 9.22486 7.24527 9.07152 7.62861L7.32297 12H6C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14H8C8.4089 14 8.77661 13.751 8.92848 13.3714L9.96992 10.7678L12.0637 16.3511C12.1954 16.7025 12.5128 16.9505 12.8856 16.9934C13.2584 17.0364 13.6239 16.8669 13.8321 16.5547L15.5352 14H18C18.5523 14 19 13.5523 19 13C19 12.4477 18.5523 12 18 12H15C14.6656 12 14.3534 12.1671 14.1679 12.4453L13.2509 13.8209L10.9363 7.64888ZM17 8C17 7.44772 17.4477 7 18 7C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9C17.4477 9 17 8.55228 17 8ZM15 7C14.4477 7 14 7.44772 14 8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
}
