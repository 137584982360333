import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const rippleTable: ComponentStyleConfig = {
  parts: ['table', 'thead', 'tbody', 'tr'],
  baseStyle: {
    table: {
      display: 'inline-flex',
      flexDirection: 'column',
      position: 'relative',
    },
    thead: {
      display: 'flex',
      height: '40px',
      minWidth: '100%',
      alignItems: 'flex-start',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'neutral.60',
    },
    tbody: {
      display: 'flex',
      flexDirection: 'column',
      borderColor: 'neutral.60',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      overflow: 'hidden',
      backgroundColor: 'white',
      '.ripple-tr': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'neutral.60',
        _first: {
          borderTop: 'none',
        },
      },
    },
    tr: {
      display: 'flex',
    },
  },
  variants: {
    standard: {
      tr: {
        height: '56px',
      },
    },
    rich: {
      tr: {
        height: '72px',
      },
    },
    compact: {
      tr: {
        height: '40px',
      },
    },
  },
  defaultProps: {
    variant: 'standard',
  },
};

export const rippleTh: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    h: '40px',
    pr: '8px',
    pl: '8px',
  },
  sizes: {
    xxs: {
      width: '64px',
    },
    xs: {
      width: '96px',
    },
    s: {
      width: '128px',
    },
    m: {
      width: '160px',
    },
    l: {
      width: '192px',
    },
    xl: {
      width: '272px',
    },
    xxl: {
      width: '320px',
    },
  },
  defaultProps: {
    size: 'm',
  },
};

export const rippleTd: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    py: '4px',
    pr: '8px',
    pl: '8px',
  },
  sizes: {
    xxs: {
      width: '64px',
    },
    xs: {
      width: '96px',
    },
    s: {
      width: '128px',
    },
    m: {
      width: '160px',
    },
    l: {
      width: '192px',
    },
    xl: {
      width: '272px',
    },
    xxl: {
      width: '320px',
    },
  },
  defaultProps: {
    size: 'm',
  },
};
