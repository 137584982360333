export function createParameterControl<T extends Record<string, string>>(defaultData: T) {
  let data = { ...defaultData };

  return {
    getParameter(name: keyof T): string {
      return data[name];
    },
    setParameter(name: keyof T, value: string): void {
      data[name] = value as T[keyof T];
    },
    reset(): void {
      data = { ...defaultData };
    },
  };
}
