import type { ChangeEvent } from 'react';

export type UseLocalPageSizeProps = {
  pageIndex: number;
  pageSize: number;
  total: number;
  setPageSize: (pageSize: number) => void;
};

// Copy from @/components/SimpleTable/Pagination/useLocalPageSize

function useLocalPageSize(props: UseLocalPageSizeProps) {
  const { pageIndex, pageSize, total, setPageSize } = props;

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const startItem = (pageIndex - 1) * pageSize + 1;
  const tempEndItem = pageIndex * pageSize;
  const endItem = tempEndItem < total ? tempEndItem : total;

  return {
    handleChange,
    startItem,
    endItem,
  };
}

export default useLocalPageSize;
