import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleOneToManyActionsIcon({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M2 10H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6 20H18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 10V13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M21 10V13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 20V22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7 20V22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6 7.5V9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M18 7.5V9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12 17.5V19.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <rect x="8" y="12.5" width="8" height="5" rx="1" stroke="currentColor" strokeWidth="1.5" />
      <rect x="14.5" y="2.5" width="7" height="5" rx="1" stroke="currentColor" strokeWidth="1.5" />
      <rect x="2.5" y="2.5" width="7" height="5" rx="1" stroke="currentColor" strokeWidth="1.5" />
    </Icon>
  );
}
