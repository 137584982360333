import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleAndroidMenu({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" color={color}>
      <path d="M5 8H19" stroke="currentColor" strokeWidth="1.8" />
      <path d="M5 12H19" stroke="currentColor" strokeWidth="1.8" />
      <path d="M5 16H19" stroke="currentColor" strokeWidth="1.8" />
    </Icon>
  );
}
