import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export function RippleWindowsOnline32() {
  const id = useId();
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" fill="none">
      <g clipPath={`url(#${id})`}>
        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DDDDDD" />
        <rect x="7.5" y="7.5" width="8" height="8" fill="#0074CD" />
        <rect x="16.5" y="7.5" width="8" height="8" fill="#0074CD" />
        <rect x="7.5" y="16.5" width="8" height="8" fill="#0074CD" />
        <rect x="16.5" y="16.5" width="8" height="8" fill="#0074CD" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
