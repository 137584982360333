import { MouseEvent, ReactElement } from 'react';

import { Box, SystemStyleObject } from '@chakra-ui/react';

import { RippleClose } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleTypography } from '../RippleTypography';

type RippleChipProps = {
  sx?: SystemStyleObject;
  name: string;
  renderName?: (name: string) => string | ReactElement;
  count?: number;
  onClose?: () => void;
};

export const RippleChip = ({ sx, name, renderName, count, onClose }: RippleChipProps) => {
  const hasCount = count !== undefined;
  const hasCloseBtn = onClose !== undefined;

  const handleClose = onClose
    ? (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onClose();
      }
    : undefined;

  return (
    <Box className="ripple-chip__box" display="inline-flex" alignItems="center" bg="blue.40" borderRadius="9999px" sx={sx}>
      <RippleTypography
        variant="buttonText"
        color="dark.90"
        padding="7px 0 7px 12px"
        py="7px"
        pl="12px"
        pr={!hasCount && !hasCloseBtn ? '12px' : '0'}
        title={name}
      >
        {renderName ? renderName(name) : name}
      </RippleTypography>
      {hasCount && (
        <Box bg="blue.300" my="7px" ml="6px" mr={hasCloseBtn ? '0' : '12px'}>
          <RippleTypography variant="heading09" color="white" borderRadius="4px" px="6px">
            {count}
          </RippleTypography>
        </Box>
      )}
      {hasCloseBtn && (
        <RippleIconButton
          className="ripple-chip__close"
          aria-label={name}
          bg="blue.40"
          _hover={{ bg: 'blue.0' }}
          p="4px"
          borderRadius="50%"
          minWidth="initial"
          ml="4px"
          flexShrink={0}
          onClick={handleClose}
          icon={<RippleClose />}
        />
      )}
    </Box>
  );
};
