import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleAdd16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="11" y="4" width="2" height="16" rx="1" fill="currentColor" />
      <rect x="4" y="11" width="16" height="2" rx="1" fill="currentColor" />
    </Icon>
  );
}
