import { Badge, BadgeProps, forwardRef } from '@chakra-ui/react';

export type RippleBadgeProps = Omit<BadgeProps, 'variant' | 'size' | 'colorScheme'> & {
  variant?: 'primary' | 'secondary';
  size?: 'normal' | 'inline' | 'tiny';
  colorScheme?:
    | 'blue'
    | 'darkBlue'
    | 'success'
    | 'danger'
    | 'disabled'
    | 'grey'
    | 'yellow'
    | 'green'
    | 'magenta'
    | 'violet'
    | 'lightBlue'
    | 'orange'
    | 'new'
    | 'dark';
};

export const RippleBadge = forwardRef<RippleBadgeProps, 'span'>(({ variant, size, colorScheme, ...otherProps }, ref) => {
  return <Badge variant={variant} size={size} textAlign="center" colorScheme={colorScheme} ref={ref} {...otherProps} />;
});
