import { getSBADownloadUrl } from '@/services/common';
import getOS from '@/utils/getOS';

function createDownloadIframe(src: string) {
  if (typeof document !== 'undefined') {
    const iframeTag = 'download-src-iframe';
    const iframeEl = document.getElementById(iframeTag);
    if (iframeEl) {
      iframeEl.parentNode?.removeChild(iframeEl);
    }

    const newIframeEl = document.createElement('iframe');
    newIframeEl.id = iframeTag;
    newIframeEl.height = '0';
    newIframeEl.width = '0';
    newIframeEl.src = src;

    document.body.appendChild(newIframeEl);
  }
}

type downloadSRCOptions = {
  isInIframe?: boolean;
};

function downloadSRC(options?: downloadSRCOptions) {
  const os = getOS();

  let newWindow: Window | null = null;

  switch (os) {
    case 'Mac':
    case 'Windows': {
      const downloadUrl = getSBADownloadUrl(os === 'Mac' ? 'mac' : 'win');
      createDownloadIframe(downloadUrl);
      break;
    }
    case 'iOS':
      setTimeout(() => {
        const iOSUrl = 'https://itunes.apple.com/us/app/splashtop-business/id650739354';
        if (options?.isInIframe) {
          if (window.top) window.top.location.href = iOSUrl;
        } else window.location.href = iOSUrl;
      }, 500);
      break;
    case 'Android':
      newWindow = window.open(
        'https://play.google.com/store/apps/details?id=com.splashtop.remote.business',
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
      break;
    default:
      newWindow = window.open('https://www.splashtop.com/downloads#b', '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      break;
  }
}

export default downloadSRC;
