import { Button, ButtonProps, forwardRef, useStyleConfig } from '@chakra-ui/react';

export type RippleInlineButtonProps = Omit<ButtonProps, 'variant'> & {
  variant?: 'primary' | 'secondary' | 'tertiary';
};

export const RippleInlineButton = forwardRef<RippleInlineButtonProps, 'button'>(({ variant = 'primary', ...otherProps }, ref) => {
  const styles = useStyleConfig('rippleInlineButton', { variant });
  return <Button __css={styles} variant={variant} ref={ref} {...otherProps} />;
});
