import { useEffect, useRef, useState } from 'react';

import { isAfter, isBefore } from 'date-fns';

import { useEvent } from '@/utils/useEvent';

import {
  MonthIndexes,
  canChangeToDate,
  canChangeToMonth,
  canChangeToNextMonth,
  canChangeToNextYear,
  canChangeToPrevMonth,
  canChangeToPrevYear,
  getDayButtons,
  getHourButtons,
  getMinuteButtons,
  nextDateMonth,
  nextDateYear,
  prevDateMonth,
  prevDateYear,
  setDateHours,
  setDateMinutes,
  setDateMonth,
  setDateMonthAndDay,
  setDateYear,
} from './utils';

export type UseDatePickerProps = {
  value: Date | null;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

export function useDatePicker(props: UseDatePickerProps) {
  const { value, onChange, minDate, maxDate } = props;

  let defaultValue = value ? value : new Date();
  defaultValue = minDate && isBefore(defaultValue, minDate) ? minDate : defaultValue;
  defaultValue = maxDate && isAfter(defaultValue, maxDate) ? maxDate : defaultValue;

  const [currentDate, setCurrentDate] = useState<Date>(defaultValue);

  const isFirstRun = useRef(true);
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    if (!isFirstRun.current) {
      onChangeRef.current(currentDate);
    }
    isFirstRun.current = false;
  }, [currentDate]);

  const setYear = useEvent((year: number) => {
    setCurrentDate((value) => {
      return setDateYear(value, year);
    });
  });

  const prevYear = useEvent(() => {
    setCurrentDate((value) => {
      return prevDateYear(value, minDate);
    });
  });

  const nextYear = useEvent(() => {
    setCurrentDate((value) => {
      return nextDateYear(value, maxDate);
    });
  });

  const getPrevYearDisabled = useEvent(() => {
    if (!minDate) {
      return false;
    }

    return !canChangeToPrevYear(currentDate, minDate);
  });

  const getNextYearDisabled = useEvent(() => {
    if (!maxDate) {
      return false;
    }

    return !canChangeToNextYear(currentDate, maxDate);
  });

  const setMonth = useEvent((month: MonthIndexes) => {
    setCurrentDate((value) => {
      return setDateMonth(value, month, minDate, maxDate);
    });
  });

  const prevMonth = useEvent(() => {
    setCurrentDate((value) => {
      return prevDateMonth(value, minDate);
    });
  });

  const nextMonth = useEvent(() => {
    setCurrentDate((value) => {
      return nextDateMonth(value, maxDate);
    });
  });

  const getPrevMonthDisabled = useEvent(() => {
    if (!minDate) {
      return false;
    }

    return !canChangeToPrevMonth(currentDate, minDate);
  });

  const getNextMonthDisabled = useEvent(() => {
    if (!maxDate) {
      return false;
    }

    return !canChangeToNextMonth(currentDate, maxDate);
  });

  const getMonthDisabled = useEvent((month: MonthIndexes) => {
    return !canChangeToMonth(currentDate, month, minDate, maxDate);
  });

  const setMonthAndDay = useEvent((date: Date) => {
    setCurrentDate(() => {
      return setDateMonthAndDay(date, minDate, maxDate);
    });
  });

  const getDateDisabled = useEvent((date: Date) => {
    return !canChangeToDate(date, minDate, maxDate);
  });

  const setToday = useEvent(() => {
    setCurrentDate(new Date());
  });

  const setHours = useEvent((hours: number) => {
    setCurrentDate((value) => {
      return setDateHours(value, hours, minDate, maxDate);
    });
  });

  const setMinutes = useEvent((minutes: number) => {
    setCurrentDate((value) => {
      return setDateMinutes(value, minutes);
    });
  });

  const getDayButtonProps = useEvent(() => {
    return getDayButtons(currentDate);
  });

  const getHourButtonProps = useEvent(() => {
    return getHourButtons(currentDate, minDate, maxDate);
  });

  const getMinuteButtonProps = useEvent(() => {
    return getMinuteButtons(currentDate, minDate, maxDate);
  });

  return {
    currentDate,
    setYear,
    prevYear,
    nextYear,
    getPrevYearDisabled,
    getNextYearDisabled,
    setMonth,
    prevMonth,
    nextMonth,
    getPrevMonthDisabled,
    getNextMonthDisabled,
    getMonthDisabled,
    setMonthAndDay,
    getDateDisabled,
    setToday,
    setHours,
    setMinutes,
    getDayButtonProps,
    getHourButtonProps,
    getMinuteButtonProps,
  };
}
