import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import { RippleIconProps } from './types';

export function RippleTipsIcon({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8395 12.6109C15.3521 13.0171 15 13.588 15 14.2225V15C15 15.5523 14.5523 16 14 16H13.2225H10.7775H10C9.44772 16 9 15.5523 9 15V14.2225C9 13.588 8.64787 13.0171 8.16051 12.6109C6.84022 11.5103 6 9.85325 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 9.85325 17.1598 11.5103 15.8395 12.6109ZM13.0065 14C13.0822 12.7091 13.8072 11.7012 14.5589 11.0746C15.4428 10.3377 16 9.23485 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 9.23485 8.55715 10.3377 9.44113 11.0746C10.1928 11.7012 10.9178 12.7091 10.9935 14H13.0065ZM10 17C9.44772 17 9 17.4477 9 18C9 18.5523 9.44772 19 10 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H10ZM12 22C13.1046 22 14 21.1046 14 20H10C10 21.1046 10.8954 22 12 22ZM12.1848 5.0057C11.7714 4.98018 11.4156 5.29464 11.39 5.70807C11.3645 6.1215 11.679 6.47733 12.0924 6.50285C12.3143 6.51654 12.5303 6.57937 12.7249 6.68679C12.9195 6.79421 13.0878 6.94353 13.2176 7.12396C13.3474 7.3044 13.4355 7.51142 13.4755 7.73007C13.5155 7.94872 13.5064 8.17352 13.4489 8.38823C13.3417 8.78833 13.5791 9.19958 13.9792 9.30679C14.3793 9.41399 14.7906 9.17656 14.8978 8.77646C15.0128 8.34705 15.031 7.89744 14.951 7.46014C14.871 7.02284 14.6948 6.60879 14.4352 6.24793C14.1756 5.88707 13.839 5.58842 13.4498 5.37358C13.0606 5.15874 12.6285 5.03308 12.1848 5.0057Z"
        fill="currentColor"
      />
    </Icon>
  );
}
