import React from 'react';

import { Box, Flex, chakra } from '@chakra-ui/react';
import type { FlexProps, StyleProps } from '@chakra-ui/react';

import { RippleArrowLeft, RippleArrowRight, RippleIconButton } from '@/design';

import useLocalPagination from './useLocalPagination';

// Copy and modified from @/components/SimpleTable/Pagination/PageList

type RipplePaginationProps = FlexProps & {
  pageIndex: number;
  pageCount: number;
  previousPage: () => void;
  nextPage: () => void;
  gotoPage: (page: number) => void;
  siblingCount?: number;
  boundaryCount?: number;
};

export function RipplePagination(props: RipplePaginationProps) {
  const { pageIndex, pageCount, gotoPage, nextPage, previousPage, siblingCount = 1, boundaryCount = 1, ...otherProps } = props;

  const { isFirstPage, isLastPage, pageItemList } = useLocalPagination({
    pageIndex,
    pageCount,
    siblingCount,
    boundaryCount,
  });

  const iconColorDisabled: StyleProps['color'] = 'neutral.80';
  const iconColor: StyleProps['color'] = 'neutral.300';
  const firstIconColor = isFirstPage ? iconColorDisabled : iconColor;
  const lastIconColor = isLastPage ? iconColorDisabled : iconColor;

  return (
    <Flex alignItems="center" {...otherProps}>
      <RippleIconButton
        data-testid="pagination-prev-btn"
        aria-label="Previous Page"
        isDisabled={isFirstPage}
        onClick={previousPage}
        minWidth="initial"
        width="32px"
        height="32px"
        p={0}
        m={0}
        mr="4px"
        backgroundColor="transparent"
        _hover={{ bg: 'blue.20' }}
        icon={<RippleArrowLeft color={firstIconColor} />}
      />
      {pageItemList.map((page) => {
        if (page === 'start-ellipsis' || page === 'end-ellipsis') {
          return (
            <Box key={page} data-testid={`pagination-btn-${page}`} width="32px" height="32px" mr="4px" position="relative">
              <Box
                as="p"
                position="absolute"
                left="50%"
                bottom="6px"
                transform="translateX(-50%)"
                fontSize="0.875rem"
                lineHeight="20px"
                color="neutral.200"
              >
                ...
              </Box>
            </Box>
          );
        }

        const handleClickPage = () => {
          const pageIndex = typeof page === 'string' ? parseInt(page, 10) : page;
          gotoPage(pageIndex - 1);
        };

        const isCurrentPage = page === pageIndex;

        return (
          <RippleIconButton
            key={page}
            aria-label={`page-${page}`}
            data-testid={`pagination-btn-${page}${isCurrentPage ? '-current' : ''}`}
            onClick={handleClickPage}
            minWidth="32px"
            width="initial"
            height="32px"
            m={0}
            mr="4px"
            backgroundColor={isCurrentPage ? 'blue.20' : 'transparent'}
            color={isCurrentPage ? 'blue.100' : 'neutral.200'}
            _hover={{ bg: 'blue.20', color: 'blue.100' }}
            icon={<chakra.span px="4px">{page}</chakra.span>}
          />
        );
      })}
      <RippleIconButton
        data-testid="pagination-next-btn"
        aria-label="Next Page"
        isDisabled={isLastPage}
        onClick={nextPage}
        minWidth="initial"
        width="32px"
        height="32px"
        p={0}
        m={0}
        backgroundColor="transparent"
        _hover={{ bg: 'blue.20' }}
        icon={<RippleArrowRight color={lastIconColor} />}
      />
    </Flex>
  );
}
