export const styles = {
  baseStyle: {
    fontWeight: 600,
  },
  variants: {
    heading01: {
      fontSize: '3.75rem',
      lineHeight: '4.5rem',
      letterSpacing: '-1.6px',
    },
    heading02: {
      fontSize: '3rem',
      lineHeight: '3.5rem',
      letterSpacing: '-1.6px',
    },
    heading03: {
      fontSize: '2.5rem',
      lineHeight: '3rem',
      letterSpacing: '-0.4px',
    },
    heading04: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      letterSpacing: '-0.32px',
    },
    heading05: {
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
      letterSpacing: '-0.24px',
    },
    heading06: {
      fontSize: '1.25rem',
      lineHeight: '1.625rem',
      letterSpacing: '-0.16px',
    },
    heading07: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      letterSpacing: '0px',
    },
    heading08: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '1.125rem',
      letterSpacing: '0px',
    },
    heading09: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      letterSpacing: '0px',
    },
    body01: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      letterSpacing: '0px',
    },
    body02: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      letterSpacing: '0px',
    },
    body03: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      letterSpacing: '0px',
    },
    buttonText: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: '0px',
    },
  },
  defaultProps: {
    variant: 'heading01',
  },
} as const;

export type rippleTypographyName = typeof rippleTypography;

export const rippleTypography = styles;
