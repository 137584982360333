import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleAndroidBack({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" color={color}>
      <path d="M18 19.5V4.5L5 12L18 19.5Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    </Icon>
  );
}
