import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleRemoteSessionGranted({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  const id = useId();

  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <g clipPath={`url(#${id})`}>
        <path d="M10 13V7L14.875 10L10 13Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 17H11V19H8C7.44771 19 7 19.4477 7 20C7 20.5523 7.44771 21 8 21H14.083C14.0284 20.6748 14 20.3407 14 20C14 19.6593 14.0284 19.3252 14.083 19H13V17H14.8027C15.2671 16.1972 15.9121 15.512 16.6822 15H5V5H19V14.083C19.3252 14.0284 19.6593 14 20 14C20.3407 14 20.6748 14.0284 21 14.083V4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V16C3 16.5523 3.44772 17 4 17Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 20C24 22.2091 22.2091 24 20 24C17.7909 24 16 22.2091 16 20C16 17.7909 17.7909 16 20 16C22.2091 16 24 17.7909 24 20ZM22.28 19.7085C22.5924 19.3961 22.5924 18.8896 22.28 18.5772C21.9676 18.2648 21.461 18.2648 21.1486 18.5772L19.4286 20.2972L18.8514 19.72C18.539 19.4076 18.0324 19.4076 17.72 19.72C17.4076 20.0324 17.4076 20.539 17.72 20.8514L18.8629 21.9943C19.1753 22.3067 19.6818 22.3067 19.9943 21.9943L22.28 19.7085Z"
          fill="#14C02F"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
