import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleEndpointSecurityDashboard({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M20.5 6.74354C20.5 5.32837 19.511 4.10566 18.1271 3.80982L12.4181 2.58938C12.1425 2.53046 11.8575 2.53046 11.5819 2.58938L5.87285 3.80982C4.48895 4.10566 3.5 5.32837 3.5 6.74354V11C3.5 16.2683 10.1111 21 12 21C13.8889 21 20.5 16.2683 20.5 11V6.74354Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M17.5 7.7638C17.5 7.31284 17.1982 6.91769 16.7631 6.79903L12.2631 5.57176C12.0909 5.52478 11.9091 5.52478 11.7369 5.57176L7.23688 6.79903C6.80182 6.91769 6.5 7.31284 6.5 7.7638L6.5 11C6.5 14 10.8796 17.5 12 17.5C13.1204 17.5 17.5 14 17.5 11V7.7638Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path d="M14.5 9.5L11.5 12.5L10 11" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
