import { useRouter } from 'next/router';
import React, { createContext, useContext } from 'react';

import { Box, Flex, Stack } from '@chakra-ui/react';

import { RippleClose } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleBodyText02, RippleHeading04 } from '../RippleText';

const ACTION_BAR_HEIGHT = '74px';

export type RippleSheetProps = {
  children?: React.ReactNode;
  title: string;
  description?: string;
  contentWidth?: `${number}px`;
  isDisabled?: boolean;
  handleClose?: () => void;
};

export type RippleSheetAction = {
  children?: React.ReactNode;
};

const RippleSheetContext = createContext<{ contentWidth: string }>({} as never);

export function RippleSheet({
  children,
  title,
  description,
  contentWidth = '1140px',
  isDisabled = false,
  handleClose,
}: Readonly<RippleSheetProps>): React.JSX.Element {
  const router = useRouter();

  function onClose(): void {
    handleClose ? handleClose() : router.back();
  }

  return (
    <RippleSheetContext.Provider value={{ contentWidth }}>
      <Flex bg="white" position="absolute" left="0" top="0" w="100%" minH="100vh" justifyContent="center">
        <Box maxW={contentWidth} w="100%" pb="200px" h="100%" mx="16px">
          <Box w={contentWidth}>
            <Stack spacing="8px" my="32px" maxW="100vw" position="sticky" left="0px">
              <Flex direction="row" justifyContent="space-between" alignItems="center">
                <RippleHeading04>{title}</RippleHeading04>
                <RippleIconButton aria-label="close" icon={<RippleClose />} onClick={onClose} isDisabled={isDisabled} />
              </Flex>
              {description && <RippleBodyText02 color="neutral.300">{description}</RippleBodyText02>}
            </Stack>
          </Box>
          {children}
        </Box>
      </Flex>
    </RippleSheetContext.Provider>
  );
}

export function RippleSheetAction({ children }: RippleSheetAction) {
  const { contentWidth } = useContext(RippleSheetContext);

  return (
    <Flex
      position="fixed"
      left="0"
      bottom="0"
      h={ACTION_BAR_HEIGHT}
      width="100vw"
      justifyContent="center"
      borderTop="1px solid"
      borderTopColor="neutral.60"
      bgColor="white"
    >
      <Stack maxW={contentWidth} w="100%" mx="16px" direction="row" alignItems="center" justifyContent="end">
        {children}
      </Stack>
    </Flex>
  );
}
