import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleVNCConnected = () => {
  const filter1 = useId();
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const clipPath1 = useId();
  const clipPath2 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <g clipPath={`url(#${clipPath1})`}>
        <g filter={`url(#${filter1})`}>
          <rect x={4} y={4} width={32} height={32} rx={4} fill="white" />
          <rect x={5} y={5} width={30} height={30} rx={3} stroke={`url(#${linearGradient1})`} strokeWidth={2} />
        </g>
        <g clipPath={`url(#${clipPath2})`}>
          <path
            d="M19.5125 19.7133C19.75 19.8508 19.945 20.1883 19.945 20.4633L19.9625 29.4558C19.9625 29.7308 19.7675 29.8433 19.53 29.7058L11.7325 25.2058C11.495 25.0683 11.3 24.7308 11.3 24.4558V15.4508C11.3 15.1758 11.495 15.0633 11.7325 15.2008L19.5125 19.7133Z"
            fill="#0F00B9"
          />
          <path
            d="M16.0651 12.7086C15.8276 12.5711 15.6326 12.6836 15.6326 12.9586V14.4011C15.6326 14.6761 15.8276 15.0111 16.0676 15.1486L21.7851 18.3961C22.0251 18.5311 22.2201 18.8686 22.2201 19.1436L22.2251 25.7536C22.2251 26.0286 22.4201 26.3661 22.6576 26.5036L23.8626 27.1986C24.1001 27.3361 24.2951 27.2236 24.2951 26.9486L24.2776 17.9561C24.2776 17.6811 24.0826 17.3436 23.8426 17.2061L16.0651 12.7086Z"
            fill="#0F00B9"
          />
          <path
            d="M20.3976 10.2086C20.1601 10.0711 19.9651 10.1836 19.9651 10.4586V11.8986C19.9651 12.1736 20.1601 12.5086 20.4001 12.6461L26.1176 15.8936C26.3576 16.0286 26.5526 16.3661 26.5526 16.6411L26.5576 23.2511C26.5576 23.5261 26.7526 23.8636 26.9901 24.0011L28.1951 24.6961C28.4326 24.8336 28.6276 24.7211 28.6276 24.4461L28.6101 15.4536C28.6101 15.1786 28.4151 14.8411 28.1751 14.7036L20.3976 10.2086Z"
            fill="#0F00B9"
          />
        </g>
        <circle cx={31} cy={31} r={7} fill={`url(#${linearGradient2})`} stroke="white" strokeWidth={2} />
        <rect x={29} y="30.25" width={4} height="1.5" fill="white" />
        <path
          d="M33 28.4828V33.5172C33 33.6953 33.2154 33.7846 33.3414 33.6586L35.8586 31.1414C35.9367 31.0633 35.9367 30.9367 35.8586 30.8586L33.3414 28.3414C33.2154 28.2154 33 28.3047 33 28.4828Z"
          fill="white"
        />
        <path
          d="M29 28.4828V33.5172C29 33.6953 28.7846 33.7846 28.6586 33.6586L26.1414 31.1414C26.0633 31.0633 26.0633 30.9367 26.1414 30.8586L28.6586 28.3414C28.7846 28.2154 29 28.3047 29 28.4828Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id={filter1} x={-1} y={-1} width={42} height={42} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9874_75725" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={-1} dy={-1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.572549 0 0 0 0 0.92549 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9874_75725" result="effect2_dropShadow_9874_75725" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9874_75725" result="shape" />
        </filter>
        <linearGradient id={linearGradient1} x1={4} y1={11} x2={32} y2={36} gradientUnits="userSpaceOnUse">
          <stop offset="0.208275" stopColor="#AC59FF" />
          <stop offset={1} stopColor="#036DEA" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="37.4167" y1={38} x2="25.1663" y2="24.8754" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
        <clipPath id={clipPath1}>
          <rect width={40} height={40} fill="white" />
        </clipPath>
        <clipPath id={clipPath2}>
          <rect width={18} height={20} fill="white" transform="translate(11 10)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export const RippleVNCOffline = () => {
  const clipPath1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" />
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" stroke="#DDDDDD" />
      <g clipPath={`url(#${clipPath1})`}>
        <path
          d="M19.5125 19.7133C19.75 19.8508 19.945 20.1883 19.945 20.4633L19.9625 29.4558C19.9625 29.7308 19.7675 29.8433 19.53 29.7058L11.7325 25.2058C11.495 25.0683 11.3 24.7308 11.3 24.4558V15.4508C11.3 15.1758 11.495 15.0633 11.7325 15.2008L19.5125 19.7133Z"
          fill="#B0B0B0"
        />
        <path
          d="M16.0651 12.7086C15.8276 12.5711 15.6326 12.6836 15.6326 12.9586V14.4011C15.6326 14.6761 15.8276 15.0111 16.0676 15.1486L21.7851 18.3961C22.0251 18.5311 22.2201 18.8686 22.2201 19.1436L22.2251 25.7536C22.2251 26.0286 22.4201 26.3661 22.6576 26.5036L23.8626 27.1986C24.1001 27.3361 24.2951 27.2236 24.2951 26.9486L24.2776 17.9561C24.2776 17.6811 24.0826 17.3436 23.8426 17.2061L16.0651 12.7086Z"
          fill="#B0B0B0"
        />
        <path
          d="M20.3976 10.2086C20.1601 10.0711 19.9651 10.1836 19.9651 10.4586V11.8986C19.9651 12.1736 20.1601 12.5086 20.4001 12.6461L26.1176 15.8936C26.3576 16.0286 26.5526 16.3661 26.5526 16.6411L26.5576 23.2511C26.5576 23.5261 26.7526 23.8636 26.9901 24.0011L28.1951 24.6961C28.4326 24.8336 28.6276 24.7211 28.6276 24.4461L28.6101 15.4536C28.6101 15.1786 28.4151 14.8411 28.1751 14.7036L20.3976 10.2086Z"
          fill="#B0B0B0"
        />
      </g>
      <defs>
        <clipPath id={clipPath1}>
          <rect width={18} height={20} fill="white" transform="translate(11 10)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export const RippleVNCOnline = () => {
  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" />
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" stroke="#DFE4E9" />
      <path
        d="M19.5125 19.7134C19.75 19.8509 19.945 20.1884 19.945 20.4634L19.9625 29.4559C19.9625 29.7309 19.7675 29.8434 19.53 29.7059L11.7325 25.2059C11.495 25.0684 11.3 24.7309 11.3 24.4559V15.4509C11.3 15.1759 11.495 15.0634 11.7325 15.2009L19.5125 19.7134Z"
        fill="#0F00B9"
      />
      <path
        d="M16.0651 12.7087C15.8276 12.5712 15.6326 12.6837 15.6326 12.9587V14.4012C15.6326 14.6762 15.8276 15.0112 16.0676 15.1487L21.7851 18.3962C22.0251 18.5312 22.2201 18.8687 22.2201 19.1437L22.2251 25.7537C22.2251 26.0287 22.4201 26.3662 22.6576 26.5037L23.8626 27.1987C24.1001 27.3362 24.2951 27.2237 24.2951 26.9487L24.2776 17.9562C24.2776 17.6812 24.0826 17.3437 23.8426 17.2062L16.0651 12.7087Z"
        fill="#0F00B9"
      />
      <path
        d="M20.3976 10.2087C20.1601 10.0712 19.9651 10.1837 19.9651 10.4587V11.8987C19.9651 12.1737 20.1601 12.5087 20.4001 12.6462L26.1176 15.8937C26.3576 16.0287 26.5526 16.3662 26.5526 16.6412L26.5576 23.2512C26.5576 23.5262 26.7526 23.8637 26.9901 24.0012L28.1951 24.6962C28.4326 24.8337 28.6276 24.7212 28.6276 24.4462L28.6101 15.4537C28.6101 15.1787 28.4151 14.8412 28.1751 14.7037L20.3976 10.2087Z"
        fill="#0F00B9"
      />
    </Icon>
  );
};
