import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RipplePolicy({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M16 21H17C18.1045 21 19 20.1046 19 19V5C19 4.50265 19.2226 3.63428 20 3.22526M20 3H7C6.33333 3 5 3.4 5 5V17"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M9 7H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 10H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 13L13 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2 19C2 20.1046 2.89543 21 4 21H16C15.3333 21 14 20.6 14 19C14 17.4 15.3333 17 16 17H4C2.89543 17 2 17.8954 2 19Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 7C21.1046 7 22 6.10457 22 5C22 3.89543 21.1046 3 20 3" stroke="currentColor" strokeWidth="2" />
    </Icon>
  );
}
