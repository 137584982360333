import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleNextMonitor({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M20 14V18H17.9143L18.7071 17.2072L17.2929 15.793L14.7929 18.293C14.6054 18.4805 14.5 18.7349 14.5 19.0001C14.5 19.2653 14.6054 19.5196 14.7929 19.7072L17.2929 22.2072L18.7071 20.793L17.9141 20H21C21.5523 20 22 19.5523 22 19V14H20Z"
        fill="currentColor"
      />
      <path
        d="M9 5V6H7V4C7 3.44772 7.44772 3 8 3H19C19.5523 3 20 3.44772 20 4V12C20 12.5523 19.5523 13 19 13H16V11H18V5H9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C2.44772 7 2 7.44772 2 8V16C2 16.5523 2.44772 17 3 17H7.5V19H5.5C4.94772 19 4.5 19.4477 4.5 20C4.5 20.5523 4.94772 21 5.5 21H11.5C12.0523 21 12.5 20.5523 12.5 20C12.5 19.4477 12.0523 19 11.5 19H9.5V17H14C14.5523 17 15 16.5523 15 16V8C15 7.44772 14.5523 7 14 7H3ZM4 9V15H13V9H4Z"
        fill="currentColor"
      />
    </Icon>
  );
}
