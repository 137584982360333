import { useTranslation } from 'next-i18next';

import { RippleFilter } from '../RippleIcon';
import { RippleMenu, RippleMenuIconButton, RippleMenuItemWithSwitch, RippleMenuList } from '../RippleMenu';
import { RippleTooltip } from '../RippleTooltip';

type RippleComputerFilterActionsProps = {
  showSelectedOnly: boolean;
  onToggleShowSelectedOnly: () => void;
};

export function RippleComputerFilterActions({ showSelectedOnly, onToggleShowSelectedOnly }: RippleComputerFilterActionsProps) {
  const { t } = useTranslation();
  const isAppliedFilters = showSelectedOnly;
  const iconColor = isAppliedFilters ? 'blue.300' : 'neutral.300';
  const buttonLabel = isAppliedFilters ? t('computer:selector.filtersApplied') : t('common:filter');

  return (
    <RippleMenu closeOnBlur closeOnSelect={false} placement="bottom">
      <RippleTooltip aria-label={buttonLabel} label={buttonLabel} placement="bottom">
        <RippleMenuIconButton
          aria-label={buttonLabel}
          data-testid="filter-button"
          width="32px"
          minWidth="32px"
          height="32px"
          borderRadius="4px"
          backgroundColor={isAppliedFilters ? 'blue.20' : 'transparent'}
          _hover={{ bg: 'blue.10' }}
          icon={<RippleFilter color={iconColor} isApplied={isAppliedFilters} />}
        />
      </RippleTooltip>
      <RippleMenuList width="336px" zIndex={5}>
        <RippleMenuItemWithSwitch data-testid="show-selected-only" isChecked={showSelectedOnly} onChange={onToggleShowSelectedOnly}>
          {t('computer:selector.showSelectedOnly')}
        </RippleMenuItemWithSwitch>
      </RippleMenuList>
    </RippleMenu>
  );
}
