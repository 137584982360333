/**
 * Basic Ripple Table component
 * Those component is always align with our design system
 * You can easily use it directly or integrate with other library like react-table, virtuoso, etc.
 */
import { Flex, FlexProps, createStylesContext, forwardRef, useMultiStyleConfig, useStyleConfig } from '@chakra-ui/react';
import type { SortDirection } from '@tanstack/react-table';

import { RippleInlineAscending, RippleInlineDescending } from '../RippleIcon';
import { RippleTypography } from '../RippleTypography';
import { RippleColumnSize } from './constants';

const [StylesProvider, useStyles] = createStylesContext('rippleTable');

export type RippleTableProps = FlexProps & {
  /** The variant of the row height, can be 'rich', 'standard', or 'compact'. */
  variant?: 'rich' | 'standard' | 'compact';
  /** The color scheme of the table header, can be 'grey' or 'white'. */
  colorScheme?: 'grey' | 'white';
};
export const RippleTable = forwardRef<RippleTableProps, 'table'>(
  ({ children, variant, colorScheme = 'grey', ...props }: RippleTableProps, ref) => {
    const styles = useMultiStyleConfig('rippleTable', { variant });

    return (
      <Flex
        ref={ref}
        as="div"
        className="ripple-table"
        __css={styles.table}
        sx={{
          '& .ripple-table-thead': {
            backgroundColor: colorScheme === 'white' ? 'white' : 'neutral.10',
          },
        }}
        {...props}
      >
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Flex>
    );
  },
);

export const RippleTHead = forwardRef((props: FlexProps, ref) => {
  const styles = useStyles();
  return <Flex ref={ref} as="div" className="ripple-table-thead" __css={styles.thead} {...props} />;
});

export type RippleTBodyProps = FlexProps;
export const RippleTBody = forwardRef((props: RippleTBodyProps, ref) => {
  const styles = useStyles();
  return <Flex ref={ref} as="div" className="ripple-tbody" __css={styles.tbody} {...props} />;
});

export type RippleTRProps = FlexProps & {
  isSelected?: boolean;
};
export const RippleTR = forwardRef(({ isSelected, ...props }: RippleTRProps, ref) => {
  const styles = useStyles();
  return (
    <Flex
      ref={ref}
      as="div"
      className="ripple-tr"
      __css={styles.tr}
      transition="background-color 160ms"
      sx={{
        '.ripple-tbody &': {
          backgroundColor: isSelected ? 'green.10' : 'white',
          '&:hover': {
            backgroundColor: isSelected ? 'green.40' : 'blue.0',
          },
        },
      }}
      {...props}
    />
  );
});

export type RippleTHProps = FlexProps & {
  size?: RippleColumnSize;
  isSortable?: boolean;
  sortDirection?: SortDirection | false;
};
export const RippleTH = forwardRef(({ children, size, isSortable, sortDirection, ...props }: RippleTHProps, ref) => {
  const styles = useStyleConfig('rippleTh', { size });
  if (isSortable) {
    return (
      <Flex ref={ref} as="div" className="ripple-th" __css={styles} cursor="pointer" {...props}>
        <RippleTypography as="span" variant="heading09" color="neutral.300">
          {children}
        </RippleTypography>
        {sortDirection === 'desc' && <RippleInlineDescending color="blue.200" />}
        {sortDirection === 'asc' && <RippleInlineAscending color="blue.200" />}
      </Flex>
    );
  }
  return (
    <Flex ref={ref} as="div" className="ripple-th" __css={styles} {...props}>
      <RippleTypography as="span" variant="heading09" color="neutral.300">
        {children}
      </RippleTypography>
    </Flex>
  );
});

export type RippleTDProps = FlexProps & {
  size?: RippleColumnSize;
};
export const RippleTD = forwardRef(({ size = 'm', ...props }: RippleTDProps, ref) => {
  const styles = useStyleConfig('rippleTd', { size });
  return <Flex ref={ref} as="div" className="ripple-td" __css={styles} {...props} />;
});

export type RippleTableGroupHeadProps = FlexProps & {
  isSelected?: boolean;
};
export const RippleTableGroupHead = forwardRef(({ isSelected = false, ...props }: RippleTableGroupHeadProps, ref) => (
  <Flex ref={ref} as="div" h="40px" alignItems="center" background={isSelected ? 'green.20' : 'neutral.40'} {...props} />
));
