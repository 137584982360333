import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleVncIcon({ color = '#0F00B9' }: RippleIconProps) {
  const clipPath1 = useId();
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <g clipPath={`url(#${clipPath1})`}>
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DFE4E9" />
        <path
          d="M11.552 11.6373C11.7499 11.7519 11.9124 12.0331 11.9124 12.2623L11.927 19.756C11.927 19.9852 11.7645 20.079 11.5666 19.9644L5.06867 16.2144C4.87075 16.0998 4.70825 15.8185 4.70825 15.5894V8.08524C4.70825 7.85607 4.87075 7.76232 5.06867 7.87691L11.552 11.6373Z"
          fill="currentColor"
        />
        <path
          d="M8.67926 5.80012C8.48135 5.68554 8.31885 5.77929 8.31885 6.00846V7.21054C8.31885 7.43971 8.48135 7.71887 8.68135 7.83346L13.4459 10.5397C13.6459 10.6522 13.8084 10.9334 13.8084 11.1626L13.8126 16.6709C13.8126 16.9001 13.9751 17.1813 14.173 17.2959L15.1772 17.8751C15.3751 17.9897 15.5376 17.8959 15.5376 17.6667L15.523 10.173C15.523 9.94387 15.3605 9.66262 15.1605 9.54804L8.67926 5.80012Z"
          fill="currentColor"
        />
        <path
          d="M12.2896 3.71675C12.0917 3.60217 11.9292 3.69592 11.9292 3.92508V5.12508C11.9292 5.35425 12.0917 5.63342 12.2917 5.748L17.0563 8.45425C17.2563 8.56675 17.4188 8.848 17.4188 9.07717L17.4229 14.5855C17.4229 14.8146 17.5854 15.0959 17.7834 15.2105L18.7875 15.7896C18.9854 15.9042 19.1479 15.8105 19.1479 15.5813L19.1334 8.08758C19.1334 7.85842 18.9709 7.57717 18.7709 7.46258L12.2896 3.71675Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipPath1}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
