import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export type RippleGripDotsProps = RippleIconProps & {
  size?: 'sm' | 'md';
};
export function RippleGripDots({ color = DEFAULT_ICON_COLOR, size = 'md' }: RippleGripDotsProps) {
  if (size === 'sm') {
    return (
      <Icon width="12px" height="24px" viewBox="0 0 12 24" fill="none" color={color}>
        <circle cx="3" cy="4" r="1" fill="currentColor" />
        <circle cx="3" cy="9" r="1" fill="currentColor" />
        <circle cx="3" cy="14" r="1" fill="currentColor" />
        <circle cx="3" cy="19" r="1" fill="currentColor" />
        <circle cx="7" cy="4" r="1" fill="currentColor" />
        <circle cx="7" cy="9" r="1" fill="currentColor" />
        <circle cx="7" cy="14" r="1" fill="currentColor" />
        <circle cx="7" cy="19" r="1" fill="currentColor" />
      </Icon>
    );
  }
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <circle cx="6" cy="4" r="1" fill="currentColor" />
      <circle cx="6" cy="9" r="1" fill="currentColor" />
      <circle cx="6" cy="14" r="1" fill="currentColor" />
      <circle cx="6" cy="19" r="1" fill="currentColor" />
      <circle cx="12" cy="4" r="1" fill="currentColor" />
      <circle cx="12" cy="9" r="1" fill="currentColor" />
      <circle cx="12" cy="14" r="1" fill="currentColor" />
      <circle cx="12" cy="19" r="1" fill="currentColor" />
      <circle cx="18" cy="4" r="1" fill="currentColor" />
      <circle cx="18" cy="9" r="1" fill="currentColor" />
      <circle cx="18" cy="14" r="1" fill="currentColor" />
      <circle cx="18" cy="19" r="1" fill="currentColor" />
    </Icon>
  );
}
