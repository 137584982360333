import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RipplePassword({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M11 8H13C14.6569 8 16 9.34315 16 11V14H8V11C8 9.34315 9.34315 8 11 8Z" stroke="currentColor" strokeWidth="2" />
      <path d="M6 14H18V22.8182C18 23.5546 17.4722 24 17 24H7C6.52784 24 6 23.5546 6 22.8182V14Z" stroke="currentColor" strokeWidth="2" />
      <rect x="11" y="17" width="2" height="4" rx="1" fill="currentColor" />
    </Icon>
  );
}
