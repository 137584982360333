import { Accordion, AccordionButton, AccordionItem, AccordionItemProps, AccordionPanel, AccordionProps, Box } from '@chakra-ui/react';

import { RippleCaretCollapsed, RippleCaretExpanded } from '@/design';

import { SIZE_MAP_MIN_HEIGHT, SIZE_MAP_PADDING } from './constant';

export type RippleAccordionItemProps = AccordionItemProps & {
  size?: 'sm' | 'md';
  header: React.ReactNode;
  children: React.ReactNode;
};

export const RippleAccordionItem = ({ header, size = 'md', children, ...otherProps }: RippleAccordionItemProps): React.JSX.Element => {
  return (
    <AccordionItem borderColor="neutral.60" minH={SIZE_MAP_MIN_HEIGHT[size]} {...otherProps}>
      {({ isExpanded }) => (
        <>
          <AccordionButton p={SIZE_MAP_PADDING[size]} alignItems="center">
            {isExpanded ? <RippleCaretExpanded /> : <RippleCaretCollapsed />}
            <Box fontWeight="600" ml="4px">
              {header}
            </Box>
          </AccordionButton>
          <AccordionPanel pt="0px" pl="40px" pr="16px" pb="16px">
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export const RippleAccordion = ({ children, variant = 'border', defaultIndex = [0], ...otherProps }: AccordionProps): React.JSX.Element => {
  return (
    <Accordion bg="white" variant={variant} defaultIndex={defaultIndex} {...otherProps}>
      {children}
    </Accordion>
  );
};
