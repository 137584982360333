import type { ComponentStyleConfig } from '@chakra-ui/theme';

const completedColor = 'blue.300';
const activeColor = 'blue.100';
const incompleteColor = 'neutral.80';

const styles: ComponentStyleConfig = {
  parts: ['stepper', 'step', 'title', 'description', 'indicator', 'icon', 'number'],
  baseStyle: {
    stepper: {
      width: 'max-content',
      alignItems: 'flex-start !important',
    },
    step: {
      minWidth: '184px',
      alignItems: 'flex-start  !important',
      padding: '7px 4px 0 0',
      flex: '1 !important',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '-3px',
        left: 0,
        width: '100%',
        height: '3px',
        borderRadius: '3px',
        backgroundColor: incompleteColor,
        transition: 'backgroundColor .2s ease',
      },
      "&[data-status='complete']:before": {
        backgroundColor: completedColor,
      },
      "&[data-status='active']:before": {
        backgroundColor: activeColor,
      },
    },
    indicator: {
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: incompleteColor,
      transition: 'background .2s ease',
      border: 'none',
      "&[data-status='complete']": {
        background: completedColor,
      },
      "&[data-status='active']": {
        background: activeColor,
      },
    },
    number: {
      fontSize: '12px',
      color: 'white',
      fontWeight: 600,
      lineHeight: '18px',
    },
    title: {
      fontSize: '14px',
      lineHeight: '20px',
      color: incompleteColor,
      "&[data-status='complete']": {
        color: completedColor,
      },
      "&[data-status='active']": {
        color: activeColor,
      },
    },
    description: {
      fontSize: '12px',
      color: 'neutral.300',
    },
  },
};

export const rippleStepper = styles;
