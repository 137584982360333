import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowDown({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2318 8.85984C5.58537 8.43556 6.21593 8.37824 6.64021 8.7318L12 13.1983L17.3598 8.7318C17.7841 8.37824 18.4147 8.43556 18.7682 8.85984C19.1218 9.28412 19.0645 9.91468 18.6402 10.2682L12.6402 15.2682C12.2694 15.5773 11.7307 15.5773 11.3598 15.2682L5.35984 10.2682C4.93556 9.91468 4.87824 9.28412 5.2318 8.85984Z"
        fill="currentColor"
      />
    </Icon>
  );
}
