import { useState } from 'react';

import { useTranslation } from 'next-i18next';

import { RippleMenu, RippleMenuButtonGhost, RippleMenuItemSingleSelect, RippleMenuList } from '../RippleMenu';

export const COMPUTER_VIEWS = {
  COMPUTER: 'COMPUTER',
  GROUP: 'GROUP',
} as const;
type ComputerViewKeys = keyof typeof COMPUTER_VIEWS;
export type ComputerViewValues = (typeof COMPUTER_VIEWS)[ComputerViewKeys];

export const useRippleComputerViewDropdown = () => {
  const [currentComputerView, setCurrentComputerView] = useState<ComputerViewValues>(COMPUTER_VIEWS.COMPUTER);

  return {
    currentComputerView,
    setCurrentComputerView,
  };
};

export const RippleComputerViewDropdown = ({
  currentComputerView,
  setCurrentComputerView,
}: {
  currentComputerView: ComputerViewValues;
  setCurrentComputerView: (key: ComputerViewValues) => void;
}) => {
  const { t } = useTranslation();
  const currentComputerViewName = (() => {
    if (currentComputerView === COMPUTER_VIEWS.COMPUTER) {
      return t('computer:computerView');
    } else if (currentComputerView === COMPUTER_VIEWS.GROUP) {
      return t('computer:groupView');
    }
  })();

  return (
    <RippleMenu>
      {({ isOpen }) => {
        return (
          <>
            <RippleMenuButtonGhost isOpen={isOpen}>{currentComputerViewName}</RippleMenuButtonGhost>
            <RippleMenuList zIndex={5}>
              <RippleMenuItemSingleSelect
                isSelected={currentComputerView === COMPUTER_VIEWS.COMPUTER}
                onClick={() => setCurrentComputerView(COMPUTER_VIEWS.COMPUTER)}
              >
                {t('computer:computerView')}
              </RippleMenuItemSingleSelect>
              <RippleMenuItemSingleSelect
                isSelected={currentComputerView === COMPUTER_VIEWS.GROUP}
                onClick={() => setCurrentComputerView(COMPUTER_VIEWS.GROUP)}
              >
                {t('computer:groupView')}
              </RippleMenuItemSingleSelect>
            </RippleMenuList>
          </>
        );
      }}
    </RippleMenu>
  );
};
