import * as Sentry from '@sentry/nextjs';
import axios, { type AxiosInstance, type AxiosResponse } from 'axios';
import { z } from 'zod';

import { LS_KEY_PCP_CSRF_TOKEN } from '@/authentication';
import { LOCAL_ONLY, PRODUCTION_ONLY } from '@/feature/toggle/condition';

import { SUCCESS_RESULT } from './constants';
import {
  exceededLimitErrorSchema,
  memberDisabledErrorSchema,
  notMatchAnyProductErrorSchema,
  resourceNotExistErrorSchema,
  roleNotSupportedErrorSchema,
  successResponseSchema,
  teamSeatExpiredErrorSchema,
  twoStepVerificationErrorSchema,
  userHasNoTeamErrorSchema,
  wrongParamsErrorSchema,
} from './schemas';

export function isSuccess(result: number) {
  return result === SUCCESS_RESULT;
}

/**
 * Check if the result is an expected error which means the request may not have the necessary permission or the resource is not found.
 *
 * Most of the time, the error code starts with `4` (4xxxx).
 *
 * Usually we don't need to log this kind of error to Sentry.
 *
 * @example `40404` is a common error code for most web API to indicate the resource is not found.
 */
function isExpectedError(responseData: any) {
  const expectedErrorSchema = z.object({
    result: z.number().refine((result) => result >= 40000 && result < 50000),
  });

  return expectedErrorSchema.safeParse(responseData).success;
}

/**
 * Use it when the API no need to auth.
 * Should NOT add interceptor on it!
 */
export const pureInstance = axios.create() as Omit<AxiosInstance, 'interceptors'>;
pureInstance.defaults.withCredentials = false;

/**
 * Mainly used for mocking API in testing / storybook environment.
 */
export function generateSuccessResponse<T>(data: T): z.infer<typeof successResponseSchema> {
  return {
    result: SUCCESS_RESULT,
    data,
    messages: [],
  };
}

/**
 * 檢查和處理特定的 Axios 請求。
 * @param {Promise<AxiosResponse>} axiosRequest - 待處理的 Axios 請求。
 * @param {z.ZodType<T>} schema - 用於驗證回應數據的 Zod 結構。
 * @returns {Promise<T>} 處理並驗證過的數據。
 */
export function checkResponse<T>(axiosRequest: Promise<AxiosResponse>, schema: z.ZodType<T>): Promise<T> {
  return axiosRequest
    .then((response) => validateResponse(response, schema))
    .catch((error) => {
      // 只有當 error 是 Error 物件時才記錄到 Sentry
      // 預期的情況是 HTTP status code 非 2XX
      if (error instanceof Error) {
        handleError(error);
      }
      return Promise.reject(error);
    });
}

/**
 * 驗證 Axios 回應是否符合預定的結構。
 * @param {AxiosResponse} response - 從 Axios 獲得的回應。
 * @param {z.ZodType<T>} schema - 用於驗證數據的 Zod 結構。
 * @returns {T | Promise<never>} 返回解析後的數據或在出現錯誤時拒絕 Promise。
 */
function validateResponse<T>(response: AxiosResponse, schema: z.ZodType<T>): T | Promise<never> {
  const parsedResponse = successResponseSchema.safeParse(response.data);
  if (!parsedResponse.success) {
    // 如果回應不符合預定的結構，記錄錯誤並拒絕 Promise

    if (isExpectedError(response.data)) {
      // 預期的錯誤，不記錄錯誤
      console.error(response);
    } else {
      handleError(new Error('Response does not match SuccessResponseSchema'), { response });
    }

    return Promise.reject(response.data);
  }

  const validationResult = schema.safeParse(parsedResponse.data.data);
  if (!validationResult.success) {
    // 如果回應數據不符合 schema，記錄錯誤
    handleError(new Error('Response data does not match schema'), {
      response,
      originalData: parsedResponse.data.data,
      errorDetails: validationResult.error,
    });

    // Production 仍然回傳 response data 是希望前端在缺少部分資料時仍然可正常運作
    // 在實作中要使用 ErrorBoundary 來避免整個 app 崩潰
    if (PRODUCTION_ONLY) return parsedResponse.data.data as T;
    return Promise.reject(parsedResponse.data.data);
  }

  return validationResult.data;
}

/**
 * 處理和記錄錯誤。
 * @param {Error} error 捕獲到的錯誤物件。
 * @param {any} [extra] 錯誤處理時的額外資訊。
 */
function handleError(error: Error, extra?: any) {
  if (LOCAL_ONLY) {
    console.error(error, extra);
  } else {
    Sentry.captureException(error, { extra });
  }
}

/**
 * For rails ajax API
 * Normally the url should be `/ajax_data` (you can use `ajaxPath` to get it)
 */
export const ajaxInstance = axios.create();
ajaxInstance.interceptors.request.use((config) => {
  const csrfToken = localStorage.getItem(LS_KEY_PCP_CSRF_TOKEN);

  return {
    ...config,
    headers: {
      ...config.headers,
      'X-Csrf-Token': csrfToken,
    },
  };
});

/**
 * For rails ajax API
 * Should use it with `ajaxInstance`
 */
export const ajaxPath = '/ajax_data';

export type GenericErrorHandlers = ReturnType<typeof createGenericErrorHandlers>;
/** Create a generic error handlers */
export const createGenericErrorHandlers = () => {
  return {
    // 40401
    /**
     * Generic Two Step Verification error
     * The general error handling on PCP is redirect user to account info page to enable 2sv:
     *
     * - User is the owner of the team:
     *  `?msg=pref_policy_security_to_owner`
     *
     * - User is *NOT* the owner:
     *  `?msg=pref_policy_security_to_owner_without_granular`
     *
     * @ref src/modules/TeamSettings/SettingPanel/SettingControlContextProvider/SettingControlContextProvider_new.tsx L141
     */
    require2SV: (error: any) => twoStepVerificationErrorSchema.safeParse(error).success,

    // 40403
    /** The team seat is expired */
    teamSeatExpired: (error: any) => teamSeatExpiredErrorSchema.safeParse(error).success,

    // 40404
    /** The API resource is not support to teams's feature */
    notMatchAnyProduct: (error: any) => notMatchAnyProductErrorSchema.safeParse(error).success,
    /** The user does not have a team */
    userHasNoTeam: (error: any) => userHasNoTeamErrorSchema.safeParse(error).success,
    /** The requested resource not exist */
    resourceNotExist: (error: any) => resourceNotExistErrorSchema.safeParse(error).success,

    // 40409
    /** Exceeded the limit of batch actions */
    exceededLimit: (error: any) => exceededLimitErrorSchema.safeParse(error).success,

    // 40422
    /** The parameter is wrong */
    wrongParams: (error: any) => wrongParamsErrorSchema.safeParse(error).success,

    // 41403
    /** The user's role is not supported */
    roleNotSupported: (error: any) => roleNotSupportedErrorSchema.safeParse(error).success,
    /** The user is disabled */
    memberDisabled: (error: any) => memberDisabledErrorSchema.safeParse(error).success,
  };
};
