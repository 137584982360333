import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleSupportSessionClose({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M4 20C4 20.5523 4.44772 21 5 21H9.17087C9.31527 20.5905 9.55122 20.2062 9.87871 19.8787C11.0503 18.7071 12.9498 18.7071 14.1214 19.8787C14.4488 20.2062 14.6848 20.5905 14.8292 21L19 21C19.5523 21 20 20.5523 20 20V4C20 3.44772 19.5523 3 19 3L5 3C4.44771 3 4 3.44772 4 4L4 20Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M8.5 11.5L10.5 14L15.5 8.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
