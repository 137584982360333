import { Box, BoxProps, Flex, forwardRef } from '@chakra-ui/react';

export const RippleTableLegacy = forwardRef<BoxProps, 'div'>((props, ref) => <Box ref={ref} {...props} />);
export const RippleTheadLegacy = forwardRef<BoxProps, 'div'>((props, ref) => <Flex ref={ref} {...props} />);
export const RippleTbodyLegacy = forwardRef<BoxProps, 'div'>((props, ref) => <Box ref={ref} {...props} />);
export const RippleTfootLegacy = forwardRef<BoxProps, 'div'>((props, ref) => <Box ref={ref} {...props} />);
export const RippleTrLegacy = forwardRef<BoxProps, 'div'>((props, ref) => <Flex alignItems="stretch" ref={ref} {...props} />);
export const RippleThLegacy = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Flex alignItems="center" textTransform="capitalize" ref={ref} {...props} />
));
export type RippleTdLegacyProps = BoxProps;
export const RippleTdLegacy = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Flex className="td" alignItems="center" flexShrink={0} px="8px" wordBreak="break-all" ref={ref} {...props} />
));
export const RippleTableCaptionLegacy = forwardRef<BoxProps, 'div'>((props, ref) => <Box ref={ref} {...props} />);
