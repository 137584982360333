import colors from '../colors';
import { textStyles } from '../text';

const newVariants = {
  dangerGhost: {
    color: 'red.100',
    borderColor: 'red.100_0',
    bg: 'red.100_0',
    _hover: {
      bg: 'red.100_8',
      _disabled: {
        color: 'dark.40',
        bg: 'red.100_0',
      },
    },
    _active: {
      bg: 'red.100_16',
    },
    _disabled: {
      color: 'dark.40',
      bg: 'red.100_0',
    },
  },
  grayScalePrimary: {
    color: 'white',
    borderColor: 'dark.100',
    bg: 'dark.100',
    _hover: {
      borderColor: 'dark.90',
      bg: 'dark.90',
      _disabled: {
        color: 'dark.40',
        borderColor: 'dark.20',
        bg: 'dark.20',
      },
    },
    _active: {
      borderColor: 'dark.80',
      bg: 'dark.80',
    },
    _disabled: {
      color: 'dark.40',
      borderColor: 'dark.20',
      bg: 'dark.20',
    },
  },
  grayScaleSecondary: {
    color: 'dark.100',
    borderColor: 'dark.100',
    bg: 'dark.100_0',
    _hover: {
      bg: 'dark.100_4',
      _disabled: {
        color: 'dark.40',
        borderColor: 'dark.40',
        bg: 'dark.100_0',
      },
    },
    _active: {
      color: 'dark.100',
      borderColor: 'dark.100',
      bg: 'dark.100_8',
    },
    _disabled: {
      color: 'dark.40',
      borderColor: 'dark.40',
      bg: 'dark.100_0',
    },
  },
  grayScaleTertiary: {
    color: 'dark.100',
    borderColor: 'dark.100_0',
    bg: 'dark.100_4',
    _hover: {
      borderColor: 'dark.100_0',
      bg: 'dark.100_8',
      _disabled: {
        color: 'dark.40',
        borderColor: 'dark.100_0',
        bg: 'dark.100_0',
      },
    },
    _active: {
      color: 'dark.100',
      borderColor: 'dark.100_0',
      bg: 'dark.100_12',
    },
    _disabled: {
      color: 'dark.40',
      borderColor: 'dark.100_0',
      bg: 'dark.100_0',
    },
  },
  grayScaleGhost: {
    color: 'dark.100',
    borderColor: 'dark.100_0',
    bg: 'dark.100_0',
    _hover: {
      bg: 'dark.100_4',
      _disabled: {
        color: 'dark.40',
        bg: 'dark.100_0',
      },
    },
    _active: {
      bg: 'dark.100_8',
    },
    _disabled: {
      color: 'dark.40',
      bg: 'dark.100_0',
    },
  },
  neutralGhost: {
    color: 'neutral.300',
    borderColor: 'neutral.400_0',
    bg: 'neutral.400_0',
    _hover: {
      bg: 'neutral.400_8',
      _disabled: {
        color: 'dark.40',
        bg: 'neutral.400_0',
      },
    },
    _active: {
      bg: 'neutral.400_16',
    },
    _disabled: {
      color: 'dark.40',
      bg: 'neutral.400_0',
    },
  },
};

const styles = {
  baseStyle: {
    ...textStyles.buttonText,
    w: 'fit-content',
    border: '1px solid',
    borderRadius: '4px',
    _focus: {
      // TODO: Chakra token invalid in box-shadow property
      boxShadow: `0 0 0 2px ${colors.blue[60]}`,
    },
    _disabled: {
      opacity: 1,
    },
  },
  sizes: {
    xxs: {
      fontSize: '12px',
      minW: '56px',
      p: '3px 7px',
      h: '26px',
    },
    xs: {
      fontSize: '14px',
      minW: '72px',
      p: '3px 11px',
      h: '32px',
    },
    sm: {
      fontSize: '14px',
      minW: '80px',
      p: '7px 11px',
      h: '40px',
    },
    md: {
      fontSize: '16px',
      minW: '88px',
      p: '11px 15px',
      h: '44px',
    },
    lg: {
      fontSize: '16px',
      minW: '96px',
      p: '11px 15px',
      h: '48px',
    },
  },
  variants: {
    primary: {
      color: 'white',
      borderColor: 'blue.100',
      bg: 'blue.100',
      _hover: {
        borderColor: 'blue.200',
        bg: 'blue.200',
        _disabled: {
          borderColor: 'blue.40',
          bg: 'blue.40',
        },
      },
      _active: {
        borderColor: 'blue.300',
        bg: 'blue.300',
      },
      _disabled: {
        borderColor: 'blue.40',
        bg: 'blue.40',
      },
    },
    secondary: {
      color: 'blue.100',
      borderColor: 'blue.100',
      bg: 'blue.100_0',
      _hover: {
        bg: 'blue.100_8',
        _disabled: {
          color: 'dark.40',
          borderColor: 'dark.40',
          bg: 'blue.100_0',
        },
      },
      _active: {
        color: 'blue.100',
        borderColor: 'blue.100',
        bg: 'blue.100_16',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'dark.40',
        bg: 'blue.100_0',
      },
    },
    tertiary: {
      color: 'blue.100',
      borderColor: 'blue.100_0',
      bg: 'blue.100_4',
      _hover: {
        borderColor: 'blue.100_0',
        bg: 'blue.100_8',
        _disabled: {
          color: 'dark.40',
          borderColor: 'blue.100_0',
          bg: 'blue.100_0',
        },
      },
      _active: {
        color: 'blue.100',
        borderColor: 'blue.100_0',
        bg: 'blue.100_16',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'blue.100_0',
        bg: 'blue.100_0',
      },
    },
    ghost: {
      color: 'blue.100',
      borderColor: 'blue.100_0',
      bg: 'blue.100_0',
      _hover: {
        bg: 'blue.100_8',
        _disabled: {
          color: 'dark.40',
          bg: 'blue.100_0',
        },
      },
      _active: {
        bg: 'blue.100_16',
      },
      _disabled: {
        color: 'dark.40',
        bg: 'blue.100_0',
      },
    },
    success: {
      color: 'dark.100',
      borderColor: 'green.100',
      bg: 'green.100',
      _hover: {
        borderColor: 'green.200',
        bg: 'green.200',
        _disabled: {
          color: 'dark.40',
          borderColor: 'green.40',
          bg: 'green.40',
        },
      },
      _active: {
        borderColor: 'green.300',
        bg: 'green.300',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'green.40',
        bg: 'green.40',
      },
    },
    danger: {
      color: 'white',
      borderColor: 'red.100',
      bg: 'red.100',
      _hover: {
        borderColor: 'red.200',
        bg: 'red.200',
        _disabled: {
          borderColor: 'red.40',
          bg: 'red.40',
        },
      },
      _active: {
        borderColor: 'red.300',
        bg: 'red.300',
      },
      _disabled: {
        borderColor: 'red.40',
        bg: 'red.40',
      },
    },
    dangerSecondary: {
      color: 'red.100',
      borderColor: 'red.100',
      bg: 'red.100_0',
      _hover: {
        bg: 'red.100_8',
        _disabled: {
          color: 'dark.40',
          borderColor: 'dark.40',
          bg: 'red.100_0',
        },
      },
      _active: {
        color: 'red.100',
        borderColor: 'red.100',
        bg: 'red.100_16',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'dark.40',
        bg: 'red.100_0',
      },
    },
    dangerTertiary: {
      color: 'red.100',
      borderColor: 'red.100_0',
      bg: 'red.100_4',
      _hover: {
        bg: 'red.100_8',
        _disabled: {
          color: 'dark.40',
          bg: 'red.100_0',
        },
      },
      _active: {
        bg: 'red.100_16',
      },
      _disabled: {
        color: 'dark.40',
        bg: 'red.100_0',
      },
    },
    ...newVariants,
  },
  defaultProps: {
    variant: 'primary',
    size: 'sm',
  },
};

export const rippleButton = styles;
