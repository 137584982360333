import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleSecurityUpgrade({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12.3996C18.9999 12.4036 18.9997 12.4106 18.9993 12.4204C18.9982 12.4494 18.9956 12.5031 18.9894 12.5785C18.9771 12.7296 18.951 12.965 18.8962 13.2609C18.7858 13.8566 18.5636 14.6717 18.122 15.5328C17.29 17.155 15.6134 19.088 12 19.9726C8.3866 19.088 6.71 17.155 5.87803 15.5328C5.43639 14.6717 5.21423 13.8566 5.10381 13.2609C5.04896 12.965 5.02287 12.7296 5.01057 12.5785C5.00444 12.5031 5.00179 12.4494 5.00068 12.4204C5.0003 12.4106 5.0001 12.4036 5 12.3996V6.78945L12 4.07076L19 6.78945V12.3996ZM20.6907 14.3878C21 13.2245 21 12.4116 21 12.4116V6.10507C21 5.69249 20.7466 5.32227 20.362 5.1729L12.362 2.06582C12.1292 1.97537 11.8708 1.97537 11.638 2.06582L3.63796 5.1729C3.25338 5.32227 3 5.69249 3 6.10506V12.4116C3 12.4116 3 13.2245 3.30933 14.3878C3.94385 16.7741 5.87992 20.6346 11.7874 21.9773C11.9247 22.0085 12.0753 22.0085 12.2126 21.9773C18.1201 20.6346 20.0562 16.7741 20.6907 14.3878Z"
        fill="currentColor"
      />
      <path d="M8 11L12 8L16 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 8V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </Icon>
  );
}
