import { useEffect, useState } from 'react';

import { useSetAtom } from 'jotai';

import { basicProfileAtom } from './atoms';
import type { BasicProfile } from './types';

type BasicProfileProviderProps = { basicProfile: BasicProfile; children?: React.ReactNode };
export function BasicProfileProvider({ basicProfile, children }: BasicProfileProviderProps): React.JSX.Element | null {
  const [settled, setSettled] = useState(false);
  const setBasicProfile = useSetAtom(basicProfileAtom);

  useEffect(
    function updateBasicProfile() {
      setBasicProfile(basicProfile);
      setSettled(true);
    },
    [setBasicProfile, basicProfile],
  );

  if (settled) return <>{children}</>;

  return null;
}
