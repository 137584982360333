import { useId } from 'react';

import { Box, Icon } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const spinAnimation = `${spin} 0.8s linear infinite`;

type LoadingSpinnerProps = {
  children?: React.ReactNode;
};
export function LoadingSpinner({ children }: LoadingSpinnerProps): React.JSX.Element {
  return (
    <Box boxSize="40px" position="relative">
      {children}
      <Box as="span" position="absolute" left="4px" top="4px" animation={spinAnimation}>
        <Spinner />
      </Box>
    </Box>
  );
}

function Spinner(): React.JSX.Element {
  const radialGradient1 = useId();

  return (
    <Icon boxSize="32px" viewBox="0 0 32 32" fill="none">
      <path
        d="M30.4 16C31.2837 16 32.0083 16.7181 31.9201 17.5974C31.6718 20.0714 30.8494 22.4614 29.5092 24.5732C27.8824 27.1368 25.5597 29.1845 22.8125 30.4772C20.0652 31.77 17.0069 32.2544 13.9947 31.8738C10.9824 31.4933 8.14064 30.2635 5.80122 28.3282C3.4618 26.3929 1.72133 23.8319 0.783096 20.9443C-0.155136 18.0567 -0.252399 14.9618 0.502669 12.021C1.25774 9.08017 2.83396 6.41491 5.04725 4.3365C6.8705 2.62435 9.06406 1.36867 11.4478 0.661237C12.2949 0.409828 13.1361 0.98706 13.3017 1.85506C13.4673 2.72307 12.8932 3.55135 12.0526 3.82385C10.2638 4.40377 8.61815 5.37297 7.2378 6.6692C5.46717 8.33193 4.20619 10.4641 3.60214 12.8168C2.99808 15.1694 3.07589 17.6454 3.82648 19.9554C4.57706 22.2655 5.96944 24.3143 7.84097 25.8626C9.71251 27.4108 11.9859 28.3946 14.3957 28.6991C16.8055 29.0035 19.2522 28.616 21.45 27.5818C23.6478 26.5476 25.5059 24.9094 26.8074 22.8586C27.822 21.2598 28.4657 19.4617 28.7001 17.5959C28.8103 16.7191 29.5163 16 30.4 16Z"
        fill={`url(#${radialGradient1})`}
      />
      <defs>
        <radialGradient id={radialGradient1} cx="0" cy="0" r="1">
          <stop stopColor="#949494" stopOpacity="1" />
          <stop offset="1" stopColor="#949494" stopOpacity="0" />
        </radialGradient>
      </defs>
    </Icon>
  );
}
