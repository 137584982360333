import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleEdit16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        d="M2 11.8805L9.53052 4.34997L11.6518 6.47129L12.3375 7.15697L11.6518 6.47129L4.12132 14.0019L2 14.0019L2 11.8805Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M11.1671 1.29289C11.5576 0.902369 12.1908 0.902369 12.5813 1.29289L14.7026 3.41421C15.0931 3.80474 15.0931 4.4379 14.7026 4.82843L13.9955 5.53553L10.46 2L11.1671 1.29289Z"
        fill="currentColor"
      />
    </Icon>
  );
}
