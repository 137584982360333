import {
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';

export const RipplePopover = Popover;
export const RipplePopoverTrigger = PopoverTrigger;
export const RipplePopoverContent = PopoverContent;
export const RipplePopoverHeader = PopoverHeader;
export const RipplePopoverBody = PopoverBody;
export const RipplePopoverFooter = PopoverFooter;
export const RipplePopoverArrow = PopoverArrow;
export const RipplePopoverCloseButton = PopoverCloseButton;
export const RipplePopoverAnchor = PopoverAnchor;
