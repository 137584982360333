import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowLeft({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1402 5.2318C15.5644 5.58537 15.6218 6.21593 15.2682 6.64021L10.8017 12L15.2682 17.3598C15.6218 17.7841 15.5644 18.4147 15.1402 18.7682C14.7159 19.1218 14.0853 19.0645 13.7318 18.6402L8.73175 12.6402C8.42272 12.2694 8.42272 11.7307 8.73175 11.3598L13.7318 5.35984C14.0853 4.93556 14.7159 4.87824 15.1402 5.2318Z"
        fill="currentColor"
      />
    </Icon>
  );
}
