import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleEdit({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4H5L5 20H18V13H20V20C20 21.1046 19.1046 22 18 22H5C3.89543 22 3 21.1046 3 20V4C3 2.89543 3.89543 2 5 2H12V4Z"
        fill="currentColor"
      />
      <path
        d="M8.27002 13.6066L15.8005 6.07604L17.9219 8.19736L18.6075 8.88304L17.9219 8.19736L10.3913 15.7279L8.27002 15.7279L8.27002 13.6066Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M17.1671 3.29289C17.5576 2.90237 18.1908 2.90237 18.5813 3.29289L20.7026 5.41421C21.0931 5.80474 21.0931 6.4379 20.7026 6.82843L19.9955 7.53553L16.46 4L17.1671 3.29289Z"
        fill="currentColor"
      />
    </Icon>
  );
}
