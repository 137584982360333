import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCollapseLeftIcon({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H4V18H10V6ZM12 6V18H20V6H12ZM4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4ZM5 8H9V10H5V8ZM9 12H5V14H9V12ZM14.5333 12.4L17.2 14.4C17.5296 14.6472 18 14.412 18 14V10C18 9.58798 17.5296 9.35279 17.2 9.6L14.5333 11.6C14.2667 11.8 14.2667 12.2 14.5333 12.4Z"
        fill="currentColor"
      />
    </Icon>
  );
}
