import { useMemo } from 'react';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetPluginFunc, OTablePlugin } from '@/components/ObservableTable/types';
import { makeSortPipe } from '@/components/ObservableTable/utils/sort';

export const OTableSortStates = {
  UNDEFINED: 'UNDEFINED',
  DESC: 'DESC',
  ASC: 'ASC',
} as const;
type OTableSortStateKeys = keyof typeof OTableSortStates;
export type OTableSortStateValues = (typeof OTableSortStates)[OTableSortStateKeys];

export type OTableOnToggleSort = (accessor: string, options?: { sortByDesc?: boolean; mustSort?: boolean }) => void;

export type OTableSortBy = {
  accessor: string;
  desc: boolean;
};
export type OTableSortByList = Array<OTableSortBy>;

export type OTableSortParams = {
  sortBy: OTableSortByList;
};

type UseObservableSortProps = {
  initSortBy?: OTableSortByList;
};

function useObservableSort(props: UseObservableSortProps = {}) {
  return useMemo(() => {
    const { initSortBy } = props;
    const initParams: OTableSortParams = {
      sortBy: initSortBy ?? [],
    };

    const sortSubject = new BehaviorSubject<OTableSortParams>(initParams);

    const getPlugin: GetPluginFunc = (tableResult) => sortSubject.pipe(map(makeSortPipe(tableResult)));

    const onToggleSort: OTableOnToggleSort = (accessor, options = {}) => {
      const currentValue = sortSubject.getValue();
      const sortIndex = currentValue.sortBy.findIndex((sort) => sort.accessor === accessor);

      let nextSortBy: OTableSortByList = [];
      if (sortIndex === -1) {
        nextSortBy = [
          {
            accessor,
            desc: Boolean(options.sortByDesc),
          },
        ];
      } else {
        const currentSort = currentValue.sortBy[sortIndex];

        if (options.sortByDesc !== undefined) {
          const nextSort = {
            ...currentSort,
            desc: options.sortByDesc,
          };
          nextSortBy = [nextSort];
        } else if (!currentSort.desc) {
          const nextSort = {
            ...currentSort,
            desc: true,
          };
          nextSortBy = [nextSort];
        } else if (options.mustSort) {
          const nextSort = {
            ...currentSort,
            desc: false,
          };
          nextSortBy = [nextSort];
        }
      }

      sortSubject.next({
        ...currentValue,
        sortBy: nextSortBy,
      });
    };

    const onToggleManySort = (accessor: string) => {
      const currentValue = sortSubject.getValue();
      const sortIndex = currentValue.sortBy.findIndex((sort) => sort.accessor === accessor);

      const nextSortBy = [...currentValue.sortBy];
      if (sortIndex === -1) {
        nextSortBy.push({
          accessor,
          desc: false,
        });
      } else {
        const currentSort = currentValue.sortBy[sortIndex];

        if (!currentSort.desc) {
          const nextSort = {
            ...currentSort,
            desc: true,
          };
          nextSortBy[sortIndex] = nextSort;
        } else {
          nextSortBy.splice(sortIndex, 1);
        }
      }

      sortSubject.next({
        ...currentValue,
        sortBy: nextSortBy,
      });
    };

    const getSort = (accessor: string): OTableSortStateValues => {
      const currentValue = sortSubject.getValue();
      const sort = currentValue.sortBy.find((sort) => sort.accessor === accessor);

      if (sort === undefined) {
        return OTableSortStates.UNDEFINED;
      } else if (sort?.desc) {
        return OTableSortStates.DESC;
      } else {
        return OTableSortStates.ASC;
      }
    };

    const sortPlugin: OTablePlugin = {
      type: 'sort' as const,
      getPlugin,
    };

    return {
      sortPlugin,
      onToggleSort,
      onToggleManySort,
      getSort,
    };
  }, []); // eslint-disable-line
}

export default useObservableSort;
