import { ReactNode, useMemo } from 'react';

import { Box, Flex, FlexProps, useToast } from '@chakra-ui/react';

import { RippleButton, RippleButtonProps } from '../RippleButton';
import { RippleAlertSpecificColor, RippleClear, RippleClose, RippleInfoFilled, RippleReady } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleStrong } from '../RippleStrong';

export type NotificationVariant = 'info' | 'success' | 'alert' | 'error';

const VARIANT_MAP_ICON = {
  info: <RippleInfoFilled color="blue.80" />,
  success: <RippleReady color="green.100" />,
  alert: <RippleAlertSpecificColor />,
  error: <RippleClear color="red.100" />,
};

type RippleNotificationOptions = {
  title: ReactNode;
  variant: NotificationVariant;
  /**
   * Decide background color and text color...etc
   */
  theme?: 'light' | 'dark';
  id?: string;
  /**
   * Main content
   */
  content?: ReactNode;
  /**
   * primary button on the bottom-right of Notification
   */
  primaryAction?: RippleButtonProps & { label: string };
  /**
   * Other buttons exclude primary button, will order from right to left
   */
  secondaryActions?: Array<RippleButtonProps & { label: string }>;
  /**
   * Should show close button on the top-right or not
   */
  isClosable?: boolean;
  /**
   * Adust notification container style.
   */
  containerStyle?: FlexProps;
};

export type RippleNotificationProps = Omit<RippleNotificationOptions, 'isClosable'> & {
  variant: NotificationVariant;
  onClose?: () => void;
  title: ReactNode;
};

export const RippleNotification = ({
  theme = 'dark',
  variant,
  onClose,
  title,
  content,
  primaryAction,
  secondaryActions,
  containerStyle,
}: RippleNotificationProps) => {
  const icon = VARIANT_MAP_ICON[variant];

  return (
    <Flex
      p="8px"
      bg={theme === 'light' ? 'blue.0' : 'dark.90'}
      border="1px solid"
      borderColor={theme === 'light' ? 'blue.20' : 'dark.60'}
      borderRadius="4px"
      color={theme === 'light' ? 'dark.100' : 'white'}
      boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
      minW="300px"
      maxW="376px"
      direction="column"
      columnGap="8px"
      zIndex="toast"
      {...containerStyle}
    >
      <Flex columnGap="8px" w="100%">
        <Flex columnGap="8px" p="4px 0 12px 8px" flex={1}>
          {icon}
          <RippleStrong as="p" variant="strong01" color={theme === 'light' ? 'dark.100' : 'white'}>
            {title}
          </RippleStrong>
        </Flex>
        {onClose && <RippleIconButton aria-label="close" icon={<RippleClose />} onClick={onClose} />}
      </Flex>
      <Box ml="40px">
        <>
          {content}
          {(primaryAction || secondaryActions) && (
            <Flex p="8px" columnGap="10px" direction="row-reverse">
              {primaryAction && (
                <RippleButton {...primaryAction} size="xs">
                  {primaryAction.label}
                </RippleButton>
              )}
              {secondaryActions &&
                secondaryActions.map((action) => (
                  <RippleButton key={action.label} {...action} size="xs">
                    {action.label}
                  </RippleButton>
                ))}
            </Flex>
          )}
        </>
      </Box>
    </Flex>
  );
};

export const useRippleNotification = () => {
  const toast = useToast();

  return useMemo(() => {
    return {
      isNotificationActive: toast.isActive,
      openNotification: (option: RippleNotificationOptions) => {
        const { id, variant, title, content, primaryAction, secondaryActions, isClosable = true, containerStyle, theme } = option;

        toast({
          id,
          position: 'top-right',
          duration: null,
          render: ({ onClose }) => {
            return (
              <RippleNotification
                variant={variant}
                theme={theme}
                onClose={isClosable ? onClose : undefined}
                title={title}
                content={content}
                primaryAction={primaryAction}
                secondaryActions={secondaryActions}
                containerStyle={containerStyle}
              />
            );
          },
        });
      },
      closeAll: toast.closeAll,
    };
  }, [toast]);
};
