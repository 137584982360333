import type { UseModalProps } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import emptyFunc from '@/utils/emptyFunc';

import {
  RippleButton,
  RippleButtonProps,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalContentProps,
  RippleModalFooter,
  RippleModalOverlay,
  RippleTypography,
} from '../';

export type RippleMessageModalProps = {
  title?: string;
  isOpen: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose?: UseModalProps['onClose'];
  onConfirm(): void;
  children: React.ReactNode;
  contentStyle?: RippleModalContentProps;
  confirmButtonProps?: RippleButtonProps;
};

export const RippleMessageModal = ({
  title,
  isOpen,
  cancelButtonText,
  confirmButtonText,
  onClose,
  onConfirm,
  children,
  contentStyle,
  confirmButtonProps,
}: RippleMessageModalProps) => {
  const { t } = useTranslation();
  return (
    <RippleModal isOpen={isOpen} onClose={onClose ?? emptyFunc} closeOnOverlayClick={false}>
      <RippleModalOverlay />
      <RippleModalContent borderRadius="4px" maxWidth="608px" {...contentStyle}>
        <RippleModalBody data-testid="modal-body" flexBasis="auto" pb="24px">
          {title && (
            <RippleTypography data-testid="modal-title" variant="heading05" marginTop="32px" marginBottom="24px">
              {title}
            </RippleTypography>
          )}
          {children}
        </RippleModalBody>

        <RippleModalFooter data-testid="modal-footer" borderTop="1px solid" borderTopColor="neutral.60" gap="8px">
          {onClose && (
            <RippleButton data-testid="cancel-btn" variant="grayScaleGhost" size="sm" minWidth="80px" onClick={onClose}>
              {cancelButtonText ?? t('common:cancel')}
            </RippleButton>
          )}
          <RippleButton size="sm" minWidth="80px" onClick={onConfirm} data-testid="confirm-btn" {...confirmButtonProps}>
            {confirmButtonText ?? t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
