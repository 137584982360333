import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowTailLeft({ color = DEFAULT_ICON_COLOR, _groupActive }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" color={color} fill="none" _groupActive={_groupActive}>
      <g id="Size=24px, Tail=True">
        <path id="Arrow" d="M9 18L4 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="stem" d="M4 12L20 12" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      </g>
    </Icon>
  );
}
