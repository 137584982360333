import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleIp({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 16H19V20H5L5 16H7V14H5C3.89543 14 3 14.8954 3 16V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V16C21 14.8954 20.1046 14 19 14H17V16Z"
        fill="currentColor"
      />
      <circle cx="7" cy="18" r="1" fill="currentColor" />
      <circle cx="10" cy="18" r="1" fill="currentColor" />
      <path
        d="M18 7.80337C18 9.63802 16.9371 11.5794 14.895 13.5654C14.1992 14.2421 13.4515 14.8606 12.7003 15.4085C12.4462 15.5939 12.2108 15.757 12 15.8967C11.7892 15.757 11.5538 15.5939 11.2997 15.4085C10.5485 14.8606 9.80082 14.2421 9.10497 13.5654C7.06293 11.5794 6 9.63802 6 7.80337C6 4.62564 8.6584 2 12 2C15.3422 2 18 4.62532 18 7.80337Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11 11H12.2301V9.05469H13.2642C14.6023 9.05469 15.3807 8.23145 15.3807 7.0332C15.3807 5.84082 14.6165 5 13.2955 5H11V11ZM12.2301 8.03809V6.03711H13.0597C13.7699 6.03711 14.1136 6.43555 14.1136 7.0332C14.1136 7.62793 13.7699 8.03809 13.0653 8.03809H12.2301Z"
        fill="currentColor"
      />
      <path d="M10.2301 5H9V11H10.2301V5Z" fill="currentColor" />
    </Icon>
  );
}
