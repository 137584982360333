import { Flex, forwardRef } from '@chakra-ui/react';

import { OTableSortStateValues, OTableSortStates } from '@/components/ObservableTable/useObservableSort';

import { RippleComputerDeviceIcon, RippleComputerDeviceIconProps } from '../RippleComputer';
import { RippleInlineAscending, RippleInlineDescending } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleTdLegacy, RippleTdLegacyProps, RippleThLegacy } from '../RippleTableLegacy';
import { RippleBodyText02, RippleHeading09 } from '../RippleText';

export const RippleComputerTableHead = forwardRef<
  RippleTdLegacyProps & { isSortable?: boolean; sortState?: OTableSortStateValues; onClick?: () => void },
  'div'
>(({ children, sortState, isSortable, onClick, ...otherProps }, ref) => {
  return (
    <RippleThLegacy p="11px 0 11px 6px" cursor={isSortable ? 'pointer' : 'initial'} onClick={onClick} ref={ref} {...otherProps}>
      <RippleHeading09 color="neutral.300">{children}</RippleHeading09>
      {sortState === OTableSortStates.DESC && <RippleInlineDescending color="blue.200" />}
      {sortState === OTableSortStates.ASC && <RippleInlineAscending color="blue.200" />}
    </RippleThLegacy>
  );
});

export const RippleComputerNameCell = forwardRef<RippleTdLegacyProps & RippleComputerDeviceIconProps, 'div'>(
  ({ children, type, state, ...otherProps }, ref) => {
    return (
      <RippleTdLegacy p="6px 11px" ref={ref} {...otherProps}>
        <Flex alignItems="center">
          <RippleComputerDeviceIcon type={type} state={state} />
          <RippleBodyText02 color="dark.90" pl="8px">
            {children}
          </RippleBodyText02>
        </Flex>
      </RippleTdLegacy>
    );
  },
);

export type RippleComputerCellProps = RippleTdLegacyProps;
export const RippleComputerCell = forwardRef<RippleComputerCellProps, 'div'>(({ children, ...otherProps }, ref) => {
  return (
    <RippleTdLegacy ref={ref} {...otherProps}>
      <RippleBodyText02 color="dark.90">{children}</RippleBodyText02>
    </RippleTdLegacy>
  );
});

export const RippleComputerIconCell = forwardRef<RippleTdLegacyProps, 'div'>((props, ref) => {
  return <RippleTdLegacy justifyContent="center" ref={ref} {...props} />;
});

export const RippleComputerIconButtonCell = forwardRef<
  RippleTdLegacyProps & {
    'aria-label': string;
    icon: React.ReactElement;
    onClick: () => void;
  },
  'div'
>(({ 'aria-label': ariaLabel, icon, onClick, ...otherProps }, ref) => {
  return (
    <RippleTdLegacy justifyContent="center" ref={ref} {...otherProps}>
      <RippleIconButton aria-label={ariaLabel} icon={icon} onClick={onClick} />
    </RippleTdLegacy>
  );
});
