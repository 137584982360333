import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleGranularControlOneToManyRebootAndUpdateIcon({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path d="M3.5 21.5H9.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M6.5 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 15C13 13.8954 13.8954 13 15 13H20C20.8577 13 21.5892 13.5398 21.8734 14.2983C21.284 14.1047 20.6542 14 20 14C18.7733 14 17.6325 14.3682 16.6822 15H15V16.6822C14.4138 17.5639 14.0545 18.6096 14.0057 19.7357C13.4048 19.3907 13 18.7426 13 18V15ZM14.0313 20.6164C13.7152 20.7844 13.5 21.1171 13.5 21.5C13.5 22.0523 13.9477 22.5 14.5 22.5H14.544C14.2762 21.9165 14.0993 21.2826 14.0313 20.6164Z"
            fill="currentColor"
          />
          <rect x="3" y="14" width="7" height="5" rx="1" stroke="currentColor" strokeWidth="2" />
          <path d="M9 11.5H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M12 9V11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <rect x="8" y="3" width="8" height="6" rx="1" stroke="currentColor" strokeWidth="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.5L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <path d="M3.5 21.5H9.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M14.5 21.5H20.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M6.5 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M17.5 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <rect x="14" y="14" width="7" height="5" rx="1" stroke="currentColor" strokeWidth="2" />
          <rect x="3" y="14" width="7" height="5" rx="1" stroke="currentColor" strokeWidth="2" />
          <path d="M9 11.5H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M12 9V11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <rect x="8" y="3" width="8" height="6" rx="1" stroke="currentColor" strokeWidth="2" />
        </>
      )}
    </Icon>
  );
}
