import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleSplashtopLogo = () => {
  const clipPath = useId();
  return (
    <Icon width="128px" height="28px" viewBox="0 0 128 28" fill="none">
      <g clipPath={`url(#${clipPath})`}>
        <path
          d="M126.949 7.75635C127.007 7.75635 127.065 7.75635 127.094 7.78541C127.123 7.81447 127.181 7.81447 127.21 7.8726C127.239 7.90166 127.268 7.93073 127.297 7.98885C127.327 8.01792 127.327 8.07604 127.327 8.13417C127.327 8.22136 127.297 8.27949 127.268 8.33761C127.239 8.39574 127.181 8.4248 127.123 8.45387C127.152 8.45387 127.181 8.48293 127.21 8.48293C127.239 8.51199 127.239 8.51199 127.268 8.54106C127.268 8.57012 127.297 8.59918 127.297 8.62825C127.297 8.65731 127.297 8.68637 127.297 8.71544C127.297 8.7445 127.297 8.7445 127.297 8.77356C127.297 8.80263 127.297 8.83169 127.297 8.86075C127.297 8.88982 127.297 8.91888 127.297 8.94794C127.297 8.97701 127.327 9.00607 127.327 9.00607H127.065C127.036 8.97701 127.036 8.91888 127.036 8.86075C127.036 8.80263 127.036 8.7445 127.007 8.71544C127.007 8.65731 126.978 8.59918 126.949 8.57012C126.92 8.54106 126.862 8.51199 126.803 8.51199H126.542V9.00607H126.28V7.75635H126.949ZM126.862 8.33761C126.92 8.33761 126.978 8.33761 127.007 8.30855C127.036 8.27949 127.065 8.22136 127.065 8.16323C127.065 8.10511 127.036 8.04698 127.007 8.01792C126.978 7.98885 126.92 7.98885 126.862 7.98885H126.571V8.33761H126.862Z"
          fill="#231F20"
        />
        <path
          d="M96.1994 6.91382H98.7279L98.2339 10.7792H99.8614L99.5417 12.9881H97.9432L96.8388 21.1839H94.2812L95.4147 12.9881H94.3975L94.6881 10.7792H95.7053L96.1994 6.91382Z"
          fill="#231F20"
        />
        <path
          d="M39.6714 11.0115C38.6542 10.4593 37.4045 10.314 36.7651 10.314C35.8641 10.314 35.0794 10.5755 34.44 11.1277C33.7716 11.6799 33.4519 12.3775 33.4519 13.2494C33.4519 13.8597 33.6553 14.47 34.0913 15.0222C34.3819 15.4291 34.9341 15.8941 35.7188 16.4754C36.3873 16.9695 36.8232 17.2892 36.9685 17.4926C37.1139 17.667 37.201 17.8704 37.201 18.0448C37.201 18.2773 37.1139 18.5098 36.9104 18.6551C36.707 18.8295 36.4454 18.8876 36.0966 18.8876C35.2538 18.8876 33.9169 18.5389 33.0159 17.7542L31.5337 19.527C32.1731 20.1083 33.074 20.6024 33.7425 20.864C34.3819 21.1255 35.1666 21.2418 35.9513 21.2418C37.1429 21.2708 38.0729 20.893 38.7123 20.2246C39.3517 19.527 39.6714 18.8005 39.6714 18.0448C39.6714 17.4635 39.5261 16.9113 39.2064 16.4173C38.8867 15.9232 38.2473 15.3129 37.2882 14.6153C36.6779 14.1794 36.3001 13.8888 36.1838 13.7144C36.0676 13.5691 35.9804 13.4237 35.9804 13.2494C35.9804 13.104 36.0676 12.9587 36.2129 12.8134C36.3873 12.6972 36.5616 12.639 36.7942 12.61C37.5207 12.5228 38.4798 12.8134 38.8577 13.0169C39.1483 13.1622 39.497 13.3365 39.9621 13.6853L41.0665 11.9415C40.5724 11.4474 40.1946 11.2731 39.6714 11.0115Z"
          fill="#231F20"
        />
        <path d="M54.4067 21.1835H56.9643L58.9116 6.79712H56.383L54.4067 21.1835Z" fill="#231F20" />
        <path
          d="M65.5667 10.3432C64.6076 10.4013 63.7067 10.6048 62.8348 11.1279C61.9629 11.651 61.2654 12.3776 60.7422 13.2786C60.2191 14.2086 59.9575 15.1677 59.9575 16.214C59.9575 17.5509 60.3644 18.7425 61.1782 19.7307C61.9919 20.7479 63.0964 21.2419 64.4623 21.2419C65.0727 21.2419 65.5958 21.1257 66.0899 20.9223C66.5839 20.7188 67.078 20.37 67.6302 19.8469L67.4849 21.0676H70.0134C70.0134 21.0676 70.7691 16.9115 70.7981 16.1849C70.8563 15.4583 71.0306 14.0052 69.9262 12.4939C68.7637 10.9535 66.8455 10.256 65.5667 10.3432ZM67.7174 17.0859C67.3977 17.6962 67.0199 18.1322 66.5549 18.3937C66.0899 18.6844 65.5377 18.8006 64.8983 18.8006C64.1426 18.8006 63.5323 18.5391 63.0382 18.045C62.5442 17.5509 62.3116 16.8824 62.3116 16.0687C62.3116 15.0224 62.6313 14.1505 63.2707 13.482C63.9101 12.8136 64.6658 12.4939 65.5958 12.4939C66.3805 12.4939 66.9908 12.7555 67.4849 13.2495C67.9499 13.7436 68.2115 14.4121 68.2115 15.2258C68.2115 15.8943 68.0371 16.5046 67.7174 17.0859Z"
          fill="#231F20"
        />
        <path
          d="M89.0499 10.5177C88.0036 10.5177 86.9864 10.9537 85.9982 11.8256L86.6957 6.76855H84.1672L82.1909 21.1549H84.7194L85.2135 17.4929C85.417 16.0398 85.6204 15.0516 85.8529 14.5285C86.0854 14.0053 86.4051 13.5984 86.8411 13.3078C87.277 13.0172 87.6839 12.8719 88.1198 12.8719C88.5558 12.8719 88.9046 12.9881 89.1371 13.2206C89.3696 13.4531 89.4858 13.7728 89.4858 14.1797C89.4858 14.4703 89.4568 14.9063 89.3696 15.4876L88.5848 21.1549H91.1134L91.8981 15.3422C92.0143 14.5285 92.0724 13.9181 92.0724 13.5112C92.0724 12.6393 91.7818 11.9418 91.2005 11.3606C90.6774 10.8084 89.9508 10.5177 89.0499 10.5177Z"
          fill="#231F20"
        />
        <path
          d="M108.987 10.9827C108.232 10.5467 107.389 10.3433 106.488 10.3433C104.919 10.3433 103.524 10.9245 102.332 12.058C101.14 13.2205 100.559 14.6446 100.559 16.3884C100.559 17.8416 100.995 19.0041 101.867 19.9051C102.739 20.8061 103.93 21.242 105.384 21.242C107.069 21.242 108.493 20.6607 109.656 19.5273C110.818 18.3938 111.371 17.0278 111.371 15.4003C111.371 14.4412 111.167 13.5402 110.731 12.7555C110.324 11.9999 109.743 11.4186 108.987 10.9827ZM108.028 17.8125C107.476 18.5391 106.662 18.8879 105.587 18.8879C104.831 18.8879 104.221 18.6554 103.785 18.1904C103.32 17.7253 103.117 17.086 103.117 16.3303C103.117 15.4584 103.407 14.6446 103.959 13.889C104.512 13.1333 105.325 12.7555 106.343 12.7555C107.098 12.7555 107.709 12.988 108.203 13.4821C108.668 13.9471 108.929 14.5865 108.929 15.3422C108.871 16.2431 108.581 17.086 108.028 17.8125Z"
          fill="#231F20"
        />
        <path
          d="M80.0694 11.0115C79.0522 10.4593 77.8024 10.314 77.163 10.314C76.2621 10.314 75.4774 10.5755 74.838 11.1277C74.1695 11.6799 73.8498 12.3775 73.8498 13.2494C73.8498 13.8597 74.0533 14.47 74.4892 15.0222C74.7798 15.4291 75.332 15.8941 76.1168 16.4754C76.7852 16.9695 77.2212 17.2892 77.3665 17.4926C77.5118 17.667 77.599 17.8704 77.599 18.0448C77.599 18.2773 77.5118 18.5098 77.3084 18.6551C77.1049 18.8295 76.8433 18.8876 76.4946 18.8876C75.6518 18.8876 74.3148 18.5389 73.4139 17.7542L71.9316 19.527C72.571 20.1083 73.472 20.6024 74.1405 20.864C74.7798 21.1255 75.5646 21.2418 76.3493 21.2418C77.5409 21.2708 78.4709 20.893 79.1103 20.2246C79.7497 19.527 80.0694 18.8005 80.0694 18.0448C80.0694 17.4635 79.9241 16.9113 79.6044 16.4173C79.2847 15.9232 78.6453 15.3129 77.6862 14.6153C77.0758 14.1794 76.698 13.8888 76.5818 13.7144C76.4655 13.5691 76.3783 13.4237 76.3783 13.2494C76.3783 13.104 76.4655 12.9587 76.6108 12.8134C76.7562 12.6972 76.9596 12.639 77.1921 12.61C77.9187 12.5228 78.8778 12.8134 79.2556 13.0169C79.5462 13.1622 79.895 13.3365 80.36 13.6853L81.4644 11.9415C80.9413 11.4474 80.5925 11.2731 80.0694 11.0115Z"
          fill="#231F20"
        />
        <path
          d="M48.7393 10.3722C46.4142 10.2559 44.9901 11.3022 44.2054 11.9707C43.7694 12.3485 42.9266 13.4529 42.6941 14.9351C42.5488 15.7489 41.3281 24.7585 41.3281 24.7585L43.8566 23.7704L44.5542 19.5271C44.9029 20.0794 45.3389 20.5153 45.862 20.8059C46.3851 21.0966 46.9955 21.2419 47.6058 21.2419C48.5358 21.2419 49.4368 20.9803 50.3087 20.4572C51.1806 19.934 51.8491 19.2075 52.3722 18.3065C52.8953 17.3765 53.1278 16.4174 53.1278 15.4002C53.1278 14.0632 52.721 12.9007 51.9362 11.8835C51.1806 10.8663 50.1053 10.4303 48.7393 10.3722ZM49.7274 17.8996C49.1171 18.5681 48.3615 18.8878 47.4605 18.8878C46.7048 18.8878 46.0654 18.6262 45.6004 18.1321C45.1354 17.638 44.9029 16.9696 44.9029 16.1558C44.9029 15.5455 45.0482 14.9351 45.3679 14.3248C45.6876 13.7145 46.0655 13.2785 46.5305 13.017C46.9955 12.7263 47.5186 12.581 48.1289 12.581C48.8846 12.581 49.4659 12.8426 49.9309 13.3367C50.3959 13.8307 50.6284 14.4992 50.6284 15.313C50.6575 16.3883 50.3378 17.2311 49.7274 17.8996Z"
          fill="#231F20"
        />
        <path
          d="M119.45 10.256C117.125 10.1397 115.701 11.186 114.916 11.8545C114.48 12.2323 113.638 13.3367 113.405 14.8189C113.26 15.6327 112.039 24.6423 112.039 24.6423L114.568 23.6542L115.265 19.4109C115.614 19.9631 116.05 20.3991 116.573 20.6897C117.096 20.9804 117.706 21.1257 118.317 21.1257C119.247 21.1257 120.148 20.8641 121.02 20.341C121.892 19.8178 122.56 19.0912 123.083 18.1903C123.606 17.2603 123.839 16.3012 123.839 15.2839C123.839 13.947 123.432 12.7845 122.647 11.7673C121.892 10.7791 120.816 10.3432 119.45 10.256ZM120.438 17.8125C119.828 18.4809 119.072 18.8006 118.171 18.8006C117.416 18.8006 116.776 18.539 116.311 18.045C115.846 17.5509 115.614 16.8824 115.614 16.0687C115.614 15.4583 115.759 14.848 116.079 14.2377C116.399 13.6273 116.776 13.1914 117.241 12.9298C117.706 12.6392 118.23 12.4939 118.84 12.4939C119.596 12.4939 120.177 12.7554 120.642 13.2495C121.107 13.7436 121.339 14.412 121.339 15.2258C121.368 16.2721 121.049 17.144 120.438 17.8125Z"
          fill="#231F20"
        />
        <path
          d="M126.803 9.61635C126.164 9.61635 125.612 9.09321 125.612 8.42475C125.612 7.78536 126.135 7.23315 126.803 7.23315C127.443 7.23315 127.995 7.75629 127.995 8.42475C127.966 9.09321 127.443 9.61635 126.803 9.61635ZM126.803 7.49472C126.28 7.49472 125.844 7.93067 125.844 8.45381C125.844 8.97695 126.28 9.4129 126.803 9.4129C127.327 9.4129 127.762 8.97695 127.762 8.45381C127.762 7.90161 127.327 7.49472 126.803 7.49472Z"
          fill="#231F20"
        />
        <path
          d="M21.972 14.5285C21.972 14.5285 21.9429 14.4704 21.9429 14.3541C21.6813 13.1044 21.3907 10.3143 23.1926 9.09366C24.9655 7.90206 26.7384 9.2971 26.9999 10.0237C27.3778 11.099 26.5059 11.5931 24.9655 12C22.5532 12.6394 22.001 14.2088 21.972 14.5285Z"
          fill="#0D6DC0"
        />
        <path
          d="M22.4954 16.4464C18.8915 14.7026 19.2984 11.7962 18.8334 10.3431C16.2758 1.39156 11.1898 0.752164 10.5794 0.7231C9.18439 0.694037 6.68494 1.33343 6.51056 4.18163C6.24899 8.22144 11.9454 7.95987 14.067 9.61648C15.5202 10.7499 16.5083 13.2494 13.8636 12.6972C10.6666 11.5056 6.91745 11.8544 5.34803 12.4937C2.70327 13.5982 1.8023 15.0223 1.54074 15.3129C1.27917 15.6035 -1.97593 20.1374 1.97668 23.8575C5.08646 26.7929 9.3297 24.7003 10.9863 22.3753C11.9745 20.9802 12.7011 19.5852 13.4858 18.3645C16.5955 14.5572 20.3156 17.0567 21.3038 18.8005C22.8151 21.5034 23.8614 22.8693 25.1983 21.9684C26.5352 21.0965 26.3608 18.3355 22.4954 16.4464Z"
          fill="#0D6DC0"
        />
      </g>
      <defs>
        <clipPath id={clipPath}>
          <rect width="128" height="28" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
