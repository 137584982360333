import { useCallback, useEffect, useMemo, useState } from 'react';

import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  OTablePlugin,
  OTableResult,
  OTableSourceData,
  OTableSourceDataList,
  OTableSourceGroup,
  OTableSourceGroupList,
} from '@/components/ObservableTable/types';
import { FormatSourceGroupProps, formatSourceGroup } from '@/components/ObservableTable/utils/group';
import { formatSourceData } from '@/components/ObservableTable/utils/table';
import { arrayToExistence, arrayToHashTable } from '@/utils/hashTable';

export const OTableRowTypes = {
  ROW: 'ROW',
  GROUP: 'GROUP',
} as const;
export type OTableRowTypeKeys = keyof typeof OTableRowTypes;
export type OTableRowTypeValues = (typeof OTableRowTypes)[OTableRowTypeKeys];

export type GroupParams<G extends OTableSourceGroup> = {
  groups: OTableSourceGroupList<G>;
};
export const OTableViewStates = {
  BASIC: 'BASIC',
  GROUP: 'GROUP',
} as const;
type OTableViewStateKeys = keyof typeof OTableViewStates;
export type OTableViewStateValues = (typeof OTableViewStates)[OTableViewStateKeys];

type UseObservableTableProps<D extends OTableSourceData, G extends OTableSourceGroup> = {
  plugins?: Array<OTablePlugin>;
  initStates?: {
    data?: OTableSourceDataList<D>;
    groups?: OTableSourceGroupList<G>;
    viewState?: OTableViewStateValues;
  };
  groupSort?: FormatSourceGroupProps<G>['groupSort'];
};

export type OTableResultStates = {
  viewState: OTableViewStateValues;
};

type UpdateData<D extends OTableSourceData, G extends OTableSourceGroup> = (
  data: OTableSourceDataList<D>,
  groups?: OTableSourceGroupList<G>,
) => void;
type GetAllRowCountInGroup = (gid: string) => number;
type GetAllRowIdsInGroup = (gid: string) => Array<string>;
type OnChangeViewState = (viewState: OTableViewStateValues) => void;
type UseObservableTableResult<D extends OTableSourceData, G extends OTableSourceGroup> = {
  result: OTableResult<D, G>;
  updateData: UpdateData<D, G>;
  appendData: UpdateData<D, G>;
  getAllRowCountInGroup: GetAllRowCountInGroup;
  getAllRowIdsInGroup: GetAllRowIdsInGroup;
  onChangeViewState: OnChangeViewState;
};

function useObservableTable<D extends OTableSourceData, G extends OTableSourceGroup = OTableSourceGroup>(
  props: UseObservableTableProps<D, G>,
): UseObservableTableResult<D, G> {
  const { plugins = [], initStates = {}, groupSort } = props;

  const initResult: OTableResult<D, G> = useMemo(
    () => {
      const { data, groups, viewState } = initStates;

      return {
        data: data ?? [],
        groups,
        rows: [],
        rowHashMap: {},
        states: {
          viewState: viewState ?? OTableViewStates.BASIC,
        },
      };
    },
    [], // eslint-disable-line
  );
  const [result, setResult] = useState<OTableResult<D, G>>(initResult);

  const subject = useMemo(
    () => new BehaviorSubject<OTableResult<D, G>>(initResult),
    [], // eslint-disable-line
  );

  useEffect(() => {
    let tmpListener = subject.pipe(map(formatSourceData));

    const prePlugins = plugins.filter((plugin) => plugin.type === 'groupSort');
    prePlugins.forEach((prePlugin) => {
      tmpListener = tmpListener.pipe(switchMap(prePlugin.getPlugin));
    });

    tmpListener = tmpListener.pipe(map((tableResult) => formatSourceGroup(tableResult, { groupSort })));

    const postPlugins = plugins.filter((plugin) => plugin.type !== 'groupSort');
    postPlugins.forEach((postPlugin) => {
      tmpListener = tmpListener.pipe(switchMap(postPlugin.getPlugin));
    });

    const listener = tmpListener.subscribe((tableResult: OTableResult<D, G>) => {
      setResult(tableResult);
    });

    return () => {
      listener.unsubscribe();
    };
  }, []); // eslint-disable-line

  const updateData: UpdateData<D, G> = useCallback(
    (data: OTableSourceDataList<D>, groups?: OTableSourceGroupList<G>) => {
      const nextValue = {
        ...subject.getValue(),
        data,
      };

      if (groups) {
        nextValue.groups = groups;
      }

      subject.next(nextValue);
    },
    [subject],
  );

  const onChangeViewState: OnChangeViewState = useCallback(
    (viewState: OTableViewStateValues) => {
      const value = subject.getValue();

      subject.next({
        ...value,
        states: {
          ...value.states,
          viewState,
        },
      });
    },
    [subject],
  );

  const appendData: UpdateData<D, G> = useCallback(
    (appendData: OTableSourceDataList<D>, appendGroups?: OTableSourceGroupList<G>) => {
      const value = subject.getValue();

      const nextDataHashMap = arrayToHashTable(appendData, { mainKey: 'id' });
      const prevData = value.data.map((item) => {
        if (nextDataHashMap[item.id]) {
          return {
            ...item,
            ...nextDataHashMap[item.id],
          };
        }

        return item;
      });

      const prevDataHashMap = arrayToExistence(value.data, { mainKey: 'id' });
      const nextData = appendData.filter((item) => !prevDataHashMap[item.id]);

      const nextValue = {
        ...value,
        data: [...prevData, ...nextData],
      };

      if (appendGroups) {
        const nextGroupHashMap = arrayToHashTable(appendGroups, { mainKey: 'id' });
        const prevGroups =
          value?.groups?.map((item) => {
            if (nextGroupHashMap[item.id]) {
              return {
                ...item,
                ...nextGroupHashMap[item.id],
              };
            }

            return item;
          }) ?? [];

        const prevGroupHashMap = arrayToExistence(prevGroups, { mainKey: 'id' });
        const nextGroups = appendGroups.filter((item) => !prevGroupHashMap[item.id]);

        nextValue.groups = [...prevGroups, ...nextGroups];
      }

      subject.next(nextValue);
    },
    [subject],
  );

  const getAllRowCountInGroup: GetAllRowCountInGroup = (gid) => {
    return result?.groupHashMap?.[gid]?.rowIds?.length ?? 0;
  };

  const getAllRowIdsInGroup: GetAllRowIdsInGroup = (gid) => {
    return result?.groupHashMap?.[gid]?.rowIds ?? [];
  };

  return {
    result,
    updateData,
    appendData,
    getAllRowCountInGroup,
    getAllRowIdsInGroup,
    onChangeViewState,
  };
}

export default useObservableTable;
