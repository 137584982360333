import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleSupportSessionTake({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M9 11L12 14L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M12 3L12 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.89543 2 3 2.89543 3 4V20C3 21.1046 3.89543 22 5 22H9.17087H9.87856L10.1139 21.3326C10.2097 21.061 10.3662 20.8054 10.5858 20.5858C11.3669 19.8047 12.6332 19.8047 13.4142 20.5858C13.6339 20.8054 13.7904 21.061 13.8861 21.3326L14.1215 22H14.8292L19 22C20.1046 22 21 21.1046 21 20V4C21 2.89543 20.1046 2 19 2H15.0001V4L19 4V20H15.465C15.2936 19.7033 15.0812 19.4243 14.8285 19.1716C13.2664 17.6095 10.7337 17.6095 9.17161 19.1716C8.91883 19.4243 8.7065 19.7033 8.53507 20H5L5 4L9.00006 4V2H5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
