import { useMemo, useState } from 'react';

import { addMonths, set, subMonths } from 'date-fns';

import { getCalendarName } from '../utils';

type UseRangedCalendar = (params: { mode: 'past' | 'future'; startDate: Date; endDate: Date }) => {
  calendarStartDate: Date;
  calendarEndDate: Date;
  resetCalendar: (start: Date, end: Date) => void;
  calendarStartName: string;
  calendarEndName: string;
  goPrevMonth: () => void;
  goNextMonth: () => void;
};

export const useRangedCalendar: UseRangedCalendar = ({ mode, startDate, endDate }) => {
  const [calendarDate, setCalendarDate] = useState<Date>(
    mode === 'future'
      ? set(startDate, { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
      : subMonths(set(endDate, { date: 1, hours: 1, minutes: 0, seconds: 0, milliseconds: 0 }), 1),
  );

  // date of calendar on the left
  const calendarStartDate = useMemo(() => {
    return set(calendarDate, { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }, [calendarDate]);

  // date of calendar on the right
  const calendarEndDate = useMemo(() => {
    const ending = set(calendarStartDate, { date: 0, hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
    return addMonths(ending, 2);
  }, [calendarStartDate]);

  const resetCalendar = (start: Date, end: Date) => {
    const newCalendarDate = mode === 'future' ? start : subMonths(end, 1);
    setCalendarDate(newCalendarDate);
  };

  return {
    calendarStartDate,
    calendarEndDate,
    resetCalendar,
    calendarStartName: getCalendarName(calendarStartDate),
    calendarEndName: getCalendarName(calendarEndDate),
    goPrevMonth: () => setCalendarDate(subMonths(calendarDate, 1)),
    goNextMonth: () => setCalendarDate(addMonths(calendarDate, 1)),
  };
};
