import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowUp16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" color={color}>
      <path
        d="M3.62344 10.7819C3.19158 11.1262 2.56241 11.0552 2.21814 10.6233C1.87388 10.1915 1.94488 9.56231 2.37674 9.21805L7.39443 5.21805C7.76016 4.9265 8.27911 4.9274 8.64383 5.22021L13.6261 9.22021C14.0568 9.56596 14.1256 10.1954 13.7799 10.626C13.4341 11.0567 12.8047 11.1255 12.374 10.7798L8.01557 7.28062L3.62344 10.7819Z"
        fill="currentColor"
      />
    </Icon>
  );
}
