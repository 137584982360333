import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { INFO_QUERY } from '../../common';
import {
  checkUserIsShareableResponseSchema,
  computerRemoteControlPermissionsSchema,
  getComputerSharedModeResponseSchema,
  updateComputerSharedModeResponseSchema,
  updateStreamerResponseSchema,
} from '../schemas';
import type { UpdateComputerSharedModePayload } from '../types';

// TODO: Move each service function to new independent file

export function updateStreamers(computerIdList: Array<number>) {
  return checkResponse(axios.post('/api/web/v1/computers/force_upgrade', { server_ids: computerIdList }), updateStreamerResponseSchema);
}

/**
 * This API is **use for SBA team only**
 *
 * Get the shared mode for target computer.
 *
 * You may need a API to list all team member infos (User Searchable Info API).
 */
export function getComputerSharedMode(teamId: number, computerId: number) {
  return checkResponse(axios.get(`/api/web/v1/teams/${teamId}/shared/computers/${computerId}`), getComputerSharedModeResponseSchema);
}

/**
 * This API is **use for SBA team only**
 *
 * Change the shared mode for target computer.
 */
export function updateComputerSharedMode(teamId: number, computerId: number, payload: UpdateComputerSharedModePayload) {
  return checkResponse(
    axios.patch(`/api/web/v1/teams/${teamId}/shared/computers/${computerId}`, payload),
    updateComputerSharedModeResponseSchema,
  );
}

/**
 * This API is **use for SBA team only**
 *
 * Check whether a user (not in current team) can be shared or not, return info if shareable.
 *
 * There is a `share` feature (SBA) on PCP, the share flow need this API:
 * - When user want to share his managed computer to target_user, he need key-in the email first.
 * - If user can share to target_user (Backend check it by email), show the target_user can be share on web-page.
 * - When user click submit, send all the shared_target (user-id for users out of the team) in one request.
 */
export function checkUserIsShareable(teamId: number, computerId: number, email: string) {
  return checkResponse(
    axios.get(`/api/web/v1/teams/${teamId}/shared/computers/${computerId}/shareable_user_check`, { params: { email } }),
    checkUserIsShareableResponseSchema,
  );
}

export function getComputerRemoteControlPermissions() {
  return checkResponse(
    axios.post(INFO_QUERY, { team_permissions: ['rdp_computer', 'vnc_computer'] }),
    computerRemoteControlPermissionsSchema,
  );
}
