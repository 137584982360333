import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleMac({ color = 'black' }: RippleIconProps) {
  const clipPath1 = useId();
  const radialGradient1 = useId();

  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <g clipPath={`url(#${clipPath1})`}>
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
        <path
          d="M16.3797 11.5001C16.3869 10.8876 16.5494 10.287 16.8519 9.75445C17.1545 9.2219 17.5872 8.77483 18.1097 8.45508C17.778 7.98256 17.3412 7.59346 16.8337 7.31842C16.3261 7.04338 15.7616 6.88991 15.1847 6.87008C13.9397 6.74508 12.7547 7.60508 12.1197 7.60508C11.4847 7.60508 10.5147 6.89008 9.47966 6.90508C8.80222 6.92432 8.1413 7.11864 7.56125 7.46913C6.98119 7.81961 6.50177 8.31432 6.16966 8.90508C4.75966 11.3551 5.80966 14.9801 7.16966 16.9701C7.83966 17.9401 8.64466 19.0301 9.69466 18.9701C10.7447 18.9101 11.0897 18.3151 12.3147 18.3151C13.5397 18.3151 13.8997 19.0001 14.9697 19.0001C16.0397 19.0001 16.7547 18.0001 17.4197 17.0351C17.891 16.3255 18.2596 15.5529 18.5147 14.7401C17.8831 14.4649 17.3452 14.0122 16.9661 13.437C16.5871 12.8619 16.3834 12.1889 16.3797 11.5001V11.5001Z"
          fill="currentColor"
        />
        <path
          d="M14.3647 5.555C14.6654 5.20516 14.894 4.79934 15.0373 4.36085C15.1806 3.92237 15.2358 3.45989 15.1997 3C14.2886 3.08657 13.4456 3.51972 12.8447 4.21C12.5478 4.546 12.3213 4.93819 12.1787 5.36333C12.0362 5.78847 11.9804 6.23791 12.0147 6.685C12.4667 6.68857 12.9135 6.58859 13.3209 6.39271C13.7282 6.19684 14.0853 5.91028 14.3647 5.555V5.555Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <radialGradient
          id={radialGradient1}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 12) rotate(90) scale(12)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
        <clipPath id={clipPath1}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
