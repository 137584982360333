import { IconButton, IconButtonProps, forwardRef } from '@chakra-ui/react';

export type RippleIconButtonProps = IconButtonProps;
export const RippleIconButton = forwardRef<RippleIconButtonProps, 'button'>((props, ref) => {
  const { isDisabled, width = '32px', height = '32px', variant = 'grayScaleGhost' } = props;
  return (
    <IconButton
      minWidth="initial"
      width={width}
      height={height}
      border={0}
      _hover={{
        bg: isDisabled ? 'transparent' : 'blue.10',
      }}
      _active={{
        bg: isDisabled ? 'transparent' : 'blue.20',
      }}
      _disabled={{
        bg: 'transparent',
      }}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      isDisabled={isDisabled}
      ref={ref}
      variant={variant}
      {...props}
    />
  );
});
