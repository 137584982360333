import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleWatermark({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17H4C3.44772 17 3 16.5523 3 16V4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V16C21 16.5523 20.5523 17 20 17H13V19H16C16.5523 19 17 19.4477 17 20C17 20.5523 16.5523 21 16 21H8C7.44771 21 7 20.5523 7 20C7 19.4477 7.44771 19 8 19H11V17ZM5 15V5H19V15H5Z"
        fill="currentColor"
      />
      <path
        d="M12.1814 12.5209L9.70637 6.61391C9.57437 6.28391 9.33237 6.10791 9.00237 6.10791C8.67237 6.10791 8.43037 6.28391 8.29837 6.61391L5.81237 12.5209C5.75737 12.6749 5.72437 12.8069 5.72437 12.9169C5.72437 13.3129 6.04337 13.6319 6.46137 13.6319C6.78037 13.6319 7.02237 13.4449 7.15437 13.1039L7.46237 12.3559H10.5314L10.8394 13.1039C10.9824 13.4449 11.2244 13.6319 11.5434 13.6319C11.9614 13.6319 12.2694 13.3129 12.2694 12.9169C12.2694 12.8069 12.2474 12.6749 12.1814 12.5209ZM9.00237 8.29691L10.0364 11.1349H7.94637L9.00237 8.29691Z"
        fill="currentColor"
      />
      <path
        d="M15.5926 7.93391C14.8006 7.93391 14.0856 8.17591 13.6236 8.60491C13.4256 8.79191 13.3156 8.98991 13.3156 9.19891C13.3156 9.52891 13.5906 9.80391 13.9536 9.80391C14.0966 9.80391 14.2176 9.75991 14.3936 9.62791C14.7126 9.35291 15.0536 9.22091 15.4386 9.22091C16.0986 9.22091 16.4616 9.57291 16.4616 10.1779V10.4199H15.0976C13.8216 10.4199 13.0406 11.0249 13.0406 12.0039C13.0406 12.9719 13.7996 13.6319 14.9216 13.6319C15.5706 13.6319 16.1206 13.4229 16.5166 13.0489C16.5826 13.3789 16.8686 13.6209 17.2316 13.6209C17.6386 13.6209 17.9466 13.3129 17.9466 12.8839V10.2659C17.9466 8.78091 17.0886 7.93391 15.5926 7.93391ZM15.3176 12.5429C14.8336 12.5429 14.5366 12.3119 14.5366 11.9489C14.5366 11.5859 14.8006 11.3989 15.2956 11.3989H16.4616V11.4649C16.4616 12.0809 15.9666 12.5429 15.3176 12.5429Z"
        fill="currentColor"
      />
    </Icon>
  );
}
