import {
  addDays,
  addMonths,
  addYears,
  format,
  getDate,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  isAfter,
  isBefore,
  isSameDay,
  lastDayOfMonth,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfMonth,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

function isSameDateByFormat(date1: Date, date2: Date, dateFormat: string) {
  return format(date1, dateFormat) === format(date2, dateFormat);
}

export function setDateYear(date: Date, year: number) {
  return setYear(date, year);
}

function setDateMonthForMinDate(date: Date, minDate: Date) {
  const isBeforeDate = isBefore(date, minDate);
  if (isBeforeDate) {
    return setMonth(date, getMonth(minDate));
  }

  return date;
}

function setDateDayForMinDate(date: Date, minDate: Date) {
  const isBeforeDate = isBefore(date, minDate);
  if (isBeforeDate) {
    return setDate(date, getDate(minDate));
  }

  return date;
}

function setDateHoursForMinDate(date: Date, minDate: Date) {
  const isBeforeDate = isBefore(date, minDate);
  if (isBeforeDate) {
    return setHours(date, getHours(minDate));
  }

  return date;
}

function setDateMinutesForMinDate(date: Date, minDate: Date) {
  const isBeforeDate = isBefore(date, minDate);

  if (isBeforeDate) {
    return setMinutes(date, getMinutes(minDate));
  }

  return date;
}

function setDateMonthForMaxDate(date: Date, maxDate: Date) {
  const isAfterDate = isAfter(date, maxDate);
  if (isAfterDate) {
    return setMonth(date, getMonth(maxDate));
  }

  return date;
}

function setDateDayForMaxDate(date: Date, maxDate: Date) {
  const isAfterDate = isAfter(date, maxDate);
  if (isAfterDate) {
    return setDate(date, getDate(maxDate));
  }

  return date;
}

function setDateHoursForMaxDate(date: Date, maxDate: Date) {
  const isAfterDate = isAfter(date, maxDate);
  if (isAfterDate) {
    return setHours(date, getHours(maxDate));
  }

  return date;
}

function setDateMinutesForMaxDate(date: Date, maxDate: Date) {
  const isAfterDate = isAfter(date, maxDate);

  if (isAfterDate) {
    return setMinutes(date, getMinutes(maxDate));
  }

  return date;
}

export function prevDateYear(date: Date, minDate?: Date) {
  let prevDate = subYears(date, 1);

  const isBeforeYear = minDate && isBefore(prevDate, minDate);
  if (isBeforeYear) {
    prevDate = setDateMonthForMinDate(prevDate, minDate);
    prevDate = setDateDayForMinDate(prevDate, minDate);
    prevDate = setDateHoursForMinDate(prevDate, minDate);
    prevDate = setDateMinutesForMinDate(prevDate, minDate);
  }

  return prevDate;
}

export function nextDateYear(date: Date, maxDate?: Date) {
  let nextDate = addYears(date, 1);

  const isAfterDate = maxDate && isAfter(nextDate, maxDate);
  if (isAfterDate) {
    nextDate = setDateMonthForMaxDate(nextDate, maxDate);
    nextDate = setDateDayForMaxDate(nextDate, maxDate);
    nextDate = setDateHoursForMaxDate(nextDate, maxDate);
    nextDate = setDateMinutesForMaxDate(nextDate, maxDate);
  }

  return nextDate;
}

export function canChangeToPrevYear(date: Date, minDate: Date) {
  const prevDate = prevDateYear(date);
  const isAfterYear = isAfter(prevDate, minDate);
  const isSameYear = isSameDateByFormat(prevDate, minDate, 'yyyy');

  return isAfterYear || isSameYear;
}

export function canChangeToNextYear(date: Date, maxDate: Date) {
  const nextDate = nextDateYear(date);
  const isBeforeYear = isBefore(nextDate, maxDate);
  const isSameYear = isSameDateByFormat(nextDate, maxDate, 'yyyy');

  return isBeforeYear || isSameYear;
}

export type MonthIndexes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export function setDateMonth(date: Date, month: MonthIndexes, minDate?: Date, maxDate?: Date) {
  let nextDate = setMonth(date, month);

  const isBeforeDate = minDate && isBefore(nextDate, minDate);
  if (isBeforeDate) {
    nextDate = setDateDayForMinDate(nextDate, minDate);
    nextDate = setDateHoursForMinDate(nextDate, minDate);
    nextDate = setDateMinutesForMinDate(nextDate, minDate);
  }

  const isAfterDate = maxDate && isAfter(nextDate, maxDate);
  if (isAfterDate) {
    nextDate = setDateDayForMaxDate(nextDate, maxDate);
    nextDate = setDateHoursForMaxDate(nextDate, maxDate);
    nextDate = setDateMinutesForMaxDate(nextDate, maxDate);
  }

  return nextDate;
}

export function prevDateMonth(date: Date, minDate?: Date) {
  let prevDate = subMonths(date, 1);

  const isBeforeDate = minDate && isBefore(prevDate, minDate);
  if (isBeforeDate) {
    prevDate = setDateDayForMinDate(prevDate, minDate);
    prevDate = setDateHoursForMinDate(prevDate, minDate);
    prevDate = setDateMinutesForMinDate(prevDate, minDate);
  }

  return prevDate;
}

export function nextDateMonth(date: Date, maxDate?: Date) {
  let nextDate = addMonths(date, 1);

  const isAfterDate = maxDate && isAfter(nextDate, maxDate);
  if (isAfterDate) {
    nextDate = setDateDayForMaxDate(nextDate, maxDate);
    nextDate = setDateHoursForMaxDate(nextDate, maxDate);
    nextDate = setDateMinutesForMaxDate(nextDate, maxDate);
  }

  return nextDate;
}

const matchMinDate = (nextDate: Date, minDate?: Date) => (minDate ? isAfter(nextDate, minDate) : true);
const matchMaxDate = (nextDate: Date, maxDate?: Date) => (maxDate ? isBefore(nextDate, maxDate) : true);
const matchMinAndMaxDate = (nextDate: Date, minDate?: Date, maxDate?: Date) =>
  matchMinDate(nextDate, minDate) && matchMaxDate(nextDate, maxDate);

export function canChangeToMonth(date: Date, month: MonthIndexes, minDate?: Date, maxDate?: Date) {
  const nextDate = setDateMonth(date, month);
  const isSameDateWithMinDate = minDate ? matchMinDate(nextDate, minDate) || isSameDateByFormat(nextDate, minDate, 'yyyyMM') : true;
  const isSameDateWithMaxDate = maxDate ? matchMaxDate(nextDate, maxDate) || isSameDateByFormat(nextDate, maxDate, 'yyyyMM') : true;

  return matchMinAndMaxDate(nextDate, minDate, maxDate) || (isSameDateWithMinDate && isSameDateWithMaxDate);
}

export function canChangeToDate(date: Date, minDate?: Date, maxDate?: Date) {
  const isSameDateWithMinDate = minDate ? matchMinDate(date, minDate) || isSameDateByFormat(date, minDate, 'yyyyMMdd') : true;
  const isSameDateWithMaxDate = maxDate ? matchMaxDate(date, maxDate) || isSameDateByFormat(date, maxDate, 'yyyyMMdd') : true;

  return matchMinAndMaxDate(date, minDate, maxDate) || (isSameDateWithMinDate && isSameDateWithMaxDate);
}

export function canChangeToPrevMonth(date: Date, minDate: Date) {
  const prevDate = prevDateMonth(date);
  const isAfterYear = isAfter(prevDate, minDate);
  const isSameMonth = isSameDateByFormat(prevDate, minDate, 'yyyyMM');

  return isAfterYear || isSameMonth;
}

export function canChangeToNextMonth(date: Date, maxDate: Date) {
  const nextDate = nextDateMonth(date);
  const isBeforeYear = isBefore(nextDate, maxDate);
  const isSameMonth = isSameDateByFormat(nextDate, maxDate, 'yyyyMM');

  return isBeforeYear || isSameMonth;
}

export type DayOfMonthIndexes =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31;
export type WeekdayIndexes = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export function setDateMonthAndDay(date: Date, minDate?: Date, maxDate?: Date) {
  let nextDate = date;

  const isBeforeMinDate = minDate && isBefore(nextDate, minDate);
  if (isBeforeMinDate) {
    nextDate = setDateHoursForMinDate(nextDate, minDate);
    nextDate = setDateMinutesForMinDate(nextDate, minDate);
  }

  const isAfterMaxDate = maxDate && isAfter(nextDate, maxDate);
  if (isAfterMaxDate) {
    nextDate = setDateHoursForMaxDate(nextDate, maxDate);
    nextDate = setDateMinutesForMaxDate(nextDate, maxDate);
  }

  return nextDate;
}

export function setDateHours(date: Date, hours: number, minDate?: Date, maxDate?: Date) {
  let nextDate = setHours(date, hours);

  const isBeforeDate = minDate && isBefore(nextDate, minDate);
  if (isBeforeDate) {
    nextDate = setDateMinutesForMinDate(nextDate, minDate);
  }
  const isAfterDate = maxDate && isAfter(nextDate, maxDate);
  if (isAfterDate) {
    nextDate = setDateMinutesForMaxDate(nextDate, maxDate);
  }

  return nextDate;
}

export function setDateMinutes(date: Date, minutes: number) {
  return setMinutes(date, minutes);
}

export function canChangeToHours(date: Date, hours: number, minDate?: Date, maxDate?: Date) {
  const nextDate = setDateHours(date, hours);

  const matchMinDate = minDate ? isAfter(nextDate, minDate) : true;
  const matchMaxDate = maxDate ? isBefore(nextDate, maxDate) : true;

  const isSameMinDate = minDate ? isSameDateByFormat(nextDate, minDate, 'yyyyMMddHH') : true;
  const isSameMaxDate = maxDate ? isSameDateByFormat(nextDate, maxDate, 'yyyyMMddHH') : true;

  return (matchMinDate && matchMaxDate) || (isSameMinDate && isSameMaxDate);
}

export function canChangeToMinutes(date: Date, minutes: number, minDate?: Date, maxDate?: Date) {
  const nextDate = setDateMinutes(date, minutes);

  const matchMinDate = minDate ? isAfter(nextDate, minDate) : true;
  const matchMaxDate = maxDate ? isBefore(nextDate, maxDate) : true;
  const isSameMinDate = minDate ? isSameDateByFormat(nextDate, minDate, 'yyyyMMddHHmm') : true;
  const isSameMaxDate = maxDate ? isSameDateByFormat(nextDate, maxDate, 'yyyyMMddHHmm') : true;

  return (matchMinDate && matchMaxDate) || (isSameMinDate && isSameMaxDate);
}

export type DayButtonProps = {
  date: Date;
  monthIndex: MonthIndexes;
  dayOfMonthIndex: DayOfMonthIndexes;
  WeekdayIndex: WeekdayIndexes;
  isInMonth: boolean;
  isSelected: boolean;
};

const SUNDAY_INDEX = 0;

export function getDayButtons(date: Date): Array<DayButtonProps> {
  const currentMonthIndex = getMonth(date);
  let startDate = startOfMonth(date);
  const startWeekdayIndex = getDay(startDate);

  if (startWeekdayIndex !== SUNDAY_INDEX) {
    const prevMonthDate = lastDayOfMonth(prevDateMonth(date));
    startDate = subDays(prevMonthDate, startWeekdayIndex - 1);
  }

  return Array(7 * 6)
    .fill(null)
    .map((_, index) => {
      const currentDate = index === 0 ? startDate : addDays(startDate, index);
      const monthIndex = getMonth(currentDate) as MonthIndexes;
      const dayOfMonthIndex = getDate(currentDate) as DayOfMonthIndexes;
      const WeekdayIndex = getDay(currentDate) as WeekdayIndexes;
      const isInMonth = getMonth(currentDate) === currentMonthIndex;
      const isSelected = isSameDay(date, currentDate);

      return {
        date: currentDate,
        monthIndex,
        dayOfMonthIndex,
        WeekdayIndex,
        isInMonth,
        isSelected,
      };
    });
}

export const getRangePickDayButtonProps = (calendarDate: Date, startDate: Date, endDate: Date): Array<DayButtonProps> => {
  const currentMonthIndex = getMonth(calendarDate);
  let monthStart = startOfMonth(calendarDate);
  const startWeekdayIndex = getDay(monthStart);

  if (startWeekdayIndex !== SUNDAY_INDEX) {
    const prevMonthDate = lastDayOfMonth(prevDateMonth(calendarDate));
    monthStart = subDays(prevMonthDate, startWeekdayIndex - 1);
  }

  return Array.from({ length: 42 }, (_, index) => {
    const date = index === 0 ? monthStart : addDays(monthStart, index);
    const monthIndex = getMonth(date) as MonthIndexes;
    const dayOfMonthIndex = getDate(date) as DayOfMonthIndexes;
    const WeekdayIndex = getDay(date) as WeekdayIndexes;
    const isInMonth = getMonth(date) === currentMonthIndex;
    const isSelected = isSameDay(date, startDate) || isSameDay(date, endDate);

    return {
      date,
      monthIndex,
      dayOfMonthIndex,
      WeekdayIndex,
      isInMonth,
      isSelected,
    };
  });
};

type HourButtonProps = {
  hourIndex: number;
  isSelected: boolean;
  isDisabled: boolean;
};

export function getHourButtons(date: Date, minDate?: Date, maxDate?: Date): Array<HourButtonProps> {
  const hours = getHours(date);

  return Array(24)
    .fill(null)
    .map((_, index) => {
      const isSelected = hours === index;
      const isDisabled = !canChangeToHours(date, index, minDate, maxDate);

      return {
        hourIndex: index,
        isSelected,
        isDisabled,
      };
    });
}

type MinuteButtonProps = {
  minuteIndex: number;
  isSelected: boolean;
  isDisabled: boolean;
};

export function getMinuteButtons(date: Date, minDate?: Date, maxDate?: Date): Array<MinuteButtonProps> {
  const minutes = getMinutes(date);

  return Array(60)
    .fill(null)
    .map((_, index) => {
      const isSelected = minutes === index;
      const isDisabled = !canChangeToMinutes(date, index, minDate, maxDate);

      return {
        minuteIndex: index,
        isSelected,
        isDisabled,
      };
    });
}
