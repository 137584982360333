import { Flex, forwardRef, useControllableState, useFormControl, useMultiStyleConfig } from '@chakra-ui/react';
import type { StyleProps, UseFormControlProps } from '@chakra-ui/react';

import { RippleMenu, RippleMenuButton, RippleMenuDivider, RippleMenuGroup, RippleMenuList } from '../RippleMenu';
import { RippleTypography } from '../RippleTypography';
import { RippleSelectContextProvider, RippleSelectLabel, RippleSelectOptionWithCheckbox } from './RippleSelect';
import type { RippleSelectOptionProps } from './RippleSelect';
import { RippleSelectButtonWithContext } from './RippleSelectButton';

export type RippleMultipleSelectProps<T extends string = string> = {
  children: React.ReactNode;
  value: Array<T> | undefined;
  placeholder: React.ReactNode;
  customLabel?: React.ReactNode; // Custom label for the select button
  onChange: (value: Array<T> | undefined) => void;
  variant?: 'border' | 'borderless';
  size?: 'lg' | 'md' | 'sm' | 'xs';
  helperText?: string;
  onCloseMenuList?: () => void;
} & StyleProps &
  UseFormControlProps<HTMLButtonElement>;

function RippleMultipleSelectCore(props: RippleMultipleSelectProps, ref: React.ForwardedRef<any>): React.JSX.Element {
  const { customLabel, onCloseMenuList, ...selectProps } = props;
  const { children, value, placeholder, onChange, variant = 'border', size = 'sm', helperText, ...otherProps } = selectProps;
  const styles = useMultiStyleConfig('rippleSelect', { variant, size });
  const formProps = useFormControl<HTMLButtonElement>(selectProps);
  const { id: _id, onFocus: _onFocus, onBlur: _onBlur, ...helperTextProps } = formProps;

  return (
    <RippleSelectContextProvider type="multiple" value={value} onChange={onChange}>
      <Flex direction="column">
        <RippleMenu closeOnSelect={false} closeOnBlur matchWidth onClose={onCloseMenuList}>
          <RippleMenuButton
            ref={ref}
            as={RippleSelectButtonWithContext}
            size={size}
            isShowCount={value && Boolean(value.length > 1)}
            count={value?.length}
            {...formProps}
            {...otherProps}
          >
            <RippleSelectLabel placeholder={placeholder} customLabel={customLabel} />
          </RippleMenuButton>
          <RippleMenuList maxWidth="100%" zIndex="dropdown" position="relative">
            {children}
          </RippleMenuList>
        </RippleMenu>
        {helperText && (
          <RippleTypography variant="body03" __css={styles.helperText} {...helperTextProps}>
            {helperText}
          </RippleTypography>
        )}
      </Flex>
    </RippleSelectContextProvider>
  );
}

export const RippleMultipleSelect = forwardRef(RippleMultipleSelectCore) as <T extends string>(
  props: RippleMultipleSelectProps<T> & { ref?: React.ForwardedRef<any> },
) => React.JSX.Element;

export type RippleMultipleSelectOptionProps = RippleSelectOptionProps;
export const RippleMultipleSelectOption = RippleSelectOptionWithCheckbox;

export const RippleMultipleSelectOptionGroup = RippleMenuGroup;

export const RippleMultipleSelectOptionDivider = RippleMenuDivider;

export type UseRippleMultipleSelectOptions<T extends string> = {
  defaultValue?: Array<T> | undefined;
  onChange?: (value: Array<T> | undefined) => void;
};

export type UseRippleMultipleSelectReturn<T extends string> = {
  value: Array<T> | undefined;
  setValue: React.Dispatch<React.SetStateAction<Array<T> | undefined>>;
  selectProps: {
    value: Array<T> | undefined;
    onChange: React.Dispatch<React.SetStateAction<Array<T> | undefined>>;
  };
};

export function useRippleMultipleSelect<T extends string = string>({
  defaultValue,
  onChange,
}: UseRippleMultipleSelectOptions<T> = {}): UseRippleMultipleSelectReturn<T> {
  const [value, setValue] = useControllableState<Array<T> | undefined>({
    defaultValue,
    onChange,
  });

  return {
    value,
    setValue,
    selectProps: {
      value,
      onChange: setValue,
    },
  };
}
