import { Icon, useToken } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleAlert({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13C13 13.553 12.552 14 12 14C11.448 14 11 13.553 11 13V9C11 8.448 11.448 8 12 8C12.552 8 13 8.448 13 9V13ZM12 18.2C11.3376 18.2 10.8 17.6636 10.8 17C10.8 16.3364 11.3376 15.8 12 15.8C12.6624 15.8 13.2 16.3364 13.2 17C13.2 17.6636 12.6624 18.2 12 18.2V18.2ZM20.722 16.986L13.693 4.978C12.931 3.674 11.069 3.674 10.307 4.978L3.27796 16.986C2.49996 18.316 3.44596 20 4.97196 20H19.028C20.554 20 21.5 18.316 20.722 16.986V16.986Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13C13 13.553 12.552 14 12 14C11.448 14 11 13.553 11 13V9C11 8.448 11.448 8 12 8C12.552 8 13 8.448 13 9V13Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0008 18.2C11.3384 18.2 10.8008 17.6636 10.8008 17C10.8008 16.3364 11.3384 15.8 12.0008 15.8C12.6632 15.8 13.2008 16.3364 13.2008 17C13.2008 17.6636 12.6632 18.2 12.0008 18.2Z"
        fill="none"
      />
    </Icon>
  );
}

export function RippleAlert16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7836 11.7395L9.31675 2.7335C8.72409 1.7555 7.27591 1.7555 6.68325 2.7335L1.21638 11.7395C0.611281 12.737 1.34704 14 2.5339 14H13.4661C14.653 14 15.3887 12.737 14.7836 11.7395ZM7.19995 5.8C7.19995 5.35817 7.55812 5 7.99995 5C8.44178 5 8.79995 5.35817 8.79995 5.8V8.2C8.79995 8.64183 8.44178 9 7.99995 9C7.55812 9 7.19995 8.64183 7.19995 8.2V5.8ZM7 11C7 11.553 7.448 12 8 12C8.552 12 9 11.553 9 11C9 10.447 8.552 10 8 10C7.448 10 7 10.447 7 11Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export function RippleAlertSpecificColor({ color = 'yellow.100' }: RippleIconProps) {
  const [dark90] = useToken('colors', ['dark.90']);

  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13C13 13.553 12.552 14 12 14C11.448 14 11 13.553 11 13V9C11 8.448 11.448 8 12 8C12.552 8 13 8.448 13 9V13ZM12 18.2C11.3376 18.2 10.8 17.6636 10.8 17C10.8 16.3364 11.3376 15.8 12 15.8C12.6624 15.8 13.2 16.3364 13.2 17C13.2 17.6636 12.6624 18.2 12 18.2V18.2ZM20.722 16.986L13.693 4.978C12.931 3.674 11.069 3.674 10.307 4.978L3.27796 16.986C2.49996 18.316 3.44596 20 4.97196 20H19.028C20.554 20 21.5 18.316 20.722 16.986V16.986Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13C13 13.553 12.552 14 12 14C11.448 14 11 13.553 11 13V9C11 8.448 11.448 8 12 8C12.552 8 13 8.448 13 9V13Z"
        fill={dark90}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0008 18.2C11.3384 18.2 10.8008 17.6636 10.8008 17C10.8008 16.3364 11.3384 15.8 12.0008 15.8C12.6632 15.8 13.2008 16.3364 13.2008 17C13.2008 17.6636 12.6632 18.2 12.0008 18.2Z"
        fill={dark90}
      />
    </Icon>
  );
}

export function RippleAlertSpecificColor16({ color = 'yellow.100' }: RippleIconProps) {
  const [dark90] = useToken('colors', ['dark.90']);

  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7836 11.7395L9.31675 2.7335C8.72409 1.7555 7.27591 1.7555 6.68325 2.7335L1.21638 11.7395C0.611281 12.737 1.34704 14 2.5339 14H13.4661C14.653 14 15.3887 12.737 14.7836 11.7395Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C7.448 12 7 11.553 7 11C7 10.447 7.448 10 8 10C8.552 10 9 10.447 9 11C9 11.553 8.552 12 8 12Z"
        fill={dark90}
      />
      <rect x="7.19995" y="5" width="1.6" height="4" rx="0.8" fill={dark90} />
    </Icon>
  );
}
