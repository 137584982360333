import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, forwardRef } from '@chakra-ui/react';
import type {
  DrawerContentProps,
  DrawerProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlayProps,
} from '@chakra-ui/react';

import { RippleButton } from '../RippleButton';
import type { RippleButtonProps } from '../RippleButton';

export const RippleDrawer = (props: DrawerProps) => <Drawer placement="right" {...props} />;
export const RippleDrawerContent = forwardRef<DrawerContentProps, 'div'>((props, ref) => (
  <DrawerContent ref={ref} minWidth="600px" {...props} />
));
export const RippleDrawerHeader = forwardRef<ModalHeaderProps, 'div'>((props, ref) => (
  <DrawerHeader ref={ref} padding="24px 24px 16px" {...props} />
));
export const RippleDrawerBody = forwardRef<ModalBodyProps, 'div'>((props, ref) => <DrawerBody ref={ref} {...props} />);
export const RippleDrawerFooter = forwardRef<ModalFooterProps, 'div'>((props, ref) => (
  <DrawerFooter ref={ref} height="72px" borderTopWidth="1px" borderTopStyle="solid" borderTopColor="neutral.60" {...props} />
));
export const RippleDrawerCloseButton = forwardRef<RippleButtonProps, 'button'>((props, ref) => (
  <RippleButton data-testid="close" size="sm" px="20px" ref={ref} {...props} />
));
export const RippleDrawerOverlay = forwardRef<ModalOverlayProps, 'div'>((props, ref) => (
  <DrawerOverlay ref={ref} background="none" {...props} />
));
