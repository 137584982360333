import { createStandaloneToast } from '@chakra-ui/react';

import theme from '../theme';
import { FlexMessageBox } from './FlexMessageBox';
import { RippleFlashMessageOptions } from './types';

const { ToastContainer, toast: originalToast } = createStandaloneToast({ theme });

export const RippleFlashMessageStandaloneContainer = ToastContainer;

export function showRippleFlashMessageStandalone(options: RippleFlashMessageOptions) {
  const { id, variant, title, position } = options;

  originalToast({
    id,
    position: position ?? 'bottom',
    duration: 5000,
    render: ({ onClose }) => {
      return (
        <FlexMessageBox variant={variant} onClose={onClose}>
          {title}
        </FlexMessageBox>
      );
    },
  });
}
