import { z } from 'zod';

export const authTokensSchema = z.object({
  access_token: z.string(),
  access_token_ttl: z.number().describe('時間長度(單位秒)'),
  refresh_token: z.string(),
  refresh_token_ttl: z.number().describe('時間長度(單位秒)'),
  dev_uuid: z.string(),
  csrf_token: z.string(),
});

export const refreshAccessTokenSchema = z.object({
  access_token: z.string(),
  access_token_ttl: z.number().describe('時間長度(單位秒)'),
});
