import { atom } from 'jotai';

import type { BasicProfile } from './types';

/**
 * SHOULD NOT USE IT DIRECTLY
 */
const basicProfilePrivateAtom = atom<BasicProfile | null>(null);

export const basicProfileAtom = atom(
  (get) => {
    const basicProfile = get(basicProfilePrivateAtom);

    if (basicProfile === null) {
      throw new Error('NO BASIC PROFILE');
    }

    return basicProfile;
  },
  (_get, set, update: BasicProfile) => {
    set(basicProfilePrivateAtom, update);
  },
);
