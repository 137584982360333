import { Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepStatus, StepTitle, Stepper } from '@chakra-ui/react';

export const RippleStepper = Stepper;
export const RippleStep = Step;
export const RippleStepTitle = StepTitle;
export const RippleStepDescription = StepDescription;
export const RippleStepIcon = StepIcon;
export const RippleStepIndicator = StepIndicator;
export const RippleStepNumber = StepNumber;
export const RippleStepStatus = StepStatus;
