import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowRight({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85984 18.7683C8.43556 18.4147 8.37824 17.7841 8.7318 17.3599L13.1983 12L8.7318 6.64022C8.37824 6.21594 8.43556 5.58538 8.85984 5.23182C9.28412 4.87825 9.91468 4.93557 10.2682 5.35985L15.2682 11.3599C15.5773 11.7307 15.5773 12.2694 15.2682 12.6402L10.2682 18.6402C9.91468 19.0645 9.28412 19.1218 8.85984 18.7683Z"
        fill="currentColor"
      />
    </Icon>
  );
}
