import { Box, forwardRef } from '@chakra-ui/react';

import { RippleTypography, RippleTypographyProps } from '../RippleTypography';

export type RippleHyperLinkProps = Omit<RippleTypographyProps, 'variant'> & {
  variant: 'hyperlink01' | 'hyperlink02';
  activeColor?: RippleTypographyProps['color'];
  isDisabled?: boolean;
};

export const RippleHyperLink = forwardRef<RippleHyperLinkProps, 'a'>(
  ({ variant, color = 'blue.100', activeColor = 'blue.200', isDisabled, ...otherProps }, ref) => {
    if (isDisabled)
      return (
        <Box as="span" cursor="not-allowed">
          <RippleTypography
            as="a"
            ref={ref}
            variant={variant === 'hyperlink01' ? 'body01' : 'body02'}
            fontWeight="600"
            color="blue.40"
            aria-disabled
            pointerEvents="none"
            {...otherProps}
          />
        </Box>
      );

    return (
      <RippleTypography
        as="a"
        ref={ref}
        variant={variant === 'hyperlink01' ? 'body01' : 'body02'}
        fontWeight="600"
        cursor="pointer"
        _hover={{
          textDecoration: 'underline',
          textDecorationColor: color,
        }}
        _active={{
          color: activeColor,
          textDecoration: 'underline',
          textDecorationColor: activeColor,
        }}
        color={color}
        {...otherProps}
      />
    );
  },
);
