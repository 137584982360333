import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

import { ComputerVersion } from './types';

type Datetime = string;

export type ComputerDetail_deprecated = {
  name: string;
  host_name: string;
  email: string;
  version: ComputerVersion;
  online_status: boolean;
  idle_since: Datetime;
  online_since: Datetime;
  connected: boolean;
  last_session: Datetime;
  last_online: Datetime;
  pubip: string;
  local_ip: string;
  time_offset: number;
  /**
   * @todo 好像沒用到，且與 `ComputerData` 定義不同？
   */
  share_mode: 'not_share' | 'share_to_all' | 'share_to_members' | 'share_to_admin' | 'share_to_owner' | 'share_to_group';
  deployed: boolean;
  os: string;
  // TODO: Computer List Page
  // features: {
  //   force_disconnect: 'enabled' | 'no_stb' | 'no_enabled_seat' | 'not_support' | null;
  //   reboot: 'enabled' | 'no_stb' | 'no_enabled_seat' | 'not_support' | 'need_plus' | 'need_pro' | 'team_not_allow' | null;
  //   wake_up: 'enabled' | 'no_stb' | 'no_enabled_seat' | 'not_support' | 'need_plus' | 'need_pro' | 'team_not_allow' | null;
  //   delete: 'enabled' | 'no_enabled_seat' | null;
  //   rename: 'enabled' | 'no_stb' | 'no_enabled_seat' | 'not_support' | null;
  //   assign_group: 'enabled' | 'disabled' | null;
  //   add_note: 'enabled' | 'disabled' | null;
  //   windows_update: 'enabled' | 'need_premier' | 'not_support' | null;
  //   antivirus: 'enabled' | 'need_premier' | 'not_support' | null;
  //   alert_log: 'enabled' | 'need_premier' | 'not_support' | null;
  //   scheduled_actions: 'enabled' | 'need_premier' | 'not_support' | null;
  //   inventory: 'enabled' | 'need_premier' | 'not_support' | null;
  //   event_logs: 'enabled' | 'need_premier' | 'not_support' | null;
  //   command_prompt: 'enabled' | 'need_premier' | 'not_support' | null;
  //   msp_access_permission: 'enabled' | 'no_enabled_seat' | null;
  //   access_permission: 'enabled' | 'no_enabled_seat' | 'not_support' | null;
  //   feature_permission: 'enabled' | 'no_enabled_seat' | 'not_support' | null;
  // };
};

/**
 * @deprecated Use `getTeamComputerDetail` from `@/services/computers/services/getTeamComputerDetail` instead.
 */
export function getTeamComputerDetail_deprecated(teamId: string, computerId: string) {
  return new Promise<ComputerDetail_deprecated>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ComputerDetail_deprecated }>(`/api/web/v1/teams/${teamId}/computers/${computerId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
