import { Button, ButtonProps, forwardRef, useStyleConfig } from '@chakra-ui/react';

export type RippleFloatButtonProps = Omit<ButtonProps, 'variant'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger' | 'dangerSecondary' | 'dangerTertiary';
};

export const RippleFloatButton = forwardRef<RippleFloatButtonProps, 'button'>(
  ({ size = 'md', variant = 'primary', ...otherProps }, ref) => {
    const styles = useStyleConfig('rippleFloatButton', { variant, size });
    return <Button __css={styles} size={size} variant={variant} ref={ref} {...otherProps} />;
  },
);
