import React, { ChangeEvent } from 'react';

import { Box, Textarea, TextareaProps, forwardRef } from '@chakra-ui/react';

import { RippleTypography } from '../RippleTypography';

export type RippleTextareaProps = TextareaProps & { helperText?: string; isAutoExpand?: boolean };
export const RippleTextarea = forwardRef<RippleTextareaProps, 'input'>((props, ref) => {
  const { sx, helperText, isAutoExpand, onChange, ...otherProps } = props;

  const handleChange = isAutoExpand
    ? (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.target.style.height = '0px'; // If I don't reset the height, the collapse behavior will not be smooth.
        event.target.style.height = `${event.target.scrollHeight}px`;

        onChange && onChange(event);
      }
    : onChange;

  return (
    <Box sx={sx}>
      <Textarea
        className="ripple-textarea"
        mb={helperText ? '4px' : undefined}
        borderColor="neutral.300"
        overflowY={isAutoExpand ? 'hidden' : 'auto'}
        onChange={handleChange}
        borderRadius="4px"
        px="12px"
        py="10px"
        fontSize="14px"
        lineHeight="20px"
        _placeholder={{ color: 'dark.40' }}
        _hover={{
          borderColor: 'blue.100',
        }}
        _focus={{
          borderColor: 'blue.100',
          boxShadow: 'none',
        }}
        _invalid={{
          borderColor: 'red.100',
          boxShadow: 'none',
          _hover: {
            borderColor: 'red.100',
          },
        }}
        _disabled={{
          borderColor: 'dark.40',
          bg: 'neutral.10',
          _placeholder: {
            color: 'dark.40',
          },
          _hover: {
            borderColor: 'dark.40',
          },
        }}
        ref={ref}
        {...otherProps}
      />
      {helperText && (
        <RippleTypography variant="body03" color="neutral.300">
          {helperText}
        </RippleTypography>
      )}
    </Box>
  );
});
