import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleBodyText03, RippleHeading08 } from '../RippleText';

export type RippleSectionProps = { name: string; children?: React.ReactNode; isOptional?: boolean; description?: string } & FlexProps;

export const RippleSection = ({ name, isOptional, description, children, ...otherProps }: RippleSectionProps): React.JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" className="ripple-section" {...otherProps}>
      <Box>
        <RippleHeading08 as="label" className="ripple-section__label">
          {name}
        </RippleHeading08>
        {isOptional && (
          <RippleBodyText03 ml="4px" color="neutral.200" as="span">
            {`(${t('common:optional')})`}
          </RippleBodyText03>
        )}
      </Box>
      {description && (
        <RippleBodyText02 mt="4px" className="ripple-section__description">
          {description}
        </RippleBodyText02>
      )}
      <Box mt="8px" className="ripple-section__container">
        {children}
      </Box>
    </Flex>
  );
};
