import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import { RippleIconProps } from './types';

export function RippleCopyLink({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M11.9998 17.6568L10.5149 19.1418C9.3835 20.2731 7.54502 20.2731 6.34294 19.0711L4.92872 17.6568C3.72664 16.4548 3.72664 14.6163 4.85801 13.4849L8.25212 10.0908C9.3835 8.95943 11.222 8.95943 12.4241 10.1615L13.1312 10.8686L13.8383 11.5757"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.9996 6.34315L13.4845 4.85823C14.6159 3.72686 16.4544 3.72686 17.6565 4.92894L19.0707 6.34315C20.2728 7.54523 20.2728 9.38371 19.1414 10.5151L15.7473 13.9092C14.6159 15.0406 12.7774 15.0406 11.5754 13.8385L10.8683 13.1314L10.1611 12.4243"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </Icon>
  );
}
