import type { UseModalProps } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import emptyFunc from '@/utils/emptyFunc';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalOverlay,
  RippleTypography,
} from '..';

export type RippleConfirmModalProps = {
  title?: string;
  isOpen: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose?: UseModalProps['onClose'];
  onConfirm(): void;
  children: React.ReactNode;
};

export const RippleConfirmModal = ({
  title,
  isOpen,
  cancelButtonText,
  confirmButtonText,
  onClose,
  onConfirm,
  children,
}: RippleConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <RippleModal isOpen={isOpen} onClose={onClose ?? emptyFunc} closeOnOverlayClick={false}>
      <RippleModalOverlay />
      <RippleModalContent borderRadius="4px" maxWidth="608px">
        <RippleModalBody data-testid="modal-body" flexBasis="auto">
          {title && (
            <RippleTypography data-testid="modal-title" variant="heading05" marginTop="32px" marginBottom="24px">
              {title}
            </RippleTypography>
          )}
          {children}
        </RippleModalBody>

        <RippleModalFooter data-testid="modal-footer" marginTop="24px" borderTop="1px solid" borderTopColor="neutral.60" gap="8px">
          {onClose && (
            <RippleButton data-testid="cancel-btn" variant="grayScaleGhost" size="sm" minWidth="80px" onClick={onClose}>
              {cancelButtonText ?? t('common:cancel')}
            </RippleButton>
          )}
          <RippleButton variant="danger" size="sm" minWidth="80px" onClick={onConfirm} data-testid="confirm-btn">
            {confirmButtonText ?? t('common:confirm')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
