import React, { ReactElement } from 'react';

import { featureControl } from '@/feature/toggle';

import {
  RippleAndroidConnected,
  RippleAndroidLoading,
  RippleAndroidOffline,
  RippleAndroidOnline,
  RippleFallbackConnected,
  RippleFallbackOffline,
  RippleFallbackOnline,
  RippleIOSConnected,
  RippleIOSOffline,
  RippleIOSOnline,
  RippleLinuxConnected,
  RippleLinuxLoading,
  RippleLinuxOffline,
  RippleLinuxOnline,
  RippleMacConnected,
  RippleMacLoading,
  RippleMacOffline,
  RippleMacOnline,
  RippleRDPConnected,
  RippleRDPOffline,
  RippleRDPOnline,
  RippleRDSConnected,
  RippleRDSOffline,
  RippleRDSOnline,
  RippleSSHOffline,
  RippleSSHOnline,
  RippleVNCConnected,
  RippleVNCOffline,
  RippleVNCOnline,
  RippleWindowsConnected,
  RippleWindowsLoading,
  RippleWindowsOffline,
  RippleWindowsOnline,
} from './RippleComputerIcons';
import {
  RippleAndroidConnected as RippleAndroidConnectedLegacy,
  RippleAndroidOffline as RippleAndroidOfflineLegacy,
  RippleAndroidOnline as RippleAndroidOnlineLegacy,
  RippleLinuxConnected as RippleLinuxConnectedLegacy,
  RippleLinuxOffline as RippleLinuxOfflineLegacy,
  RippleLinuxOnline as RippleLinuxOnlineLegacy,
  RippleMacConnected as RippleMacConnectedLegacy,
  RippleMacOffline as RippleMacOfflineLegacy,
  RippleMacOnline as RippleMacOnlineLegacy,
  RipplePCConnected as RipplePCConnectedLegacy,
  RipplePCOffline as RipplePCOfflineLegacy,
  RipplePCOnline as RipplePCOnlineLegacy,
  RippleRDPConnected as RippleRDPConnectedLegacy,
  RippleRDPOffline as RippleRDPOfflineLegacy,
  RippleRDPOnline as RippleRDPOnlineLegacy,
  RippleRDSConnected as RippleRDSConnectedLegacy,
  RippleRDSOffline as RippleRDSOfflineLegacy,
  RippleRDSOnline as RippleRDSOnlineLegacy,
  RippleSSHConnected as RippleSSHConnectedLegacy,
  RippleSSHOffline as RippleSSHOfflineLegacy,
  RippleSSHOnline as RippleSSHOnlineLegacy,
  RippleVNCConnected as RippleVNCConnectedLegacy,
  RippleVNCOffline as RippleVNCOfflineLegacy,
  RippleVNCOnline as RippleVNCOnlineLegacy,
  RippleWindowsConnected as RippleWindowsConnectedLegacy,
  RippleWindowsOffline as RippleWindowsOfflineLegacy,
  RippleWindowsOnline as RippleWindowsOnlineLegacy,
} from './RippleComputerIconsLegacy';

export type RippleComputerDeviceIconProps = {
  type: 'win' | 'mac' | 'ios' | 'linux' | 'android' | 'rdp' | 'vnc' | 'rds' | 'ssh' | 'pc' | 'fallback';
  state: 'online' | 'offline' | 'connected' | 'loading';
};

type IconMap = Record<RippleComputerDeviceIconProps['type'], Record<RippleComputerDeviceIconProps['state'], () => ReactElement>>;

const ICONS_Legacy: IconMap = {
  win: {
    online: RippleWindowsOnlineLegacy,
    offline: RippleWindowsOfflineLegacy,
    connected: RippleWindowsConnectedLegacy,
    loading: RippleWindowsOfflineLegacy,
  },
  mac: {
    online: RippleMacOnlineLegacy,
    offline: RippleMacOfflineLegacy,
    connected: RippleMacConnectedLegacy,
    loading: RippleMacOfflineLegacy,
  },
  /**
   * No iOS icon for legacy
   */
  ios: {
    online: RippleMacOnlineLegacy,
    offline: RippleMacOfflineLegacy,
    connected: RippleMacConnectedLegacy,
    loading: RippleMacOfflineLegacy,
  },
  linux: {
    online: RippleLinuxOnlineLegacy,
    offline: RippleLinuxOfflineLegacy,
    connected: RippleLinuxConnectedLegacy,
    loading: RippleLinuxOfflineLegacy,
  },
  android: {
    online: RippleAndroidOnlineLegacy,
    offline: RippleAndroidOfflineLegacy,
    connected: RippleAndroidConnectedLegacy,
    loading: RippleAndroidOfflineLegacy,
  },
  pc: {
    online: RipplePCOnlineLegacy,
    offline: RipplePCOfflineLegacy,
    connected: RipplePCConnectedLegacy,
    loading: RipplePCOfflineLegacy,
  },
  fallback: {
    online: RipplePCOnlineLegacy,
    offline: RipplePCOfflineLegacy,
    connected: RipplePCConnectedLegacy,
    loading: RipplePCOfflineLegacy,
  },
  rdp: {
    online: RippleRDPOnlineLegacy,
    offline: RippleRDPOfflineLegacy,
    connected: RippleRDPConnectedLegacy,
    loading: RippleRDPOfflineLegacy,
  },
  vnc: {
    online: RippleVNCOnlineLegacy,
    offline: RippleVNCOfflineLegacy,
    connected: RippleVNCConnectedLegacy,
    loading: RippleVNCOfflineLegacy,
  },
  rds: {
    online: RippleRDSOnlineLegacy,
    offline: RippleRDSOfflineLegacy,
    connected: RippleRDSConnectedLegacy,
    loading: RippleRDSOfflineLegacy,
  },
  ssh: {
    online: RippleSSHOnlineLegacy,
    offline: RippleSSHOfflineLegacy,
    connected: RippleSSHConnectedLegacy,
    loading: RippleSSHOfflineLegacy,
  },
} as const;

const ICONS_New: IconMap = {
  win: {
    online: RippleWindowsOnline,
    offline: RippleWindowsOffline,
    connected: RippleWindowsConnected,
    loading: RippleWindowsLoading,
  },
  mac: {
    online: RippleMacOnline,
    offline: RippleMacOffline,
    connected: RippleMacConnected,
    loading: RippleMacLoading,
  },
  ios: {
    online: RippleIOSOnline,
    offline: RippleIOSOffline,
    connected: RippleIOSConnected,
    loading: RippleIOSOffline, // NOTE: no loading icon
  },
  linux: {
    online: RippleLinuxOnline,
    offline: RippleLinuxOffline,
    connected: RippleLinuxConnected,
    loading: RippleLinuxLoading,
  },
  android: {
    online: RippleAndroidOnline,
    offline: RippleAndroidOffline,
    connected: RippleAndroidConnected,
    loading: RippleAndroidLoading,
  },
  /**
   * @deprecated Rename to `fallback`
   */
  pc: {
    online: RippleFallbackOnline,
    offline: RippleFallbackOffline,
    connected: RippleFallbackConnected,
    loading: RippleFallbackOffline, // NOTE: no loading icon
  },
  fallback: {
    online: RippleFallbackOnline,
    offline: RippleFallbackOffline,
    connected: RippleFallbackConnected,
    loading: RippleFallbackOffline, // NOTE: no loading icon
  },
  rdp: {
    online: RippleRDPOnline,
    offline: RippleRDPOffline,
    connected: RippleRDPConnected,
    loading: RippleRDPOffline, // NOTE: no loading icon
  },
  vnc: {
    online: RippleVNCOnline,
    offline: RippleVNCOffline,
    connected: RippleVNCConnected,
    loading: RippleVNCOffline, // NOTE: no loading icon
  },
  rds: {
    online: RippleRDSOnline,
    offline: RippleRDSOffline,
    connected: RippleRDSConnected,
    loading: RippleRDSOffline, // NOTE: no loading icon
  },
  ssh: {
    online: RippleSSHOnline,
    offline: RippleSSHOffline,
    connected: RippleSSHOnline, // NOTE: no connected icon
    loading: RippleSSHOffline, // NOTE: no loading icon
  },
} as const;

const ICONS: IconMap = featureControl.getToggle('CLE_53__Computer_Device_Icon_Change') ? ICONS_New : ICONS_Legacy;

export function RippleComputerDeviceIcon({ type, state }: RippleComputerDeviceIconProps) {
  const Icon = ICONS[type]?.[state];

  return Icon ? <Icon /> : null;
}
