import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCollapse({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M12 21L12 17.3787" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path
        d="M14.83 18.7929L12.0016 15.9645L9.17313 18.7929"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <rect x="3" y="11" width="18" height="2" rx="1" fill="currentColor" />
      <path
        d="M9.17001 5.20709L11.9984 8.03552L14.8269 5.20709"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M12 6.62134L12 3" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
    </Icon>
  );
}
