import { textStyles } from '../text';

const styles = {
  baseStyle: {
    ...textStyles.buttonText,
    minW: '80px',
    border: '1px solid',
    borderRadius: '4px',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3), 0px 0px 1px rgba(0, 0, 0, 0.2)',
    _hover: {
      boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2)',
    },
    _disabled: {
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _active: {
      boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2)',
    },
  },
  sizes: {
    xs: {
      p: '6px 16px',
      h: '32px',
    },
    sm: {
      p: '10px 16px',
      h: '40px',
    },
    md: {
      p: '12px 16px',
      h: '44px',
    },
    lg: {
      p: '14px 16px',
      h: '48px',
    },
  },
  variants: {
    primary: {
      color: 'white',
      borderColor: 'blue.100',
      backgroundColor: 'blue.100',
      _hover: {
        borderColor: 'blue.80',
        backgroundColor: 'blue.80',
      },
      _active: {
        borderColor: 'blue.200',
        backgroundColor: 'blue.200',
      },
      _disabled: {
        borderColor: 'blue.40',
        backgroundColor: 'blue.40',
      },
    },
    secondary: {
      color: 'blue.100',
      borderColor: 'blue.100',
      backgroundColor: 'white',
      _hover: {
        backgroundColor: 'blue.10',
      },
      _active: {
        color: 'blue.200',
        borderColor: 'blue.200',
        backgroundColor: 'blue.20',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'dark.40',
        backgroundColor: 'white',
      },
    },
    tertiary: {
      color: 'blue.100',
      borderColor: 'white',
      backgroundColor: 'white',
      _hover: {
        borderColor: 'blue.10',
        backgroundColor: 'blue.10',
      },
      _active: {
        color: 'blue.200',
        borderColor: 'blue.20',
        backgroundColor: 'blue.20',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'white',
        backgroundColor: 'white',
      },
    },
    ghost: {
      color: 'neutral.300',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: {
        color: 'blue.100',
        backgroundColor: 'blue.10',
      },
      _active: {
        color: 'blue.200',
        backgroundColor: 'blue.20',
      },
      _disabled: {
        color: 'dark.20',
        backgroundColor: 'transparent',
      },
    },
    success: {
      color: 'dark.90',
      borderColor: 'green.100',
      backgroundColor: 'green.100',
      _hover: {
        borderColor: 'green.80',
        backgroundColor: 'green.80',
      },
      _active: {
        borderColor: 'green.200',
        backgroundColor: 'green.200',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'green.40',
        backgroundColor: 'green.40',
      },
    },
    danger: {
      color: 'white',
      borderColor: 'red.100',
      backgroundColor: 'red.100',
      _hover: {
        borderColor: 'red.80',
        backgroundColor: 'red.80',
      },
      _active: {
        borderColor: 'red.100',
        backgroundColor: 'red.100',
      },
      _disabled: {
        borderColor: 'red.40',
        backgroundColor: 'red.40',
      },
    },
    dangerSecondary: {
      color: 'red.100',
      borderColor: 'red.100',
      backgroundColor: 'white',
      _hover: {
        backgroundColor: 'red.10',
      },
      _active: {
        color: 'red.200',
        borderColor: 'red.200',
        backgroundColor: 'red.20',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'dark.40',
        backgroundColor: 'white',
      },
    },
    dangerTertiary: {
      color: 'red.100',
      borderColor: 'white',
      backgroundColor: 'white',
      _hover: {
        borderColor: 'red.10',
        backgroundColor: 'red.10',
      },
      _active: {
        borderColor: 'red.20',
        backgroundColor: 'red.20',
      },
      _disabled: {
        color: 'dark.40',
        borderColor: 'white',
        backgroundColor: 'white',
      },
    },
  },
};

export const rippleFloatButton = styles;
