import { useId } from 'react';

import { Icon, keyframes } from '@chakra-ui/react';

export function RippleLoader16({ isLoading = false }) {
  const radialGradient1 = useId();
  const animation = isLoading ? `${spin}` : undefined;
  return (
    <Icon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
      sx={{
        transformOrigin: 'center',
        animationDuration: '0.45s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      }}
      opacity="0.5"
      animation={animation}
    >
      <path
        d="M14.88 8C15.4986 8 16.008 8.50386 15.9217 9.11636C15.7634 10.2399 15.3672 11.3214 14.7546 12.2866C13.9412 13.5684 12.7798 14.5922 11.4062 15.2386C10.0326 15.885 8.50346 16.1272 6.99733 15.9369C5.49121 15.7467 4.07032 15.1318 2.90061 14.1641C1.7309 13.1964 0.860664 11.9159 0.391548 10.4721C-0.0775681 9.02835 -0.126199 7.48088 0.251335 6.01048C0.628869 4.54008 1.41698 3.20746 2.52362 2.16825C3.35697 1.38569 4.34504 0.79384 5.41903 0.427772C6.00451 0.22821 6.59491 0.634261 6.71082 1.24186C6.82672 1.84947 6.42152 2.42607 5.84777 2.6572C5.18805 2.92296 4.58018 3.30985 4.05701 3.80114C3.26023 4.54937 2.69279 5.50886 2.42096 6.56755C2.14914 7.62623 2.18415 8.74041 2.52191 9.77994C2.85968 10.8195 3.48625 11.7414 4.32844 12.4382C5.17063 13.1349 6.19367 13.5776 7.27808 13.7146C8.36249 13.8516 9.46349 13.6772 10.4525 13.2118C11.4415 12.7464 12.2777 12.0092 12.8633 11.0864C13.2479 10.4804 13.514 9.8108 13.6515 9.11297C13.771 8.50607 14.2614 8 14.88 8Z"
        fill={`url(#${radialGradient1})`}
      />
      <defs>
        <radialGradient id={radialGradient1} cx="0" cy="0" r="1">
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </radialGradient>
      </defs>
    </Icon>
  );
}

const spin = keyframes`
  0% {
    transform: rotate(0deg) scaleX(-1);
  }
  100% {
    transform: rotate(360deg) scaleX(-1);
  }
`;
