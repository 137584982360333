import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import { LoadingSpinner } from './LoadingSpinner';

export const RippleMacConnected = () => {
  const filter1 = useId();
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const clipPath1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <g clipPath={`url(#${clipPath1})`}>
        <g filter={`url(#${filter1})`}>
          <rect x={4} y={4} width={32} height={32} rx={4} fill="white" />
          <rect x={5} y={5} width={30} height={30} rx={3} stroke={`url(#${linearGradient1})`} strokeWidth={2} />
        </g>
        <path
          d="M24.9196 19.0937C24.9282 18.3664 25.1211 17.6532 25.4804 17.0208C25.8397 16.3884 26.3536 15.8575 26.974 15.4778C26.5801 14.9167 26.0615 14.4546 25.4587 14.128C24.856 13.8014 24.1857 13.6191 23.5005 13.5956C22.0221 13.4472 20.6149 14.4684 19.8608 14.4684C19.1068 14.4684 17.9549 13.6194 16.7258 13.6372C15.9214 13.66 15.1365 13.8908 14.4477 14.307C13.7589 14.7232 13.1896 15.3106 12.7952 16.0122C11.1209 18.9215 12.3677 23.2262 13.9827 25.5893C14.7784 26.7412 15.7343 28.0356 16.9812 27.9643C18.228 27.8931 18.6377 27.1865 20.0924 27.1865C21.5471 27.1865 21.9746 28 23.2452 28C24.5158 28 25.3649 26.8125 26.1546 25.6665C26.7143 24.8239 27.152 23.9064 27.4549 22.9412C26.705 22.6145 26.0661 22.0769 25.616 21.3939C25.1659 20.7108 24.924 19.9117 24.9196 19.0937Z"
          fill="#1C1C1C"
        />
        <path
          d="M22.5267 12.0341C22.8838 11.6186 23.1552 11.1367 23.3254 10.616C23.4955 10.0953 23.5611 9.54612 23.5183 9C22.4364 9.1028 21.4353 9.61717 20.7217 10.4369C20.3691 10.8359 20.1002 11.3016 19.9308 11.8065C19.7615 12.3113 19.6953 12.845 19.7361 13.3759C20.2728 13.3802 20.8034 13.2615 21.2871 13.0288C21.7709 12.7962 22.1949 12.456 22.5267 12.0341Z"
          fill="#1C1C1C"
        />
        <circle cx={31} cy={31} r={7} fill={`url(#${linearGradient2})`} stroke="white" strokeWidth={2} />
        <rect x={29} y="30.25" width={4} height="1.5" fill="white" />
        <path
          d="M33 28.4828V33.5172C33 33.6953 33.2154 33.7846 33.3414 33.6586L35.8586 31.1414C35.9367 31.0633 35.9367 30.9367 35.8586 30.8586L33.3414 28.3414C33.2154 28.2154 33 28.3047 33 28.4828Z"
          fill="white"
        />
        <path
          d="M29 28.4828V33.5172C29 33.6953 28.7846 33.7846 28.6586 33.6586L26.1414 31.1414C26.0633 31.0633 26.0633 30.9367 26.1414 30.8586L28.6586 28.3414C28.7846 28.2154 29 28.3047 29 28.4828Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id={filter1} x={-1} y={-1} width={42} height={42} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9875_102180" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={-1} dy={-1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.572549 0 0 0 0 0.92549 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9875_102180" result="effect2_dropShadow_9875_102180" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9875_102180" result="shape" />
        </filter>
        <linearGradient id={linearGradient1} x1={4} y1={11} x2={32} y2={36} gradientUnits="userSpaceOnUse">
          <stop offset="0.208275" stopColor="#AC59FF" />
          <stop offset={1} stopColor="#036DEA" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="37.4167" y1={38} x2="25.1663" y2="24.8754" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
        <clipPath id={clipPath1}>
          <rect width={40} height={40} fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export const RippleMacOffline = () => {
  const radialGradient1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
      <path
        d="M24.9196 19.0939C24.9282 18.3666 25.1211 17.6534 25.4804 17.021C25.8397 16.3886 26.3536 15.8577 26.974 15.478C26.5801 14.9169 26.0615 14.4548 25.4587 14.1282C24.856 13.8016 24.1857 13.6193 23.5005 13.5958C22.0221 13.4474 20.6149 14.4686 19.8608 14.4686C19.1068 14.4686 17.9549 13.6195 16.7258 13.6374C15.9214 13.6602 15.1365 13.891 14.4477 14.3072C13.7589 14.7234 13.1896 15.3108 12.7952 16.0124C11.1209 18.9217 12.3677 23.2264 13.9827 25.5895C14.7784 26.7414 15.7343 28.0358 16.9812 27.9645C18.228 27.8933 18.6377 27.1867 20.0924 27.1867C21.5471 27.1867 21.9746 28.0002 23.2452 28.0002C24.5158 28.0002 25.3649 26.8127 26.1546 25.6667C26.7143 24.8241 27.152 23.9066 27.4549 22.9414C26.705 22.6147 26.0661 22.0771 25.616 21.3941C25.1659 20.711 24.924 19.9119 24.9196 19.0939V19.0939Z"
        fill="#B0B0B0"
      />
      <path
        d="M22.5267 12.0341C22.8837 11.6186 23.1552 11.1367 23.3253 10.616C23.4955 10.0953 23.5611 9.54612 23.5182 9C22.4363 9.1028 21.4352 9.61717 20.7217 10.4369C20.369 10.8359 20.1001 11.3016 19.9308 11.8065C19.7615 12.3113 19.6953 12.845 19.736 13.3759C20.2728 13.3802 20.8034 13.2615 21.2871 13.0288C21.7708 12.7962 22.1948 12.456 22.5267 12.0341V12.0341Z"
        fill="#B0B0B0"
      />
      <defs>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(16)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};

export const RippleMacOnline = () => {
  const radialGradient1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
      <path
        d="M24.9196 19.0939C24.9282 18.3666 25.1211 17.6534 25.4804 17.021C25.8397 16.3886 26.3536 15.8577 26.974 15.478C26.5801 14.9169 26.0615 14.4548 25.4587 14.1282C24.856 13.8016 24.1857 13.6193 23.5005 13.5958C22.0221 13.4474 20.6149 14.4686 19.8608 14.4686C19.1068 14.4686 17.9549 13.6195 16.7258 13.6374C15.9214 13.6602 15.1365 13.891 14.4477 14.3072C13.7589 14.7234 13.1896 15.3108 12.7952 16.0124C11.1209 18.9217 12.3677 23.2264 13.9827 25.5895C14.7784 26.7414 15.7343 28.0358 16.9812 27.9645C18.228 27.8933 18.6377 27.1867 20.0924 27.1867C21.5471 27.1867 21.9746 28.0002 23.2452 28.0002C24.5158 28.0002 25.3649 26.8127 26.1546 25.6667C26.7143 24.8241 27.152 23.9066 27.4549 22.9414C26.705 22.6147 26.0661 22.0771 25.616 21.3941C25.1659 20.711 24.924 19.9119 24.9196 19.0939V19.0939Z"
        fill="black"
      />
      <path
        d="M22.5267 12.0341C22.8837 11.6186 23.1552 11.1367 23.3253 10.616C23.4955 10.0953 23.5611 9.54612 23.5182 9C22.4363 9.1028 21.4352 9.61717 20.7217 10.4369C20.369 10.8359 20.1001 11.3016 19.9308 11.8065C19.7615 12.3113 19.6953 12.845 19.736 13.3759C20.2728 13.3802 20.8034 13.2615 21.2871 13.0288C21.7708 12.7962 22.1948 12.456 22.5267 12.0341V12.0341Z"
        fill="black"
      />
      <defs>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(16)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};

export const RippleMacLoading = () => {
  return (
    <LoadingSpinner>
      <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
        <path
          d="M24.9196 19.0939C24.9282 18.3666 25.1211 17.6534 25.4804 17.021C25.8397 16.3886 26.3536 15.8577 26.974 15.478C26.5801 14.9169 26.0615 14.4548 25.4587 14.1282C24.856 13.8016 24.1857 13.6193 23.5005 13.5958C22.0221 13.4474 20.6149 14.4686 19.8608 14.4686C19.1068 14.4686 17.9549 13.6195 16.7258 13.6374C15.9214 13.6602 15.1365 13.891 14.4477 14.3072C13.7589 14.7234 13.1896 15.3108 12.7952 16.0124C11.1209 18.9217 12.3677 23.2264 13.9827 25.5895C14.7784 26.7414 15.7343 28.0358 16.9812 27.9645C18.228 27.8933 18.6377 27.1867 20.0924 27.1867C21.5471 27.1867 21.9746 28.0002 23.2452 28.0002C24.5158 28.0002 25.3649 26.8127 26.1546 25.6667C26.7143 24.8241 27.152 23.9066 27.4549 22.9414C26.705 22.6147 26.0661 22.0771 25.616 21.3941C25.1659 20.711 24.924 19.9119 24.9196 19.0939V19.0939Z"
          fill="#B0B0B0"
        />
        <path
          d="M22.5267 12.0341C22.8837 11.6186 23.1552 11.1367 23.3253 10.616C23.4955 10.0953 23.5611 9.54612 23.5182 9C22.4363 9.1028 21.4352 9.61717 20.7217 10.4369C20.369 10.8359 20.1001 11.3016 19.9308 11.8065C19.7615 12.3113 19.6953 12.845 19.736 13.3759C20.2728 13.3802 20.8034 13.2615 21.2871 13.0288C21.7708 12.7962 22.1948 12.456 22.5267 12.0341V12.0341Z"
          fill="#B0B0B0"
        />
      </Icon>
    </LoadingSpinner>
  );
};
