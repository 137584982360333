import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleExpand({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M9.17001 18.2071L11.9984 21.0355L14.8269 18.2071"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M12 19.6213L12 16" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <rect x="3" y="11" width="18" height="2" rx="1" fill="currentColor" />
      <path d="M12 8L12 4.37867" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path
        d="M14.83 5.79291L12.0016 2.96448L9.17313 5.79291"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
