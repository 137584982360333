import React from 'react';

import { Box, forwardRef, useMenuContext } from '@chakra-ui/react';
import type { StyleProps, UseFormControlProps } from '@chakra-ui/react';

import { RippleButton, RippleButtonProps } from '../RippleButton';
import { RippleArrowDown16, RippleArrowUp16 } from '../RippleIcon';
import {
  RippleMenu,
  RippleMenuButton,
  RippleMenuDivider,
  RippleMenuGroup,
  RippleMenuItem,
  RippleMenuItemProps,
  RippleMenuList,
  RippleMenuProps,
} from '../RippleMenu';

const DropdownButton = forwardRef<RippleButtonProps, 'button'>(({ children, ...otherProps }, ref) => {
  const { isOpen } = useMenuContext();

  return (
    <RippleButton isActive={isOpen} paddingInline="12px" ref={ref} {...otherProps}>
      {children}
      <Box as="span" ml="4px">
        {isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
      </Box>
    </RippleButton>
  );
});

export type RippleDropdownProps = {
  children: React.ReactNode;
  placeholder: string;
  variant?: RippleButtonProps['variant'];
  size?: RippleButtonProps['size'];
  menuSize?: RippleMenuProps['size'];
} & StyleProps &
  UseFormControlProps<HTMLButtonElement>;

function RippleDropdownCore(props: RippleDropdownProps, ref: React.ForwardedRef<any>) {
  const { children, placeholder, variant = 'primary', size = 'sm', menuSize, ...otherProps } = props;

  return (
    <RippleMenu closeOnSelect closeOnBlur placement="bottom-end" size={menuSize}>
      <RippleMenuButton ref={ref} as={DropdownButton} variant={variant} size={size} {...otherProps}>
        {placeholder}
      </RippleMenuButton>
      <RippleMenuList>{children}</RippleMenuList>
    </RippleMenu>
  );
}

export const RippleDropdown = forwardRef(RippleDropdownCore);

export type RippleDropdownOptionProps = Omit<RippleMenuItemProps, 'isSelected' | 'children'> & { value?: never; children: string };
export const RippleDropdownOption = forwardRef<RippleDropdownOptionProps, 'button'>(({ value, children, onClick, ...otherProps }, ref) => {
  return (
    <RippleMenuItem ref={ref} onClick={onClick} {...otherProps}>
      {children}
    </RippleMenuItem>
  );
});

export const RippleDropdownOptionGroup = RippleMenuGroup;

export const RippleDropdownOptionDivider = RippleMenuDivider;
