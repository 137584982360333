import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleEndUser({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path d="M12 16V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0696 19.2532C16.0467 19.2511 16.0235 19.25 16 19.25H8C7.58579 19.25 7.25 19.5858 7.25 20C7.25 20.4142 7.58579 20.75 8 20.75H16C16.0235 20.75 16.0467 20.7489 16.0696 20.7468C16.0239 20.5049 16 20.2552 16 20C16 19.7448 16.0239 19.4951 16.0696 19.2532Z"
            fill="currentColor"
          />
          <path d="M2 10L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M11 7L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M11 13L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.6236 16.0483C20.7046 15.8828 20.75 15.6967 20.75 15.5V4.5C20.75 3.80964 20.1904 3.25 19.5 3.25H4.5C3.80964 3.25 3.25 3.80964 3.25 4.5V7C3.25 7.41421 3.58579 7.75 4 7.75C4.41421 7.75 4.75 7.41421 4.75 7V4.75H19.25V15.25H4.75V13C4.75 12.5858 4.41421 12.25 4 12.25C3.58579 12.25 3.25 12.5858 3.25 13V15.5C3.25 16.1904 3.80964 16.75 4.5 16.75H17.6676C18.3242 16.278 19.1296 16 20 16C20.2121 16 20.4204 16.0165 20.6236 16.0483Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z"
            fill="#B60022"
          />
        </>
      ) : (
        <>
          <path d="M12 16V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M8 20H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path
            d="M4 13V15.5C4 15.7761 4.22386 16 4.5 16L19.5 16C19.7761 16 20 15.7761 20 15.5V4.5C20 4.22386 19.7761 4 19.5 4L4.5 4C4.22386 4 4 4.22386 4 4.5V7"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path d="M2 10L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M11 7L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M11 13L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </>
      )}
    </Icon>
  );
}
