import { Box } from '@chakra-ui/react';
import { isAfter, isBefore } from 'date-fns';

import { DayButtonProps } from '../core/utils';
import { DatePickerButton } from './DatePickerButton';

export type DaysProps = {
  dayButtonsProps: Array<DayButtonProps>;
  startDate?: Date;
  endDate?: Date;
  isSelecting?: boolean;
  showCurrentMonthOnly?: boolean;
  getDateDisabled: (date: Date) => boolean;
  onChange: (date: Date) => void;
  onSelecting?: (date: Date) => void;
};
export const Days = ({
  dayButtonsProps,
  startDate,
  endDate,
  isSelecting,
  showCurrentMonthOnly,
  getDateDisabled,
  onChange,
  onSelecting,
}: DaysProps) => {
  const handleMouseIn = (date: Date) => {
    if (isSelecting) {
      onSelecting?.(date);
    }
  };
  return (
    // Add padding to children instead of using column gap to apply the continuous background color when range select.
    <Box display="grid" gridTemplateColumns="repeat(7, 28px)" gridTemplateRows="repeat(5, 24px)" rowGap="4px" mx="-2px">
      {dayButtonsProps.map(({ date, dayOfMonthIndex, isInMonth, isSelected }, index) => {
        const isBeyondStart = startDate && !isBefore(date, startDate);
        const isBeyondEnd = endDate && !isAfter(date, endDate);
        const isInRange = isBeyondStart && isBeyondEnd;
        const isWeekStart = index % 7 === 0;
        const isWeekEnd = index % 7 === 6;

        if (showCurrentMonthOnly && !isInMonth) {
          return <Box key={index} w="28px" h="24px" />;
        }
        return (
          <Box
            key={index}
            bgColor={!isSelected && isInRange ? 'blue.10' : 'transparent'}
            px="2px"
            borderLeftRadius={isWeekStart ? '4px' : 0}
            borderRightRadius={isWeekEnd ? '4px' : 0}
          >
            <DatePickerButton
              isActive={isSelected}
              isDisabled={!isInMonth || getDateDisabled(date)}
              onClick={() => onChange(date)}
              onMouseEnter={() => handleMouseIn(date)}
              onPointerEnter={() => handleMouseIn(date)}
            >
              {dayOfMonthIndex}
            </DatePickerButton>
          </Box>
        );
      })}
    </Box>
  );
};
