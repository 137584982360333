import { Box, Flex } from '@chakra-ui/react';
import { getMonth, getYear } from 'date-fns';

import { RippleArrowLeft16, RippleArrowRight16 } from '@/design/RippleIcon';
import { RippleIconButton } from '@/design/RippleIconButton';
import { RippleTypography } from '@/design/RippleTypography';

import { MonthIndexes } from '../core/utils';
import { MONTHS } from '../utils';
import { DatePickerMonthButton } from './DatePickerMonthButton';

export type MonthSelectorProps = {
  currentDate: Date;
  isPrevYearDisabled: boolean;
  isNextYearDisabled: boolean;
  getMonthDisabled: (monthIndex: MonthIndexes) => boolean;
  onPrevYear: () => void;
  onNextYear: () => void;
  onChange: (monthIndex: MonthIndexes) => void;
  onClose: () => void;
};

export const MonthSelector = ({
  currentDate,
  isPrevYearDisabled,
  isNextYearDisabled,
  onPrevYear,
  onNextYear,
  getMonthDisabled,
  onChange,
  onClose,
}: MonthSelectorProps) => {
  const currentMonth = getMonth(currentDate) as MonthIndexes;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb="4px">
        <RippleIconButton
          aria-label="previous year"
          width="24px"
          height="24px"
          icon={<RippleArrowLeft16 color={isPrevYearDisabled ? 'dark.20' : 'dark.90'} />}
          isDisabled={isPrevYearDisabled}
          onClick={onPrevYear}
        />
        <RippleTypography variant="heading09">{getYear(currentDate)}</RippleTypography>
        <RippleIconButton
          aria-label="next year"
          width="24px"
          height="24px"
          icon={<RippleArrowRight16 color={isNextYearDisabled ? 'dark.20' : 'dark.90'} />}
          isDisabled={isNextYearDisabled}
          onClick={onNextYear}
        />
      </Flex>
      <Box display="grid" gridTemplateColumns="auto auto auto" gap="4px">
        {MONTHS.map((month) => {
          return (
            <DatePickerMonthButton
              key={month.index}
              month={month.index}
              currentMonth={currentMonth}
              changeMonth={onChange}
              onClose={onClose}
              isDisabled={getMonthDisabled(month.index)}
            >
              {month.name}
            </DatePickerMonthButton>
          );
        })}
      </Box>
    </>
  );
};
