import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import { RippleIconProps } from './types';

export function RippleForbidden({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="4.92896" y="17.6567" width="18" height="2" rx="1" transform="rotate(-45 4.92896 17.6567)" fill="currentColor" />
    </Icon>
  );
}
