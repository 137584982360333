import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconActiveProps } from './types';

export function RippleFileTransfer({ color = DEFAULT_ICON_COLOR, isActive }: RippleIconActiveProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V7.41421C20 6.88378 19.7893 6.37507 19.4142 6L16 2.58579C15.6249 2.21071 15.1162 2 14.5858 2H6C4.89543 2 4 2.89543 4 4V6H6V4H13V7C13 8.10457 13.8954 9 15 9H18V20H6V18H4V20ZM17.5858 7L15 4.41421V7H17.5858ZM6.70711 8.70711L5.41421 10H10V12H5.41421L6.70711 13.2929L5.29289 14.7071L2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11C2 10.7348 2.10536 10.4804 2.29289 10.2929L5.29289 7.29289L6.70711 8.70711ZM15.7071 14.2929L12.7071 11.2929L11.2929 12.7071L12.5858 14L8 14V16L12.5858 16L11.2929 17.2929L12.7071 18.7071L15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929Z"
        fill="currentColor"
      />
      {isActive && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 20C24 22.2091 22.2091 24 20 24C17.7909 24 16 22.2091 16 20C16 17.7909 17.7909 16 20 16C22.2091 16 24 17.7909 24 20ZM22.28 19.7085C22.5924 19.3961 22.5924 18.8896 22.28 18.5772C21.9676 18.2648 21.461 18.2648 21.1486 18.5772L19.4286 20.2972L18.8514 19.72C18.539 19.4076 18.0324 19.4076 17.72 19.72C17.4076 20.0324 17.4076 20.539 17.72 20.8514L18.8629 21.9943C19.1753 22.3067 19.6818 22.3067 19.9943 21.9943L22.28 19.7085Z"
          fill="#14C02F"
        />
      )}
    </Icon>
  );
}
