import axios from 'axios';

import { USER_COMPUTERS } from '@/services/common';
import { checkResponse } from '@/services/utils';

import { computerDataListSchema } from '../schemas';
import { ComputerListCustomizedField } from '../types';

const getUrl = () => `${USER_COMPUTERS}/query_by_numerous_ids`;

const schema = computerDataListSchema;

const service = (params: { computerIds: Array<number>; customizedFields: Array<ComputerListCustomizedField> }) => {
  const { computerIds, customizedFields } = params;

  const body = {
    ids: computerIds,
    mode: 'customize',
    customized_fields: customizedFields.join(','),
  };

  return checkResponse(axios.post(getUrl(), body), schema);
};

export const getUserComputerListById = {
  getUrl,
  service,
};
