import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDisconnectComputer({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17H11V19H8C7.44771 19 7 19.4477 7 20C7 20.5523 7.44771 21 8 21H16C16.5523 21 17 20.5523 17 20C17 19.4477 16.5523 19 16 19H13V17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H5ZM13 15H19V5L5 5V15H11H13ZM8.08579 12.5L8.79289 11.7929L10.5858 10L8.79289 8.20711L8.08579 7.5L9.5 6.08579L10.2071 6.79289L12 8.58579L13.7929 6.79289L14.5 6.08579L15.9142 7.5L15.2071 8.20711L13.4142 10L15.2071 11.7929L15.9142 12.5L14.5 13.9142L13.7929 13.2071L12 11.4142L10.2071 13.2071L9.5 13.9142L8.08579 12.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
