import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDescending16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path d="M2 11L4 13L6 11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 13V3" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M9 13V6" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M14 13V9" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
    </Icon>
  );
}
