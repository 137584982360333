import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleScripting({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99922 2H19.9992V3.22526L20 3.22675V2C21.6569 2 23 3.34315 23 5C23 6.65685 21.6569 8 20 8V6C20.5523 6 21 5.55228 21 5C21 4.61395 20.7812 4.27899 20.4609 4.11232C20.3089 4.19354 20.1963 4.32423 20.1153 4.49935C20.0291 4.68569 19.9992 4.88195 19.9992 5.00002V14C19.2979 14.0001 18.6247 14.1205 17.9992 14.3417V4.99998C17.9992 4.73694 18.0424 4.37858 18.1669 4H6.99922C6.82591 4 6.54368 4.06022 6.33352 4.21153C6.17435 4.32614 5.99922 4.52592 5.99922 5V16H15.5278C14.5777 17.0615 14 18.4633 14 20C14 20.7013 14.1203 21.3744 14.3414 22H4C2.34315 22 1 20.6569 1 19C1 17.3434 2.34272 16.0004 3.99922 16V5C3.99922 3.87408 4.49075 3.07386 5.16491 2.58847C5.78809 2.13978 6.50586 2 6.99922 2ZM3 19C3 18.4477 3.44772 18 4 18H13.1467C13.0525 18.2988 13 18.632 13 19C13 19.368 13.0525 19.7012 13.1467 20H4C3.44772 20 3 19.5523 3 19ZM10.7071 8.70711C11.0976 8.31658 11.0976 7.68342 10.7071 7.29289C10.3166 6.90237 9.68342 6.90237 9.29289 7.29289L7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071C11.0976 12.3166 11.0976 11.6834 10.7071 11.2929L9.41421 10L10.7071 8.70711ZM13.2929 7.29289C12.9024 7.68342 12.9024 8.31658 13.2929 8.70711L14.5858 10L13.2929 11.2929C12.9024 11.6834 12.9024 12.3166 13.2929 12.7071C13.6834 13.0976 14.3166 13.0976 14.7071 12.7071L16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289L14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.4999L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <path
            d="M16 21H17C18.1045 21 19 20.1046 19 19V5C19 4.50265 19.2226 3.63428 20 3.22526M20 3H7C6.33333 3 5 3.4 5 5V17"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M2 19C2 20.1046 2.89543 21 4 21H16C15.3333 21 14 20.6 14 19C14 17.4 15.3333 17 16 17H4C2.89543 17 2 17.8954 2 19Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M20 7C21.1046 7 22 6.10457 22 5C22 3.89543 21.1046 3 20 3" stroke="currentColor" strokeWidth="2" />
          <path d="M10 8L8 10L10 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14 8L16 10L14 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </>
      )}
    </Icon>
  );
}
