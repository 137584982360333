export type RippleColumnSize = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs' | 'checkbox';

export const rippleColumnSize: Record<RippleColumnSize, number> = {
  xxl: 320,
  xl: 272,
  l: 192,
  m: 160,
  s: 128,
  xs: 96,
  xxs: 64,
  checkbox: 44,
} as const;
