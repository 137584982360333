import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleInquiries16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <circle cx="8" cy="8" r="6.2" stroke="currentColor" strokeWidth="1.6" />
      <path
        d="M10.6 6.22C10.6 7.8963 8.82603 7.9221 8.82603 8.54157V8.7C8.82603 8.86567 8.69442 9 8.53209 9H7.41397C7.25165 9 7.12003 8.86567 7.12003 8.7V8.48352C7.12003 7.5899 7.78385 7.23267 8.28548 6.94562C8.71563 6.6995 8.97927 6.5321 8.97927 6.20615C8.97927 5.775 8.44041 5.48882 8.00477 5.48882C7.43675 5.48882 7.17454 5.76325 6.80594 6.23805C6.70656 6.36605 6.52522 6.38982 6.3977 6.29115L5.71616 5.7637C5.59106 5.6669 5.56304 5.48705 5.65139 5.35462C6.23013 4.48728 6.96728 4 8.11497 4C9.31696 4 10.6 4.9576 10.6 6.22V6.22Z"
        fill="currentColor"
      />
      <path
        d="M9 11C9 11.5514 8.5514 12 8 12C7.4486 12 7 11.5514 7 11C7 10.4486 7.4486 10 8 10C8.5514 10 9 10.4486 9 11Z"
        fill="currentColor"
      />
    </Icon>
  );
}
