import { forwardRef as ReactForwardRef, useImperativeHandle } from 'react';

import { forwardRef } from '@chakra-ui/react';
import { ColumnDef, Row, Table, TableOptions, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableVirtuoso, TableVirtuosoProps } from 'react-virtuoso';

import { RippleTBody, RippleTD, RippleTH, RippleTHead, RippleTR, RippleTable, RippleTableProps } from './RippleTable';
import { FillerRow } from './VirtuosoComponents';

type VirtuosoOptions<T> = Omit<TableVirtuosoProps<Row<T>, Table<T>>, 'data' | 'width' | 'height'>;

/**
 * `RippleVirtuosoReactTableProps` is a component that wraps the `@tanstack/react-table`, `react-virtuoso`, and `RippleTable` components.
 * It applies our design system and renders a table based on the provided data and columns props.
 *
 * @component
 * @param {data} props - The data to be displayed in the table.
 * @param {columns} props - The react-table's columns props.
 * @param {width} props - The width of the table.
 * @param {height} props - The height of the table.
 * @param {TableOptions} props - The properties that define the data and structure of the table. These are passed directly to the `useReactTable` hook
 * @param {TableVirtuosoProps} props - The properties that define the data and structure of the table. These are passed directly to the `TableVirtuoso` component
 * @returns {React.JSX.Element} A table element rendered with the `RippleTable` component.
 *
 * @example
 * // Data for the table
 * const data = [
 *   { firstName: 'John', lastName: 'Doe' },
 *   { firstName: 'Jane', lastName: 'Doe' },
 * ];
 *
 * // Columns for the table
 * const columns = [
 *   { Header: 'First Name', accessor: 'firstName' },
 *   { Header: 'Last Name', accessor: 'lastName' },
 * ];
 *
 * <RippleVirtuosoReactTable data={data} columns={columns} width="500px" height="500px" />
 */
type RippleVirtuosoReactTableProps<T> = RippleTableProps & {
  data: Array<T>;
  columns: Array<ColumnDef<T, any>>;
  width?: TableVirtuosoProps<Row<T>, Table<T>>['width'];
  height?: TableVirtuosoProps<Row<T>, Table<T>>['height'];
  tableOptions?: Omit<TableOptions<T>, 'getCoreRowModel' | 'data' | 'columns'>;
  virtuosoOptions?: VirtuosoOptions<T> | ((table: Table<T>) => VirtuosoOptions<T>);
};
export const RippleVirtuosoReactTable = ReactForwardRef(function <T = unknown>(
  {
    data,
    columns,
    width = '100%',
    height = '500px',
    variant,
    colorScheme,
    tableOptions,
    virtuosoOptions = {},
    ...tableProps
  }: Readonly<RippleVirtuosoReactTableProps<T>>,
  ref: React.Ref<{ getTable: () => Table<T> }>,
) {
  const table = useReactTable({
    ...tableOptions,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        getTable() {
          return table;
        },
      };
    },
    [table],
  );

  const {
    components,
    style,
    itemContent = renderItemContent,
    fixedHeaderContent = renderFixedHeaderContent(table),
    ...otherVirtuosoProps
  } = typeof virtuosoOptions === 'function' ? virtuosoOptions(table) : virtuosoOptions;

  return (
    <TableVirtuoso
      data={table.getRowModel().rows}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={itemContent}
      components={{
        Table: rippleTable({ variant, colorScheme, ...tableProps }),
        TableHead: RippleTHead,
        TableBody: RippleTBody,
        TableRow: RippleTR,
        FillerRow,
        ...components,
      }}
      style={{
        ...style,
        width,
        height,
      }}
      {...otherVirtuosoProps}
    />
  );
}) as <T = unknown>(props: RippleVirtuosoReactTableProps<T> & { ref?: React.Ref<{ getTable: () => Table<T> }> }) => React.JSX.Element;

const rippleTable = (tableProps: RippleTableProps) => forwardRef((props, ref) => <RippleTable ref={ref} {...props} {...tableProps} />);

function renderFixedHeaderContent<T>(table: Readonly<Table<T>>) {
  return () =>
    table.getHeaderGroups().map((headerGroup) => (
      <RippleTR key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <RippleTH
            key={header.id}
            width={`${header.column.columnDef.size}px`}
            isSortable={header.column.getCanSort()}
            sortDirection={header.column.getIsSorted()}
            onClick={header.column.getToggleSortingHandler()}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </RippleTH>
        ))}
      </RippleTR>
    ));
}

function renderItemContent<T>(_index: number, row: Row<T>) {
  return (
    <RippleTR isSelected={row.getIsSelected()}>
      {row.getVisibleCells().map((cell) => (
        <RippleTD key={cell.id} width={`${cell.column.columnDef.size}px`}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </RippleTD>
      ))}
    </RippleTR>
  );
}
