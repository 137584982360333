import type { ComponentStyleConfig } from '@chakra-ui/theme';

const styles: ComponentStyleConfig = {
  parts: ['button', 'helperText'],
  baseStyle: {
    button: {
      borderRadius: '4px',
      bgColor: 'white',
      border: '1px solid',
      borderColor: 'neutral.300',
      width: '100%',
      color: 'dark.90',
      _active: {
        bgColor: 'blue.0',
        borderColor: 'blue.100',
      },
      _disabled: {
        cursor: 'not-allowed',
        borderColor: 'dark.40',
        color: 'dark.40',
      },
      _hover: {
        _disabled: {
          bgColor: 'white',
        },
      },
      _invalid: {
        borderColor: 'red.200',
      },
      flexDirection: 'row',
      pl: '12px',
      pr: '10px',
    },
    helperText: {
      mt: '4px',
      color: 'neutral.300',
      _disabled: {
        color: 'dark.40',
      },
      _invalid: {
        color: 'red.100',
      },
    },
  },
  variants: {
    border: {
      button: {},
    },
    borderless: {
      button: {
        border: 'none',
        bgColor: 'neutral.40',
        _disabled: {
          bgColor: 'dark.10',
          color: 'dark.60',
        },
        _hover: {
          _disabled: {
            bgColor: 'dark.10',
          },
        },
        _invalid: {
          bgColor: 'red.10',
        },
      },
    },
  },
  sizes: {
    lg: {
      button: {
        h: '48px',
      },
    },
    md: {
      button: {
        h: '44px',
      },
    },
    sm: {
      button: {
        h: '40px',
      },
    },
    xs: {
      button: {
        h: '32px',
      },
    },
  },
  defaultProps: {
    variant: 'border',
    size: 'sm',
  },
};

export const rippleSelect = styles;
