import { getRegion } from '@/utils/region';

import { ALWAYS, DEVELOPMENT_ONLY, LOCAL_ONLY, NOT_YET, SERVER_ENV } from './condition';
import { ASSETS_CDN_MAPPING, HOST_MAPPING } from './constants';
import { featureToggleControl } from './featureToggleControl';
import { createParameterControl } from './parameterControl';

const REGION = getRegion();

export const featureControl = featureToggleControl({
  newSettingsPage: LOCAL_ONLY,
  newTeamSettingsFailoverForTesting: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfFileTransfer: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfCopyPaster: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__sso__idpType__splashtopSecureOverlay: LOCAL_ONLY || DEVELOPMENT_ONLY,
  CF_355__computer_list_UI_renewal__phase_2: NOT_YET,
  PCP_6__dashboard__show_reboot_required: LOCAL_ONLY,
  /** Remove those two toggle together, PAGE_antivirus_threat_logs__redirect is for QA testing before antivirus release */
  PAGE_antivirus_threat_logs: ALWAYS,
  PAGE_antivirus_threat_logs__redirect: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** Remove those two toggle together end */
  CLE_53__Computer_Device_Icon_Change: NOT_YET, // NOTE: Need to wait for SRC app to implement
  PCP_1137_Refactor_Web_Client: ALWAYS,
  // Policy start
  PCP_2647__Policy_support_android_platform: ALWAYS,
  PCP_2648__Policy_software_patch_show_mac_beqa_only: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1682__Policy_os_patch: ALWAYS,
  PCP_2825__Policy_streamer_preference_display_android_support_items: ALWAYS,
  PCP_2377__Policy_os_patch_show_mac_beqa_only: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3546__Policy_preference_setting_operator_should_be_minus_when_uncheck: ALWAYS,
  PCP_3517__Policy_streamer_settings_the_enabled_can_be_revert: ALWAYS,
  PCP_3620__Policy_create_items_should_have_required_field_if_parent_has_no_value: LOCAL_ONLY || DEVELOPMENT_ONLY,
  // Policy end
  PCP_1250__SystemToolsSession__DetailsIcon: ALWAYS,
  PCP_1537__EndpointSecurity__ComputerListAPI_TokenMethod: ALWAYS,
  PCP_1850_Zendesk_Widget_Instrumentality: ALWAYS,
  PCP_1713__GetStartedPage: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2315__JotaiAtomStateIsolation_app: ALWAYS,
  PCP_2281__Impact_Advocate_Widget: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2760_modify_permission_checking_for_new_unified_product: ALWAYS,
  PCP_2888_Zendesk_GroupContext: ALWAYS,
  PCP_2988__Setup_RudderStack_Snippet: ALWAYS,
  PCP_2807__PreferencePolicy_Expiration: ALWAYS,
  PCP_2979__Reload_page_randomly: ALWAYS,
  PCP_3454__Policy_List_Order: ALWAYS,
  PCP_2871__Inventory_Export_Disabled__TeamExpiration: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3527__EMM_policy__redirect_invited_user: ALWAYS,
  PCP_3490__Improve_infoQueryService: ALWAYS,
  PCP_3044__Computer_not_found_block: ALWAYS,
  PCP_3403__EMM_policy__create_default_policy_when_assign: ALWAYS,
  PCP_3556__TeamSettings_loading_too_long: ALWAYS,
  PCP_3505__Implement_Simple_Group_List: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3529__ContactUs__SOS_Introduction: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3617__Policy__prevent_improper_popup: ALWAYS,
  PCP_3712__RudderStack_Identify_User: ALWAYS,
  PCP_3641__TeamStatus_Condition: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2430__TeamSettings_SOS_invitation_template: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3566__TeamSettings_SOS_invitation_template__allow_custom: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3670__Policy__overridden__hardcode_enable_to_true: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** Colin start */
  PCP_3665__Access_Permission_Sheet: ALWAYS,
  PCP_3705__Event_Log_Placement: ALWAYS,
  PCP_3735__Remove_Follow_Group_For_Owner: ALWAYS,
  PCP_3693__Remove_Follow_Group_For_Session_Recording: ALWAYS,
  PCP_3759__Group_Order: ALWAYS,
  PCP_3599__Default_SSO: ALWAYS,
  PCP_3813__SUPER_ADMIN_Allowed_Admin_Configurable: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3808__Add_SearchBar_To_Group_Selector: ALWAYS,
  /** Colin end */
  PCP_3477__SOS_Customization__ImageUploader: ALWAYS,
  PCP_3515__Modify_EU_Pay_By_Invoice: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3338__PCP_3339__SOS: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** --- Uni start --- */
  PCP_3052__Dashboard__Software_opportunities: LOCAL_ONLY,
  PCP_3663__Dashboard__access_checking: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2169__Dashboard__new_sos_plus_upsell_emm: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3835__Dashboard__jotai_storage_issue: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3798__Dashboard__get_computer_list_by_token: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** --- Uni end --- */
  PCP_320__Refine_New_Version_Notification: ALWAYS,
  PCP_3054__Add_SRS_Distribution_In_OS_Overview_Widget: ALWAYS,
  PCP_3760__VoiceCall_ActiveSessions_SOS14: ALWAYS,
  PCP_3748__Dashboard__group_menu__should_auto_close: ALWAYS,
  PCP_2740__TeamSettings_AllowVersionControl_android: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3553_Filter_out_computer_customize_policy: ALWAYS,
  PCP_3236_Keep_loading_indicator_on_the_screen_center: ALWAYS,
  PCP_1489__EMM_auth_header: ALWAYS,
  PCP_2742__ComputerList_backgroundActions_verifyEmail: ALWAYS,
  PCP_3803__TeamSettings_DeviceAuthentication_logoutIdleUser_30min: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3750__System_Tools_Sessions: ALWAYS,
  PCP_3801__FileUploader: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3857__TeamSettings_NewSOSPlus_EndpointManagement: ALWAYS,
  PCP_3848__Adjust_Software_Patch_List_Tooltip: ALWAYS,
  PCP_3844__EU_Pay_By_Invoice_Old_Product: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3849__Software_Patch_Reboot_Option_Child: ALWAYS,
});

export const parameterControl = createParameterControl({
  logServiceUrl: process.env.NEXT_PUBLIC_LOG_SERVICE_URL as string,
  fontDomain: `${ASSETS_CDN_MAPPING[REGION][SERVER_ENV] || ASSETS_CDN_MAPPING.global.production}/fonts`,
  host: `${HOST_MAPPING[REGION][SERVER_ENV] || HOST_MAPPING.global.production}`,
});
