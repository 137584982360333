import colors from '../colors';

const styles = {
  baseStyle: {
    _focus: {
      // TODO: Chakra token invalid in box-shadow property
      boxShadow: `0 0 0 2px ${colors.blue[60]}`,
    },
    _hover: {
      cursor: 'pointer',
    },
    _disabled: {
      color: 'dark.40',
      cursor: 'not-allowed',
    },
    fontWeight: 600,
  },
  variants: {
    standard: {
      color: 'blue.100',
    },
    grayScale: {
      color: 'dark.100',
    },
  },
  sizes: {
    sm: {
      h: '18px',
      fontSize: '12px',
    },
    md: {
      h: '24px',
      fontSize: '14px',
    },
  },
  defaultProps: {
    variant: 'standard',
  },
};

export const rippleInlineSelectButton = styles;
