import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleGranularControlInventoryIcon({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 4.5C2 3.39543 2.89543 2.5 4 2.5H20C21.1046 2.5 22 3.39543 22 4.5V7V14.3414C21.3744 14.1203 20.7013 14 20 14V8L4 8V19.5H14.0205C14.0069 19.6649 14 19.8316 14 20C14 20.518 14.0656 21.0206 14.189 21.5H4C2.89543 21.5 2 20.6046 2 19.5V7V4.5ZM16.0313 15.5C15.4058 16.052 14.8963 16.7325 14.544 17.5H12C11.4477 17.5 11 17.0523 11 16.5C11 15.9477 11.4477 15.5 12 15.5H16.0313ZM20 4.5V6L4 6V4.5H20ZM6.5 8.75C5.80964 8.75 5.25 9.30964 5.25 10V12C5.25 12.6904 5.80964 13.25 6.5 13.25H8.5C9.19036 13.25 9.75 12.6904 9.75 12V10C9.75 9.30964 9.19036 8.75 8.5 8.75H6.5ZM6.75 11.75V10.25H8.25V11.75H6.75ZM5.25 15.5C5.25 14.8096 5.80964 14.25 6.5 14.25H8.5C9.19036 14.25 9.75 14.8096 9.75 15.5V17.5C9.75 18.1904 9.19036 18.75 8.5 18.75H6.5C5.80964 18.75 5.25 18.1904 5.25 17.5V15.5ZM6.75 15.75V17.25H8.25V15.75H6.75ZM12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12H18C18.5523 12 19 11.5523 19 11C19 10.4477 18.5523 10 18 10H12Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.5L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <rect x="3" y="3.5" width="18" height="17" rx="1" stroke="currentColor" strokeWidth="2" />
          <path d="M3 7L21 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <rect x="6" y="9.5" width="3" height="3" rx="0.5" stroke="currentColor" strokeWidth="1.5" />
          <rect x="6" y="15" width="3" height="3" rx="0.5" stroke="currentColor" strokeWidth="1.5" />
          <path d="M12 11L18 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M12 16.5H18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </>
      )}
    </Icon>
  );
}
