import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconActiveProps } from './types';

export function RippleDeviceManager({ color = DEFAULT_ICON_COLOR, isActive }: RippleIconActiveProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isActive ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 4V19H6V21H3C2.44772 21 2 20.5523 2 20V3C2 2.44771 2.44772 2 3 2H15C15.5523 2 16 2.44772 16 3V8H14V4H4ZM10 14V13H20V14H10ZM22 14.3414V12C22 11.4477 21.5523 11 21 11H18C18 10.4477 17.5523 10 17 10H13C12.4477 10 12 10.4477 12 11H9C8.44772 11 8 11.4477 8 12V21C8 21.5523 8.44772 22 9 22H14.3414C14.1203 21.3744 14 20.7013 14 20H10V16H12V17C12 17.5523 12.4477 18 13 18C13.5523 18 14 17.5523 14 17V16H15.5278C16.6264 14.7725 18.223 14 20 14C20.7013 14 21.3744 14.1203 22 14.3414ZM10 7C10 7.55228 9.55228 8 9 8C8.44772 8 8 7.55228 8 7C8 6.44772 8.44772 6 9 6C9.55228 6 10 6.44772 10 7Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 20C24 22.2091 22.2091 24 20 24C17.7909 24 16 22.2091 16 20C16 17.7909 17.7909 16 20 16C22.2091 16 24 17.7909 24 20ZM22.28 19.7085C22.5924 19.3961 22.5924 18.8896 22.28 18.5772C21.9676 18.2648 21.461 18.2648 21.1486 18.5772L19.4286 20.2972L18.8514 19.72C18.539 19.4076 18.0324 19.4076 17.72 19.72C17.4076 20.0324 17.4076 20.539 17.72 20.8514L18.8629 21.9943C19.1753 22.3067 19.6818 22.3067 19.9943 21.9943L22.28 19.7085Z"
            fill="#14C02F"
          />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 19V4H14V8H16V3C16 2.44772 15.5523 2 15 2H3C2.44772 2 2 2.44771 2 3V20C2 20.5523 2.44772 21 3 21H6V19H4ZM10 13V14H20V13H10ZM10 20V16H12V17C12 17.5523 12.4477 18 13 18C13.5523 18 14 17.5523 14 17V16H16V17C16 17.5523 16.4477 18 17 18C17.5523 18 18 17.5523 18 17V16H20V20H10ZM9 11C8.44772 11 8 11.4477 8 12V21C8 21.5523 8.44772 22 9 22H21C21.5523 22 22 21.5523 22 21V12C22 11.4477 21.5523 11 21 11H18C18 10.4477 17.5523 10 17 10H13C12.4477 10 12 10.4477 12 11H9ZM9 8C9.55228 8 10 7.55228 10 7C10 6.44772 9.55228 6 9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
}
