import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleSystemTools({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M15 9V5C15 3.89543 14.1046 3 13 3H5C3.89543 3 3 3.89543 3 5V13C3 14.1046 3.89543 15 5 15H9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M14 4L4 14" stroke="currentColor" strokeWidth="2" />
      <path d="M10 3L3 10" stroke="currentColor" strokeWidth="2" />
      <path
        d="M21 13V11C21 9.89543 20.1046 9 19 9H11C9.89543 9 9 9.89543 9 11V19C9 20.1046 9.89543 21 11 21H13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.3637 22.8793L21.7954 22.526C22.0339 22.3309 22.069 21.9684 21.8737 21.7162L19.0728 18.0989L21.1538 17.4477C21.4356 17.3595 21.466 16.9607 21.2022 16.8126L14.4888 13.044C14.2283 12.8977 13.9332 13.1391 14.0134 13.4329L16.0798 21.0033C16.161 21.3008 16.5459 21.3598 16.6928 21.0973L17.7773 19.1585L20.5783 22.776C20.7736 23.0281 21.1252 23.0744 21.3637 22.8793Z"
        fill="currentColor"
      />
    </Icon>
  );
}
