import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export function RippleMacOnline32() {
  const id = useId();
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" fill="none">
      <g clipPath={`url(#${id})`}>
        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DDDDDD" />
        <path
          d="M20.9196 15.0937C20.9282 14.3664 21.1211 13.6532 21.4804 13.0207C21.8397 12.3883 22.3536 11.8574 22.974 11.4777C22.5801 10.9166 22.0615 10.4546 21.4587 10.128C20.856 9.80134 20.1857 9.6191 19.5005 9.59555C18.0221 9.44712 16.6149 10.4684 15.8608 10.4684C15.1068 10.4684 13.9549 9.61931 12.7258 9.63712C11.9214 9.65996 11.1365 9.89072 10.4477 10.3069C9.75892 10.7231 9.18961 11.3106 8.79523 12.0121C7.12085 14.9215 8.36773 19.2262 9.98273 21.5893C10.7784 22.7412 11.7343 24.0356 12.9812 23.9643C14.228 23.8931 14.6377 23.1865 16.0924 23.1865C17.5471 23.1865 17.9746 23.9999 19.2452 23.9999C20.5158 23.9999 21.3649 22.8124 22.1546 21.6665C22.7143 20.8239 23.152 19.9063 23.4549 18.9412C22.705 18.6144 22.0661 18.0769 21.616 17.3938C21.1659 16.7108 20.924 15.9117 20.9196 15.0937Z"
          fill="#1C1C1C"
        />
        <path
          d="M18.5267 8.03406C18.8837 7.61863 19.1552 7.13671 19.3253 6.61602C19.4955 6.09532 19.5611 5.54612 19.5182 5C18.4363 5.1028 17.4352 5.61717 16.7217 6.43688C16.369 6.83587 16.1001 7.3016 15.9308 7.80645C15.7615 8.31131 15.6953 8.84501 15.736 9.37594C16.2728 9.38018 16.8034 9.26145 17.2871 9.02885C17.7708 8.79625 18.1948 8.45595 18.5267 8.03406Z"
          fill="#1C1C1C"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
