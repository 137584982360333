import { Icon, keyframes } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export function RippleRefresh16({ color = DEFAULT_ICON_COLOR, isLoading = false }: RippleIconProps & { isLoading?: boolean }) {
  const animation = isLoading ? `${spin}` : undefined;

  return (
    <Icon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
      color={color}
      sx={{
        transformOrigin: 'center',
        animationDuration: '1.2s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      }}
      animation={animation}
    >
      <path
        d="M12.666 8.00033C12.666 10.6003 10.5993 12.667 7.99935 12.667C5.39935 12.667 3.33268 10.6003 3.33268 8.00033C3.33268 5.40033 5.39935 3.33366 7.99935 3.33366"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        color="currentColor"
      />
      <path d="M7 5.33301L9.10457 3.33195L7 1.33301" stroke="currentColor" strokeWidth="1.6" strokeLinejoin="round" color="currentColor" />
    </Icon>
  );
}
