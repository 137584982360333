import { useMemo, useState } from 'react';

import { Box, ButtonGroup, Collapse, Flex, Stack, SystemStyleObject, useMultiStyleConfig } from '@chakra-ui/react';
import { format, isAfter, isBefore, isSameDay, max, min, set } from 'date-fns';

import {
  RippleArrowDown16,
  RippleArrowLeft16,
  RippleArrowRight16,
  RippleArrowUp16,
  RippleBadge,
  RippleButton,
  RippleCalendar,
  RippleIconButton,
  RipplePopover,
  RipplePopoverContent,
  RipplePopoverFooter,
  RipplePopoverTrigger,
  RippleStrong,
  RippleTextField,
  RippleTextFieldProps,
  RippleTypography,
} from '../';
import { Days, WeekNames } from './components';
import { getRangePickDayButtonProps } from './core';
import { useRangedCalendar } from './hooks';
import { Range, getDateRangeForShortcut, useShortcuts } from './utils';

const DEFAULT_DATE_FORMAT = 'yyyy/MM/dd';
const DISABLED_COLOR = 'neutral.300';

type Actions = {
  label: string;
  onClick?: (startDate: Date, endDate: Date) => void;
};

export type RippleDateRangePickerProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  startDate?: Date;
  endDate?: Date;
  dateFormat?: string;
  inputOptions?: RippleTextFieldProps;
  minDate?: Date;
  maxDate?: Date;
  mode?: 'past' | 'future';
  sx?: SystemStyleObject;
  isDisabled?: boolean;
  primaryAction?: Actions;
  secondaryActions?: Array<Actions>;
};

export const RippleDateRangePicker = ({
  isOpen,
  onOpen,
  onClose,
  startDate = new Date(),
  endDate = new Date(),
  inputOptions = {},
  dateFormat = DEFAULT_DATE_FORMAT,
  mode = 'past',
  sx,
  isDisabled,
  minDate,
  maxDate,
  primaryAction,
  secondaryActions,
}: RippleDateRangePickerProps) => {
  const [currentStart, setCurrentStart] = useState<Date>(startDate);
  const [currentEnd, setCurrentEnd] = useState<Date>(endDate);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const { calendarEndDate, calendarStartDate, resetCalendar, goNextMonth, goPrevMonth, calendarStartName, calendarEndName } =
    useRangedCalendar({
      mode,
      startDate,
      endDate,
    });

  const minDateStart = useMemo(
    () => (minDate ? set(minDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }) : undefined),
    [minDate],
  );
  const maxDateEnd = useMemo(
    () => (maxDate ? set(maxDate, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }) : undefined),
    [maxDate],
  );

  const shortcuts = useShortcuts();

  const isPrevMonthDisabled = minDateStart ? isBefore(calendarStartDate, minDateStart) : false;
  const isNextMonthDisabled = maxDateEnd ? isAfter(calendarEndDate, maxDateEnd) : false;

  const isTodayUnavailable =
    (maxDateEnd && isAfter(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), maxDateEnd)) ||
    (minDateStart && isBefore(set(new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }), minDateStart));

  const selectTarget = isBefore(currentEnd, currentStart) ? 'start' : 'end';

  const getDateDisabled = (date: Date) => {
    return Boolean((minDateStart && isBefore(date, minDateStart)) || (maxDateEnd && isAfter(date, maxDateEnd)));
  };

  const handleDateChange = (date: Date) => {
    setIsSelecting(!isSelecting);
    if (!isSelecting) {
      setCurrentStart(date);
      setCurrentEnd(date);
      return;
    }
    if (selectTarget === 'start') {
      setCurrentStart(date);
      setCurrentEnd(currentStart);
      return;
    }
    setCurrentEnd(date);
  };

  const handleShortcutClick = (range: Range) => {
    if (isTodayUnavailable) {
      return;
    }
    const { start, end } = getDateRangeForShortcut(range);
    const newStart = minDateStart ? max([start, minDateStart]) : start;
    const newEnd = maxDateEnd ? min([end, maxDateEnd]) : end;
    setCurrentStart(newStart);
    setCurrentEnd(newEnd);
    setIsSelecting(false);
    resetCalendar(newStart, newEnd);
  };

  const styles = useMultiStyleConfig('rippleSelect', { variant: 'border', size: 'sm' });

  return (
    <Box sx={sx}>
      <RipplePopover
        placement="bottom-start"
        isOpen={!isDisabled && isOpen}
        onOpen={onOpen}
        onClose={() => {
          onClose();
          setCurrentStart(startDate);
          setCurrentEnd(endDate);
          resetCalendar(startDate, endDate);
          setIsSelecting(false);
        }}
      >
        <RipplePopoverTrigger>
          <RippleButton
            __css={styles.button}
            size="sm"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            rightIcon={isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
            isActive={isOpen}
          >
            <RippleTypography as="div" variant="body02" isTruncated>
              {`${format(startDate, dateFormat)}-${format(endDate, dateFormat)}`}
            </RippleTypography>
          </RippleButton>
        </RipplePopoverTrigger>
        <RipplePopoverContent boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)" borderRadius="4px" width="440px">
          <Collapse in={isOpen}>
            <Stack p="12px" gap="12px" alignItems="flex-start">
              <Stack direction="row">
                <Stack gap="4px">
                  <RippleTypography as="label" variant="heading09" htmlFor="start-date">
                    Start date
                  </RippleTypography>
                  <RippleTextField
                    name="start-date"
                    leadingIcon={<RippleCalendar color={isSelecting && selectTarget === 'start' ? 'blue.100' : DISABLED_COLOR} />}
                    size="sm"
                    value={selectTarget === 'start' ? format(currentEnd, dateFormat) : format(currentStart, dateFormat)}
                    isDisabled={(isSelecting && selectTarget === 'end') || isDisabled}
                    borderColor={isSelecting && selectTarget === 'start' ? 'blue.100' : DISABLED_COLOR}
                    isReadOnly
                    {...inputOptions}
                  />
                </Stack>
                <Box w="8px" h="2px" mt="42px" borderRadius="1px" borderStyle="solid" borderWidth="1px" borderColor={DISABLED_COLOR} />
                <Stack gap="4px">
                  <RippleTypography as="label" variant="heading09" htmlFor="end-date">
                    End date
                  </RippleTypography>
                  <RippleTextField
                    name="end-date"
                    leadingIcon={<RippleCalendar color={isSelecting && selectTarget === 'end' ? 'blue.100' : DISABLED_COLOR} />}
                    size="sm"
                    value={selectTarget === 'start' ? format(currentStart, dateFormat) : format(currentEnd, dateFormat)}
                    isDisabled={(isSelecting && selectTarget === 'start') || isDisabled}
                    borderColor={isSelecting && selectTarget === 'end' ? 'blue.100' : DISABLED_COLOR}
                    isReadOnly
                    {...inputOptions}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" gap="24px" alignItems="flex-start">
                <Stack gap="8px">
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <RippleIconButton
                      aria-label="previous month"
                      icon={<RippleArrowLeft16 color={isPrevMonthDisabled ? 'dark.20' : DISABLED_COLOR} />}
                      isDisabled={isPrevMonthDisabled}
                      onClick={goPrevMonth}
                      width="24px"
                      height="24px"
                    />
                    <RippleStrong as="p" variant="strong03" w="100%" ml="-24px" textAlign="center">
                      {calendarStartName}
                    </RippleStrong>
                  </Stack>
                  <WeekNames />
                  <Days
                    isSelecting={isSelecting}
                    dayButtonsProps={getRangePickDayButtonProps(calendarStartDate, currentStart, currentEnd)}
                    getDateDisabled={getDateDisabled}
                    onChange={handleDateChange}
                    onSelecting={setCurrentEnd}
                    startDate={selectTarget === 'start' ? currentEnd : currentStart}
                    endDate={selectTarget === 'start' ? currentStart : currentEnd}
                    showCurrentMonthOnly
                  />
                </Stack>
                <Stack gap="8px">
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <RippleStrong as="p" variant="strong03" w="100%" mr="-24px" textAlign="center">
                      {calendarEndName}
                    </RippleStrong>
                    <RippleIconButton
                      aria-label="next month"
                      icon={<RippleArrowRight16 color={isNextMonthDisabled ? 'dark.20' : DISABLED_COLOR} />}
                      isDisabled={isNextMonthDisabled}
                      onClick={goNextMonth}
                      width="24px"
                      height="24px"
                    />
                  </Stack>
                  <WeekNames />
                  <Days
                    isSelecting={isSelecting}
                    dayButtonsProps={getRangePickDayButtonProps(calendarEndDate, currentStart, currentEnd)}
                    getDateDisabled={getDateDisabled}
                    onChange={handleDateChange}
                    onSelecting={setCurrentEnd}
                    startDate={selectTarget === 'start' ? currentEnd : currentStart}
                    endDate={selectTarget === 'start' ? currentStart : currentEnd}
                    showCurrentMonthOnly
                  />
                </Stack>
              </Stack>
              {!isTodayUnavailable && (
                <Stack gap="8px" pt="12px" pb="4px" borderTopWidth="1px" borderStyle="solid" borderColor="neutral.60">
                  <RippleTypography variant="heading09">Shortcuts</RippleTypography>
                  <Stack direction="row" gap="8px" flexWrap="wrap">
                    {shortcuts.map(({ label, value }) => {
                      const { start, end } = getDateRangeForShortcut(value);
                      const isActive = isSameDay(start, currentStart) && isSameDay(end, currentEnd);
                      return (
                        <RippleBadge
                          key={label}
                          as="button"
                          colorScheme={isActive ? 'darkBlue' : 'grey'}
                          variant={isActive ? 'primary' : 'secondary'}
                          size="inline"
                          onClick={() => handleShortcutClick(value)}
                        >
                          {label}
                        </RippleBadge>
                      );
                    })}
                  </Stack>
                </Stack>
              )}
            </Stack>
            {(secondaryActions || primaryAction) && (
              <RipplePopoverFooter p="16px" as={Flex} justifyContent="flex-end">
                <ButtonGroup gap="8px">
                  {secondaryActions?.map(({ onClick, label }, i) => (
                    <RippleButton
                      key={i}
                      variant="secondary"
                      size="xs"
                      onClick={() => {
                        onClick?.(currentStart, currentEnd);

                        onClose();
                      }}
                    >
                      {label}
                    </RippleButton>
                  ))}
                  {primaryAction && (
                    <RippleButton
                      variant="primary"
                      size="xs"
                      style={{ marginLeft: '0px' }}
                      onClick={() => {
                        primaryAction.onClick?.(currentStart, currentEnd);
                        onClose();
                      }}
                    >
                      {primaryAction.label}
                    </RippleButton>
                  )}
                </ButtonGroup>
              </RipplePopoverFooter>
            )}
          </Collapse>
        </RipplePopoverContent>
      </RipplePopover>
    </Box>
  );
};
