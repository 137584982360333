import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowTailRight({ color = DEFAULT_ICON_COLOR, _groupActive }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" color={color} fill="none" _groupActive={_groupActive}>
      <g>
        <path id="Arrow" d="M15 6L20 12L15 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="stem" d="M20 12H4" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      </g>
    </Icon>
  );
}
