import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleUser({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7ZM7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7ZM6.78952 19.5042C7.79986 19.8602 9.47384 20 12 20C14.5262 20 16.2001 19.8602 17.2105 19.5042C17.6758 19.3403 17.8373 19.1837 17.8887 19.1192C17.9178 19.0826 18 18.9751 18 18.625C18 17.7633 17.6069 16.929 16.6867 16.2494C15.7366 15.5475 14.185 15 12 15C9.81504 15 8.26344 15.5475 7.31327 16.2494C6.39313 16.929 6 17.7633 6 18.625C6 18.9751 6.08215 19.0826 6.11125 19.1192C6.16268 19.1837 6.32424 19.3403 6.78952 19.5042ZM4 18.625C4 21.5 7 22 12 22C17 22 20 21.5 20 18.625C20 15.5 17 13 12 13C7 13 4 15.5 4 18.625Z"
        fill="currentColor"
      />
    </Icon>
  );
}
