const styles = {
  parts: ['root', 'container', 'button'],
  variants: {
    border: {
      root: {
        border: '1px',
        borderColor: 'neutral.60',
        borderRadius: 'base',
      },
      container: { _first: { borderTopWidth: 0 }, _last: { borderBottomWidth: 0 } },
    },
    'border-less': {
      root: {
        border: '0px',
      },
      container: {
        border: '0px',
      },
    },
    'border-top': {
      root: {
        border: '0px',
      },
      container: {
        border: '0px',
      },
      button: {
        borderTopWidth: '1px',
        borderColor: 'neutral.60',
      },
    },
  },
};

export const rippleAccordion = styles;
