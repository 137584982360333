import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleInstaller({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  const clipPathId = useId();

  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 12C20.5523 12 21 11.5523 21 11V6.10513C21 5.69255 20.7466 5.32233 20.362 5.17296L12.362 2.06588C12.1292 1.97543 11.8708 1.97543 11.638 2.06588L3.63796 5.17296C3.25338 5.32233 3 5.69255 3 6.10513V12.4117C3 12.4117 3 19.4003 10.7973 21.7185C11.4073 21.8999 12 21.4244 12 20.788V20.7574C12 20.2967 11.6829 19.8996 11.2427 19.7638C8.17083 18.816 6.67471 17.0648 5.89932 15.5741C5.44719 14.7048 5.21962 13.8795 5.10646 13.2752C5.05025 12.975 5.02349 12.7359 5.01087 12.5821C5.00457 12.5055 5.00185 12.4508 5.0007 12.4212L5 12.3997V7.4739C5 7.06133 5.25338 6.69111 5.63796 6.54174L11.638 4.21143C11.8708 4.12098 12.1292 4.12098 12.362 4.21143L18.362 6.54174C18.7466 6.69111 19 7.06133 19 7.4739V11C19 11.5523 19.4477 12 20 12ZM16 12C16.5523 12 17 11.5523 17 11V9.12629C17 8.71372 16.7466 8.3435 16.362 8.19413L12.362 6.64059C12.1292 6.55014 11.8708 6.55014 11.638 6.64059L7.63796 8.19413C7.25338 8.3435 7 8.71372 7 9.12629V12.3258C7 12.3258 7 15.8911 10.8119 17.3219C11.4076 17.5456 12 17.0663 12 16.4299V16.3662C12 15.9176 11.6961 15.5342 11.2842 15.3567C10.2047 14.8916 9.68257 14.217 9.39932 13.6725C9.19719 13.2839 9.09462 12.9124 9.04396 12.6419C9.019 12.5086 9.00787 12.4063 9.00305 12.3477C9.00129 12.3262 9.00041 12.311 9 12.3028V10.4951C9 10.0825 9.25338 9.71227 9.63796 9.56291L11.638 8.78614C11.8708 8.69569 12.1292 8.69569 12.362 8.78614L14.362 9.56291C14.7466 9.71227 15 10.0825 15 10.4951V11C15 11.5523 15.4477 12 16 12ZM23 18C23 20.7614 20.7614 23 18 23C15.2386 23 13 20.7614 13 18C13 15.2386 15.2386 13 18 13C20.7614 13 23 15.2386 23 18ZM18 14.5C18.5523 14.5 19 14.9477 19 15.5V18.5L19.4 18.2C19.8418 17.8686 20.4686 17.9582 20.8 18.4C21.1314 18.8418 21.0418 19.4686 20.6 19.8L18.6 21.3C18.2444 21.5667 17.7556 21.5667 17.4 21.3L15.4 19.8C14.9582 19.4686 14.8686 18.8418 15.2 18.4C15.5314 17.9582 16.1582 17.8686 16.6 18.2L17 18.5V15.5C17 14.9477 17.4477 14.5 18 14.5Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
