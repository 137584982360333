import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDelete({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M5 6H19V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V6Z" stroke="currentColor" strokeWidth="2" />
      <path d="M8 10V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M12 10V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 10V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 6H3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M15 3H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </Icon>
  );
}
