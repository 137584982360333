import React from 'react';

import { Center, SystemStyleObject } from '@chakra-ui/react';

import colors from '@/design/theme/colors';

type NumberedTagProps = {
  children?: React.ReactNode;
  sx?: SystemStyleObject;
};

export const NumberedTag = ({ sx, children }: NumberedTagProps) => {
  return (
    <Center
      flexShrink={0}
      borderRadius="50%"
      borderWidth="1px"
      borderStyle="Solid"
      borderColor={colors.blue['40']}
      backgroundColor={colors.blue['10']}
      color={colors.blue[100]}
      width="28px"
      height="28px"
      lineHeight="1em"
      fontWeight={600}
      sx={sx}
    >
      {children}
    </Center>
  );
};
