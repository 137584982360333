import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleRDPConnected = () => {
  const filter1 = useId();
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const clipPath1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <g clipPath={`url(#${clipPath1})`}>
        <g filter={`url(#${filter1})`}>
          <rect x={4} y={4} width={32} height={32} rx={4} fill="white" />
          <rect x={5} y={5} width={30} height={30} rx={3} stroke={`url(#${linearGradient1})`} strokeWidth={2} />
        </g>
        <path
          d="M14.8572 16.665C15.0814 16.4129 15.4449 16.4129 15.669 16.665L21.0179 22.6805C21.2421 22.9326 21.2421 23.3414 21.0179 23.5935L15.4896 29.8109C15.2654 30.063 14.9019 30.063 14.6777 29.8109L13.0253 27.9525C12.8011 27.7004 12.8011 27.2916 13.0252 27.0395L16.5818 23.0392L13.2915 19.3389C13.0673 19.0868 13.0673 18.678 13.2915 18.4259L14.8572 16.665ZM25.1428 10.1891L26.7086 11.95C26.9327 12.2021 26.9327 12.6109 26.7086 12.863L23.4182 16.5633L26.9748 20.5636C27.1989 20.8157 27.1989 21.2245 26.9747 21.4766L25.3223 23.335C25.0981 23.5871 24.7346 23.5871 24.5105 23.335L18.9821 17.1176C18.7579 16.8655 18.7579 16.4567 18.9821 16.2046L24.331 10.1891C24.5552 9.93697 24.9186 9.93697 25.1428 10.1891Z"
          fill="#DA5E39"
        />
        <circle cx={31} cy={31} r={7} fill={`url(#${linearGradient2})`} stroke="white" strokeWidth={2} />
        <rect x={29} y="30.25" width={4} height="1.5" fill="white" />
        <path
          d="M33 28.4828V33.5172C33 33.6953 33.2154 33.7846 33.3414 33.6586L35.8586 31.1414C35.9367 31.0633 35.9367 30.9367 35.8586 30.8586L33.3414 28.3414C33.2154 28.2154 33 28.3047 33 28.4828Z"
          fill="white"
        />
        <path
          d="M29 28.4828V33.5172C29 33.6953 28.7846 33.7846 28.6586 33.6586L26.1414 31.1414C26.0633 31.0633 26.0633 30.9367 26.1414 30.8586L28.6586 28.3414C28.7846 28.2154 29 28.3047 29 28.4828Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id={filter1} x={-1} y={-1} width={42} height={42} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9875_102100" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={-1} dy={-1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.572549 0 0 0 0 0.92549 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9875_102100" result="effect2_dropShadow_9875_102100" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9875_102100" result="shape" />
        </filter>
        <linearGradient id={linearGradient1} x1={4} y1={11} x2={32} y2={36} gradientUnits="userSpaceOnUse">
          <stop offset="0.208275" stopColor="#AC59FF" />
          <stop offset={1} stopColor="#036DEA" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="37.4167" y1={38} x2="25.1663" y2="24.8754" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
        <clipPath id={clipPath1}>
          <rect width={40} height={40} fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export const RippleRDPOffline = () => {
  const radialGradient1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
      <path
        d="M14.8572 16.665C15.0814 16.4129 15.4449 16.4129 15.6691 16.665L21.018 22.6805C21.2421 22.9326 21.2421 23.3414 21.018 23.5935L15.4896 29.8109C15.2654 30.063 14.9019 30.063 14.6778 29.8109L13.0253 27.9525C12.8011 27.7004 12.8011 27.2916 13.0253 27.0395L16.5818 23.0392L13.2915 19.3389C13.0673 19.0868 13.0673 18.678 13.2915 18.4259L14.8572 16.665ZM25.1428 10.1891L26.7086 11.95C26.9328 12.2021 26.9328 12.6109 26.7086 12.863L23.4183 16.5633L26.9748 20.5636C27.1989 20.8157 27.1989 21.2245 26.9748 21.4766L25.3223 23.335C25.0981 23.5871 24.7347 23.5871 24.5105 23.335L18.9821 17.1176C18.7579 16.8655 18.7579 16.4567 18.9821 16.2046L24.331 10.1891C24.5552 9.93697 24.9186 9.93697 25.1428 10.1891Z"
        fill="#B0B0B0"
      />
      <defs>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(16)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};

export const RippleRDPOnline = () => {
  const radialGradient1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
      <path
        d="M14.8572 16.665C15.0814 16.4129 15.4449 16.4129 15.6691 16.665L21.018 22.6805C21.2421 22.9326 21.2421 23.3414 21.018 23.5935L15.4896 29.8109C15.2654 30.063 14.9019 30.063 14.6778 29.8109L13.0253 27.9525C12.8011 27.7004 12.8011 27.2916 13.0253 27.0395L16.5818 23.0392L13.2915 19.3389C13.0673 19.0868 13.0673 18.678 13.2915 18.4259L14.8572 16.665ZM25.1428 10.1891L26.7086 11.95C26.9328 12.2021 26.9328 12.6109 26.7086 12.863L23.4183 16.5633L26.9748 20.5636C27.1989 20.8157 27.1989 21.2245 26.9748 21.4766L25.3223 23.335C25.0981 23.5871 24.7347 23.5871 24.5105 23.335L18.9821 17.1176C18.7579 16.8655 18.7579 16.4567 18.9821 16.2046L24.331 10.1891C24.5552 9.93697 24.9186 9.93697 25.1428 10.1891Z"
        fill="#DA5E39"
      />
      <defs>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(16)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};
