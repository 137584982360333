import type { PropertiesHyphen } from 'csstype';

// Reference: https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/rails_mvc_nextjs.md#common-action
//            be-app/app/assets/javascripts/init.js.coffee.erb

export const CommonMessageType = {
  loading: 'loading',
  load_complete: 'load_complete',
  change_style: 'change_style',
  close: 'close',
  blankAreaClick: 'blankAreaClick',
  error: 'error',
  reload: 'reload',
  logout: 'logout',
} as const;

export type CommonMessageTypeKeys = keyof typeof CommonMessageType;

export type CommonMessage =
  | { type: typeof CommonMessageType.loading; data: { loading_type: 'normal' | 'navMask' } }
  | {
      type: typeof CommonMessageType.load_complete;
    }
  | {
      type: typeof CommonMessageType.change_style;
      data: PropertiesHyphen; // NOTE: This will directly change parent iframe's style
    }
  | {
      type: typeof CommonMessageType.close;
      error?: string;
    }
  | {
      type: typeof CommonMessageType.blankAreaClick;
    }
  | { type: typeof CommonMessageType.error; error: string }
  | { type: typeof CommonMessageType.reload }
  | { type: typeof CommonMessageType.logout };

export function postMessageToRailsCore(message: any) {
  window.top?.postMessage(message, window.location.origin);
}

/**
 * You can extend message type by passing a generic type.
 */
export type PostMessage<ExtendedMessage = never> = (message: CommonMessage | ExtendedMessage) => void;

export const postMessageToRails: PostMessage = postMessageToRailsCore;
