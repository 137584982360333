import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RipplePolicyWarning({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        id="Scroll body"
        d="M19 10V5C19 4.50265 19.2226 3.63428 20 3.22526M20 3H7C6.33333 3 5 3.4 5 5V17"
        stroke="#80859F"
        strokeWidth="2"
      />
      <path id="Stroke 1" d="M9 7H15" stroke="#80859F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Stroke 2" d="M9 10H15" stroke="#80859F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Stroke 3" d="M9 13H11.5" stroke="#80859F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        id="Scroll bottom"
        d="M10.5 17H4C2.89543 17 2 17.8954 2 19C2 20.1046 2.89543 21 4 21H9"
        stroke="#80859F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path id="Scroll ear" d="M20 7C21.1046 7 22 6.10457 22 5C22 3.89543 21.1046 3 20 3" stroke="#80859F" strokeWidth="2" />
      <path
        id="Triangle"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8145 20.1163L18.1286 12.6112C17.6207 11.7962 16.3793 11.7962 15.8714 12.6112L11.1855 20.1163C10.6668 20.9475 11.2975 22 12.3148 22H21.6852C22.7025 22 23.3332 20.9475 22.8145 20.1163Z"
        fill="#F8C81C"
      />
      <rect id="Stem" x="16.1992" y="14" width="1.6" height="4" rx="0.8" fill="#1C1C1C" />
      <path
        id="Point"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21C16.448 21 16 20.553 16 20C16 19.447 16.448 19 17 19C17.552 19 18 19.447 18 20C18 20.553 17.552 21 17 21Z"
        fill="#1C1C1C"
      />
    </Icon>
  );
}
