import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleTechnician({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            d="M11.9647 7.79322C11.7799 6.54183 11.0698 5.41256 10.4923 4.83511C9.43166 3.77445 8.371 3.4209 6.60324 3.77445L6.26832 4.11004L8.371 6.24932C8.371 6.24932 9.43166 7.30998 8.371 8.37064C7.31034 9.4313 6.24968 8.37064 6.24968 8.37064L4.147 6.23136C4.1287 6.24966 3.77556 6.60363 3.77556 6.60363C3.42126 8.37064 3.7749 9.43121 4.83547 10.492C5.14857 10.8051 5.62395 11.1573 6.19308 11.4435"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.75216 19.3368C5.88342 20.468 7.10865 19.8076 7.5801 19.3363L12.5302 14.3869L9.7019 11.5586L4.75216 16.5083C4.28068 16.9797 3.6209 18.2055 4.75216 19.3368Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path d="M11.1154 12.9723L16.7722 7.31543" stroke="currentColor" strokeWidth="1.5" />
          <path
            d="M18.54 4.13299L19.2471 4.8401L19.9545 5.54755L18.5403 7.66887L16.7726 7.31532L16.419 5.54755L18.54 4.13299Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0658 12.1777C15.7729 11.8848 15.2981 11.8848 15.0052 12.1777C14.7123 12.4706 14.7123 12.9454 15.0052 13.2383L18.1958 16.429C18.7069 16.1702 19.2811 16.018 19.8892 16.0015C19.8796 15.9916 19.8701 15.982 19.8607 15.9726L19.8606 15.9725L16.0658 12.1777ZM16.0014 19.8902C16.0177 19.2821 16.1699 18.7078 16.4285 18.1966L14.2984 16.0664C14.0055 15.7735 13.5306 15.7735 13.2377 16.0664C12.9448 16.3593 12.9448 16.8342 13.2377 17.1271L15.9718 19.8612L15.9719 19.8612C15.9815 19.8708 15.9913 19.8805 16.0014 19.8902Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z"
            fill="#B60022"
          />
        </>
      ) : (
        <>
          <path
            d="M15.5357 12.7081L19.3305 16.5029C19.8018 16.9743 20.4617 18.2 19.3305 19.3313C18.1992 20.4626 16.9737 19.8024 16.5024 19.331L13.7682 16.5968"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M11.9647 7.79322C11.7799 6.54183 11.0698 5.41256 10.4924 4.83511C9.43172 3.77445 8.37106 3.4209 6.6033 3.77445L6.26838 4.11004L8.37106 6.24932C8.37106 6.24932 9.43172 7.30998 8.37106 8.37064C7.3104 9.4313 6.24974 8.37064 6.24974 8.37064L4.14706 6.23136C4.12876 6.24966 3.77562 6.60363 3.77562 6.60363C3.42132 8.37064 3.77496 9.43121 4.83553 10.492C5.14864 10.8051 5.62401 11.1573 6.19315 11.4435"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.75216 19.3368C5.88342 20.468 7.10865 19.8076 7.5801 19.3363L12.5302 14.3869L9.7019 11.5586L4.75216 16.5083C4.28068 16.9797 3.6209 18.2055 4.75216 19.3368Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path d="M11.1154 12.9723L16.7723 7.31543" stroke="currentColor" strokeWidth="1.5" />
          <path
            d="M18.54 4.13299L19.2471 4.8401L19.9546 5.54755L18.5404 7.66887L16.7726 7.31532L16.4191 5.54755L18.54 4.13299Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </>
      )}
    </Icon>
  );
}
