import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleExport({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M9 7L12 4L15 7" stroke="#80859F" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M12 4L12 15" stroke="#80859F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M20 11V19C20 19.5523 19.5523 20 19 20L5 20C4.44771 20 4 19.5523 4 19L4 11"
        stroke="#80859F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Icon>
  );
}
