import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleBackgroundTools({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M16.8002 8.74C17.0134 8.53 17.0845 8.11 16.8712 7.69L15.4496 5.31C15.2363 4.96 14.8809 4.75 14.5255 4.82L13.0327 5.24C12.8905 5.17 12.8194 5.1 12.6773 5.03L12.3219 3.56C12.1797 3.21 11.8243 3 11.3978 3H8.5544C8.19898 3 7.84356 3.21 7.77248 3.56L7.34597 5.03C7.2038 5.1 7.13272 5.17 6.99055 5.24L5.49778 4.82C5.14236 4.75 4.78694 4.96 4.57369 5.31L3.15201 7.69C2.93876 8.04 2.93876 8.46 3.22309 8.74L4.36044 9.79C4.36044 9.86 4.36044 9.93 4.36044 10C4.36044 10.07 4.36044 10.14 4.36044 10.21L3.22309 11.26C3.00984 11.47 2.93876 11.89 3.15201 12.31L4.57369 14.69C4.78694 15.04 5.14236 15.25 5.49778 15.18L6.99055 14.76C7.13272 14.83 7.2038 14.9 7.34597 14.97L7.70139 16.44C7.84356 16.79 8.19898 17 8.62548 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21 14.3333V13C21 11.8954 20.1046 11 19 11H13C11.8954 11 11 11.8954 11 13V19C11 20.1046 11.8954 21 13 21H14.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.5227 21.9034L20.8466 21.6208C21.0254 21.4647 21.0517 21.1747 20.9053 20.973L18.8046 18.0791L20.3653 17.5582C20.5767 17.4876 20.5995 17.1685 20.4016 17.0501L15.3666 14.0352C15.1712 13.9182 14.9499 14.1113 15.0101 14.3463L16.5598 20.4026C16.6207 20.6406 16.9095 20.6878 17.0196 20.4778L17.833 18.9268L19.9338 21.8208C20.0802 22.0225 20.3439 22.0595 20.5227 21.9034Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.205 9.10648C10.9316 8.73846 10.4937 8.5 10 8.5C9.17157 8.5 8.5 9.17157 8.5 10C8.5 10.4937 8.73846 10.9316 9.10648 11.205C9.03707 11.4582 9 11.7248 9 12V12.8293C7.83481 12.4175 7 11.3062 7 10C7 8.34315 8.34315 7 10 7C11.3062 7 12.4175 7.83481 12.8293 9H12C11.7248 9 11.4582 9.03707 11.205 9.10648Z"
        fill="currentColor"
      />
    </Icon>
  );
}
