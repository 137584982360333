import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleGear({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M12 10C12.3956 10 12.7822 10.1173 13.1111 10.3371C13.44 10.5568 13.6964 10.8692 13.8478 11.2346C13.9991 11.6001 14.0387 12.0022 13.9616 12.3902C13.8844 12.7781 13.6939 13.1345 13.4142 13.4142C13.1345 13.6939 12.7781 13.8844 12.3902 13.9616C12.0022 14.0387 11.6001 13.9991 11.2346 13.8478C10.8692 13.6964 10.5568 13.44 10.3371 13.1111C10.1173 12.7822 10 12.3956 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8Z"
        fill="currentColor"
      />
      <path
        d="M13.4254 3.99953L13.9854 6.27894L15.9854 7.39866L18.2454 6.75882L19.6854 9.24819L17.9854 10.8778V13.1172L19.6754 14.7568L18.2454 17.2462L15.9754 16.5963L13.9854 17.716L13.4254 19.9954H10.5454L9.98543 17.716L8.03543 16.5963L5.77543 17.2362L4.33543 14.7468L6.03543 13.1072V10.8778L4.33543 9.23819L5.77543 6.74882L8.04543 7.39866L9.98543 6.27894L10.5454 3.99953H13.4254ZM13.9854 2.00003H9.98543C9.72303 1.98415 9.46251 2.05342 9.24268 2.19753C9.02285 2.34165 8.85545 2.5529 8.76543 2.79983L8.25543 4.92929C8.07544 5.01927 7.90543 5.12924 7.73543 5.22921L5.62543 4.62936C5.36483 4.57902 5.09489 4.61648 4.85785 4.73587C4.62081 4.85527 4.43005 5.04986 4.31543 5.2892L2.31543 8.70833C2.16741 8.92752 2.09598 9.18947 2.11226 9.45343C2.12854 9.7174 2.2316 9.9686 2.40543 10.168L3.98543 11.6976V12.2974L2.41543 13.817C2.24534 14.0206 2.14564 14.2737 2.13123 14.5385C2.11681 14.8034 2.18844 15.0658 2.33543 15.2866L4.33543 18.7058C4.45219 18.941 4.64178 19.1323 4.876 19.2512C5.11022 19.3701 5.37657 19.4102 5.63543 19.3656L7.74543 18.7658C7.91543 18.8657 8.09543 18.9757 8.27543 19.0657L8.78543 21.1951C8.87311 21.4396 9.03712 21.6493 9.25322 21.7933C9.46932 21.9373 9.72604 22.008 9.98543 21.9949H13.9854C14.2495 22.0131 14.5123 21.9448 14.7341 21.8006C14.956 21.6563 15.1249 21.4438 15.2154 21.1951L15.7454 19.0757L16.2654 18.7658L18.3754 19.3656C18.6356 19.4092 18.9029 19.3688 19.1385 19.2501C19.3741 19.1315 19.5657 18.9408 19.6854 18.7058L21.6854 15.2866C21.8318 15.0669 21.9023 14.8052 21.886 14.5417C21.8698 14.2782 21.7677 14.0272 21.5954 13.827L19.9854 12.2974V11.6976L21.5654 10.178C21.737 9.9755 21.8385 9.72297 21.8548 9.45809C21.871 9.19322 21.8011 8.9302 21.6554 8.70833L19.6554 5.2892C19.5369 5.05315 19.3455 4.86152 19.1096 4.74269C18.8737 4.62385 18.6057 4.58412 18.3454 4.62936L16.2354 5.22921C16.0654 5.12924 15.8954 5.01927 15.7154 4.92929L15.1854 2.79983C15.0964 2.55632 14.9321 2.3475 14.7163 2.20368C14.5005 2.05986 14.2445 1.98853 13.9854 2.00003Z"
        fill="currentColor"
      />
    </Icon>
  );
}
