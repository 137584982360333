import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDetails({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H17V10H19V4C19 2.89543 18.1046 2 17 2H4C2.89543 2 2 2.89543 2 4V18C2 19.1046 2.89543 20 4 20H10V18H4V4Z"
        fill="currentColor"
      />
      <rect x="5" y="6" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="8" y="6" width="8" height="2" rx="1" fill="currentColor" />
      <rect x="5" y="10" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="8" y="10" width="5" height="2" rx="1" fill="currentColor" />
      <rect x="5" y="14" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="8" y="14" width="3" height="2" rx="1" fill="currentColor" />

      <circle cx="16" cy="16" r="3" stroke="currentColor" strokeWidth="2" />
      <path
        d="M17.7071 17.7071C18.0976 17.3166 18.7308 17.3166 19.1213 17.7071L21.7062 20.292C22.0967 20.6825 22.0967 21.3157 21.7062 21.7062C21.3157 22.0967 20.6825 22.0967 20.292 21.7062L17.7071 19.1213C17.3166 18.7308 17.3166 18.0976 17.7071 17.7071Z"
        fill="currentColor"
      />
    </Icon>
  );
}
