import { Icon, keyframes } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export function RippleRefresh({ color = DEFAULT_ICON_COLOR, isLoading = false }: RippleIconProps & { isLoading?: boolean }) {
  const animation = isLoading ? `${spin}` : undefined;

  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      color={color}
      sx={{
        transformOrigin: 'center',
        animationDuration: '1.2s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      }}
      animation={animation}
    >
      <path
        d="M19 12C19 15.9 15.9 19 12 19C8.1 19 5 15.9 5 12C5 8.1 8.1 5 12 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M10.5 8L13.6569 4.99841L10.5 2" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    </Icon>
  );
}
