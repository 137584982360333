import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleResetIcon16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path id="Arrow" d="M5 1.5L2.5 4L5 6.5" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path
        id="Ellipse 1133"
        d="M4.41602 11C5.18757 12.7659 6.94965 14 8.99997 14C11.7614 14 14 11.7614 14 9C14 6.23858 11.7614 4 8.99997 4H3"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </Icon>
  );
}
