import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleIOSConnected = () => {
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const linearGradient3 = useId();
  const linearGradient4 = useId();

  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <path
        d="M22.5 40C33.2696 40 42 31.0457 42 20C42 8.9543 33.2696 0 22.5 0C11.7304 0 3 8.9543 3 20C3 31.0457 11.7304 40 22.5 40Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M41 20C41 30.5174 32.6936 39 22.5 39C12.3064 39 4 30.5174 4 20C4 9.48257 12.3064 1 22.5 1C32.6936 1 41 9.48257 41 20Z"
        stroke="#70B30C"
        strokeWidth="2"
      />
      <rect
        width="26.0909"
        height="20.21"
        rx="2"
        transform="matrix(-1 -8.67632e-08 -8.80873e-08 1 40 8)"
        fill={`url(#${linearGradient1})`}
      />
      <path d="M15.1824 9.26312H37.4552V26.9469H15.1824V9.26312Z" fill={`url(#${linearGradient2})`} />
      <path
        d="M38.7273 17.4734C38.8531 17.4734 38.9762 17.5105 39.0808 17.5799C39.1855 17.6493 39.267 17.7479 39.3152 17.8633C39.3634 17.9787 39.376 18.1057 39.3514 18.2282C39.3268 18.3507 39.2662 18.4633 39.1773 18.5516C39.0883 18.6399 38.9749 18.7001 38.8514 18.7244C38.728 18.7488 38.6 18.7363 38.4837 18.6885C38.3675 18.6407 38.2681 18.5597 38.1981 18.4559C38.1282 18.352 38.0909 18.2299 38.0909 18.105C38.0909 17.9375 38.158 17.7769 38.2773 17.6584C38.3966 17.54 38.5585 17.4734 38.7273 17.4734Z"
        fill="#DEDEDE"
      />
      <path d="M19.637 11.1578H17.0916V13.6841H19.637V11.1578Z" fill="#86AEDD" />
      <path d="M19.637 15.5788H17.0916V19.3681H19.637V15.5788Z" fill="#85AB70" />
      <path opacity="0.15" d="M33 11.1578H24.0909V12.4209H33V11.1578Z" fill="#313E4B" />
      <path opacity="0.15" d="M35.5455 13.6841H24.0909V14.9472H35.5455V13.6841Z" fill="#313E4B" />
      <path opacity="0.15" d="M29.8182 16.2103H24.0909V17.4734H29.8182V16.2103Z" fill="#313E4B" />
      <path opacity="0.15" d="M33.6364 18.7366H24.0909V19.9997H33.6364V18.7366Z" fill="#313E4B" />
      <path opacity="0.15" d="M31.7273 21.2628H24.0909V22.5259H31.7273V21.2628Z" fill="#313E4B" />
      <path opacity="0.15" d="M22.1818 25.0522V11.1578H20.9091V25.0522H22.1818Z" fill="#313E4B" />
      <path opacity="0.15" d="M28.5455 23.7891H24.0909V25.0522H28.5455V23.7891Z" fill="#313E4B" />
      <path d="M19.6364 21.2628H17.0909V25.0522H19.6364V21.2628Z" fill="#593E82" />
      <rect x="5" y="11.79" width="10.8182" height="20.21" rx="2" fill={`url(#${linearGradient3})`} />
      <path d="M6.27273 13.0525H14.5455V29.4731H6.27273V13.0525Z" fill={`url(#${linearGradient4})`} />
      <path
        d="M10.4091 30.1047C10.535 30.1047 10.658 30.1417 10.7626 30.2111C10.8673 30.2805 10.9488 30.3792 10.997 30.4946C11.0452 30.61 11.0578 30.737 11.0332 30.8595C11.0087 30.982 10.9481 31.0945 10.8591 31.1829C10.7701 31.2712 10.6567 31.3313 10.5332 31.3557C10.4098 31.3801 10.2818 31.3676 10.1656 31.3198C10.0493 31.272 9.94989 31.191 9.87997 31.0871C9.81004 30.9833 9.77273 30.8612 9.77273 30.7363C9.77273 30.5688 9.83977 30.4081 9.95911 30.2897C10.0785 30.1712 10.2403 30.1047 10.4091 30.1047Z"
        fill="#DEDEDE"
      />
      <path d="M8.81816 14.9472H7.54543V17.4734H8.81816V14.9472Z" fill="#86AEDD" />
      <path d="M8.81816 19.3681H7.54543V22.5259H8.81816V19.3681Z" fill="#85AB70" />
      <path d="M8.81816 24.4206H7.54543V27.5784H8.81816V24.4206Z" fill="#593E82" />
      <path opacity="0.15" d="M12 14.9478H10.0909V16.2109H12V14.9478Z" fill="#313E4B" />
      <path opacity="0.15" d="M13.2727 16.8419H10.0909V18.105H13.2727V16.8419Z" fill="#313E4B" />
      <path opacity="0.15" d="M12.6363 19.3681H10.0909V20.6313H12.6363V19.3681Z" fill="#313E4B" />
      <path opacity="0.15" d="M13.2727 22.5259H10.0909V21.2628H13.2727V22.5259Z" fill="#313E4B" />
      <path opacity="0.15" d="M12 24.4206H10.0909V25.6838H12V24.4206Z" fill="#313E4B" />
      <path opacity="0.15" d="M13.2727 26.3153H10.0909V27.5784H13.2727V26.3153Z" fill="#313E4B" />
      <path
        d="M8 38C11.3137 38 14 35.3137 14 32C14 28.6863 11.3137 26 8 26C4.68629 26 2 28.6863 2 32C2 35.3137 4.68629 38 8 38Z"
        fill="#70B30C"
      />
      <path d="M9.8 32.6H6.2V34.4L3.2 32.0061L6.2 29.6V31.4H9.8V29.6L12.8 31.994L9.8 34.4V32.6Z" fill="#F5F6EE" />
      <defs>
        <linearGradient id={linearGradient1} x1="13.0455" y1="19.6643" x2="13.0455" y2="0.970081" gradientUnits="userSpaceOnUse">
          <stop stopColor="#141414" />
          <stop offset="0.131" stopColor="#232525" />
          <stop offset="0.296" stopColor="#343535" />
          <stop offset="0.465" stopColor="#414141" />
          <stop offset="0.637" stopColor="#4A4A4A" />
          <stop offset="0.813" stopColor="#504F4F" />
          <stop offset="1" stopColor="#515151" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="26.3188" y1="9.26312" x2="26.3188" y2="26.9469" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8E2E8" />
          <stop offset="1" stopColor="#B7C6CF" />
        </linearGradient>
        <linearGradient id={linearGradient3} x1="10.4091" y1="31.4543" x2="10.4091" y2="12.7601" gradientUnits="userSpaceOnUse">
          <stop stopColor="#141414" />
          <stop offset="0.131" stopColor="#232525" />
          <stop offset="0.296" stopColor="#343535" />
          <stop offset="0.465" stopColor="#414141" />
          <stop offset="0.637" stopColor="#4A4A4A" />
          <stop offset="0.813" stopColor="#504F4F" />
          <stop offset="1" stopColor="#515151" />
        </linearGradient>
        <linearGradient id={linearGradient4} x1="10.4091" y1="13.0525" x2="10.4091" y2="29.4731" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8E2E8" />
          <stop offset="1" stopColor="#B7C6CF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export const RippleIOSOffline = () => {
  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <rect x="13.9091" y="8" width="26.0909" height="20.21" rx="2" fill="#777777" />
      <path d="M15.1818 9.26312H37.4545V26.9469H15.1818V9.26312Z" fill="#DEDEDE" />
      <path
        d="M38.7273 17.4741C38.8531 17.4741 38.9762 17.5111 39.0808 17.5805C39.1855 17.6499 39.267 17.7485 39.3152 17.8639C39.3634 17.9793 39.376 18.1063 39.3514 18.2288C39.3268 18.3513 39.2662 18.4639 39.1773 18.5522C39.0883 18.6405 38.9749 18.7007 38.8514 18.7251C38.728 18.7494 38.6 18.7369 38.4837 18.6891C38.3675 18.6413 38.2681 18.5604 38.1982 18.4565C38.1282 18.3526 38.0909 18.2305 38.0909 18.1056C38.0909 17.9381 38.158 17.7775 38.2773 17.659C38.3966 17.5406 38.5585 17.4741 38.7273 17.4741Z"
        fill="#DEDEDE"
      />
      <rect x="5" y="11.7894" width="10.8182" height="20.21" rx="2" fill="#777777" />
      <path d="M6.27272 13.0525H14.5454V29.4731H6.27272V13.0525Z" fill="#DEDEDE" />
      <path
        d="M10.4091 30.1053C10.5349 30.1053 10.658 30.1424 10.7626 30.2118C10.8673 30.2812 10.9488 30.3798 10.997 30.4952C11.0452 30.6106 11.0578 30.7376 11.0332 30.8601C11.0087 30.9826 10.9481 31.0951 10.8591 31.1835C10.7701 31.2718 10.6567 31.3319 10.5332 31.3563C10.4098 31.3807 10.2818 31.3682 10.1656 31.3204C10.0493 31.2726 9.94988 31.1916 9.87996 31.0878C9.81003 30.9839 9.77272 30.8618 9.77272 30.7369C9.77272 30.5694 9.83977 30.4087 9.95911 30.2903C10.0784 30.1719 10.2403 30.1053 10.4091 30.1053Z"
        fill="#DEDEDE"
      />
    </Icon>
  );
};

export const RippleIOSOnline = () => {
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const linearGradient3 = useId();
  const linearGradient4 = useId();

  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <rect
        width="26.0909"
        height="20.21"
        rx="2"
        transform="matrix(-1 -8.67632e-08 -8.80873e-08 1 40 8)"
        fill={`url(#${linearGradient1})`}
      />
      <path d="M15.1824 9.26312H37.4552V26.9469H15.1824V9.26312Z" fill={`url(#${linearGradient2})`} />
      <path
        d="M38.7273 17.4734C38.8531 17.4734 38.9762 17.5105 39.0808 17.5799C39.1855 17.6493 39.267 17.7479 39.3152 17.8633C39.3634 17.9787 39.3759 18.1057 39.3514 18.2282C39.3268 18.3507 39.2662 18.4633 39.1772 18.5516C39.0882 18.6399 38.9749 18.7001 38.8514 18.7244C38.728 18.7488 38.6 18.7363 38.4837 18.6885C38.3675 18.6407 38.2681 18.5597 38.1981 18.4559C38.1282 18.352 38.0909 18.2299 38.0909 18.105C38.0909 17.9375 38.1579 17.7769 38.2773 17.6584C38.3966 17.54 38.5585 17.4734 38.7273 17.4734Z"
        fill="#DEDEDE"
      />
      <path d="M19.637 11.1578H17.0915V13.6841H19.637V11.1578Z" fill="#86AEDD" />
      <path d="M19.637 15.5788H17.0915V19.3681H19.637V15.5788Z" fill="#85AB70" />
      <path opacity="0.15" d="M33 11.1578H24.0909V12.4209H33V11.1578Z" fill="#313E4B" />
      <path opacity="0.15" d="M35.5455 13.6841H24.0909V14.9472H35.5455V13.6841Z" fill="#313E4B" />
      <path opacity="0.15" d="M29.8182 16.2103H24.0909V17.4734H29.8182V16.2103Z" fill="#313E4B" />
      <path opacity="0.15" d="M33.6364 18.7366H24.0909V19.9997H33.6364V18.7366Z" fill="#313E4B" />
      <path opacity="0.15" d="M31.7273 21.2628H24.0909V22.5259H31.7273V21.2628Z" fill="#313E4B" />
      <path opacity="0.15" d="M22.1818 25.0522V11.1578H20.9091V25.0522H22.1818Z" fill="#313E4B" />
      <path opacity="0.15" d="M28.5455 23.7891H24.0909V25.0522H28.5455V23.7891Z" fill="#313E4B" />
      <path d="M19.6364 21.2628H17.0909V25.0522H19.6364V21.2628Z" fill="#593E82" />
      <rect x="5" y="11.79" width="10.8182" height="20.21" rx="2" fill={`url(#${linearGradient3})`} />
      <path d="M6.27273 13.0525H14.5455V29.4731H6.27273V13.0525Z" fill={`url(#${linearGradient4})`} />
      <path
        d="M10.4091 30.1047C10.535 30.1047 10.658 30.1417 10.7626 30.2111C10.8673 30.2805 10.9488 30.3792 10.997 30.4946C11.0452 30.61 11.0578 30.737 11.0332 30.8595C11.0087 30.982 10.9481 31.0945 10.8591 31.1829C10.7701 31.2712 10.6567 31.3313 10.5332 31.3557C10.4098 31.3801 10.2818 31.3676 10.1656 31.3198C10.0493 31.272 9.94989 31.191 9.87997 31.0871C9.81004 30.9833 9.77273 30.8612 9.77273 30.7363C9.77273 30.5688 9.83977 30.4081 9.95911 30.2897C10.0785 30.1712 10.2403 30.1047 10.4091 30.1047Z"
        fill="#DEDEDE"
      />
      <path d="M8.81816 14.9472H7.54543V17.4734H8.81816V14.9472Z" fill="#86AEDD" />
      <path d="M8.81816 19.3681H7.54543V22.5259H8.81816V19.3681Z" fill="#85AB70" />
      <path d="M8.81816 24.4206H7.54543V27.5784H8.81816V24.4206Z" fill="#593E82" />
      <path opacity="0.15" d="M12 14.9478H10.0909V16.2109H12V14.9478Z" fill="#313E4B" />
      <path opacity="0.15" d="M13.2727 16.8419H10.0909V18.105H13.2727V16.8419Z" fill="#313E4B" />
      <path opacity="0.15" d="M12.6363 19.3681H10.0909V20.6313H12.6363V19.3681Z" fill="#313E4B" />
      <path opacity="0.15" d="M13.2727 22.5259H10.0909V21.2628H13.2727V22.5259Z" fill="#313E4B" />
      <path opacity="0.15" d="M12 24.4206H10.0909V25.6838H12V24.4206Z" fill="#313E4B" />
      <path opacity="0.15" d="M13.2727 26.3153H10.0909V27.5784H13.2727V26.3153Z" fill="#313E4B" />
      <defs>
        <linearGradient id={linearGradient1} x1="13.0455" y1="19.6643" x2="13.0455" y2="0.970081" gradientUnits="userSpaceOnUse">
          <stop stopColor="#141414" />
          <stop offset="0.131" stopColor="#232525" />
          <stop offset="0.296" stopColor="#343535" />
          <stop offset="0.465" stopColor="#414141" />
          <stop offset="0.637" stopColor="#4A4A4A" />
          <stop offset="0.813" stopColor="#504F4F" />
          <stop offset="1" stopColor="#515151" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="26.3188" y1="9.26312" x2="26.3188" y2="26.9469" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8E2E8" />
          <stop offset="1" stopColor="#B7C6CF" />
        </linearGradient>
        <linearGradient id={linearGradient3} x1="10.4091" y1="31.4543" x2="10.4091" y2="12.7601" gradientUnits="userSpaceOnUse">
          <stop stopColor="#141414" />
          <stop offset="0.131" stopColor="#232525" />
          <stop offset="0.296" stopColor="#343535" />
          <stop offset="0.465" stopColor="#414141" />
          <stop offset="0.637" stopColor="#4A4A4A" />
          <stop offset="0.813" stopColor="#504F4F" />
          <stop offset="1" stopColor="#515151" />
        </linearGradient>
        <linearGradient id={linearGradient4} x1="10.4091" y1="13.0525" x2="10.4091" y2="29.4731" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8E2E8" />
          <stop offset="1" stopColor="#B7C6CF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
