import axios from 'axios';

import { checkResponse, pureInstance } from '@/services/utils';

import { authTokensSchema, refreshAccessTokenSchema } from './types';

export function getAuthTokens() {
  return checkResponse(pureInstance.get('/cookie_tokens'), authTokensSchema);
}

export function refreshAccessToken(refreshToken: string, devUUID: string, userId: number | undefined) {
  // TODO: `user_id` is not marked as optional on API doc, check this with backend
  const payload: { refresh_token: string; user_id?: number } = { refresh_token: refreshToken };
  if (userId !== undefined) {
    payload.user_id = userId;
  }

  return checkResponse(
    axios.post('/api/web/v1/auth/access_token', payload, {
      headers: {
        'Dev-UUID': devUUID,
      },
    }),
    refreshAccessTokenSchema,
  );
}
