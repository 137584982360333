import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCall({ color = DEFAULT_ICON_COLOR, width = '24px', height = '24px' }: RippleIconProps) {
  return (
    <Icon width={width} height={height} viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M19.8 15.7L18.7 14.6C17.9 13.8 16.9 13.8 16.1 14.6C16.1 14.6 16.1 14.6 14.9 15.8C14.2 16.5 12.2 15.2 10.4 13.4C8.60001 11.6 7.40001 9.69998 8.10001 8.99998C9.30001 7.79998 9.30001 7.79998 9.30001 7.79998C10.1 6.99998 10.1 5.99998 9.30001 5.19998L8.30001 4.19998C7.50001 3.39998 6.50001 3.39998 5.70001 4.19998L4.50001 5.39998C2.60001 7.29998 3.90001 12.5 7.70001 16.3C11.5 20.1 16.7 21.4 18.6 19.5L19.8 18.3C20.6 17.6 20.6 16.5 19.8 15.7Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  );
}
