import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleRedirect({ color = DEFAULT_ICON_COLOR, width = '24px', height = '24px' }: RippleIconProps) {
  return (
    <Icon width={width} height={height} viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M9 15L19 5" stroke="currentColor" stroke-width="2" stroke-linecap="square" />
      <path d="M20 8L20 4L16 4" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
      <path
        d="M18 13V19C18 19.5523 17.5523 20 17 20H5C4.44772 20 4 19.5523 4 19V7C4 6.44772 4.44772 6 5 6H10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Icon>
  );
}

export function RippleRedirect16({ color = DEFAULT_ICON_COLOR, width = '16px', height = '16px' }: RippleIconProps) {
  return (
    <Icon width={width} height={height} viewBox="0 0 16 16" fill="none" color={color}>
      <path d="M6 10L13 3" stroke="currentColor" stroke-width="1.6" stroke-linecap="square" />
      <path d="M14 5L14 2L11 2" stroke="currentColor" stroke-width="1.6" stroke-linecap="square" stroke-linejoin="round" />
      <path
        d="M12 9V13C12 13.5523 11.5523 14 11 14H3C2.44772 14 2 13.5523 2 13V5C2 4.44772 2.44772 4 3 4H6.28571"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </Icon>
  );
}
