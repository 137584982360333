/**
 * Basic Ripple Sticky Table component
 * Those component is always align with our design system
 * You can easily use it directly or integrate with other library like react-table, virtuoso, etc.
 */
import { Box, Flex, FlexProps, SystemStyleObject, forwardRef, useToken } from '@chakra-ui/react';

import { RippleInlineAscending, RippleInlineDescending } from '../RippleIcon';
import { RippleTypography } from '../RippleTypography';

export type RippleStickyTableProps = FlexProps;
export const RippleStickyTable = forwardRef<RippleStickyTableProps, 'table'>((props: RippleStickyTableProps, ref) => {
  return (
    <Flex
      ref={ref}
      as="table"
      className="ripple-sticky-table"
      display="inline-flex"
      flexDirection="column"
      position="relative"
      {...props}
    />
  );
});

export const RippleStickyTHead = forwardRef((props: FlexProps, ref) => {
  return <Flex ref={ref} as="thead" className="ripple-sticky-thead" {...props} />;
});

export type RippleStickyTBodyProps = FlexProps;
export const RippleStickyTBody = forwardRef((props: RippleStickyTBodyProps, ref) => {
  return <Flex ref={ref} as="tbody" className="ripple-sticky-tbody" display="inline-flex" flexDirection="column" {...props} />;
});

export type RippleStickyTRProps = FlexProps & {
  canExpand?: boolean;
  isExpanded?: boolean;
  hasPadding?: boolean;
};

export const RippleStickyTR = forwardRef(({ canExpand, isExpanded, hasPadding, ...props }: RippleStickyTRProps, ref) => {
  const padding = hasPadding ? '12px' : '0px';

  return (
    <Flex ref={ref} as="tr" className="ripple-sticky-tr" cursor={canExpand ? 'pointer' : 'initial'} paddingBottom={padding} {...props} />
  );
});

export type SortByStates = 'asc' | 'desc' | false;

export type RippleStickyTHProps = FlexProps & {
  isSortable?: boolean;
  sortDirection?: SortByStates;
  isStickyLeft?: boolean;
  isStickyLeftShadow?: boolean;
  isStickyRight?: boolean;
  isStickyRightShadow?: boolean;
  stickyOffset?: number;
  isResizable?: boolean;
} & RippleResizerProps;
export const RippleStickyTH = forwardRef(
  (
    {
      children,
      sx,
      isStickyLeft,
      isStickyLeftShadow,
      isStickyRight,
      isStickyRightShadow,
      stickyOffset,
      isSortable,
      sortDirection,
      onClick,
      isResizable,
      isResizing,
      onResizeHandler,
      onResetHandler,
      ...props
    }: RippleStickyTHProps,
    ref,
  ) => {
    const stickyStyle = calcStickyStyle({
      isStickyLeft,
      isStickyRight,
      stickyOffset,
    });

    return (
      <Flex
        ref={ref}
        as="th"
        className="ripple-sticky-th"
        position="relative"
        borderColor="neutral.60"
        borderTopWidth="1px"
        alignItems="center"
        flexShrink={0}
        height="40px"
        p="8px"
        _hover={{
          [`& .${RippleResizerClassName}`]: {
            backgroundColor: 'neutral.60',
          },
          [`& .${RippleShadowClassName}`]: {
            visibility: isResizable || isResizing ? 'hidden' : 'visible',
          },
        }}
        sx={{
          ...stickyStyle,
          ...sx,
        }}
        {...props}
      >
        <RippleTypography as="span" variant="heading09" color="neutral.300" cursor={isSortable ? 'pointer' : 'initial'} onClick={onClick}>
          {children}
        </RippleTypography>
        {isSortable && sortDirection === 'desc' && <RippleInlineDescending color="blue.200" />}
        {isSortable && sortDirection === 'asc' && <RippleInlineAscending color="blue.200" />}
        {!isResizing && <StickyShadow isStickyLeftShadow={isStickyLeftShadow} isStickyRightShadow={isStickyRightShadow} />}
        {isResizable && <RippleResizer isResizing={isResizing} onResizeHandler={onResizeHandler} onResetHandler={onResetHandler} />}
      </Flex>
    );
  },
);

export type RippleStickyGroupTDProps = FlexProps & {
  isFirstCell?: boolean;
  isLastCell?: boolean;
  isExpanded?: boolean;
};

export const RippleStickyGroupTD = forwardRef(
  ({ isFirstCell, isLastCell, isExpanded, sx, children, ...props }: RippleStickyGroupTDProps, ref) => {
    const styles: SystemStyleObject = {};
    if (isFirstCell) {
      styles['borderLeftWidth'] = '1px';
      styles['borderTopLeftRadius'] = '4px';

      if (isExpanded === false) {
        styles['borderBottomLeftRadius'] = '4px';
      }
    }
    if (isLastCell) {
      styles['borderRightWidth'] = '1px';
      styles['borderTopRightRadius'] = '4px';

      if (isExpanded === false) {
        styles['borderBottomRightRadius'] = '4px';
      }
    }

    return (
      <Flex ref={ref} as="td" className="ripple-sticky-group-td" flexShrink={0} bg="neutral.40" p={0} height="40px" {...props}>
        <Flex
          as="span"
          alignItems="center"
          flexShrink={0}
          width="100%"
          height="100%"
          p="8px"
          borderColor="neutral.60"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          sx={styles}
        >
          {children}
        </Flex>
      </Flex>
    );
  },
);

export type RippleStickyTDSpaceProps = FlexProps & {
  variant: 'TH' | 'GroupTD' | 'RowTD';
  isStickyLeft?: boolean;
  isStickyRight?: boolean;
  stickyOffset?: number;
};

export const RippleStickyTDSpace = forwardRef(
  ({ variant, isStickyLeft, isStickyRight, stickyOffset = 0, sx, ...props }: RippleStickyTDSpaceProps, ref) => {
    const height = variant === 'RowTD' ? '56px' : '40px';
    const styles = calcStickyStyle({
      isStickyLeft,
      isStickyRight,
      stickyOffset,
    });
    const asDom = variant === 'TH' ? 'th' : 'td';

    return (
      <Flex
        ref={ref}
        as={asDom}
        className={`ripple-sticky-${asDom}-space`}
        alignItems="center"
        flexShrink={0}
        height={height}
        sx={{
          ...styles,
          ...sx,
        }}
        {...props}
      />
    );
  },
);

export type RippleStickyTDProps = FlexProps & {
  variant: 'row' | 'rowInGroup';
  isFirstCell?: boolean;
  isLastCell?: boolean;
  isLastRow?: boolean;
  isStickyLeft?: boolean;
  isStickyLeftShadow?: boolean;
  isStickyRight?: boolean;
  isStickyRightShadow?: boolean;
  stickyOffset?: number;
};

export const RippleStickyTD = forwardRef(
  (
    {
      variant,
      isFirstCell,
      isLastCell,
      isLastRow,
      isStickyLeft,
      isStickyLeftShadow,
      isStickyRight,
      isStickyRightShadow,
      stickyOffset,
      children,
      sx,
      ...props
    }: RippleStickyTDProps,
    ref,
  ) => {
    const styles: SystemStyleObject = {};
    if (variant === 'rowInGroup') {
      if (isFirstCell) {
        styles['borderLeftWidth'] = '1px';
        if (isLastRow) {
          styles['borderBottomLeftRadius'] = '4px';
        }
      }
      if (isLastCell) {
        styles['borderRightWidth'] = '1px';
        if (isLastRow) {
          styles['borderBottomRightRadius'] = '4px';
        }
      }
    }

    const stickyStyle = calcStickyStyle({
      isStickyLeft,
      isStickyRight,
      stickyOffset,
    });

    return (
      <Flex
        ref={ref}
        as="td"
        className="ripple-sticky-td"
        flexShrink={0}
        alignItems="center"
        bg="white"
        p={0}
        height="56px"
        borderColor="neutral.60"
        borderBottomWidth="1px"
        sx={{ ...styles, ...stickyStyle, ...sx }}
        {...props}
      >
        <Box as="span" p="8px" width="100%">
          {children}
        </Box>
        <StickyShadow isStickyLeftShadow={isStickyLeft && isStickyLeftShadow} isStickyRightShadow={isStickyRight && isStickyRightShadow} />
      </Flex>
    );
  },
);

const RippleShadowClassName = 'ripple-sticky-shadow';
const StickyShadow = ({ isStickyLeftShadow, isStickyRightShadow }: { isStickyLeftShadow?: boolean; isStickyRightShadow?: boolean }) => {
  if (isStickyLeftShadow || isStickyRightShadow) {
    return (
      <Flex
        as="span"
        className={RippleShadowClassName}
        width="calc(100% + 8px)"
        height="100%"
        position="absolute"
        top={0}
        left={isStickyLeftShadow ? 0 : undefined}
        right={isStickyRightShadow ? 0 : undefined}
        overflow="hidden"
        zIndex={-1}
      >
        <Flex
          as="span"
          position="absolute"
          top={0}
          left={isStickyLeftShadow ? 0 : undefined}
          right={isStickyRightShadow ? 0 : undefined}
          width="calc(100% - 8px)"
          height="100%"
          boxShadow={isStickyLeftShadow ? '1px 0px 4px 1px rgba(0, 0, 0, 0.16)' : '-1px 0px 4px 1px rgba(0, 0, 0, 0.16)'}
        />
      </Flex>
    );
  }

  return null;
};

const calcStickyStyle = ({
  isStickyLeft,
  isStickyRight,
  stickyOffset,
}: {
  isStickyLeft?: boolean;
  isStickyRight?: boolean;
  stickyOffset?: number;
}) => {
  let stickyStyle = undefined;
  if (isStickyLeft || isStickyRight) {
    stickyStyle = {
      position: 'sticky',
      left: isStickyLeft ? `${stickyOffset}px` : undefined,
      right: isStickyRight ? `${stickyOffset}px` : undefined,
      zIndex: 1,
    };
  }

  return stickyStyle;
};

const RippleResizerClassName = 'ripple-sticky-th-resizer';
type RippleResizerProps = {
  isResizing?: boolean;
  onResizeHandler?: (event: unknown) => void;
  onResetHandler?: () => void;
};
const RippleResizer = ({ isResizing, onResizeHandler, onResetHandler }: RippleResizerProps) => {
  const [resizerColor] = useToken('colors', ['neutral.60']);

  return (
    <div
      onMouseDown={onResizeHandler}
      onTouchStart={onResizeHandler}
      onDoubleClick={onResetHandler}
      style={{
        position: 'absolute',
        top: 0,
        right: '6px',
        width: '5px',
        height: '100%',
        padding: '6px 0 6px 2px',
        cursor: 'col-resize',
        userSelect: 'none',
        zIndex: 2,
      }}
    >
      <div
        className={RippleResizerClassName}
        style={{
          width: '3px',
          height: '100%',
          backgroundColor: isResizing ? resizerColor : undefined,
        }}
      />
    </div>
  );
};
