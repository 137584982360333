import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconActiveProps } from './types';

export function RippleRegistryEditor({ color = DEFAULT_ICON_COLOR, isActive }: RippleIconActiveProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isActive ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3604 7.52353C12.5793 6.74248 12.5793 5.47615 13.3604 4.6951L16.4716 1.58383C17.2527 0.802786 18.519 0.802784 19.3 1.58383L22.4113 4.6951C23.1924 5.47615 23.1924 6.74248 22.4113 7.52353L19.3 10.6348C18.519 11.4158 17.2527 11.4158 16.4716 10.6348L13.3604 7.52353ZM17.8858 2.99805L14.7746 6.10932L17.8858 9.22059L20.9971 6.10932L17.8858 2.99805ZM3 6.99994C3 5.89537 3.89543 4.99994 5 4.99994H10C11.1046 4.99994 12 5.89537 12 6.99994V11.9999H17C18.1046 11.9999 19 12.8954 19 13.9999V14.083C18.2821 14.2034 17.6074 14.4513 17 14.8027V13.9999L12 13.9999V18.9999H14.083C14.0284 19.3252 14 19.6593 14 20C14 20.3407 14.0284 20.6748 14.0829 20.9999H12H11H10H5C3.89543 20.9999 3 20.1045 3 18.9999V13.9999V12.9999V11.9999V6.99994ZM10 18.9999V13.9999H5V18.9999H10ZM10 11.9999V6.99994H5V11.9999H10Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 20C24 22.2091 22.2091 24 20 24C17.7909 24 16 22.2091 16 20C16 17.7909 17.7909 16 20 16C22.2091 16 24 17.7909 24 20ZM22.28 19.7085C22.5924 19.3961 22.5924 18.8896 22.28 18.5772C21.9676 18.2648 21.461 18.2648 21.1486 18.5772L19.4286 20.2972L18.8514 19.72C18.539 19.4076 18.0324 19.4076 17.72 19.72C17.4076 20.0324 17.4076 20.539 17.72 20.8514L18.8629 21.9943C19.1753 22.3067 19.6818 22.3067 19.9943 21.9943L22.28 19.7085Z"
            fill="#14C02F"
          />
        </>
      ) : (
        <>
          <path d="M4 7C4 6.44772 4.44772 6 5 6H10C10.5523 6 11 6.44772 11 7V13H4V7Z" stroke="currentColor" strokeWidth="2" />
          <path d="M4 13H11V20H5C4.44772 20 4 19.5523 4 19V13Z" stroke="currentColor" strokeWidth="2" />
          <path d="M11 13H17C17.5523 13 18 13.4477 18 14V19C18 19.5523 17.5523 20 17 20H11V13Z" stroke="currentColor" strokeWidth="2" />
          <rect
            x="13.3594"
            y="6.10938"
            width="6.4"
            height="6.4"
            rx="1"
            transform="rotate(-45 13.3594 6.10938)"
            stroke="currentColor"
            strokeWidth="2"
          />
        </>
      )}
    </Icon>
  );
}
