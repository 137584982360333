import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleGranularControlOsPatchIcon({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.4 4C4.58026 4 4 4.62105 4 5.28571V6.5H20V5.28571C20 4.62105 19.4197 4 18.6 4H5.4ZM2 5.28571V7.5V16.7143C2 18.5744 3.56877 20 5.4 20H7C7.55228 20 8 19.5523 8 19C8 18.4477 7.55228 18 7 18H5.4C4.58026 18 4 17.379 4 16.7143V8.5H20C20 9.05228 20.4477 9.5 21 9.5C21.5523 9.5 22 9.05228 22 8.5V7.5V5.28571C22 3.42565 20.4312 2 18.6 2H5.4C3.56877 2 2 3.42565 2 5.28571ZM20 12.1221V11.5C20 10.9477 20.4477 10.5 21 10.5C21.5523 10.5 22 10.9477 22 11.5V14.3414C21.3744 14.1203 20.7013 14 20 14C18.9162 14 17.8996 14.2873 17.0221 14.79C17.1186 14.3385 17.5198 14 18 14H18.792C18.3239 12.8793 17.0902 12 15.4964 12C13.6154 12 12.2179 13.2391 12.0216 14.6389C11.9448 15.1859 11.4393 15.567 10.8923 15.4903C10.3454 15.4136 9.96422 14.908 10.0409 14.3611C10.3982 11.8145 12.7879 10 15.4964 10C17.3075 10 18.9853 10.8178 20 12.1221ZM14.4937 17.6127C14.4979 17.5757 14.5 17.5381 14.5 17.5C14.5 16.9477 14.0523 16.5 13.5 16.5H11H10.5C9.67157 16.5 9 17.1716 9 18V21C9 21.5523 9.44772 22 10 22C10.5523 22 11 21.5523 11 21V20.3825C11.8261 21.4418 13.0899 22.1794 14.5068 22.4173C14.2083 21.7398 14.0314 20.9967 14.0038 20.2155C13.1502 19.8685 12.512 19.2364 12.2044 18.5H13.5C13.835 18.5 14.1315 18.3353 14.3129 18.0825C14.3668 17.9228 14.4271 17.7661 14.4937 17.6127Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.5L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <path
            d="M11 17.5C11 19.7091 13.0147 21.5 15.5 21.5C17.7948 21.5 19.6884 19.9732 19.9652 18"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M19.9964 15C19.9964 12.7909 17.9817 11 15.4964 11C13.2016 11 11.3081 12.5268 11.0312 14.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path d="M21 11.5V14.5C21 14.7761 20.7761 15 20.5 15H18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M10 21V18C10 17.7239 10.2239 17.5 10.5 17.5H13.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M3 7.5H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path
            d="M7 19H5.4C4.07452 19 3 17.9767 3 16.7143V5.28571C3 4.02335 4.07452 3 5.4 3H18.6C19.9255 3 21 4.02335 21 5.28571V8.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </>
      )}
    </Icon>
  );
}
