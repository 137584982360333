import { chakra as Chakra, TextProps, forwardRef, useStyleConfig } from '@chakra-ui/react';

export type TypographyVariant =
  | 'heading01'
  | 'heading02'
  | 'heading03'
  | 'heading04'
  | 'heading05'
  | 'heading06'
  | 'heading07'
  | 'heading08'
  | 'heading09'
  | 'body01'
  | 'body02'
  | 'body03'
  | 'buttonText';

export type RippleTypographyProps = Omit<TextProps, 'variant'> & {
  variant: TypographyVariant;
};

export const RippleTypography = forwardRef(({ variant = 'heading01', as, ...otherProps }: RippleTypographyProps, ref) => {
  const styles = useStyleConfig('rippleTypography', { variant });
  return <Chakra.div __css={styles} as={as || getDefaultElement(variant)} variant={variant} ref={ref} {...otherProps} />;
});

export type DefaultTypographyElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

function getDefaultElement(variant: TypographyVariant): DefaultTypographyElement {
  switch (variant) {
    case 'heading01':
      return 'h1';
    case 'heading02':
      return 'h2';
    case 'heading03':
      return 'h3';
    case 'heading04':
      return 'h4';
    case 'heading05':
      return 'h5';
    case 'heading06':
    case 'heading07':
    case 'heading08':
    case 'heading09':
      return 'h6';
    case 'body01':
    case 'body02':
    case 'body03':
      return 'p';
    default:
      return 'span';
  }
}
