import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleInventoryReporting({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="3" y="3.5" width="18" height="17" rx="1" stroke="currentColor" strokeWidth="1.5" />
      <path d="M3 7L21 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <rect x="6" y="9.5" width="3" height="3" rx="0.5" stroke="currentColor" strokeWidth="1.5" />
      <rect x="6" y="15" width="3" height="3" rx="0.5" stroke="currentColor" strokeWidth="1.5" />
      <path d="M12 11L18 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12 16.5H18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </Icon>
  );
}
