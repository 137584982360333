import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleAndroidIcon({ color = '#30D780' }: RippleIconProps) {
  const strokeId = useId();
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" stroke={`url(#${strokeId})`} />
      <path
        d="M15.682 13.7348C15.3147 13.7348 15.0158 13.4357 15.0158 13.0684C15.0158 12.701 15.3147 12.4022 15.682 12.4022C16.0494 12.4022 16.3483 12.701 16.3483 13.0684C16.3483 13.4358 16.0494 13.7348 15.682 13.7348ZM8.31798 13.7348C7.95061 13.7348 7.65178 13.4357 7.65178 13.0684C7.65178 12.701 7.95055 12.4022 8.31798 12.4022C8.68534 12.4022 8.98418 12.701 8.98418 13.0684C8.98418 13.4358 8.68541 13.7348 8.31798 13.7348ZM15.921 9.72153L17.2525 7.41538C17.2892 7.35174 17.2991 7.27615 17.2801 7.20521C17.2611 7.13427 17.2147 7.07377 17.1511 7.03698C17.0875 7.00034 17.0119 6.99044 16.941 7.00945C16.87 7.02846 16.8095 7.07483 16.7727 7.13838L15.4245 9.47367C14.3935 9.0032 13.2356 8.74107 12 8.74107C10.7644 8.74107 9.60654 9.0032 8.57553 9.47367L7.22728 7.13838C7.19048 7.07483 7.12998 7.02846 7.05904 7.00945C6.9881 6.99044 6.91252 7.00034 6.84888 7.03698C6.78527 7.07373 6.73887 7.13424 6.71985 7.20519C6.70084 7.27615 6.71077 7.35175 6.74748 7.41538L8.07902 9.72153C5.79257 10.9651 4.22877 13.2798 4 16.0146H20C19.771 13.2799 18.2072 10.9651 15.921 9.72147"
        fill="currentColor"
      />
      <defs>
        <radialGradient
          id={strokeId}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 12) rotate(90) scale(12)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
}
