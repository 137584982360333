import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleGranularControlSoftwarePatchIcon({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5H20V7H4V5ZM4 9H20V14C20.7013 14 21.3744 14.1203 22 14.3414V8V5C22 3.89543 21.1046 3 20 3H4C2.89543 3 2 3.89543 2 5V8V19C2 20.1046 2.89543 21 4 21H14.083C14.0284 20.6748 14 20.3407 14 20C14 19.6593 14.0284 19.3252 14.083 19H4V9ZM18.9965 14.0835C17.4095 14.3508 16.034 15.241 15.1303 16.4941C14.9132 16.1125 14.9674 15.6184 15.2929 15.2929L16.5858 14L15.2929 12.7071C14.9024 12.3166 14.9024 11.6834 15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929L18.7071 13.2929C18.9236 13.5094 19.0201 13.8005 18.9965 14.0835ZM8.70711 11.2929C8.31658 10.9024 7.68342 10.9024 7.29289 11.2929L5.29289 13.2929C4.90237 13.6834 4.90237 14.3166 5.29289 14.7071L7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071C9.09763 16.3166 9.09763 15.6834 8.70711 15.2929L7.41421 14L8.70711 12.7071C9.09763 12.3166 9.09763 11.6834 8.70711 11.2929ZM13.8944 12.4472C14.1414 11.9532 13.9412 11.3526 13.4472 11.1056C12.9532 10.8586 12.3526 11.0588 12.1056 11.5528L10.1056 15.5528C9.85858 16.0468 10.0588 16.6474 10.5528 16.8944C11.0468 17.1414 11.6474 16.9412 11.8944 16.4472L13.8944 12.4472Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.5L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 5H4V7H20V5ZM20 9H4V19H20V9ZM2 8V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8ZM15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929L18.7071 13.2929C19.0976 13.6834 19.0976 14.3166 18.7071 14.7071L16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929L16.5858 14L15.2929 12.7071C14.9024 12.3166 14.9024 11.6834 15.2929 11.2929ZM7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929C9.09763 11.6834 9.09763 12.3166 8.70711 12.7071L7.41421 14L8.70711 15.2929C9.09763 15.6834 9.09763 16.3166 8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071L5.29289 14.7071C4.90237 14.3166 4.90237 13.6834 5.29289 13.2929L7.29289 11.2929ZM13.4472 11.1056C13.9412 11.3526 14.1414 11.9532 13.8944 12.4472L11.8944 16.4472C11.6474 16.9412 11.0468 17.1414 10.5528 16.8944C10.0588 16.6474 9.85858 16.0468 10.1056 15.5528L12.1056 11.5528C12.3526 11.0588 12.9532 10.8586 13.4472 11.1056Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
}
