import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import {
  rippleAccordion,
  rippleBadge,
  rippleButton,
  rippleCard,
  rippleCheckbox,
  rippleFloatButton,
  rippleInlineButton,
  rippleInlineSelectButton,
  rippleInput,
  rippleMenu,
  rippleModal,
  rippleRadio,
  rippleSelect,
  rippleSkeleton,
  rippleStepper,
  rippleSubscribeBanner,
  rippleSwitch,
  rippleTable,
  rippleTabs,
  rippleTd,
  rippleTh,
  rippleTypography,
} from './components';
import shadows from './shadows';
import styles from './styles';
import { textStyles } from './text';

export const breakpoints = {
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1920px',
  '2xl': '1920px', // NOTE: DO NOT REMOVE `2xl`, ref: https://chakra-ui.com/docs/styled-system/responsive-styles#customizing-breakpoints
};

const customTheme = {
  styles,
  colors,
  breakpoints,
  textStyles,
  shadows: shadows,
  components: {
    Accordion: rippleAccordion,
    Badge: rippleBadge,
    Button: rippleButton,
    Card: rippleCard,
    Checkbox: rippleCheckbox,
    Input: rippleInput,
    Menu: rippleMenu,
    Modal: rippleModal,
    Radio: rippleRadio,
    Skeleton: rippleSkeleton,
    Stepper: rippleStepper,
    Switch: rippleSwitch,
    Tabs: rippleTabs,
    rippleFloatButton,
    rippleInlineButton,
    rippleSelect,
    rippleTable,
    rippleTh,
    rippleTd,
    rippleTypography,
    rippleSubscribeBanner,
    rippleInlineSelectButton,
  },
};

export default extendTheme(customTheme);
