import {
  OTableGroup,
  OTableResult,
  OTableRow,
  OTableRowOrGroupList,
  OTableSourceData,
  OTableSourceGroup,
} from '@/components/ObservableTable/types';
import { OTableRowTypes, OTableViewStates } from '@/components/ObservableTable/useObservableTable';

export type OTableGroupHashMap<G extends OTableSourceGroup> = Record<
  string,
  {
    group: G;
    rowIds: Array<string>;
  }
>;

export type FormatSourceGroupProps<G extends OTableSourceGroup> = {
  groupSort?: (aGroup: G, bGroup: G) => number;
};

export function formatSourceGroup<D extends OTableSourceData, G extends OTableSourceGroup>(
  tableResult: OTableResult<D, G>,
  props: FormatSourceGroupProps<G> = {},
): OTableResult<D, G> {
  const { groups, rows, rowHashMap, states } = tableResult;

  if (!groups) {
    return tableResult;
  }

  const groupHashMap = groups.reduce<OTableGroupHashMap<G>>((acc, group) => {
    acc[group.id] = {
      group,
      rowIds: [],
    };

    return acc;
  }, {});

  rows.forEach((row) => {
    if (row.type === OTableRowTypes.ROW && row.original.gid) {
      groupHashMap[row.original.gid].rowIds.push(row.original.id);
    }
  });

  if (states.viewState !== OTableViewStates.GROUP) {
    return {
      ...tableResult,
      groupHashMap,
    };
  }

  const { groupSort } = props;
  const sortedGroups = groupSort ? [...groups].sort(groupSort) : groups;

  const nextRows = sortedGroups.reduce(
    (acc, group) => {
      const groupRow: OTableGroup<G> = {
        type: OTableRowTypes.GROUP,
        original: group,
        isFiltered: false,
        filterMatches: {
          isFiltered: null,
          isSearched: null,
          isSelected: null,
        },
      };
      acc.push(groupRow);

      const targetGroupHashMap = groupHashMap[group.id];
      targetGroupHashMap.rowIds.forEach((rowId) => {
        const rowData = rowHashMap[rowId];

        const row: OTableRow<D> = {
          type: OTableRowTypes.ROW,
          original: rowData,
          isFiltered: false,
          filterMatches: {
            isFiltered: null,
            isSearched: null,
            isSelected: null,
          },
        };
        acc.push(row);
      });

      return acc;
    },
    [] as OTableRowOrGroupList<D, G>,
  );

  return {
    ...tableResult,
    groups: sortedGroups,
    groupHashMap,
    rows: nextRows,
  };
}
