import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleAndroidIcon32({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" fill="none" color={color}>
      <rect width="32" height="32" rx="4" fill="currentColor" fillOpacity="0.16" />
      <path
        d="M20.909 18.3133C20.4192 18.3133 20.0208 17.9145 20.0208 17.4247C20.0208 16.9349 20.4192 16.5364 20.909 16.5364C21.3989 16.5364 21.7974 16.9348 21.7974 17.4247C21.7974 17.9146 21.3989 18.3133 20.909 18.3133ZM11.0903 18.3133C10.6005 18.3133 10.202 17.9145 10.202 17.4247C10.202 16.9349 10.6004 16.5364 11.0903 16.5364C11.5801 16.5364 11.9786 16.9348 11.9786 17.4247C11.9786 17.9146 11.5802 18.3133 11.0903 18.3133ZM21.2276 12.9622L23.003 9.88734C23.0519 9.80248 23.0651 9.7017 23.0398 9.60711C23.0144 9.51253 22.9526 9.43186 22.8678 9.3828C22.783 9.33395 22.6822 9.32076 22.5876 9.3461C22.493 9.37144 22.4124 9.43326 22.3633 9.518L20.5657 12.6317C19.191 12.0044 17.6471 11.6549 15.9997 11.6549C14.3522 11.6549 12.8084 12.0044 11.4337 12.6317L9.63605 9.518C9.58698 9.43326 9.50631 9.37144 9.41173 9.3461C9.31715 9.32076 9.21637 9.33395 9.13151 9.3828C9.04671 9.43181 8.98483 9.51248 8.95948 9.60709C8.93413 9.70169 8.94737 9.8025 8.99631 9.88734L10.7717 12.9622C7.7231 14.6203 5.63804 17.7066 5.33301 21.3529H26.6663C26.3611 17.7067 24.276 14.6203 21.2276 12.9621"
        fill="currentColor"
      />
    </Icon>
  );
}
