import { Icon } from '@chakra-ui/react';

export function RippleAndroidOnline32() {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" fill="none">
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DFE4E9" />
      <path
        d="M20.6025 17.9187C20.1433 17.9187 19.7698 17.5448 19.7698 17.0856C19.7698 16.6264 20.1433 16.2529 20.6025 16.2529C21.0618 16.2529 21.4354 16.6263 21.4354 17.0856C21.4354 17.5449 21.0618 17.9187 20.6025 17.9187ZM11.3975 17.9187C10.9383 17.9187 10.5647 17.5448 10.5647 17.0856C10.5647 16.6264 10.9382 16.2529 11.3975 16.2529C11.8567 16.2529 12.2302 16.6263 12.2302 17.0856C12.2302 17.5449 11.8568 17.9187 11.3975 17.9187ZM20.9012 12.902L22.5657 10.0194C22.6115 9.9398 22.6239 9.84531 22.6001 9.75664C22.5763 9.66796 22.5184 9.59233 22.4389 9.54635C22.3593 9.50055 22.2649 9.48818 22.1762 9.51194C22.0875 9.5357 22.0119 9.59365 21.9659 9.6731L20.2807 12.5922C18.9918 12.0041 17.5445 11.6765 16 11.6765C14.4555 11.6765 13.0082 12.0041 11.7194 12.5922L10.0341 9.6731C9.9881 9.59365 9.91247 9.5357 9.8238 9.51194C9.73513 9.48818 9.64066 9.50055 9.5611 9.54635C9.48159 9.59229 9.42358 9.66792 9.39982 9.75661C9.37605 9.84531 9.38847 9.93981 9.43435 10.0194L11.0988 12.902C8.24071 14.4565 6.28597 17.3499 6 20.7683H26C25.7138 17.35 23.7591 14.4565 20.9012 12.902"
        fill="#30D780"
      />
    </Icon>
  );
}
