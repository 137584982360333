import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleSshIcon({ color = '#000000' }: RippleIconProps) {
  const clipId = useId();
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <g clipPath={`url(#${clipId})`}>
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DFE4E9" />
        <path
          d="M19 4.5H5C4.17157 4.5 3.5 5.17157 3.5 6V18C3.5 18.8284 4.17157 19.5 5 19.5H19C19.8284 19.5 20.5 18.8284 20.5 18V6C20.5 5.17157 19.8284 4.5 19 4.5Z"
          fill="currentColor"
          stroke="white"
        />
        <path d="M13 15H17" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M7 15L11 11.9091L7.12903 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
