import { useEffect, useRef } from 'react';

import { Box, Flex, Stack, SystemStyleObject } from '@chakra-ui/react';
import { format, getHours, getMinutes } from 'date-fns';

import { RippleDivider } from '../RippleDivider';
import { RippleArrowLeft16, RippleArrowRight16, RippleCalendar } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleInlineButton } from '../RippleInlineButton';
import { RippleInlineDropdown } from '../RippleInlineDropdown';
import { RipplePopover, RipplePopoverBody, RipplePopoverContent, RipplePopoverTrigger } from '../RipplePopover';
import { RippleTextField, RippleTextFieldProps } from '../RippleTextField';
import { RippleTypography } from '../RippleTypography';
import { DatePickerButton, Days, MonthSelector, WeekNames } from './components';
import { useDatePicker } from './core';
import { getCalendarName, hideScrollbarStyle, zeroPadding } from './utils';

const TimeButtonHeight = 32;

export type RippleDatePickerProps = {
  value: Date | null;
  onChange: (date: Date) => void;
  dateFormat?: string;
  inputOptions?: RippleTextFieldProps;
  minDate?: Date;
  maxDate?: Date;
  sx?: SystemStyleObject;
  showTimePicker?: boolean;
  isDisabled?: boolean;
};

export const RippleDatePicker = (props: RippleDatePickerProps) => {
  const { value, onChange, inputOptions = {}, sx, showTimePicker, dateFormat, isDisabled, minDate, maxDate } = props;

  const hourButtonBoxRef = useRef<HTMLDivElement | null>(null);
  const minuteButtonBoxRef = useRef<HTMLDivElement | null>(null);

  const {
    currentDate,
    prevYear,
    nextYear,
    getPrevYearDisabled,
    getNextYearDisabled,
    setMonth,
    prevMonth,
    nextMonth,
    getPrevMonthDisabled,
    getNextMonthDisabled,
    getMonthDisabled,
    setMonthAndDay,
    getDateDisabled,
    setToday,
    setHours,
    setMinutes,
    getDayButtonProps,
    getHourButtonProps,
    getMinuteButtonProps,
  } = useDatePicker({
    value,
    onChange,
    minDate,
    maxDate,
  });

  const currentHour = getHours(currentDate);
  const currentMinutes = getMinutes(currentDate);
  useEffect(() => {
    if (hourButtonBoxRef.current) {
      hourButtonBoxRef.current.scrollTo({ top: (currentHour - 3) * TimeButtonHeight, behavior: 'smooth' });
    }
    if (minuteButtonBoxRef.current) {
      minuteButtonBoxRef.current.scrollTo({ top: (currentMinutes - 3) * TimeButtonHeight, behavior: 'smooth' });
    }
  }, [currentHour, currentMinutes]);

  const defaultDateFormat = showTimePicker ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd';
  const prevYearButtonDisabled = getPrevYearDisabled();
  const nextYearButtonDisabled = getNextYearDisabled();
  const prevMonthButtonDisabled = getPrevMonthDisabled();
  const nextMonthButtonDisabled = getNextMonthDisabled();

  return (
    <Box sx={sx}>
      <RipplePopover placement="bottom-start" isOpen={isDisabled ? false : undefined}>
        <RipplePopoverTrigger>
          <RippleTextField
            leadingIcon={<RippleCalendar />}
            size="sm"
            value={value ? format(value, dateFormat ?? defaultDateFormat) : ''}
            isDisabled={isDisabled}
            {...inputOptions}
          />
        </RipplePopoverTrigger>
        <RipplePopoverContent
          boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
          borderRadius="4px"
          width={showTimePicker ? '341px' : '216px'}
        >
          <Box p="12px" display="flex">
            <Stack gap="8px" alignItems="flex-start">
              <Flex justifyContent="space-between">
                <RipplePopover placement="bottom">
                  {({ isOpen, onClose }) => (
                    <>
                      <RipplePopoverTrigger>
                        <RippleInlineDropdown isOpen={isOpen} variant="secondary" size="sm" height="24px">
                          {getCalendarName(currentDate)}
                        </RippleInlineDropdown>
                      </RipplePopoverTrigger>
                      <RipplePopoverContent width="194px">
                        <RipplePopoverBody p="8px">
                          <MonthSelector
                            currentDate={currentDate}
                            isPrevYearDisabled={prevYearButtonDisabled}
                            isNextYearDisabled={nextYearButtonDisabled}
                            getMonthDisabled={getMonthDisabled}
                            onChange={setMonth}
                            onClose={onClose}
                            onPrevYear={prevYear}
                            onNextYear={nextYear}
                          />
                        </RipplePopoverBody>
                      </RipplePopoverContent>
                    </>
                  )}
                </RipplePopover>

                <Box flexShrink={0}>
                  <RippleIconButton
                    aria-label="previous month"
                    icon={<RippleArrowLeft16 color={prevMonthButtonDisabled ? 'dark.20' : 'neutral.300'} />}
                    isDisabled={prevMonthButtonDisabled}
                    onClick={prevMonth}
                    width="24px"
                    height="24px"
                  />

                  <RippleIconButton
                    aria-label="next month"
                    icon={<RippleArrowRight16 color={nextMonthButtonDisabled ? 'dark.20' : 'neutral.300'} />}
                    isDisabled={nextMonthButtonDisabled}
                    onClick={nextMonth}
                    width="24px"
                    height="24px"
                  />
                </Box>
              </Flex>

              <WeekNames />

              <Days dayButtonsProps={getDayButtonProps()} getDateDisabled={getDateDisabled} onChange={setMonthAndDay} />

              <RippleInlineButton variant="tertiary" color="blue.100" onClick={setToday}>
                Today
              </RippleInlineButton>
            </Stack>
            {showTimePicker && <RippleDivider orientation="vertical" mx="12px" height="264px" />}
            {showTimePicker && (
              <Box>
                <RippleTypography variant="heading09" mt="4px" mb="8px">
                  Time
                </RippleTypography>
                <Flex height="234px" gap="4px">
                  <Box ref={hourButtonBoxRef} overflowY="auto" sx={hideScrollbarStyle}>
                    {getHourButtonProps().map(({ hourIndex, isSelected, isDisabled }) => {
                      return (
                        <DatePickerButton
                          key={hourIndex}
                          isActive={isSelected}
                          isDisabled={isDisabled}
                          onClick={() => {
                            setHours(hourIndex);
                          }}
                          sx={{
                            width: '48px',
                            height: `${TimeButtonHeight}px`,
                          }}
                        >
                          {zeroPadding(hourIndex)}
                        </DatePickerButton>
                      );
                    })}
                    {/* Add a placeholder to enable the last-time button to scroll to the middle of the box. */}
                    <Placeholder />
                  </Box>
                  <Box ref={minuteButtonBoxRef} overflowY="auto" sx={hideScrollbarStyle}>
                    {getMinuteButtonProps().map(({ minuteIndex, isSelected, isDisabled }) => {
                      return (
                        <DatePickerButton
                          key={minuteIndex}
                          isActive={isSelected}
                          isDisabled={isDisabled}
                          onClick={() => {
                            setMinutes(minuteIndex);
                          }}
                          sx={{
                            width: '48px',
                            height: `${TimeButtonHeight}px`,
                          }}
                        >
                          {zeroPadding(minuteIndex)}
                        </DatePickerButton>
                      );
                    })}
                    {/* Add a placeholder to enable the last-time button to scroll to the middle of the box. */}
                    <Placeholder />
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        </RipplePopoverContent>
      </RipplePopover>
    </Box>
  );
};

const Placeholder = () => <Box h="106px" />;
