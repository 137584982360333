import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCaretExpanded({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" color={color}>
      <path
        d="M12.866 16.5C12.4811 17.1667 11.5189 17.1667 11.134 16.5L7.66987 10.5C7.28497 9.83333 7.7661 9 8.5359 9L15.4641 9C16.2339 9 16.715 9.83333 16.3301 10.5L12.866 16.5Z"
        fill="#606060"
      />
    </Icon>
  );
}
