import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleMoreIcon16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2 9.20005C3.86274 9.20005 4.4 8.66279 4.4 8.00005C4.4 7.33731 3.86274 6.80005 3.2 6.80005C2.53726 6.80005 2 7.33731 2 8.00005C2 8.66279 2.53726 9.20005 3.2 9.20005Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00005 9.20005C8.66279 9.20005 9.20005 8.66279 9.20005 8.00005C9.20005 7.33731 8.66279 6.80005 8.00005 6.80005C7.33731 6.80005 6.80005 7.33731 6.80005 8.00005C6.80005 8.66279 7.33731 9.20005 8.00005 9.20005Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8 9.20005C13.4627 9.20005 14 8.66279 14 8.00005C14 7.33731 13.4627 6.80005 12.8 6.80005C12.1372 6.80005 11.6 7.33731 11.6 8.00005C11.6 8.66279 12.1372 9.20005 12.8 9.20005Z"
        fill="currentColor"
      />
    </Icon>
  );
}
