import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleRedirectIcon({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M9 15L19 5" stroke="#036DEA" strokeWidth="2" strokeLinecap="square" />
      <path d="M20 8L20 4L16 4" stroke="#036DEA" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path
        d="M18 13V19C18 19.5523 17.5523 20 17 20H5C4.44772 20 4 19.5523 4 19V7C4 6.44772 4.44772 6 5 6H10"
        stroke="#036DEA"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Icon>
  );
}
