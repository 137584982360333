import React from 'react';

import { Box, Breadcrumb, BreadcrumbItem, forwardRef } from '@chakra-ui/react';
import type { BreadcrumbProps } from '@chakra-ui/react';

export type RippleBreadcrumbProps = {
  items: Array<React.ReactNode>;
  separator?: string | React.ReactElement;
} & BreadcrumbProps;

export const RippleBreadcrumbContainer = forwardRef<BreadcrumbProps, 'nav'>((props, ref) => (
  <Breadcrumb fontSize="12px" fontWeight="600" lineHeight="18px" color="neutral.300" ref={ref} {...props} />
));

export const RippleBreadcrumbItem = BreadcrumbItem;

export const RippleBreadcrumb = React.memo(({ items, ...otherProps }: RippleBreadcrumbProps) => {
  return (
    <RippleBreadcrumbContainer data-testid="ripple-breadcrumb" pt="7px" pb="5px" {...otherProps}>
      {items.map((item, index) => {
        return (
          <RippleBreadcrumbItem key={index} data-testid="ripple-breadcrumb-item">
            <Box wordBreak="break-all">{item}</Box>
          </RippleBreadcrumbItem>
        );
      })}
    </RippleBreadcrumbContainer>
  );
});
