import { ReactNode } from 'react';

import { MonthIndexes } from '../core';
import { DatePickerButton } from './DatePickerButton';

type DatePickerMonthButtonProps = {
  children: ReactNode;
  month: MonthIndexes;
  currentMonth: MonthIndexes;
  changeMonth: (month: MonthIndexes) => void;
  onClose: () => void;
  isDisabled: boolean;
};

export const DatePickerMonthButton = ({ children, month, currentMonth, changeMonth, onClose, isDisabled }: DatePickerMonthButtonProps) => {
  const isCurrent = month === currentMonth;
  const onClick = () => {
    changeMonth(month);
    onClose();
  };

  return (
    <DatePickerButton
      sx={{
        width: '56px',
        height: '32px',
      }}
      isActive={isCurrent}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </DatePickerButton>
  );
};
