import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleInheritFrom({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70711 9.70711C6.31658 10.0976 5.68342 10.0976 5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289L9.29289 4.29289C9.30081 4.28498 9.30884 4.27722 9.31698 4.2696C9.49573 4.10237 9.73591 4 10 4C10.2771 4 10.5279 4.11269 10.709 4.29475L14.7071 8.29289C15.0976 8.68342 15.0976 9.31658 14.7071 9.70711C14.3166 10.0976 13.6834 10.0976 13.2929 9.70711L11 7.41422L11 13C11 14.6569 12.3432 16 14 16L20 16C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18L14 18C11.2386 18 9.00001 15.7614 9.00001 13L9.00001 7.41421L6.70711 9.70711Z"
        fill="currentColor"
      />
    </Icon>
  );
}
