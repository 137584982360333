import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleLog({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H17V10H19V4C19 2.89543 18.1046 2 17 2H4C2.89543 2 2 2.89543 2 4V18C2 19.1046 2.89543 20 4 20H10V18H4V4Z"
        fill="currentColor"
      />
      <rect id="note 1" x="6" y="6" width="9" height="2" rx="1" fill="currentColor" />
      <rect id="note 2" x="6" y="10" width="8" height="2" rx="1" fill="currentColor" />
      <rect id="note 3" x="6" y="14" width="5" height="2" rx="1" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22ZM18 14.5C18 13.9477 17.5523 13.5 17 13.5C16.4477 13.5 16 13.9477 16 14.5V17C16 17.3788 16.214 17.725 16.5528 17.8944L18.5528 18.8944C19.0468 19.1414 19.6474 18.9412 19.8944 18.4472C20.1414 17.9532 19.9412 17.3526 19.4472 17.1056L18 16.382V14.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
