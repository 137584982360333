import { Button, type ButtonProps, useToken } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { mix } from 'polished';

import { RippleButtonText, RippleDownloadAvailable } from '@/design';

import { DownloadModal, useDownloadModal } from './DownloadModal';

type RippleDownloadBusinessAppProps = {
  emphasis: 'primary' | 'secondary';
  isInIframe?: boolean;
};
export function RippleDownloadBusinessApp({ emphasis, isInIframe = false }: RippleDownloadBusinessAppProps): React.JSX.Element {
  const { t } = useTranslation();

  const downloadModal = useDownloadModal();

  const [legacyPurple200, legacyPurple300] = useToken('colors', ['legacyPurple.200', 'legacyPurple.300']);

  const emphasisStyleProps = getEmphasisStyleProps(emphasis);
  function getEmphasisStyleProps(emphasis: RippleDownloadBusinessAppProps['emphasis']): ButtonProps {
    switch (emphasis) {
      case 'primary': {
        return {
          bgColor: 'legacyPurple.100',
          _hover: {
            bgColor: 'legacyPurple.200',
          },
          _active: {
            bgColor: 'legacyPurple.300',
          },
        };
      }
      case 'secondary': {
        return {
          bgColor: 'transparent',
          color: 'legacyPurple.100',
          borderColor: 'currentColor',
          _hover: {
            color: 'legacyPurple.200',
            bgColor: mix('0.08', legacyPurple200, 'white'),
          },
          _active: {
            color: 'legacyPurple.300',
            bgColor: mix('0.16', legacyPurple300, 'white'),
          },
        };
      }
    }
  }

  return (
    <>
      <Button
        data-testid="ripple-download-business-app"
        leftIcon={<RippleDownloadAvailable color="inherit" />}
        iconSpacing="0"
        {...emphasisStyleProps}
        pl="9px"
        pr="11px"
        py="4px"
        size="xs"
        borderRadius="4px"
        onClick={downloadModal.open}
      >
        <RippleButtonText as="span" color="inherit" px="4px" py="2px">
          {t('common:businessApp')}
        </RippleButtonText>
      </Button>

      <DownloadModal isInIframe={isInIframe} />
    </>
  );
}
