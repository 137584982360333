import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleGranularControlRemoteControl({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M21 8.83333V4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V16C3 16.5523 3.44772 17 4 17H12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M12 17L12 21" stroke="currentColor" strokeWidth="2" />
      <path d="M7 21H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M21.2841 18.8672L21.7699 18.4786C22.0382 18.264 22.0776 17.8652 21.8579 17.5878L18.7069 13.6087L21.048 12.8925C21.3651 12.7955 21.3993 12.3567 21.1025 12.1938L13.5499 8.04836C13.2568 7.88748 12.9249 8.15304 13.0151 8.47621L15.3397 16.8036C15.4311 17.1308 15.8642 17.1958 16.0294 16.907L17.2495 14.7743L20.4006 18.7536C20.6203 19.0309 21.0158 19.0818 21.2841 18.8672Z"
        fill="currentColor"
      />
    </Icon>
  );
}
