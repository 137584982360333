import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';

type IFrameEvents =
  | {
      type: 'reminder-show';
      width: number;
      height: number;
    }
  | {
      type: 'reminder-push';
      url: string;
    }
  | {
      type: 'reminder-close';
      width: number;
      height: number;
    };

export const TryBeforeCommit = () => {
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  useEffect(() => {
    const handler = (message: MessageEvent<IFrameEvents>) => {
      const isSameDomain = message.origin === window.location.origin;
      if (!isSameDomain) {
        return;
      }

      switch (message.data.type) {
        case 'reminder-push': {
          window.location.href = message.data.url;
          break;
        }
        case 'reminder-show': {
          if (message.data.width !== 0 && message.data.height !== 0) {
            setSize({
              width: message.data.width,
              height: message.data.height,
            });
          }
          break;
        }
        case 'reminder-close':
          {
            setSize({
              width: message.data.width,
              height: message.data.height,
            });
          }

          break;
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  return (
    <Box
      as="iframe"
      zIndex={2000}
      src="/try_before_commit"
      position="fixed"
      top="8px"
      right="8px"
      width={`${size.width}px`}
      height={`${size.height}px`}
    />
  );
};
