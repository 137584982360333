import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleInherited({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2929 16.2929C13.9024 16.6834 13.9024 17.3166 14.2929 17.7071C14.6834 18.0977 15.3166 18.0977 15.7071 17.7071L19.7071 13.7071C19.715 13.6992 19.7228 13.6912 19.7304 13.683C19.8976 13.5043 20 13.2641 20 13C20 12.7229 19.8873 12.4722 19.7052 12.2911L15.7071 8.29292C15.3166 7.9024 14.6834 7.9024 14.2929 8.29292C13.9024 8.68345 13.9024 9.31661 14.2929 9.70714L16.5858 12L11 12C9.34315 12 8 10.6569 8 9.00002L8 3.00003C8 2.44774 7.55229 2.00003 7 2.00003C6.44772 2.00003 6 2.44774 6 3.00003V9.00002C6 11.7614 8.23858 14 11 14L16.5858 14L14.2929 16.2929Z"
        fill="currentColor"
      />
    </Icon>
  );
}
