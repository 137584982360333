import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { computerDataListSchema } from '../schemas';
import { ComputerListCustomizedField, ComputerListMode, FilterComputersOptions, GetComputerListByIdBody } from '../types';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/computers/query_by_numerous_ids`;

const schema = computerDataListSchema;

const service = (params: {
  teamId: number;
  computerIds: Array<number>;
  mode: ComputerListMode;
  customizedFields: Array<ComputerListCustomizedField>;
  options?: FilterComputersOptions;
}) => {
  const { teamId, computerIds, mode, customizedFields, options } = params;

  const body: GetComputerListByIdBody = {
    ids: computerIds,
    mode,
    customized_fields: customizedFields,
  };

  if (options?.feature) {
    body.features = [options.feature];
  }

  if (options?.actionKind) {
    body.action_kind = options.actionKind;
  }
  if (options?.onlineStatus) {
    body.online_status = options.onlineStatus;
  }
  if (options?.actionType) {
    body.action_type = options.actionType;
  }
  if (options?.capability) {
    body.capability = options.capability;
  }

  return checkResponse(axios.post(getUrl(teamId), body), schema);
};

export const getTeamComputerListById = {
  getUrl,
  service,
};
