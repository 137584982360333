import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse } from '@/services/utils';

const method = 'PUT';

const getUrl = (computerId: number) => `/api/web/v1/computers/${computerId}/wake`;

const schema = z.object({});

const execute = async ({ computerId, sendComputerIds }: { computerId: number; sendComputerIds?: Array<number> }) => {
  const payload = {
    wake_up_by_server_ids: sendComputerIds,
  };

  return checkResponse(
    axios.request({
      method,
      url: getUrl(computerId),
      data: payload,
    }),
    schema,
  );
};

const errorHandlings = {
  /**
   * No suitable server to wake up target_server
   */
  deviceNotFound: (responseData: unknown): boolean => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(42404), data: z.object({ error: z.literal('device_not_found') }) }),
    );

    return errorSchema.safeParse(responseData).success;
  },
  /**
   * This computer must in team, so it can be wake up by other computer.
   */
  targetNotAllow: (responseData: unknown): boolean => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40403), data: z.object({ error: z.literal('target_not_allow') }) }),
    );

    return errorSchema.safeParse(responseData).success;
  },
  /**
   *  Invalid params `wake_up_by_server_ids` (must be in the same team, can't be the same with target_computer_id)
   */
  wrongParams: (responseData: unknown): boolean => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40422), data: z.object({ error: z.literal('wrong_params') }) }),
    );

    return errorSchema.safeParse(responseData).success;
  },
};

export const wakeComputerService = {
  method,
  getUrl,
  execute,
  errorHandlings,
};
