import { Input, InputProps, forwardRef } from '@chakra-ui/react';

export type RippleInputProps = Omit<InputProps, 'size'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

export const RippleInput = forwardRef<RippleInputProps, 'input'>(({ size = 'md', ...otherProps }, ref) => {
  return <Input size={size} ref={ref} {...otherProps} />;
});

RippleInput.id = 'Input';
