import { Box, SystemStyleObject } from '@chakra-ui/react';

const variantStyles = {
  blue: {
    bg: 'blue.10',
    color: 'blue.300',
  },
  darkBlue: {
    bg: 'blue.300',
    color: 'white',
  },
  red: {
    bg: 'red.100',
    color: 'white',
  },
  yellow: {
    bg: 'yellow.100',
    color: 'dark.100',
  },
  deepBlue: {
    bg: 'blue.200',
    color: 'white',
  },
  neutralDark: {
    bg: 'neutral.300',
    color: 'white',
  },
  neutralLight: {
    bg: 'neutral.60',
    color: 'neutral.400',
  },
  purple: {
    bg: 'purple.80',
    color: 'white',
  },
} as const;

type Variant = keyof typeof variantStyles;

const sizeStyle = {
  sm: {
    h: '16px',
    p: '2px 4px',
    minWidth: '16px',
    fontSize: '10px',
    fontWeight: '600',
  },
  md: {
    h: '20px',
    p: '1.5px 4px 0.5px 4px',
    minWidth: '20px',
    fontSize: '12px',
    fontWeight: '600',
  },
};

type Size = keyof typeof sizeStyle;

export const RippleCount = ({
  size = 'md',
  variant,
  count,
  sx,
}: {
  size?: Size;
  variant: Variant;
  count: number;
  sx?: SystemStyleObject;
}) => {
  const sizeStyleConfig = sizeStyle[size];
  const style = variantStyles[variant];

  return (
    <Box
      {...sizeStyleConfig}
      {...style}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      fontWeight="600"
      sx={sx}
    >
      {count}
    </Box>
  );
};
