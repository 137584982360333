import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleRemoteCommand({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 5H4V19H14.083C14.0284 19.3252 14 19.6593 14 20C14 20.3407 14.0284 20.6748 14.083 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V14.3414C21.3744 14.1203 20.7013 14 20 14V5ZM16.6822 15H12V17H14.8027C15.2671 16.1972 15.9121 15.512 16.6822 15ZM6 15V13L10 11L6 9L6 7L12 10V12L6 15Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.4999L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <rect x="3" y="4" width="18" height="16" rx="1" stroke="currentColor" strokeWidth="2" />
          <path d="M6 15L6 13L12 10L12 12L6 15Z" fill="currentColor" />
          <path d="M6 7L6 9L12 12L12 10L6 7Z" fill="currentColor" />
          <rect x="12" y="15" width="6" height="2" fill="currentColor" />
        </>
      )}
    </Icon>
  );
}
