import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export const RippleSwitchMonitorShowAll = ({ color = DEFAULT_ICON_COLOR }: RippleIconProps) => {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V16C21 16.5523 20.5523 17 20 17H13V19H16C16.5523 19 17 19.4477 17 20C17 20.5523 16.5523 21 16 21H8C7.44771 21 7 20.5523 7 20C7 19.4477 7.44771 19 8 19H11V17H4C3.44772 17 3 16.5523 3 16V4ZM5 15V5H19V15H5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 6H6V9.5H11.5V6ZM12.5 6V9.5H18V6H12.5ZM18 10.5H12.5V14H18V10.5ZM11.5 14V10.5H6V14H11.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};
