import { OTableResult, OTableSourceData, OTableSourceGroup } from '@/components/ObservableTable/types';
import { OTableSortParams } from '@/components/ObservableTable/useObservableSort';
import { OTableRowTypes, OTableViewStates } from '@/components/ObservableTable/useObservableTable';

export const makeSortPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>) =>
  (sortParams: OTableSortParams): OTableResult<D, G> => {
    const { rows } = tableResult;
    const { sortBy } = sortParams;

    let nextRows = rows;
    if (sortBy.length > 0) {
      nextRows = [...rows].sort((rowA, rowB) => {
        if (
          tableResult.states.viewState === OTableViewStates.GROUP &&
          (rowA.type === OTableRowTypes.GROUP || rowB.type === OTableRowTypes.GROUP || rowA.original.gid !== rowB.original.gid)
        ) {
          return 0;
        }

        let result = 0;
        sortBy.some((sort) => {
          const { accessor, desc } = sort;

          // FIXME: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'D | G'.
          // FIXME: No index signature with a parameter of type 'string' was found on type 'D | G'.
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          let targetA = rowA.original[accessor];
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          let targetB = rowB.original[accessor];

          if (targetA === null || targetA === undefined) {
            result = desc ? 1 : -1;
            return true;
          } else if (targetB === null || targetB === undefined) {
            result = desc ? -1 : 1;
            return true;
          } else if (typeof targetA === 'string' && typeof targetB === 'string') {
            targetA = `${targetA}`.toLocaleUpperCase();
            targetB = `${targetB}`.toLocaleUpperCase();
          }

          if (targetA > targetB) {
            result = desc ? -1 : 1;
            return true;
          }
          if (targetB > targetA) {
            result = desc ? 1 : -1;
            return true;
          }

          return false;
        });

        return result;
      });
    }

    return {
      ...tableResult,
      rows: nextRows,
    };
  };
