import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleTransfer({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M15 9L18 5.99841L15 3" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
      <path d="M9 15L6 18.0016L9 21" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
      <path d="M17 6H5C3.89543 6 3 6.89543 3 8V14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M7 18L19 18C20.1046 18 21 17.1046 21 16L21 9.99999" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </Icon>
  );
}
