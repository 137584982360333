import { List, ListProps, SystemStyleObject } from '@chakra-ui/react';

import { RippleNumberedStep } from './RippleNumberedStep';

export type RippleNumberedStepsProps = {
  steps: Array<React.ReactNode>;
  spacing?: ListProps['spacing'];
  tagSx?: SystemStyleObject;
  listSx?: SystemStyleObject;
};

export const RippleNumberedSteps = ({ steps, tagSx, listSx, spacing = '16px' }: RippleNumberedStepsProps) => (
  <List as="ol" listStyleType="none" spacing={spacing} sx={listSx}>
    {steps.map((step, i) => (
      <RippleNumberedStep key={i} tag={i + 1} tagSx={tagSx}>
        {step}
      </RippleNumberedStep>
    ))}
  </List>
);
