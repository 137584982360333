import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleGranularControlSystemToolsIcon({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            d="M16.8712 7.69L15.4496 5.31C15.2363 4.96 14.8809 4.75 14.5255 4.82L13.0327 5.24C12.8905 5.17 12.8194 5.1 12.6773 5.03L12.3219 3.56C12.1797 3.21 11.8243 3 11.3978 3H8.5544C8.19898 3 7.84356 3.21 7.77248 3.56L7.34597 5.03C7.2038 5.1 7.13272 5.17 6.99055 5.24L5.49778 4.82C5.14236 4.75 4.78694 4.96 4.57369 5.31L3.15201 7.69C2.93876 8.04 2.93876 8.46 3.22309 8.74L4.36044 9.79C4.36044 9.86 4.36044 9.93 4.36044 10C4.36044 10.07 4.36044 10.14 4.36044 10.21L3.22309 11.26C3.00984 11.47 2.93876 11.89 3.15201 12.31L4.57369 14.69C4.78694 15.04 5.14236 15.25 5.49778 15.18L6.99055 14.76C7.13272 14.83 7.2038 14.9 7.34597 14.97L7.70139 16.44"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 13C10 11.3431 11.3431 10 13 10H19C20.6569 10 22 11.3431 22 13V14.3333C22 14.336 22 14.3387 22 14.3414C21.3744 14.1203 20.7013 14 20 14V13C20 12.4477 19.5523 12 19 12H13C12.4477 12 12 12.4477 12 13V19C12 19.5523 12.4477 20 13 20H14C14 20.7013 14.1203 21.3744 14.3414 22C14.3387 22 14.336 22 14.3333 22H13C11.3431 22 10 20.6569 10 19V13ZM17.3183 14.6312C16.6528 14.9643 16.0578 15.4175 15.5618 15.9623L15.0117 13.8896C14.9416 13.6252 15.1998 13.4079 15.4277 13.5396L17.3183 14.6312Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7066 9.29237C10.5257 9.11171 10.2759 9 10 9C9.44772 9 9 9.44772 9 10C9 10.2759 9.11171 10.5257 9.29237 10.7066C9.10495 11.0982 9 11.5369 9 12V12.8293C7.83481 12.4175 7 11.3062 7 10C7 8.34315 8.34315 7 10 7C11.3062 7 12.4175 7.83481 12.8293 9H12C11.5369 9 11.0982 9.10495 10.7066 9.29237Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.5L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <path
            d="M16.8712 7.69L15.4496 5.31C15.2363 4.96 14.8809 4.75 14.5255 4.82L13.0327 5.24C12.8905 5.17 12.8194 5.1 12.6773 5.03L12.3219 3.56C12.1797 3.21 11.8243 3 11.3978 3H8.5544C8.19898 3 7.84356 3.21 7.77248 3.56L7.34597 5.03C7.2038 5.1 7.13272 5.17 6.99055 5.24L5.49778 4.82C5.14236 4.75 4.78694 4.96 4.57369 5.31L3.15201 7.69C2.93876 8.04 2.93876 8.46 3.22309 8.74L4.36044 9.79C4.36044 9.86 4.36044 9.93 4.36044 10C4.36044 10.07 4.36044 10.14 4.36044 10.21L3.22309 11.26C3.00984 11.47 2.93876 11.89 3.15201 12.31L4.57369 14.69C4.78694 15.04 5.14236 15.25 5.49778 15.18L6.99055 14.76C7.13272 14.83 7.2038 14.9 7.34597 14.97L7.70139 16.44"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M21 14.3333V13C21 11.8954 20.1046 11 19 11H13C11.8954 11 11 11.8954 11 13V19C11 20.1046 11.8954 21 13 21H14.3333"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M21.4432 22.3913L21.821 22.0734C22.0297 21.8978 22.0603 21.5715 21.8895 21.3446L19.4387 18.089L21.2596 17.503C21.5062 17.4236 21.5328 17.0646 21.3019 16.9313L15.4277 13.5396C15.1998 13.4079 14.9416 13.6252 15.0117 13.8896L16.8198 20.7029C16.8909 20.9707 17.2277 21.0238 17.3562 20.7876L18.3052 19.0426L20.7561 22.2984C20.9269 22.5253 21.2345 22.5669 21.4432 22.3913Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7066 9.29237C10.5257 9.11171 10.2759 9 10 9C9.44772 9 9 9.44772 9 10C9 10.2759 9.11171 10.5257 9.29237 10.7066C9.10495 11.0982 9 11.5369 9 12V12.8293C7.83481 12.4175 7 11.3062 7 10C7 8.34315 8.34315 7 10 7C11.3062 7 12.4175 7.83481 12.8293 9H12C11.5369 9 11.0982 9.10495 10.7066 9.29237Z"
            fill="currentColor"
          />
        </>
      )}
    </Icon>
  );
}
