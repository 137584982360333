import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { rippleTypography } from './rippleTypography';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    alignItems: 'flex-start',
  },
  control: {
    w: '20px',
    width: '20px',
    h: '20px',
    height: '20px',
    bg: 'white',
    borderColor: 'neutral.300',
    borderStyle: 'solid',
    borderWidth: '1px',
    _hover: {
      bg: 'blue.0',
    },
    _checked: {
      bg: 'blue.100',
      borderColor: 'blue.100',
      _hover: {
        bg: 'blue.100',
        borderColor: 'blue.100',
      },
      _disabled: {
        bg: 'blue.20',
        borderColor: 'blue.20',
        color: 'white',
        _hover: {
          bg: 'blue.20',
          borderColor: 'blue.20',
          color: 'white',
        },
      },
    },
    _indeterminate: {
      bg: 'blue.100',
      borderColor: 'blue.100',
      _hover: {
        bg: 'blue.100',
        borderColor: 'blue.100',
      },
      _disabled: {
        bg: 'blue.20',
        borderColor: 'blue.20',
        color: 'white',
        _hover: {
          bg: 'blue.20',
          borderColor: 'blue.20',
          color: 'white',
        },
      },
    },
    _disabled: {
      bg: 'neutral.10',
      borderColor: 'dark.40',
      _hover: {
        bg: 'neutral.10',
        borderColor: 'dark.40',
      },
    },
  },
  label: {
    ...rippleTypography.variants.body02,
  },
});

const sizes = {
  md: definePartsStyle({
    label: defineStyle({
      fontSize: '14px',
    }),
  }),
};

export const rippleCheckbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
