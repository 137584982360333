import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleSecurityNoSoftware({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9993 12.4205L19 12.3997V6.78951L12 4.07082L5 6.78951V12.3997L5.00068 12.4205C5.00179 12.4495 5.00444 12.5032 5.01057 12.5785C5.02287 12.7296 5.04896 12.965 5.10381 13.2609C5.21423 13.8567 5.43639 14.6717 5.87803 15.5328C6.71 17.1551 8.3866 19.088 12 19.9726C15.6134 19.088 17.29 17.1551 18.122 15.5328C18.5636 14.6717 18.7858 13.8567 18.8962 13.2609C18.951 12.965 18.9771 12.7296 18.9894 12.5785C18.9956 12.5032 18.9982 12.4495 18.9993 12.4205ZM12.2126 21.9773C18.1201 20.6347 20.0562 16.7741 20.6907 14.3879C21 13.2246 21 12.4117 21 12.4117V6.10513C21 5.69255 20.7466 5.32233 20.362 5.17296L12.362 2.06588C12.1292 1.97543 11.8708 1.97543 11.638 2.06588L3.63796 5.17296C3.25338 5.32233 3 5.69255 3 6.10513V12.4117C3 12.4117 3 19.9801 11.7874 21.9773C11.9247 22.0085 12.0753 22.0085 12.2126 21.9773ZM15 10.1641C15 11.4151 14.1766 11.9001 13.554 12.2668C13.1755 12.4897 12.8713 12.6689 12.8713 12.95V13.6401C12.8713 13.8389 12.7133 14.0001 12.5185 14.0001H11.1768C10.982 14.0001 10.8241 13.8389 10.8241 13.6401V12.8803C10.8241 11.8336 11.583 11.4002 12.179 11.0597L12.2226 11.0348C12.7388 10.7395 13.0552 10.5386 13.0552 10.1474C13.0552 9.63006 12.4085 9.28665 11.8857 9.28665C11.2041 9.28665 10.8895 9.61596 10.4472 10.1857C10.3279 10.3393 10.1103 10.3679 9.95727 10.2494L9.13942 9.6165C8.9893 9.50034 8.95568 9.28452 9.0617 9.12561C9.75619 8.08479 10.6408 7.50006 12.018 7.50006C13.4604 7.50006 15 8.64918 15 10.1641ZM12.8199 16.0001C12.8199 16.5515 12.3714 17.0001 11.8199 17.0001C11.2685 17.0001 10.8199 16.5515 10.8199 16.0001C10.8199 15.4487 11.2685 15.0001 11.8199 15.0001C12.3714 15.0001 12.8199 15.4487 12.8199 16.0001Z"
        fill="currentColor"
      />
    </Icon>
  );
}
