import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleProactiveAlerts({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M13.4774 12.4148L13.675 13.1383L13.4774 12.4148ZM13.7018 12.4401L14.0475 11.7745L13.7018 12.4401ZM9.80621 12.8985L10.4763 13.2353L9.80621 12.8985ZM10.1533 13.3226L9.95569 12.5991L10.1533 13.3226ZM11.3013 9.70472L12.0064 9.44904L11.3013 9.70472ZM11.2885 9.94997L10.6184 9.6131L11.2885 9.94997ZM20.2531 8C20.2531 10.3472 18.3504 12.25 16.0031 12.25V13.75C19.1788 13.75 21.7531 11.1756 21.7531 8H20.2531ZM16.0031 3.75C18.3504 3.75 20.2531 5.65279 20.2531 8H21.7531C21.7531 4.82436 19.1788 2.25 16.0031 2.25V3.75ZM11.7531 8C11.7531 5.65279 13.6559 3.75 16.0031 3.75V2.25C12.8275 2.25 10.2531 4.82436 10.2531 8H11.7531ZM12.0064 9.44904C11.8427 8.9978 11.7531 8.51019 11.7531 8H10.2531C10.2531 8.68709 10.374 9.34761 10.5962 9.96041L12.0064 9.44904ZM10.6184 9.6131L9.13612 12.5616L10.4763 13.2353L11.9586 10.2868L10.6184 9.6131ZM10.3509 14.0461L13.675 13.1383L13.2798 11.6913L9.95569 12.5991L10.3509 14.0461ZM16.0031 12.25C15.2964 12.25 14.6321 12.0781 14.0475 11.7745L13.3561 13.1057C14.1492 13.5176 15.0502 13.75 16.0031 13.75V12.25ZM13.675 13.1383C13.5591 13.1699 13.4457 13.1522 13.3561 13.1057L14.0475 11.7745C13.8196 11.6561 13.5461 11.6185 13.2798 11.6913L13.675 13.1383ZM9.13612 12.5616C8.7279 13.3736 9.47413 14.2856 10.3509 14.0461L9.95569 12.5991C10.3314 12.4965 10.6513 12.8873 10.4763 13.2353L9.13612 12.5616ZM10.5962 9.96041C10.5587 9.85695 10.5593 9.73068 10.6184 9.6131L11.9586 10.2868C12.0943 10.0168 12.1021 9.71294 12.0064 9.44904L10.5962 9.96041Z"
        fill="currentColor"
      />
      <rect x="15.2969" y="5" width="1.4" height="3.5" rx="0.7" fill="currentColor" />
      <circle cx="16" cy="10" r="1" fill="currentColor" />
      <path
        d="M9 5H5C4.44772 5 4 5.44772 4 6V11V16C4 16.5523 4.44772 17 5 17H19C19.5523 17 20 16.5523 20 16V14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M12 21V17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 21L16 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </Icon>
  );
}
