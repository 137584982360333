import { ReactNode } from 'react';

import { Box, Tooltip, TooltipProps, forwardRef } from '@chakra-ui/react';

import { RippleButtonText } from '../RippleText';

export type RippleTooltipProps = TooltipProps & { shortcut?: ReactNode; children: NonNullable<ReactNode> };
export const RippleTooltip = forwardRef<RippleTooltipProps, 'div'>(({ label, shortcut, ...otherProps }, ref) => {
  const formattedLabel =
    typeof label === 'string' ? (
      <RippleButtonText as="span" color="white" display="inline-block" lineHeight="18px" wordBreak="break-word">
        {label}
      </RippleButtonText>
    ) : (
      label
    );

  return (
    <Tooltip
      padding="4px 8px"
      borderRadius="4px"
      boxShadow="0px 4px 8px 2px rgba(0, 0, 0, 0.12)"
      bg="blue.300"
      label={
        <>
          {formattedLabel}
          {shortcut && (
            <Box
              as="span"
              display="inline-block"
              bg="neutral.300"
              p="1px 5px"
              borderRadius="4px"
              ml="8px"
              fontSize="12px"
              fontWeight={400}
              lineHeight="15px"
            >
              {shortcut}
            </Box>
          )}
        </>
      }
      ref={ref}
      {...otherProps}
    />
  );
});
