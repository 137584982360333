import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';

export type RippleButtonProps = Omit<ButtonProps, 'variant'> & {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'ghost'
    | 'success'
    | 'danger'
    | 'dangerSecondary'
    | 'dangerTertiary'
    | 'dangerGhost'
    | 'grayScalePrimary'
    | 'grayScaleSecondary'
    | 'grayScaleTertiary'
    | 'grayScaleGhost'
    | 'neutralGhost';
};

export const RippleButton = forwardRef<RippleButtonProps, 'button'>(
  ({ size = 'sm', variant = 'primary', leftIcon, rightIcon, ...otherProps }, ref) => {
    return <Button size={size} variant={variant} ref={ref} leftIcon={leftIcon} rightIcon={rightIcon} {...otherProps} />;
  },
);
