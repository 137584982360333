import { cssVar, defineStyleConfig } from '@chakra-ui/react';

import colors from '../colors';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

export const style = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    borderRadius: '4px',

    [$startColor.variable]: colors.neutral[80],
    [$endColor.variable]: colors.neutral[10],
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
});

export const rippleSkeleton = style;
