import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleVNCConnected = () => {
  const linearGradient1 = useId();

  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <path
        d="M22.5 40C33.2696 40 42 31.0457 42 20C42 8.9543 33.2696 0 22.5 0C11.7304 0 3 8.9543 3 20C3 31.0457 11.7304 40 22.5 40Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M41 20C41 30.5174 32.6936 39 22.5 39C12.3064 39 4 30.5174 4 20C4 9.48257 12.3064 1 22.5 1C32.6936 1 41 9.48257 41 20Z"
        stroke="#70B30C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C6.89543 6 6 6.89543 6 8V27.5455C6 28.65 6.89543 29.5455 8 29.5455H20.9135V32.7274H16.7885V34.0001H28.2115V32.7274H24.0865V29.5455H37C38.1046 29.5455 39 28.65 39 27.5455V8C39 6.89543 38.1046 6 37 6H8Z"
        fill={`url(#${linearGradient1})`}
      />
      <rect width="30.4615" height="21" transform="translate(7.26923 7.27274)" fill="#0F00B9" />
      <path
        d="M22.0452 17.0855C22.2462 17.2021 22.4112 17.4885 22.4112 17.7218L22.426 25.3518C22.426 25.5852 22.261 25.6806 22.06 25.5639L15.4621 21.7458C15.2612 21.6291 15.0962 21.3427 15.0962 21.1094V13.4688C15.0962 13.2355 15.2612 13.14 15.4621 13.2567L22.0452 17.0855V17.0855Z"
        fill="white"
      />
      <path
        d="M19.1281 11.1418C18.9271 11.0252 18.7621 11.1206 18.7621 11.354V12.5779C18.7621 12.8112 18.9271 13.0955 19.1302 13.2121L23.9681 15.9676C24.1712 16.0821 24.3362 16.3685 24.3362 16.6018L24.3404 22.2103C24.3404 22.4437 24.5054 22.73 24.7063 22.8467L25.726 23.4364C25.9269 23.553 26.0919 23.4576 26.0919 23.2243L26.0771 15.5943C26.0771 15.3609 25.9121 15.0746 25.709 14.9579L19.1281 11.1418Z"
        fill="white"
      />
      <path
        d="M22.794 9.02062C22.5931 8.90395 22.4281 8.99941 22.4281 9.23274V10.4546C22.4281 10.6879 22.5931 10.9721 22.7962 11.0888L27.634 13.8443C27.8371 13.9588 28.0021 14.2452 28.0021 14.4785L28.0063 20.087C28.0063 20.3203 28.1713 20.6067 28.3723 20.7233L29.3919 21.313C29.5929 21.4297 29.7579 21.3343 29.7579 21.1009L29.7431 13.4709C29.7431 13.2376 29.5781 12.9512 29.375 12.8346L22.794 9.02062Z"
        fill="white"
      />
      <path
        d="M8 38C11.3137 38 14 35.3137 14 32C14 28.6863 11.3137 26 8 26C4.68629 26 2 28.6863 2 32C2 35.3137 4.68629 38 8 38Z"
        fill="#70B30C"
      />
      <path d="M9.8 32.6H6.2V34.4L3.2 32.0061L6.2 29.6V31.4H9.8V29.6L12.8 31.994L9.8 34.4V32.6Z" fill="#F5F6EE" />
      <defs>
        <linearGradient id={linearGradient1} x1="22.5" y1="28.9097" x2="22.5" y2="7.13018" gradientUnits="userSpaceOnUse">
          <stop stopColor="#504F4F" />
          <stop offset="0.747188" stopColor="#504F4F" />
          <stop offset="1" stopColor="#515151" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export const RippleVNCOffline = () => {
  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C6.89543 6 6 6.89543 6 8V27.5455C6 28.65 6.89543 29.5455 8 29.5455H20.9135V32.7274H16.7885V34.0001H28.2115V32.7274H24.0865V29.5455H37C38.1046 29.5455 39 28.65 39 27.5455V8C39 6.89543 38.1046 6 37 6H8Z"
        fill="#777777"
      />
      <rect width="30.4615" height="21" transform="translate(7.26923 7.27274)" fill="#DEDEDE" />
      <path
        d="M22.0452 17.0855C22.2461 17.2021 22.4111 17.4885 22.4111 17.7218L22.426 25.3518C22.426 25.5852 22.261 25.6806 22.06 25.5639L15.4621 21.7458C15.2611 21.6291 15.0961 21.3427 15.0961 21.1094V13.4688C15.0961 13.2355 15.2611 13.14 15.4621 13.2567L22.0452 17.0855V17.0855Z"
        fill="#BCBCBC"
      />
      <path
        d="M19.1281 11.1418C18.9271 11.0252 18.7621 11.1206 18.7621 11.354V12.5779C18.7621 12.8112 18.9271 13.0955 19.1302 13.2121L23.9681 15.9676C24.1711 16.0821 24.3361 16.3685 24.3361 16.6018L24.3404 22.2103C24.3404 22.4437 24.5054 22.73 24.7063 22.8467L25.7259 23.4364C25.9269 23.553 26.0919 23.4576 26.0919 23.2243L26.0771 15.5943C26.0771 15.3609 25.9121 15.0746 25.709 14.9579L19.1281 11.1418Z"
        fill="#BCBCBC"
      />
      <path
        d="M22.794 9.02062C22.5931 8.90395 22.4281 8.99941 22.4281 9.23274V10.4546C22.4281 10.6879 22.5931 10.9721 22.7961 11.0888L27.634 13.8443C27.8371 13.9588 28.0021 14.2452 28.0021 14.4785L28.0063 20.087C28.0063 20.3203 28.1713 20.6067 28.3723 20.7233L29.3919 21.313C29.5929 21.4297 29.7579 21.3343 29.7579 21.1009L29.7431 13.4709C29.7431 13.2376 29.5781 12.9512 29.375 12.8346L22.794 9.02062Z"
        fill="#BCBCBC"
      />
    </Icon>
  );
};

export const RippleVNCOnline = () => {
  const linearGradient1 = useId();

  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C6.89543 6 6 6.89543 6 8V27.5455C6 28.65 6.89543 29.5455 8 29.5455H20.9135V32.7274H16.7885V34.0001H28.2115V32.7274H24.0865V29.5455H37C38.1046 29.5455 39 28.65 39 27.5455V8C39 6.89543 38.1046 6 37 6H8Z"
        fill={`url(#${linearGradient1})`}
      />
      <rect width="30.4615" height="21" transform="translate(7.26923 7.27274)" fill="#0F00B9" />
      <path
        d="M22.0452 17.0855C22.2462 17.2021 22.4112 17.4885 22.4112 17.7218L22.426 25.3518C22.426 25.5852 22.261 25.6806 22.06 25.5639L15.4621 21.7458C15.2612 21.6291 15.0962 21.3427 15.0962 21.1094V13.4688C15.0962 13.2355 15.2612 13.14 15.4621 13.2567L22.0452 17.0855V17.0855Z"
        fill="white"
      />
      <path
        d="M19.1281 11.1418C18.9271 11.0252 18.7621 11.1206 18.7621 11.354V12.5779C18.7621 12.8112 18.9271 13.0955 19.1302 13.2121L23.9681 15.9676C24.1712 16.0821 24.3362 16.3685 24.3362 16.6018L24.3404 22.2103C24.3404 22.4437 24.5054 22.73 24.7064 22.8467L25.726 23.4364C25.9269 23.553 26.0919 23.4576 26.0919 23.2243L26.0771 15.5943C26.0771 15.3609 25.9121 15.0746 25.709 14.9579L19.1281 11.1418Z"
        fill="white"
      />
      <path
        d="M22.794 9.02062C22.5931 8.90395 22.4281 8.99941 22.4281 9.23274V10.4546C22.4281 10.6879 22.5931 10.9721 22.7962 11.0888L27.634 13.8443C27.8371 13.9588 28.0021 14.2452 28.0021 14.4785L28.0064 20.087C28.0064 20.3203 28.1714 20.6067 28.3723 20.7233L29.3919 21.313C29.5929 21.4297 29.7579 21.3343 29.7579 21.1009L29.7431 13.4709C29.7431 13.2376 29.5781 12.9512 29.375 12.8346L22.794 9.02062Z"
        fill="white"
      />
      <defs>
        <linearGradient id={linearGradient1} x1="22.5" y1="28.9097" x2="22.5" y2="7.13018" gradientUnits="userSpaceOnUse">
          <stop stopColor="#504F4F" />
          <stop offset="0.747188" stopColor="#504F4F" />
          <stop offset="1" stopColor="#515151" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
