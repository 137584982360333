import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import { LoadingSpinner } from './LoadingSpinner';

export const RippleAndroidConnected = () => {
  const filter1 = useId();
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const clipPath1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <g clipPath={`url(#${clipPath1})`}>
        <g filter={`url(#${filter1})`}>
          <rect x={4} y={4} width={32} height={32} rx={4} fill="white" />
          <rect x={5} y={5} width={30} height={30} rx={3} stroke={`url(#${linearGradient1})`} strokeWidth={2} />
        </g>
        <path
          d="M24.6025 21.9187C24.1433 21.9187 23.7697 21.5449 23.7697 21.0856C23.7697 20.6264 24.1433 20.2529 24.6025 20.2529C25.0618 20.2529 25.4353 20.6264 25.4353 21.0856C25.4353 21.5449 25.0618 21.9187 24.6025 21.9187ZM15.3974 21.9187C14.9382 21.9187 14.5647 21.5449 14.5647 21.0856C14.5647 20.6264 14.9381 20.2529 15.3974 20.2529C15.8566 20.2529 16.2302 20.6264 16.2302 21.0856C16.2302 21.5449 15.8567 21.9187 15.3974 21.9187ZM24.9012 16.9021L26.5656 14.0194C26.6114 13.9398 26.6238 13.8454 26.6001 13.7567C26.5763 13.668 26.5183 13.5924 26.4389 13.5464C26.3593 13.5006 26.2648 13.4882 26.1762 13.512C26.0875 13.5357 26.0119 13.5937 25.9659 13.6731L24.2806 16.5923C22.9918 16.0042 21.5444 15.6765 20 15.6765C18.4555 15.6765 17.0081 16.0042 15.7194 16.5923L14.0341 13.6731C13.9881 13.5937 13.9124 13.5357 13.8238 13.512C13.7351 13.4882 13.6406 13.5006 13.5611 13.5464C13.4816 13.5923 13.4235 13.668 13.3998 13.7567C13.376 13.8454 13.3884 13.9399 13.4343 14.0194L15.0987 16.9021C12.2407 18.4565 10.2859 21.35 9.99996 24.7684H30C29.7138 21.35 27.759 18.4565 24.9012 16.902"
          fill="#30D780"
        />
        <circle cx={31} cy={31} r={7} fill={`url(#${linearGradient2})`} stroke="white" strokeWidth={2} />
        <rect x={29} y="30.25" width={4} height="1.5" fill="white" />
        <path
          d="M33 28.4828V33.5172C33 33.6953 33.2154 33.7846 33.3414 33.6586L35.8586 31.1414C35.9367 31.0633 35.9367 30.9367 35.8586 30.8586L33.3414 28.3414C33.2154 28.2154 33 28.3047 33 28.4828Z"
          fill="white"
        />
        <path
          d="M29 28.4828V33.5172C29 33.6953 28.7846 33.7846 28.6586 33.6586L26.1414 31.1414C26.0633 31.0633 26.0633 30.9367 26.1414 30.8586L28.6586 28.3414C28.7846 28.2154 29 28.3047 29 28.4828Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id={filter1} x={-1} y={-1} width={42} height={42} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9875_102273" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={-1} dy={-1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.572549 0 0 0 0 0.92549 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9875_102273" result="effect2_dropShadow_9875_102273" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9875_102273" result="shape" />
        </filter>
        <linearGradient id={linearGradient1} x1={4} y1={11} x2={32} y2={36} gradientUnits="userSpaceOnUse">
          <stop offset="0.208275" stopColor="#AC59FF" />
          <stop offset={1} stopColor="#036DEA" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="37.4167" y1={38} x2="25.1663" y2="24.8754" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
        <clipPath id={clipPath1}>
          <rect width={40} height={40} fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export const RippleAndroidOffline = () => {
  const radialGradient1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
      <path
        d="M24.6025 21.9185C24.1433 21.9185 23.7698 21.5447 23.7698 21.0855C23.7698 20.6263 24.1433 20.2527 24.6025 20.2527C25.0618 20.2527 25.4354 20.6262 25.4354 21.0855C25.4354 21.5448 25.0618 21.9185 24.6025 21.9185ZM15.3975 21.9185C14.9383 21.9185 14.5647 21.5447 14.5647 21.0855C14.5647 20.6263 14.9382 20.2527 15.3975 20.2527C15.8567 20.2527 16.2302 20.6262 16.2302 21.0855C16.2302 21.5448 15.8568 21.9185 15.3975 21.9185ZM24.9012 16.9019L26.5657 14.0192C26.6115 13.9397 26.6239 13.8452 26.6001 13.7565C26.5763 13.6678 26.5184 13.5922 26.4389 13.5462C26.3593 13.5004 26.2649 13.4881 26.1762 13.5118C26.0875 13.5356 26.0119 13.5935 25.9659 13.673L24.2807 16.5921C22.9918 16.004 21.5445 15.6763 20 15.6763C18.4555 15.6763 17.0082 16.004 15.7194 16.5921L14.0341 13.673C13.9881 13.5935 13.9125 13.5356 13.8238 13.5118C13.7351 13.4881 13.6407 13.5004 13.5611 13.5462C13.4816 13.5922 13.4236 13.6678 13.3998 13.7565C13.376 13.8452 13.3885 13.9397 13.4343 14.0192L15.0988 16.9019C12.2407 18.4564 10.286 21.3498 10 24.7682H30C29.7138 21.3499 27.7591 18.4564 24.9012 16.9018"
        fill="#B0B0B0"
      />
      <defs>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(16)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};

export const RippleAndroidOnline = () => {
  const radialGradient1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
      <path
        d="M24.6025 21.9185C24.1433 21.9185 23.7698 21.5447 23.7698 21.0855C23.7698 20.6263 24.1433 20.2527 24.6025 20.2527C25.0618 20.2527 25.4354 20.6262 25.4354 21.0855C25.4354 21.5448 25.0618 21.9185 24.6025 21.9185ZM15.3975 21.9185C14.9383 21.9185 14.5647 21.5447 14.5647 21.0855C14.5647 20.6263 14.9382 20.2527 15.3975 20.2527C15.8567 20.2527 16.2302 20.6262 16.2302 21.0855C16.2302 21.5448 15.8568 21.9185 15.3975 21.9185ZM24.9012 16.9019L26.5657 14.0192C26.6115 13.9397 26.6239 13.8452 26.6001 13.7565C26.5763 13.6678 26.5184 13.5922 26.4389 13.5462C26.3593 13.5004 26.2649 13.4881 26.1762 13.5118C26.0875 13.5356 26.0119 13.5935 25.9659 13.673L24.2807 16.5921C22.9918 16.004 21.5445 15.6763 20 15.6763C18.4555 15.6763 17.0082 16.004 15.7194 16.5921L14.0341 13.673C13.9881 13.5935 13.9125 13.5356 13.8238 13.5118C13.7351 13.4881 13.6407 13.5004 13.5611 13.5462C13.4816 13.5922 13.4236 13.6678 13.3998 13.7565C13.376 13.8452 13.3885 13.9397 13.4343 14.0192L15.0988 16.9019C12.2407 18.4564 10.286 21.3498 10 24.7682H30C29.7138 21.3499 27.7591 18.4564 24.9012 16.9018"
        fill="#30D780"
      />
      <defs>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(16)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};

export const RippleAndroidLoading = () => {
  return (
    <LoadingSpinner>
      <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
        <path
          d="M24.6025 21.9185C24.1433 21.9185 23.7698 21.5447 23.7698 21.0855C23.7698 20.6263 24.1433 20.2527 24.6025 20.2527C25.0618 20.2527 25.4354 20.6262 25.4354 21.0855C25.4354 21.5448 25.0618 21.9185 24.6025 21.9185ZM15.3975 21.9185C14.9383 21.9185 14.5647 21.5447 14.5647 21.0855C14.5647 20.6263 14.9382 20.2527 15.3975 20.2527C15.8567 20.2527 16.2302 20.6262 16.2302 21.0855C16.2302 21.5448 15.8568 21.9185 15.3975 21.9185ZM24.9012 16.9019L26.5657 14.0192C26.6115 13.9397 26.6239 13.8452 26.6001 13.7565C26.5763 13.6678 26.5184 13.5922 26.4389 13.5462C26.3593 13.5004 26.2649 13.4881 26.1762 13.5118C26.0875 13.5356 26.0119 13.5935 25.9659 13.673L24.2807 16.5921C22.9918 16.004 21.5445 15.6763 20 15.6763C18.4555 15.6763 17.0082 16.004 15.7194 16.5921L14.0341 13.673C13.9881 13.5935 13.9125 13.5356 13.8238 13.5118C13.7351 13.4881 13.6407 13.5004 13.5611 13.5462C13.4816 13.5922 13.4236 13.6678 13.3998 13.7565C13.376 13.8452 13.3885 13.9397 13.4343 14.0192L15.0988 16.9019C12.2407 18.4564 10.286 21.3498 10 24.7682H30C29.7138 21.3499 27.7591 18.4564 24.9012 16.9018"
          fill="#B0B0B0"
        />
      </Icon>
    </LoadingSpinner>
  );
};
