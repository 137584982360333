import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleViewOptions({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 11.7126 4.12146 11.1822 4.52476 10.4724C4.9144 9.78657 5.50488 9.04578 6.26514 8.36308C7.79966 6.98512 9.8428 6 12 6C14.1566 6 16.1998 6.98508 17.7345 8.36313C18.4949 9.04586 19.0855 9.78667 19.4752 10.4725C19.8785 11.1823 20 11.7127 20 12C20 12.2873 19.8785 12.8177 19.4752 13.5275C19.0855 14.2133 18.4949 14.9541 17.7345 15.6369C16.1998 17.0149 14.1566 18 12 18C9.8428 18 7.79966 17.0149 6.26514 15.6369C5.50488 14.9542 4.9144 14.2134 4.52476 13.5276C4.12146 12.8178 4 12.2874 4 12ZM12 4C6.477 4 2 9 2 12C2 15 6.477 20 12 20C17.522 20 22 15 22 12C22 9 17.522 4 12 4ZM10 12C10 10.8956 10.8956 10 12 10C13.1044 10 14 10.8956 14 12C14 13.1044 13.1044 14 12 14C10.8956 14 10 13.1044 10 12ZM12 8C9.791 8 8 9.791 8 12C8 14.209 9.791 16 12 16C14.209 16 16 14.209 16 12C16 9.791 14.209 8 12 8Z"
        fill="currentColor"
      />
    </Icon>
  );
}
