import { Icon } from '@chakra-ui/react';

export const RippleSSHConnected = () => {
  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <path d="M16 34V33H20L20.26 28H23.7404L24 33H28V34H16Z" fill="#504F4F" />
      <path
        d="M38 6H6C5.44772 6 5 6.44772 5 7V29C5 29.5523 5.44772 30 6 30H38C38.5523 30 39 29.5523 39 29V7C39 6.44772 38.5523 6 38 6Z"
        fill="#504F4F"
      />
      <path d="M38 7H6V29H38V7Z" fill="#040B36" />
      <path
        d="M29 10H15C13.8954 10 13 10.8954 13 12V24C13 25.1046 13.8954 26 15 26H29C30.1046 26 31 25.1046 31 24V12C31 10.8954 30.1046 10 29 10Z"
        fill="white"
      />
      <path d="M23 21H27" stroke="#040B36" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 21L21 17.9091L17.129 15" stroke="#040B36" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};

export const RippleSSHOffline = () => {
  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <path d="M16 34V33H20L20.26 28H23.7404L24 33H28V34H16Z" fill="#777777" />
      <rect x={5} y={6} width={34} height={24} rx={1} fill="#777777" />
      <rect width={32} height={22} transform="translate(6 7)" fill="#DEDEDE" />
      <rect x={13} y={10} width={18} height={16} rx={2} fill="#BCBCBC" />
      <path d="M23 21L27 21" stroke="#DEDEDE" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 21L21 17.9091L17.129 15" stroke="#DEDEDE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};

export const RippleSSHOnline = () => {
  return (
    <Icon width="45px" height="40px" viewBox="0 0 45 40" fill="none">
      <path d="M16 34V33H20L20.26 28H23.7404L24 33H28V34H16Z" fill="#504F4F" />
      <rect x={5} y={6} width={34} height={24} rx={1} fill="#504F4F" />
      <rect width={32} height={22} transform="translate(6 7)" fill="#040B36" />
      <rect x={13} y={10} width={18} height={16} rx={2} fill="white" />
      <path d="M23 21L27 21" stroke="#040B36" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 21L21 17.9091L17.129 15" stroke="#040B36" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};
