import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDashboardInsights({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="3" y="3.5" width="18" height="17" rx="2" stroke="currentColor" strokeWidth="1.5" />
      <path d="M7 13L7 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M12 7L12 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M17 10L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </Icon>
  );
}
