import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleWindows({ color = '#0074CD' }: RippleIconProps) {
  const clipPath1 = useId();
  const radialGradient1 = useId();

  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <g clipPath={`url(#${radialGradient1})`}>
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" stroke={`url(#${clipPath1})`} />
        <rect x="4.5" y="4.5" width="7.05882" height="7.05882" fill="currentColor" />
        <rect x="12.4414" y="4.5" width="7.05882" height="7.05882" fill="currentColor" />
        <rect x="4.5" y="12.4412" width="7.05882" height="7.05882" fill="currentColor" />
        <rect x="12.4414" y="12.4412" width="7.05882" height="7.05882" fill="currentColor" />
      </g>
      <defs>
        <radialGradient
          id={clipPath1}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 12) rotate(90) scale(12)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
        <clipPath id={radialGradient1}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
