import { Box, Flex } from '@chakra-ui/react';

import { RippleTypography } from '@/design/RippleTypography';

export const WEEK_NAMES = [
  { index: 0, name: 'S' },
  { index: 1, name: 'M' },
  { index: 2, name: 'T' },
  { index: 3, name: 'W' },
  { index: 4, name: 'T' },
  { index: 5, name: 'F' },
  { index: 6, name: 'S' },
] as const;

export const WeekNames = () => {
  return (
    <Box display="grid" gridTemplateColumns="repeat(7, 24px)" gap="4px">
      {WEEK_NAMES.map((weekday) => {
        return (
          <Flex key={weekday.index} justifyContent="center" alignItems="center" width="24px" height="24px">
            <RippleTypography variant="body03" color="neutral.300">
              {weekday.name}
            </RippleTypography>
          </Flex>
        );
      })}
    </Box>
  );
};
