import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleRDSConnected = () => {
  const filter1 = useId();
  const linearGradient1 = useId();
  const linearGradient2 = useId();
  const linearGradient3 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x={13} y={5} width={22} height={22} rx={3} fill="white" />
      <path
        d="M19.8858 13.332C20.0652 13.1303 20.3559 13.1303 20.5353 13.332L24.8144 18.1444C24.9937 18.3461 24.9937 18.6731 24.8144 18.8748L20.3917 23.8487C20.2123 24.0504 19.9216 24.0504 19.7422 23.8487L18.4203 22.362C18.2409 22.1603 18.2409 21.8333 18.4202 21.6316L21.2655 18.4314L18.6332 15.4711C18.4539 15.2694 18.4539 14.9424 18.6332 14.7407L19.8858 13.332ZM28.1143 8.15127L29.3669 9.55999C29.5462 9.76168 29.5462 10.0887 29.3669 10.2904L26.7346 13.2507L29.5799 16.4509C29.7592 16.6526 29.7592 16.9796 29.5798 17.1813L28.2579 18.668C28.0785 18.8697 27.7878 18.8697 27.6084 18.668L23.1857 13.6941C23.0064 13.4924 23.0064 13.1654 23.1857 12.9637L27.4648 8.15127C27.6442 7.94958 27.9349 7.94958 28.1143 8.15127Z"
        fill="#DA5E39"
      />
      <rect x={13} y={5} width={22} height={22} rx={3} stroke={`url(#${linearGradient1})`} strokeWidth="1.5" />
      <g filter={`url(#${filter1})`}>
        <rect x={5} y={13} width={22} height={22} rx={3} fill="white" shapeRendering="crispEdges" />
        <path
          d="M11.8858 21.332C12.0652 21.1303 12.3559 21.1303 12.5353 21.332L16.8144 26.1444C16.9937 26.3461 16.9937 26.6731 16.8144 26.8748L12.3917 31.8487C12.2123 32.0504 11.9216 32.0504 11.7422 31.8487L10.4203 30.362C10.2409 30.1603 10.2409 29.8333 10.4202 29.6316L13.2655 26.4314L10.6332 23.4711C10.4539 23.2694 10.4539 22.9424 10.6332 22.7407L11.8858 21.332ZM20.1143 16.1513L21.3669 17.56C21.5462 17.7617 21.5462 18.0887 21.3669 18.2904L18.7346 21.2507L21.5799 24.4509C21.7592 24.6526 21.7592 24.9796 21.5798 25.1813L20.2579 26.668C20.0785 26.8697 19.7878 26.8697 19.6084 26.668L15.1857 21.6941C15.0064 21.4924 15.0064 21.1654 15.1857 20.9637L19.4648 16.1513C19.6442 15.9496 19.9349 15.9496 20.1143 16.1513Z"
          fill="#DA5E39"
        />
        <rect
          x={5}
          y={13}
          width={22}
          height={22}
          rx={3}
          stroke={`url(#${linearGradient2})`}
          strokeWidth="1.5"
          shapeRendering="crispEdges"
        />
      </g>
      <circle cx={31} cy={31} r={7} fill={`url(#${linearGradient3})`} stroke="white" strokeWidth={2} />
      <rect x={29} y="30.25" width={4} height="1.5" fill="white" />
      <path
        d="M33 28.4828V33.5172C33 33.6953 33.2154 33.7846 33.3414 33.6586L35.8586 31.1414C35.9367 31.0633 35.9367 30.9367 35.8586 30.8586L33.3414 28.3414C33.2154 28.2154 33 28.3047 33 28.4828Z"
        fill="white"
      />
      <path
        d="M29 28.4828V33.5172C29 33.6953 28.7846 33.7846 28.6586 33.6586L26.1414 31.1414C26.0633 31.0633 26.0633 30.9367 26.1414 30.8586L28.6586 28.3414C28.7846 28.2154 29 28.3047 29 28.4828Z"
        fill="white"
      />
      <defs>
        <filter id={filter1} x="3.25" y="9.25" width="27.5" height="27.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={-1} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.572549 0 0 0 0 0.92549 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9023_84102" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9023_84102" result="shape" />
        </filter>
        <linearGradient id={linearGradient1} x1="34.0833" y1={27} x2="14.8327" y2="6.37561" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="26.0833" y1={35} x2="6.83268" y2="14.3756" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
        <linearGradient id={linearGradient3} x1="37.4167" y1={38} x2="25.1663" y2="24.8754" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export const RippleRDSOffline = () => {
  const filter1 = useId();
  const radialGradient1 = useId();
  const radialGradient2 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="13.25" y="5.25" width="21.5" height="21.5" rx="2.75" fill="white" />
      <path
        d="M19.8858 13.332C20.0652 13.1303 20.3559 13.1303 20.5353 13.332L24.8144 18.1444C24.9937 18.3461 24.9937 18.6731 24.8144 18.8748L20.3917 23.8487C20.2123 24.0504 19.9216 24.0504 19.7422 23.8487L18.4203 22.362C18.2409 22.1603 18.2409 21.8333 18.4202 21.6316L21.2655 18.4314L18.6332 15.4711C18.4539 15.2694 18.4539 14.9424 18.6332 14.7407L19.8858 13.332ZM28.1143 8.15127L29.3669 9.55999C29.5462 9.76168 29.5462 10.0887 29.3669 10.2904L26.7346 13.2507L29.5799 16.4509C29.7592 16.6526 29.7592 16.9796 29.5798 17.1813L28.2579 18.668C28.0785 18.8697 27.7878 18.8697 27.6084 18.668L23.1857 13.6941C23.0064 13.4924 23.0064 13.1654 23.1857 12.9637L27.4648 8.15127C27.6442 7.94958 27.9349 7.94958 28.1143 8.15127Z"
        fill="#B0B0B0"
      />
      <rect x="13.25" y="5.25" width="21.5" height="21.5" rx="2.75" stroke={`url(#${radialGradient1})`} strokeWidth="0.5" />
      <g filter={`url(#${filter1})`}>
        <rect x={5} y={13} width={22} height={22} rx={3} fill="white" shapeRendering="crispEdges" />
        <path
          d="M11.8858 21.332C12.0652 21.1303 12.3559 21.1303 12.5353 21.332L16.8144 26.1444C16.9937 26.3461 16.9937 26.6731 16.8144 26.8748L12.3917 31.8487C12.2123 32.0504 11.9216 32.0504 11.7422 31.8487L10.4203 30.362C10.2409 30.1603 10.2409 29.8333 10.4202 29.6316L13.2655 26.4314L10.6332 23.4711C10.4539 23.2694 10.4539 22.9424 10.6332 22.7407L11.8858 21.332ZM20.1143 16.1513L21.3669 17.56C21.5462 17.7617 21.5462 18.0887 21.3669 18.2904L18.7346 21.2507L21.5799 24.4509C21.7592 24.6526 21.7592 24.9796 21.5798 25.1813L20.2579 26.668C20.0785 26.8697 19.7878 26.8697 19.6084 26.668L15.1857 21.6941C15.0064 21.4924 15.0064 21.1654 15.1857 20.9637L19.4648 16.1513C19.6442 15.9496 19.9349 15.9496 20.1143 16.1513Z"
          fill="#B0B0B0"
        />
        <rect
          x="5.25"
          y="13.25"
          width="21.5"
          height="21.5"
          rx="2.75"
          stroke={`url(#${radialGradient2})`}
          strokeWidth="0.5"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter id={filter1} x={4} y={10} width={26} height={26} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={-1} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9023_84019" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9023_84019" result="shape" />
        </filter>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24 16) rotate(90) scale(11)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
        <radialGradient
          id={radialGradient2}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 24) rotate(90) scale(11)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};

export const RippleRDSOnline = () => {
  const filter1 = useId();
  const radialGradient1 = useId();
  const radialGradient2 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="13.25" y="5.25" width="21.5" height="21.5" rx="2.75" fill="white" />
      <path
        d="M19.8858 13.332C20.0652 13.1303 20.3559 13.1303 20.5353 13.332L24.8144 18.1444C24.9937 18.3461 24.9937 18.6731 24.8144 18.8748L20.3917 23.8487C20.2123 24.0504 19.9216 24.0504 19.7422 23.8487L18.4203 22.362C18.2409 22.1603 18.2409 21.8333 18.4202 21.6316L21.2655 18.4314L18.6332 15.4711C18.4539 15.2694 18.4539 14.9424 18.6332 14.7407L19.8858 13.332ZM28.1143 8.15127L29.3669 9.55999C29.5462 9.76168 29.5462 10.0887 29.3669 10.2904L26.7346 13.2507L29.5799 16.4509C29.7592 16.6526 29.7592 16.9796 29.5798 17.1813L28.2579 18.668C28.0785 18.8697 27.7878 18.8697 27.6084 18.668L23.1857 13.6941C23.0064 13.4924 23.0064 13.1654 23.1857 12.9637L27.4648 8.15127C27.6442 7.94958 27.9349 7.94958 28.1143 8.15127Z"
        fill="#DA5E39"
      />
      <rect x="13.25" y="5.25" width="21.5" height="21.5" rx="2.75" stroke={`url(#${radialGradient1})`} strokeWidth="0.5" />
      <g filter={`url(#${filter1})`}>
        <rect x={5} y={13} width={22} height={22} rx={3} fill="white" shapeRendering="crispEdges" />
        <path
          d="M11.8858 21.332C12.0652 21.1303 12.3559 21.1303 12.5353 21.332L16.8144 26.1444C16.9937 26.3461 16.9937 26.6731 16.8144 26.8748L12.3917 31.8487C12.2123 32.0504 11.9216 32.0504 11.7422 31.8487L10.4203 30.362C10.2409 30.1603 10.2409 29.8333 10.4202 29.6316L13.2655 26.4314L10.6332 23.4711C10.4539 23.2694 10.4539 22.9424 10.6332 22.7407L11.8858 21.332ZM20.1143 16.1513L21.3669 17.56C21.5462 17.7617 21.5462 18.0887 21.3669 18.2904L18.7346 21.2507L21.5799 24.4509C21.7592 24.6526 21.7592 24.9796 21.5798 25.1813L20.2579 26.668C20.0785 26.8697 19.7878 26.8697 19.6084 26.668L15.1857 21.6941C15.0064 21.4924 15.0064 21.1654 15.1857 20.9637L19.4648 16.1513C19.6442 15.9496 19.9349 15.9496 20.1143 16.1513Z"
          fill="#DA5E39"
        />
        <rect
          x="5.25"
          y="13.25"
          width="21.5"
          height="21.5"
          rx="2.75"
          stroke={`url(#${radialGradient2})`}
          strokeWidth="0.5"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter id={filter1} x={4} y={10} width={26} height={26} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={-1} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9023_83970" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9023_83970" result="shape" />
        </filter>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24 16) rotate(90) scale(11)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
        <radialGradient
          id={radialGradient2}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 24) rotate(90) scale(11)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
};
