import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconSubInfoProps } from './types';

export function RippleGranularControlSmartActionsIcon({ color = DEFAULT_ICON_COLOR, isShowSubInfo }: RippleIconSubInfoProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      {isShowSubInfo ? (
        <>
          <path
            d="M15 3.80269C14.1175 3.29218 13.0929 3 12 3C8.68629 3 6 5.68629 6 9C6 12 9 14 9 16.5V19C9 20.5 10 21 11 21C12 21 12 21 13 21C14 21 15 20.5 15 19V16.5C15 14.9266 16.1883 13.5512 17.0691 12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path d="M9 18H15" stroke="currentColor" strokeWidth="2" />
          <path d="M9 15H15" stroke="currentColor" strokeWidth="2" />
          <path d="M10 10L11 15" stroke="currentColor" strokeWidth="1.5" />
          <path d="M13 15L14 10" stroke="currentColor" strokeWidth="1.5" />
          <path d="M9 10H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5 3C18.7152 3 18.9063 3.13772 18.9743 3.34189L19.5662 5.11754C19.616 5.26685 19.7332 5.384 19.8825 5.43377L21.6581 6.02566C21.8623 6.09372 22 6.28478 22 6.5C22 6.71522 21.8623 6.90628 21.6581 6.97434L19.8825 7.56623C19.7332 7.616 19.616 7.73315 19.5662 7.88246L18.9743 9.65811C18.9063 9.86229 18.7152 10 18.5 10C18.2848 10 18.0937 9.86229 18.0257 9.65811L17.4338 7.88246C17.384 7.73315 17.2668 7.616 17.1175 7.56623L15.3419 6.97434C15.1377 6.90628 15 6.71522 15 6.5C15 6.28478 15.1377 6.09372 15.3419 6.02566L17.1175 5.43377C17.2668 5.384 17.384 5.26685 17.4338 5.11755L18.0257 3.34189C18.0937 3.13772 18.2848 3 18.5 3Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
            fill="#F8C81C"
          />
          <path d="M21.5 18.5L18.5 21.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.5 21.5L18.5 18.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <path
            d="M15 3.80269C14.1175 3.29218 13.0929 3 12 3C8.68629 3 6 5.68629 6 9C6 12 9 14 9 16.5V19C9 20.5 10 21 11 21C12 21 12 21 13 21C14 21 15 20.5 15 19V16.5C15 14.9266 16.1883 13.5512 17.0691 12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path d="M9 18H15" stroke="currentColor" strokeWidth="2" />
          <path d="M9 15H15" stroke="currentColor" strokeWidth="2" />
          <path d="M10 10L11 15" stroke="currentColor" strokeWidth="1.5" />
          <path d="M13 15L14 10" stroke="currentColor" strokeWidth="1.5" />
          <path d="M9 10H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5 3C18.7152 3 18.9063 3.13772 18.9743 3.34189L19.5662 5.11754C19.616 5.26685 19.7332 5.384 19.8825 5.43377L21.6581 6.02566C21.8623 6.09372 22 6.28478 22 6.5C22 6.71522 21.8623 6.90628 21.6581 6.97434L19.8825 7.56623C19.7332 7.616 19.616 7.73315 19.5662 7.88246L18.9743 9.65811C18.9063 9.86229 18.7152 10 18.5 10C18.2848 10 18.0937 9.86229 18.0257 9.65811L17.4338 7.88246C17.384 7.73315 17.2668 7.616 17.1175 7.56623L15.3419 6.97434C15.1377 6.90628 15 6.71522 15 6.5C15 6.28478 15.1377 6.09372 15.3419 6.02566L17.1175 5.43377C17.2668 5.384 17.384 5.26685 17.4338 5.11755L18.0257 3.34189C18.0937 3.13772 18.2848 3 18.5 3Z"
            fill="currentColor"
          />
        </>
      )}
    </Icon>
  );
}
