import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleUpdating({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M6 3C6 5.34783 6 6.13043 7 7.69565C8 9.26087 9 9.65217 9 11.6087C9 13.1739 8 13.9565 7 15.5217C6 17.087 6 18.6522 6 21"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M18 3C18 5.34783 18 6.13043 17 7.69565C16 9.26087 15 9.65217 15 11.6087C15 13.1739 16 13.9565 17 15.5217C18 17.087 18 18.6522 18 21"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="4" y="2" width="16" height="2" rx="1" fill="currentColor" />
      <rect x="4" y="20" width="16" height="2" rx="1" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02617 17.9416C8.88268 18.5036 9.34966 19 9.92966 19H14.0701C14.6501 19 15.117 18.5036 14.9736 17.9416C14.5357 16.2266 13.3696 15 11.9999 15C10.6302 15 9.46407 16.2266 9.02617 17.9416Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1144 7C13.8215 7 14.2938 7.73158 13.7895 8.2273C13.2973 8.7112 12.6757 8.99999 12 8.99999C11.3242 8.99999 10.7026 8.7112 10.2104 8.2273C9.7062 7.73158 10.1785 7 10.8856 7H13.1144Z"
        fill="currentColor"
      />
    </Icon>
  );
}
