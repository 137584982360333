import type { ComponentStyleConfig } from '@chakra-ui/theme';

const styles: ComponentStyleConfig = {
  parts: ['list', 'item', 'divider'],
  baseStyle: {
    list: {
      shadow: '8px',
      borderRadius: '4px',
      py: '6px',
      minWidth: '128px',
      maxWidth: '400px',
      maxHeight: '320px',
    },
    item: {
      px: '12px',
      py: '6px',
      // NOTE: Chakra MenuItem using focus to emulate hover behavior,
      //       if you define `_hover` style, it will let `_active` style being overwrite.
      //       According Chakra default theme style, we need to use `_focus` to implement hover style.
      // _hover: {
      //   bg: 'blue.0',
      // },
      _active: {
        bg: 'blue.10',
      },
      _focus: {
        bg: 'blue.0',
      },
    },
    divider: {
      my: '6px',
    },
  },
  sizes: {
    xl: {
      list: {
        width: '400px',
      },
    },
    lg: {
      list: {
        width: '336px',
      },
    },
    md: {
      list: {
        width: '272px',
      },
    },
    sm: {
      list: {
        width: '208px',
      },
    },
    xs: {
      list: {
        width: '144px',
      },
    },
    flex: {},
  },
  defaultProps: {
    size: 'flex',
  },
};

export const rippleMenu = styles;
