import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RipplePolicyChecked({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        id="Scroll body"
        d="M19 10V5C19 4.50265 19.2226 3.63428 20 3.22526M20 3H7C6.33333 3 5 3.4 5 5V17"
        stroke="#80859F"
        strokeWidth="2"
      />
      <path id="Stroke 1" d="M9 7H15" stroke="#80859F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Stroke 2" d="M9 10H12" stroke="#80859F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Stroke 3" d="M9 13H10" stroke="#80859F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        id="Scroll bottom"
        d="M10 17H4C2.89543 17 2 17.8954 2 19C2 20.1046 2.89543 21 4 21H12"
        stroke="#80859F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path id="Scroll ear" d="M20 7C21.1046 7 22 6.10457 22 5C22 3.89543 21.1046 3 20 3" stroke="#80859F" strokeWidth="2" />
      <path
        id="Shield"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6286 10.6716C16.867 10.5763 17.133 10.5763 17.3714 10.6716L21.3714 12.2716C21.751 12.4235 22 12.7912 22 13.2001V16.5231C22 16.5231 22 20.7947 17.2171 21.9725C17.0764 22.0071 16.9236 22.0071 16.7829 21.9725C12 20.7947 12 16.5231 12 16.5231V13.2001C12 12.7912 12.249 12.4235 12.6286 12.2716L16.6286 10.6716ZM20.2593 15.1739C20.6187 14.7545 20.5701 14.1232 20.1508 13.7638C19.7315 13.4044 19.1002 13.4529 18.7407 13.8723L16.4435 16.5524L15.7071 15.816C15.3166 15.4254 14.6834 15.4254 14.2929 15.816C13.9024 16.2065 13.9024 16.8396 14.2929 17.2302L15.7929 18.7302C15.9899 18.9271 16.26 19.033 16.5384 19.0223C16.8167 19.0116 17.078 18.8853 17.2593 18.6739L20.2593 15.1739Z"
        fill="#14C02F"
      />
    </Icon>
  );
}
