import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse } from '@/services/utils';

import { rebootComputerKindSchema } from '../schemas';
import type { RebootComputerOptionMap } from '../types';

const getUrl = (computerId: number) => `/api/web/v1/computers/${computerId}/reboot_computer_option`;

const rebootKindEnum = rebootComputerKindSchema.Enum;

/**
 * NOTE: z.enum does not work well with z.record that will get `Partial<Record<RebootKindEnum, boolean>>` type
 *
 * This schema type should be the same as `RebootComputerOptionMap`
 */
const schema = z.object({
  [rebootKindEnum.restart_streamer]: z.boolean(),
  [rebootKindEnum.normal_reboot]: z.boolean(),
  [rebootKindEnum.safe_mode_reboot]: z.boolean(),
  [rebootKindEnum.shut_down]: z.boolean(),
  [rebootKindEnum.log_off]: z.boolean(),
});

const service = (computerId: number): Promise<RebootComputerOptionMap> => {
  return checkResponse(axios.get(getUrl(computerId)), schema);
};

const errorHandling = {
  resourceNotExist: (response: any) => {
    const errorSchema = commonResponseSchema.merge(z.object({ data: z.object({ error: z.literal('resource_not_exists') }) }));

    return errorSchema.safeParse(response).success;
  },
  noPermission: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40403), data: z.object({ error: z.literal('team_permissions') }) }),
    );

    return errorSchema.safeParse(response).success;
  },
} as const;

export const getRebootComputerOptionMap = {
  getUrl,
  service,
  errorHandling,
};
