import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleOldWindowsNoBorder({ color = 'dark.40' }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M4 6.5V11.5H11V5.625L4 6.5Z" fill="currentColor" />
      <path d="M12 5.5V11.5H20V4.5L12 5.5Z" fill="currentColor" />
      <path d="M12 12.5V18.5L20 19.5V12.5H12Z" fill="currentColor" />
      <path d="M4 12.5V17.5L11 18.375V12.5H4Z" fill="currentColor" />
    </Icon>
  );
}
