import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleRemotePrint({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  const clipId = useId();

  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M6.8673 16L17.1327 16L17.847 21L6.15301 21L6.8673 16Z" stroke="currentColor" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11H20V17H18.2857L18.5714 19H20H22V17V11C22 9.89543 21.1046 9 20 9H4C2.89543 9 2 9.89543 2 11V17V19H4H5.42857L5.71429 17H4V11Z"
        fill="currentColor"
      />
      <rect x="5" y="12" width="2" height="2" rx="1" fill="currentColor" />
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M6 5C9.24706 2.33333 14.6118 2.33333 18 5"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M9 7C10.6842 5.66667 13.3158 5.66667 15 7"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width="14" height="6" fill="white" transform="translate(5 2)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
