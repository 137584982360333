import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleUnreadChat({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        d="M8.8 12.2V11.4H8H4C2.78497 11.4 1.8 10.415 1.8 9.2V4C1.8 2.78497 2.78497 1.8 4 1.8H12C13.215 1.8 14.2 2.78497 14.2 4V8C14.2 9.40236 13.4366 10.7489 12.3088 11.8667C11.2007 12.9649 9.83636 13.7442 8.80816 14.055C8.80352 14.0408 8.8 14.0223 8.8 14V12.2Z"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <rect x="4.19995" y="5.7998" width="1.6" height="1.6" rx="0.8" fill="currentColor" />
      <rect x="7.19995" y="5.7998" width="1.6" height="1.6" rx="0.8" fill="currentColor" />
      <rect x="10.2" y="5.7998" width="1.6" height="1.6" rx="0.8" fill="currentColor" />
      <circle cx="13.5" cy="2.5" r="2.5" fill="#C6002E" />
    </Icon>
  );
}
