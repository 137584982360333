import { ReactNode } from 'react';

import { Box, BoxProps, CSSObject, Flex } from '@chakra-ui/react';

import { RippleAlertSpecificColor, RippleClose } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleInlineButton } from '../RippleInlineButton';
import { RippleTypography, RippleTypographyProps } from '../RippleTypography';

export type RippleBannerVariant = 'primitive' | 'default' | 'success' | 'alert' | 'error';

const variantStyles: Record<RippleBannerVariant, { box: BoxProps; title: Omit<RippleTypographyProps, 'variant'> }> = {
  primitive: {
    box: {
      borderColor: 'dark.90',
      bg: 'dark.10',
    },
    title: {
      color: 'dark.90',
    },
  },
  default: {
    box: {
      borderColor: 'blue.200',
      bg: 'blue.0',
    },
    title: {
      color: 'blue.200',
    },
  },
  success: {
    box: {
      borderColor: 'green.300',
      bg: 'green.10',
    },
    title: {
      color: 'green.300',
    },
  },
  alert: {
    box: {
      borderColor: 'yellow.200',
      bg: 'yellow.10',
    },
    title: {
      color: 'dark.90',
    },
  },
  error: {
    box: {
      borderColor: 'red.200',
      bg: 'red.10',
    },
    title: {
      color: 'red.200',
    },
  },
};

export type RippleBannerProps = {
  children: ReactNode;
  variant: RippleBannerVariant;
  showShadow?: boolean;
  icon?: React.JSX.Element | null;
  button?: {
    name: string;
    onClick: () => void;
  };
  onClose?: () => void;
  sx?: CSSObject;
};

export const RippleBanner = ({
  children,
  variant,
  showShadow = true,
  icon = <RippleAlertSpecificColor />,
  button,
  onClose,
  sx,
}: RippleBannerProps) => {
  const styles = variantStyles[variant];

  return (
    <Flex
      display="inline-flex"
      position="relative"
      justifyContent="space-between"
      alignItems="center"
      borderWidth="1px"
      borderStyle="solid"
      p="8px 12px"
      borderRadius="4px"
      boxShadow={showShadow ? '0px 8px 16px 4px rgba(0, 0, 0, 0.12)' : 'none'}
      sx={sx}
      maxW="800px"
      {...styles.box}
    >
      <Flex alignItems="center" py="4px">
        {icon && <Box flexShrink={0}>{icon}</Box>}
        <RippleTypography variant="body02" {...styles.title} mx="8px">
          {children}
        </RippleTypography>
      </Flex>

      <Flex alignItems="center">
        {button && (
          <RippleInlineButton className="button" onClick={button.onClick}>
            {button.name}
          </RippleInlineButton>
        )}
        {onClose && <RippleIconButton aria-label="close" ml="16px" icon={<RippleClose />} onClick={onClose} />}
      </Flex>
    </Flex>
  );
};
