import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleChat({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M13 19V18H12H7C4.79086 18 3 16.2091 3 14V8C3 5.79086 4.79086 4 7 4H17C19.2091 4 21 5.79086 21 8V13C21 14.947 19.998 16.8064 18.5053 18.3711C17.0157 19.9327 15.1328 21.0962 13.6055 21.6502C13.4429 21.7092 13.3327 21.6756 13.2356 21.5921C13.1172 21.4904 13 21.2845 13 21V19Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="7" y="10" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="11" y="10" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="15" y="10" width="2" height="2" rx="1" fill="currentColor" />
    </Icon>
  );
}
