import React from 'react';

import { forwardRef } from '@chakra-ui/react';

import { RippleTypography } from '../RippleTypography';
import type { RippleTypographyProps } from '../RippleTypography';

type StrongVariants = 'strong01' | 'strong02' | 'strong03';

const typographyVariantMap: Record<StrongVariants, RippleTypographyProps['variant']> = {
  strong01: 'body01',
  strong02: 'body02',
  strong03: 'body03',
};

export type RippleStrongProps = Omit<RippleTypographyProps, 'variant'> & { variant: StrongVariants };

export const RippleStrong = forwardRef(({ variant, children, ...otherProps }: RippleStrongProps, ref): React.JSX.Element => {
  return (
    <RippleTypography ref={ref} as="strong" variant={typographyVariantMap[variant]} fontWeight="600" color="dark.90" {...otherProps}>
      {children}
    </RippleTypography>
  );
});
