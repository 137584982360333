import type { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';

const variantPrimary: SystemStyleFunction = (props) => {
  const { colorScheme } = props;

  const styleMap = {
    darkBlue: {
      bg: 'blue.300',
      borderColor: 'blue.300',
      color: 'white',
    },
    success: {
      bg: 'green.10',
      borderColor: 'green.10',
      color: 'green.300',
    },
    danger: {
      bg: 'red.10',
      borderColor: 'red.10',
      color: 'red.200',
    },
    disabled: {
      bg: 'dark.10',
      borderColor: 'dark.10',
      color: 'dark.40',
    },
    grey: {
      bg: 'dark.10',
      borderColor: 'dark.10',
      color: 'dark.90',
    },
    yellow: {
      bg: 'yellow.100',
      borderColor: 'yellow.100',
      color: 'dark.90',
    },
    green: {
      bg: 'green.40',
      borderColor: 'green.40',
      color: 'dark.90',
    },
    magenta: {
      bg: 'magenta.20',
      borderColor: 'magenta.20',
      color: 'dark.90',
    },
    violet: {
      bg: 'blue.40',
      borderColor: 'blue.40',
      color: 'dark.90',
    },
    lightBlue: {
      bg: 'lightBlue',
      borderColor: 'lightBlue',
      color: 'dark.90',
    },
    orange: {
      bg: 'orange.200',
      borderColor: 'orange.200',
      color: 'white',
    },
    new: {
      bg: 'red.100',
      borderColor: 'red.100',
      color: 'white',
    },
    dark: {
      bg: 'dark.80',
      borderColor: 'dark.80',
      color: 'white',
    },
  };

  return styleMap[colorScheme as keyof typeof styleMap] ?? {};
};

const variantSecondary: SystemStyleFunction = (props) => {
  const { colorScheme } = props;
  const styleMap = {
    blue: {
      borderColor: 'blue.60',
      color: 'blue.300',
    },
    success: {
      borderColor: 'green.200',
      color: 'green.300',
    },
    danger: {
      borderColor: 'red.80',
      color: 'red.300',
    },
    grey: {
      borderColor: 'dark.20',
      color: 'dark.300',
    },
    yellow: {
      borderColor: 'yellow.200',
      color: 'dark.300',
    },
  };
  return styleMap[colorScheme as keyof typeof styleMap] ?? {};
};

const baseStyle: SystemStyleObject = {
  textTransform: 'none',
  border: 'solid 1px',
};

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
};

const sizes: Record<string, SystemStyleObject> = {
  normal: {
    padding: '1px 10px',
    borderRadius: '12px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  inline: {
    padding: '1px 8px',
    borderRadius: '12px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  tiny: {
    padding: '2px 4px',
    borderRadius: '8px',
    fontSize: '8px',
    fontWeight: 600,
    lineHeight: '10px',
  },
};

const defaultProps = {
  variant: 'primary',
  size: 'normal',
  colorScheme: 'darkBlue',
};

const styles = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export const rippleBadge = styles;
