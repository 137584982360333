import { forwardRef, useImperativeHandle } from 'react';

import { Stack, StackProps } from '@chakra-ui/react';
import {
  type ColumnDef,
  type Row,
  RowModel,
  Table,
  type TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ValueIteratee } from 'lodash';
import groupBy from 'lodash/groupBy';

import { RippleTBody, RippleTD, RippleTH, RippleTHead, RippleTR, RippleTable, RippleTableGroupHead, RippleTableProps } from './RippleTable';

/**
 * Props for the RippleGroupedReactTable component.
 */
export type RippleGroupedReactTableProps<T> = RippleTableProps & {
  /** The data to be displayed in the table. */
  data: Array<T>;
  /** The react-table's columns props. */
  columns: Array<ColumnDef<T, any>>;
  /** The key to group the data by. */
  groupByFn: ValueIteratee<Row<T>>;
  /** The component to render the group header. */
  groupHeader: ({ rows }: { rows: Array<Row<T>> }) => React.JSX.Element;
  /** The component to render the group empty content. */
  groupEmptyContent: () => React.JSX.Element;
  /** The gap between the grouped row in the table. */
  gap?: StackProps['gap'];
  /** The options for the react-table. */
  tableOptions?: Omit<TableOptions<T>, 'data' | 'columns' | 'getCoreRowModel'>;
};

/**
 * A table component that groups data by a specified key.
 */
export const RippleGroupedReactTable = forwardRef(function <T = unknown>(
  {
    data,
    columns,
    groupByFn,
    groupHeader,
    groupEmptyContent,
    gap = '16px',
    tableOptions = {},
    ...tableProps
  }: Readonly<RippleGroupedReactTableProps<T>>,
  ref: React.Ref<{ getTable: () => Table<T> }>,
) {
  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    columns,
    ...tableOptions,
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        getTable() {
          return table;
        },
      };
    },
    [table],
  );

  return (
    <RippleTable {...tableProps}>
      <RippleTHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <RippleTR key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <RippleTH
                key={header.id}
                width={`${header.column.columnDef.size}px`}
                isSortable={header.column.getCanSort()}
                sortDirection={header.column.getIsSorted()}
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </RippleTH>
            ))}
          </RippleTR>
        ))}
      </RippleTHead>
      <Stack gap={gap}>
        <GroupedRows rowModel={table.getRowModel()} groupByFn={groupByFn} groupHeader={groupHeader} groupEmptyContent={groupEmptyContent} />
      </Stack>
    </RippleTable>
  );
}) as <T = unknown>(props: RippleGroupedReactTableProps<T> & { ref?: React.Ref<{ getTable: () => Table<T> }> }) => React.JSX.Element;

/**
 * Props for the GroupedRows component.
 */
type GroupedRowsProps<T> = {
  // The row model of the react-table.
  rowModel: RowModel<T>;
  // The key to group the data by.
  groupByFn: ValueIteratee<Row<T>>;
  // The component to render the group header.
  groupHeader: ({ rows }: { rows: Array<Row<T>> }) => React.JSX.Element;
  // The component to render the group empty content.
  groupEmptyContent: () => React.JSX.Element;
};

/**
 * A component that renders the grouped rows in the table.
 */
export function GroupedRows<T>({
  rowModel,
  groupByFn,
  groupHeader: GroupHeader,
  groupEmptyContent: GroupEmptyContent,
}: Readonly<GroupedRowsProps<T>>) {
  const rows = rowModel.rows;
  const grouped = groupBy<Row<T>>(rows, groupByFn);
  return (
    <Stack gap="16px">
      {/* TODO: Handle group sorting here */}
      {Object.entries(grouped).map(([key, groupedRows]) => {
        return (
          <RippleTBody key={key}>
            <RippleTableGroupHead textTransform="capitalize">
              <GroupHeader rows={groupedRows} />
            </RippleTableGroupHead>
            {groupedRows.length === 0 ? (
              <GroupEmptyContent />
            ) : (
              // TODO: handle row sorting here
              groupedRows.map((row) => {
                return (
                  <RippleTR key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <RippleTD key={cell.id} width={`${cell.column.columnDef.size}px`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </RippleTD>
                    ))}
                  </RippleTR>
                );
              })
            )}
          </RippleTBody>
        );
      })}
    </Stack>
  );
}
