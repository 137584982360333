import { forwardRef, useImperativeHandle } from 'react';

import { ColumnDef, Table, TableOptions, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { RippleTBody, RippleTD, RippleTH, RippleTHead, RippleTR, RippleTable, RippleTableProps } from './RippleTable';

/**
 * `RippleReactTable` is a component that wraps the `@tanstack/react-table` and `RippleTable` components.
 * It applies our design system and renders a table based on the provided data and columns props.
 *
 * @component
 * @param {data} props - The data to be displayed in the table.
 * @param {columns} props - The react-table's columns props.
 * @param {variant} props - The variant of table.
 * @param {TableOptions} props.tableOptions - The properties that define the data and structure of the table. These are passed directly to the `useReactTable` hook
 * @returns {React.JSX.Element} A table element rendered with the `RippleTable` component.
 *
 * @example
 * // Data for the table
 * const data = [
 *   { firstName: 'John', lastName: 'Doe' },
 *   { firstName: 'Jane', lastName: 'Doe' },
 * ];
 *
 * // Columns for the table
 * const columns = [
 *   { Header: 'First Name', accessor: 'firstName' },
 *   { Header: 'Last Name', accessor: 'lastName' },
 * ];
 *
 * <RippleReactTable data={data} columns={columns} />
 */
type RippleReactTableProps<T> = RippleTableProps & {
  data: Array<T>;
  columns: Array<ColumnDef<T, any>>;
  tableOptions?: Omit<TableOptions<T>, 'getCoreRowModel' | 'data' | 'columns'>;
};
export const RippleReactTable = forwardRef(function <T = unknown>(
  { data, columns, tableOptions = {}, ...tableProps }: RippleReactTableProps<T>,
  ref: React.Ref<{ getTable: () => Table<T> }>,
) {
  const table = useReactTable({
    data,
    columns,
    ...tableOptions,
    getCoreRowModel: getCoreRowModel(),
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        getTable() {
          return table;
        },
      };
    },
    [table],
  );

  return (
    <RippleTable {...tableProps}>
      <RippleTHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <RippleTR key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <RippleTH
                key={header.id}
                width={`${header.column.columnDef.size}px`}
                isSortable={header.column.getCanSort()}
                sortDirection={header.column.getIsSorted()}
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </RippleTH>
            ))}
          </RippleTR>
        ))}
      </RippleTHead>
      <RippleTBody>
        {table.getRowModel().rows.map((row) => (
          <RippleTR key={row.id} isSelected={row.getIsSelected()}>
            {row.getVisibleCells().map((cell) => (
              <RippleTD key={cell.id} width={`${cell.column.columnDef.size}px`}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </RippleTD>
            ))}
          </RippleTR>
        ))}
      </RippleTBody>
    </RippleTable>
  );
}) as <T = unknown>(props: RippleReactTableProps<T> & { ref?: React.Ref<{ getTable: () => Table<T> }> }) => React.JSX.Element;
