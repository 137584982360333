import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleDownloadAvailable({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM16.7071 13.7071C17.0976 13.3166 17.0976 12.6834 16.7071 12.2929C16.3166 11.9024 15.6834 11.9024 15.2929 12.2929L13 14.5858V7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V14.5858L8.70711 12.2929C8.31658 11.9024 7.68342 11.9024 7.29289 12.2929C6.90237 12.6834 6.90237 13.3166 7.29289 13.7071L11.2922 17.7064C11.2946 17.7088 11.297 17.7112 11.2995 17.7136C11.4792 17.8901 11.7254 17.9992 11.997 18C11.998 18 11.999 18 12 18C12.001 18 12.002 18 12.003 18C12.1375 17.9996 12.2657 17.9727 12.3828 17.9241C12.4999 17.8757 12.6096 17.804 12.705 17.7092M16.7071 13.7071L12.7076 17.7066Z"
        fill="currentColor"
      />
    </Icon>
  );
}
