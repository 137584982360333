import { Icon } from '@chakra-ui/react';

import { RippleIconProps } from './types';

export const RippleSortOptionAsc16 = ({ color = 'neutral.300' }: RippleIconProps) => {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path d="M10 5L12 3L14 5" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 13V3" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M7 13V6" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M2 13V9" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
    </Icon>
  );
};

export const RippleSortOptionDesc16 = ({ color = 'neutral.300' }: RippleIconProps) => {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path d="M2 11L4 13L6 11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 13V3" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M9 13V6" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M14 13V9" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
    </Icon>
  );
};
