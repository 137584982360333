import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  Portal,
  SystemStyleObject,
  forwardRef,
  useControllableState,
  useDisclosure,
  useFormControl,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import type { MenuProps, StyleProps, UseFormControlProps } from '@chakra-ui/react';
import { Virtuoso } from 'react-virtuoso';

import { RippleArrowDown16, RippleArrowUp16, RippleOK } from '../RippleIcon';
import { RippleMenu, RippleMenuButton, RippleMenuDivider, RippleMenuGroup, RippleMenuList } from '../RippleMenu';
import { RipplePopover, RipplePopoverContent, RipplePopoverTrigger } from '../RipplePopover';
import { RippleTypography } from '../RippleTypography';
import { RippleButton, RippleButtonProps } from './../RippleButton';
import { RippleSelectContext, RippleSelectContextProvider, RippleSelectLabel, RippleSelectOption, useIsSelected } from './RippleSelect';
import type { RippleSelectOptionProps } from './RippleSelect';
import { RippleSelectButtonWithContext } from './RippleSelectButton';

export type RippleSingleSelectProps<T extends string = string> = Omit<RippleButtonProps, 'onChange' | 'placeholder' | 'variant'> & {
  children: React.ReactNode;
  value: T | undefined;
  placeholder: React.ReactNode;
  onChange: (value: T | undefined) => void;
  variant?: 'border' | 'borderless';
  size?: 'lg' | 'md' | 'sm' | 'xs';
  helperText?: string;
  boxSx?: SystemStyleObject;
  menuListSx?: SystemStyleObject;
  menuListProps?: {
    appendUnderPortal?: boolean; // Whether the menu list should be appended to the portal. To Prevent Select is under container with overflow: 'hidden'
  };
  menuProps?: {
    isOpen?: MenuProps['isOpen'];
    placement?: MenuProps['placement'];
  };
  /**
   * The options' max width when
   * @note Discussed with the design team, currently we don't have a dropdown like component. So we modified the select achieve the same result.
   */
  optionMaxWidth?: StyleProps['width'];
  customLabel?: string;
} & StyleProps &
  UseFormControlProps<HTMLButtonElement>;

function RippleSingleSelectCore(props: RippleSingleSelectProps, ref: React.ForwardedRef<any>) {
  const {
    children,
    value,
    placeholder,
    onChange,
    variant = 'border',
    size = 'sm',
    helperText,
    boxSx: _boxSx, // shouldn't pass into RippleMenuButton
    menuListSx,
    menuListProps,
    menuProps: _, // shouldn't pass into RippleMenuButton
    optionMaxWidth,
    customLabel,
    ...menuButtonProps
  } = props;

  const {
    menuListProps: _menuListProps,
    menuProps,
    boxSx,
    menuListSx: _menuListSx,
    customLabel: _customLabel,
    ...formControlProps
  } = props;

  const styles = useMultiStyleConfig('rippleSelect', { variant, size });

  const formProps = useFormControl<HTMLButtonElement>(formControlProps);
  const { id: _id, onFocus: _onFocus, onBlur: _onBlur, ...helperTextProps } = formProps;

  const MenuList = (
    <RippleMenuList maxWidth={optionMaxWidth ?? '100%'} zIndex="dropdown" position="relative" sx={menuListSx}>
      {children}
    </RippleMenuList>
  );

  return (
    <RippleSelectContextProvider type="single" value={value} onChange={onChange}>
      <Flex direction="column" sx={boxSx}>
        <RippleMenu
          closeOnSelect
          closeOnBlur
          matchWidth={typeof optionMaxWidth === 'undefined'}
          isOpen={menuProps?.isOpen}
          placement={menuProps?.placement ?? 'bottom'}
        >
          <RippleMenuButton ref={ref} as={RippleSelectButtonWithContext} {...formProps} {...menuButtonProps}>
            <RippleSelectLabel placeholder={placeholder} customLabel={customLabel} />
          </RippleMenuButton>

          {menuListProps?.appendUnderPortal ? <Portal>{MenuList}</Portal> : MenuList}
        </RippleMenu>

        {helperText && (
          <RippleTypography variant="body03" __css={styles.helperText} {...helperTextProps}>
            {helperText}
          </RippleTypography>
        )}
      </Flex>
    </RippleSelectContextProvider>
  );
}

export const RippleSingleSelect = forwardRef(RippleSingleSelectCore) as <T extends string>(
  props: RippleSingleSelectProps<T> & { ref?: React.ForwardedRef<any> },
) => React.JSX.Element;

export type RippleSingleSelectOptionProps = RippleSelectOptionProps;
export const RippleSingleSelectOption = RippleSelectOption;

export const RippleSingleSelectOptionGroup = RippleMenuGroup;

export const RippleSingleSelectOptionDivider = RippleMenuDivider;

export type UseRippleSingleSelectOptions<T extends string> = {
  defaultValue?: T | undefined;
  onChange?: (value: T | undefined) => void;
};

export type UseRippleSingleSelectReturn<T extends string> = {
  value: T | undefined;
  setValue: React.Dispatch<React.SetStateAction<T | undefined>>;
  selectProps: {
    value: T | undefined;
    onChange: (value: T | undefined) => void;
  };
};

export function useRippleSingleSelect<T extends string = string>({
  defaultValue,
  onChange,
}: UseRippleSingleSelectOptions<T> = {}): UseRippleSingleSelectReturn<T> {
  const [value, setValue] = useControllableState<T | undefined>({
    defaultValue,
    onChange,
  });

  return {
    value,
    setValue,
    selectProps: {
      value,
      onChange: setValue,
    },
  };
}

export type RippleSingleSelectVirtuosoProps<D> = {
  value: string | undefined;
  valueLabel: string | undefined;
  onChange: (value: string | undefined) => void;
  height: number;
  data: Array<D>;
  itemContent: (index: number, data: D, onClose: () => void) => React.ReactNode;
};

export const RippleSingleSelectVirtuoso = <D,>(props: RippleSingleSelectVirtuosoProps<D>) => {
  const { value, onChange, valueLabel, height, data, itemContent } = props;
  const { isOpen, onToggle, onClose } = useDisclosure();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      const width = buttonRef.current?.getBoundingClientRect()?.width;
      if (width) {
        setWidth(width);
      }
    };
    updateWidth();

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        updateWidth();
      });
    });

    if (buttonRef.current) {
      resizeObserver.observe(buttonRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <RippleSelectContextProvider type="single" value={value} onChange={onChange}>
      <RipplePopover placement="bottom-start" isOpen={isOpen} onClose={onClose}>
        <RipplePopoverTrigger>
          <RippleButton
            ref={buttonRef}
            onClick={onToggle}
            variant="secondary"
            color="dark.100"
            borderColor="neutral.300"
            width="100%"
            justifyContent="space-between"
            _hover={{
              bg: 'white',
            }}
            _focus={{
              boxShadow: '',
            }}
          >
            <Box as="span" width="calc(100% - 40px)" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              {valueLabel}
            </Box>
            {isOpen ? <RippleArrowUp16 color="dark.100" /> : <RippleArrowDown16 color="dark.100" />}
          </RippleButton>
        </RipplePopoverTrigger>
        <RipplePopoverContent width={`${width}px`} py="6px">
          <Virtuoso style={{ height }} data={data} itemContent={(index, data) => itemContent(index, data, onClose)} />
        </RipplePopoverContent>
      </RipplePopover>
    </RippleSelectContextProvider>
  );
};

export const RippleSingleSelectVirtuosoOption = ({
  children,
  value,
  onClick,
  ...otherProps
}: { children: ReactNode; value: string } & RippleButtonProps) => {
  const { onSelect } = useContext(RippleSelectContext);

  const isSelected = useIsSelected({ value });

  return (
    <RippleButton
      width="100%"
      color="dark.100"
      height="36px"
      justifyContent="space-between"
      bg="white"
      borderColor="white"
      _hover={{ bg: 'blue.0' }}
      fontWeight={isSelected ? 600 : 400}
      _focus={{
        boxShadow: '',
      }}
      onClick={(event) => {
        onSelect(value);
        onClick?.(event);
      }}
      {...otherProps}
    >
      <Box as="span" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        {children}
      </Box>
      {isSelected && <RippleOK color="blue.100" />}
    </RippleButton>
  );
};
