import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleAlignLeft({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C5.44772 2 5 2.44772 5 3C5 3.55228 5.44772 4 6 4H18C18.5523 4 19 3.55228 19 3C19 2.44772 18.5523 2 18 2H6ZM6 14C5.44772 14 5 14.4477 5 15C5 15.5523 5.44772 16 6 16H18C18.5523 16 19 15.5523 19 15C19 14.4477 18.5523 14 18 14H6ZM5 9C5 8.44772 5.44772 8 6 8H14C14.5523 8 15 8.44772 15 9C15 9.55228 14.5523 10 14 10H6C5.44772 10 5 9.55228 5 9ZM6 20C5.44772 20 5 20.4477 5 21C5 21.5523 5.44772 22 6 22H14C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20H6Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
