import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleUserAdd({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M12 14C6.5 14 5.00001 17 5.00001 18.375C5.00001 20.5 7 21 11.5 21H12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="17" y="14" width="2" height="8" rx="1" fill="currentColor" />
      <rect x="14" y="17" width="8" height="2" rx="1" fill="currentColor" />
    </Icon>
  );
}
