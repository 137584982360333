import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: {
    body: {
      fontFamily: "'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
  },
};

export default styles;
