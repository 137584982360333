import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

const getUrl = (computerId: number) => `/api/web/v1/computers/${computerId}`;

const schema = z.object({});

const service = (computerId: number) => checkResponse(axios.delete(getUrl(computerId)), schema);

export const deleteComputer = {
  getUrl,
  service,
};
