import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleArrowLeft16 } from '../RippleIcon';

export type RippleBackButtonProps = Omit<ButtonProps, 'variant'>;

export const RippleBackButton = forwardRef<RippleBackButtonProps, 'button'>(({ ...otherProps }, ref) => {
  const { t } = useTranslation();
  return (
    <Button
      ref={ref}
      fontSize="14px"
      leftIcon={<RippleArrowLeft16 />}
      iconSpacing="4px"
      bg="white"
      border="1px solid"
      borderColor="neutral.300"
      borderRadius="4px"
      color="neutral.300"
      p="4px 10px 4px 4px"
      h="30px"
      _hover={{ bg: 'blue.0' }}
      _active={{ bg: 'blue.10' }}
      {...otherProps}
    >
      {t('common:back')}
    </Button>
  );
});
