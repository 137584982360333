import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowLeft16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        d="M10.7819 12.3767C11.1262 12.8085 11.0552 13.4377 10.6233 13.782C10.1915 14.1262 9.56228 14.0552 9.21802 13.6234L5.21802 8.60567C4.92647 8.23995 4.92737 7.72099 5.22018 7.35628L9.22018 2.37398C9.56593 1.94331 10.1953 1.87448 10.626 2.22023C11.0567 2.56599 11.1255 3.1954 10.7797 3.62607L7.28059 7.98453L10.7819 12.3767Z"
        fill="currentColor"
      />
    </Icon>
  );
}
