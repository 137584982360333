import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCalendar({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="4" y="5" width="16" height="15" rx="1" stroke="currentColor" strokeWidth="2" />
      <rect x="5" y="8" width="14" height="2" fill="currentColor" />
      <rect x="7" y="2" width="2" height="4" rx="1" fill="currentColor" />
      <rect x="15" y="2" width="2" height="4" rx="1" fill="currentColor" />
      <rect x="7" y="12" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="7" y="15" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="11" y="12" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="11" y="15" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="15" y="12" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="15" y="15" width="2" height="2" rx="1" fill="currentColor" />
    </Icon>
  );
}
