import styled from '@emotion/styled';

import Popple from './Popple';

const ScreenCenter = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FullPageLoading = () => {
  return (
    <ScreenCenter>
      <Popple />
    </ScreenCenter>
  );
};

export default FullPageLoading;
