import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleColumn({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="4" y="5" width="16" height="14" rx="1" stroke="currentColor" strokeWidth="2" />
      <rect x="14" y="5" width="2" height="14" fill="currentColor" />
      <rect x="8" y="5" width="2" height="14" fill="currentColor" />
    </Icon>
  );
}
