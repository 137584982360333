import { Icon } from '@chakra-ui/react';

export function RippleCalendarChecked() {
  return (
    <Icon width="64px" height="64px" fill="none" viewBox="0 0 64 64">
      <path
        d="M36 53H15C12.2386 53 10 50.7614 10 48V18C10 15.2386 12.2386 13 15 13H49C51.7614 13 54 15.2386 54 18V36"
        stroke="#494949"
        stroke-width="4"
        stroke-linecap="round"
      />
      <rect x="12" y="22" width="40" height="4" fill="#494949" />
      <rect x="20" y="8" width="4" height="11" rx="2" fill="#494949" />
      <rect x="40" y="8" width="4" height="11" rx="2" fill="#494949" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61 51C61 56.5228 56.5228 61 51 61C45.4772 61 41 56.5228 41 51C41 45.4772 45.4772 41 51 41C56.5228 41 61 45.4772 61 51ZM56.7071 48.7071C57.0976 48.3166 57.0976 47.6834 56.7071 47.2929C56.3166 46.9024 55.6834 46.9024 55.2929 47.2929L49 53.5858L46.7071 51.2929C46.3166 50.9024 45.6834 50.9024 45.2929 51.2929C44.9024 51.6834 44.9024 52.3166 45.2929 52.7071L48.2929 55.7071C48.6834 56.0976 49.3166 56.0976 49.7071 55.7071L56.7071 48.7071Z"
        fill="#14C02F"
      />
      <rect x="18" y="30" width="6" height="6" rx="2" fill="#494949" />
      <rect x="29" y="30" width="6" height="6" rx="2" fill="#494949" />
      <rect x="40" y="30" width="6" height="6" rx="2" fill="#494949" />
      <rect x="18" y="41" width="6" height="6" rx="2" fill="#494949" />
      <rect x="29" y="41" width="6" height="6" rx="2" fill="#494949" />
    </Icon>
  );
}
