import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleMacNoBorder({ color = '#1C1C1C' }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M16.3797 11.5C16.3869 10.8875 16.5494 10.2869 16.8519 9.75433C17.1545 9.22178 17.5872 8.7747 18.1097 8.45496C17.778 7.98243 17.3412 7.59333 16.8337 7.31829C16.3261 7.04326 15.7616 6.88978 15.1847 6.86996C13.9397 6.74496 12.7547 7.60496 12.1197 7.60496C11.4847 7.60496 10.5147 6.88996 9.47966 6.90496C8.80222 6.9242 8.1413 7.11852 7.56125 7.469C6.98119 7.81949 6.50177 8.31419 6.16966 8.90496C4.75966 11.355 5.80966 14.98 7.16966 16.97C7.83966 17.94 8.64466 19.03 9.69466 18.97C10.7447 18.91 11.0897 18.315 12.3147 18.315C13.5397 18.315 13.8997 19 14.9697 19C16.0397 19 16.7547 18 17.4197 17.035C17.891 16.3254 18.2596 15.5527 18.5147 14.74C17.8831 14.4648 17.3452 14.0121 16.9661 13.4369C16.5871 12.8617 16.3834 12.1888 16.3797 11.5V11.5Z"
        fill="currentColor"
      />
      <path
        d="M14.3646 5.555C14.6653 5.20516 14.8939 4.79934 15.0372 4.36085C15.1805 3.92237 15.2357 3.45989 15.1996 3C14.2885 3.08657 13.4455 3.51972 12.8446 4.21C12.5476 4.546 12.3212 4.93819 12.1786 5.36333C12.036 5.78847 11.9803 6.23791 12.0146 6.685C12.4666 6.68857 12.9134 6.58859 13.3207 6.39271C13.7281 6.19684 14.0852 5.91028 14.3646 5.555V5.555Z"
        fill="currentColor"
      />
    </Icon>
  );
}
