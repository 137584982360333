export type OS = 'Mac' | 'iOS' | 'Windows' | 'Android' | 'ChromeOS' | 'Linux' | null;

function getOS(): OS {
  const { userAgent, platform, maxTouchPoints } = window.navigator;
  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  let os: OS = null;

  if (iosPlatforms.includes(platform) || (platform === 'MacIntel' && maxTouchPoints > 1)) {
    os = 'iOS';
  } else if (macPlatforms.includes(platform)) {
    os = 'Mac';
  } else if (windowsPlatforms.includes(platform)) {
    os = 'Windows';
  } else if (platform.includes('Android') || userAgent.includes('Android')) {
    os = 'Android';
  } else if (userAgent.includes('CrOS')) {
    os = 'ChromeOS';
  } else if (!os && platform.includes('Linux')) {
    os = 'Linux';
  }

  return os;
}

export default getOS;
