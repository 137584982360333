import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

const getUrl = (computerId: number) => `/api/web/v1/computers/${computerId}/stop_sharing_with_me`;

const schema = z.object({});

const service = (computerId: number) => checkResponse(axios.delete(getUrl(computerId)), schema);

/**
 * Remove external shared computer from other team's user
 *
 * NOTE: This service is only for SBA team
 *       The computer must be from external shared (check `from_external_shared` field in the computer data and should be `true`)
 */
export const removeExternalSharedComputer = {
  getUrl,
  service,
};
