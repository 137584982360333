import React from 'react';

import { Portal, forwardRef, useFormControl } from '@chakra-ui/react';
import type { StyleProps, UseFormControlProps } from '@chakra-ui/react';

import { RippleMenu, RippleMenuButton, RippleMenuDivider, RippleMenuGroup, RippleMenuList, RippleMenuProps } from '../RippleMenu';
import { RippleSelectContextProvider, RippleSelectLabel, RippleSelectOption, RippleSelectOptionProps } from '../RippleSelect';
import { RippleInlineSelectButton, RippleInlineSelectButtonWithMenuContext } from './RippleInlineSelectButton';

export type RippleInlineSelectProps<T extends string = string> = {
  children: React.ReactNode;
  value: T | undefined;
  placeholder: string;
  customLabel?: string | React.ReactNode;
  onChange: (value: T | undefined) => void;
  variant?: 'standard' | 'grayScale';
  size?: 'md' | 'sm';
  menuSize?: RippleMenuProps['size'];
  menuPlacement?: RippleMenuProps['placement'];
  isDisabled?: boolean;
} & StyleProps &
  UseFormControlProps<HTMLButtonElement>;

function RippleInlineSelectCore(props: RippleInlineSelectProps, ref: React.ForwardedRef<any>) {
  const { menuSize, menuPlacement, customLabel, ...menuContentProps } = props;
  const { children, value, placeholder, onChange, variant = 'standard', size = 'md', isDisabled, ...otherProps } = menuContentProps;
  const formProps = useFormControl<HTMLButtonElement>({ ...menuContentProps, disabled: isDisabled });

  return (
    <RippleSelectContextProvider type="single" value={value} onChange={onChange}>
      <RippleMenu closeOnSelect closeOnBlur size={menuSize} placement={menuPlacement || 'bottom-end'}>
        <RippleMenuButton
          ref={ref}
          as={RippleInlineSelectButtonWithMenuContext}
          variant={variant}
          size={size}
          {...formProps}
          {...otherProps}
        >
          <RippleSelectLabel placeholder={placeholder} customLabel={customLabel} />
        </RippleMenuButton>
        <Portal>
          <RippleMenuList zIndex="popover">{children}</RippleMenuList>
        </Portal>
      </RippleMenu>
    </RippleSelectContextProvider>
  );
}

export const RippleInlineSelect = forwardRef(RippleInlineSelectCore) as <T extends string>(
  props: RippleInlineSelectProps<T> & { ref?: React.ForwardedRef<any> },
) => React.JSX.Element;

export type RippleInlineSelectOptionProps = RippleSelectOptionProps;
export const RippleInlineSelectOption = RippleSelectOption;

export const RippleInlineSelectOptionGroup = RippleMenuGroup;

export const RippleInlineSelectOptionDivider = RippleMenuDivider;

export { RippleInlineSelectButtonWithMenuContext, RippleInlineSelectButton };
