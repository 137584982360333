import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse } from '@/services/utils';

import type { RebootComputerKind } from '../types';

const getUrl = () => '/api/web/v1/computers/reboot';

const schema = z.object({});

function service(computerIdList: Array<number>, rebootOption: RebootComputerKind) {
  return checkResponse(axios.post(getUrl(), { server_ids: computerIdList, reboot_option: rebootOption }), schema);
}

const errorHandling = {
  /**
   * If one of the server_ids is not allowed for current_user, do nothing, and return target_not_allow.
   */
  targetNotAllow: (response: any) => {
    const schema = commonResponseSchema.merge(
      z.object({
        result: z.literal(40403),
        data: z.object({
          error: z.literal('target_not_allow'),
        }),
      }),
    );

    return schema.safeParse(response).success;
  },
} as const;

export const batchRebootComputers = {
  getUrl,
  service,
  errorHandling,
};
