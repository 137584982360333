export const textStyles = {
  h1: {
    fontSize: '3.75rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '4.5rem',
    letterSpacing: '-1.6px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h2: {
    fontSize: '3rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '3.5rem',
    letterSpacing: '-1.6px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h3: {
    fontSize: '2.5rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '3rem',
    letterSpacing: '-0.4px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h4: {
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '2.5rem',
    letterSpacing: '-0.32px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h5: {
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.875rem',
    letterSpacing: '-0.24px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h6: {
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.625rem',
    letterSpacing: '-0.16px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h7: {
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.5rem',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h8: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.125rem',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'dark.90',
  },
  h9: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.125rem',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'dark.90',
  },
  body1: {
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'dark.90',
  },
  body2: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'dark.90',
  },
  body3: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.125rem',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'dark.90',
  },
  buttonText: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.125rem',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'dark.90',
  },
} as const;

export type TextStylesNames = typeof textStyles;
