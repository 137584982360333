import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleOsSoftwarePatch({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M11 17.5C11 19.7091 13.0147 21.5 15.5 21.5C17.7948 21.5 19.6884 19.9732 19.9652 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.9964 15C19.9964 12.7909 17.9817 11 15.4964 11C13.2016 11 11.3081 12.5268 11.0312 14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M21 11.5V14.5C21 14.7761 20.7761 15 20.5 15H18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M10 21V18C10 17.7239 10.2239 17.5 10.5 17.5H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 7.5H21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M7 19H5.4C4.07452 19 3 17.9767 3 16.7143V5.28571C3 4.02335 4.07452 3 5.4 3H18.6C19.9255 3 21 4.02335 21 5.28571V8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Icon>
  );
}
