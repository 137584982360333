import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleBulk({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="3" y="7" width="14" height="14" rx="2" stroke="currentColor" strokeWidth="2" />
      <path
        d="M7 4V4C7 3.44772 7.44772 3 8 3H18C19.6569 3 21 4.34315 21 6V16C21 16.5523 20.5523 17 20 17V17"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M13.5 11.5L8.5 16.5L6.5 14.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
