import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleFilter({ color = DEFAULT_ICON_COLOR, isApplied = false }: RippleIconProps & { isApplied?: boolean }) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="4" y="5" width="16" height="2" rx="1" fill="currentColor" />
      <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" />
      <rect x="9" y="17" width="6" height="2" rx="1" fill="currentColor" />
      {isApplied && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 24C21.7614 24 24 21.7614 24 19C24 16.2386 21.7614 14 19 14C16.2386 14 14 16.2386 14 19C14 21.7614 16.2386 24 19 24ZM21.7593 18.1508C22.1187 17.7315 22.0701 17.1002 21.6508 16.7407C21.2315 16.3813 20.6002 16.4299 20.2407 16.8492L17.9435 19.5293L17.2071 18.7929C16.8166 18.4024 16.1834 18.4024 15.7929 18.7929C15.4024 19.1834 15.4024 19.8166 15.7929 20.2071L17.2929 21.7071C17.4899 21.9041 17.76 22.01 18.0384 21.9993C18.3167 21.9886 18.578 21.8623 18.7593 21.6508L21.7593 18.1508Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
}
