import { OTableResult, OTableRowOrGroup, OTableSourceData, OTableSourceGroup } from '@/components/ObservableTable/types';
import { OTableRowTypes } from '@/components/ObservableTable/useObservableTable';
import { arrayToHashTable } from '@/utils/hashTable';

export type OTableRowHashMap<D extends OTableSourceData> = Record<string, D>;

export function formatSourceData<D extends OTableSourceData, G extends OTableSourceGroup>(
  tableResult: OTableResult<D, G>,
): OTableResult<D, G> {
  const { data } = tableResult;

  const rows = data.map((item) => ({
    type: OTableRowTypes.ROW,
    original: item,
    isFiltered: false,
    filterMatches: {
      isFiltered: null,
      isSearched: null,
      isSelected: null,
    },
  }));

  const rowHashMap = arrayToHashTable(data, { mainKey: 'id' });

  return {
    ...tableResult,
    rows,
    rowHashMap,
  };
}

export function checkIsFilterMatched<D extends OTableSourceData, G extends OTableSourceGroup>(row: OTableRowOrGroup<D, G>) {
  if (!row.isFiltered) {
    return true;
  }

  const { filterMatches } = row;

  return (
    (filterMatches.isFiltered === null || filterMatches.isFiltered) &&
    (filterMatches.isSearched === null || filterMatches.isSearched) &&
    (filterMatches.isSelected === null || filterMatches.isSelected)
  );
}
