import React from 'react';

import { Tab, TabList, TabPanel, TabPanels, Tabs, forwardRef } from '@chakra-ui/react';
import type { TabPanelProps, TabProps, TabsProps } from '@chakra-ui/react';

import { RippleButtonText } from '../RippleText';

export type RippleTabsProps = Omit<TabsProps, 'variant'> & { variant?: 'default' | 'withinContainer' };

export const RippleTabs = ({ variant = 'default', ...otherProps }: RippleTabsProps) => {
  return <Tabs isFitted={variant === 'withinContainer'} variant={variant} {...otherProps} />;
};

export const RippleTabList = TabList;

export type RippleTabProps = TabProps;
export const RippleTab = forwardRef<TabProps, 'button'>((props, ref) => {
  const { children, ...otherProps } = props;
  return (
    <Tab ref={ref} {...otherProps}>
      <RippleButtonText margin="0" whiteSpace="nowrap" color="inherit">
        {children}
      </RippleButtonText>
    </Tab>
  );
});

export const RippleTabPanels = TabPanels;

export type RippleTabPanelProps = TabPanelProps;
export const RippleTabPanel = TabPanel;
