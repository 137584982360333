import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export const RippleIOSConnected = () => {
  const clipPath1 = useId();
  const filter1 = useId();
  const linearGradient1 = useId();
  const linearGradient2 = useId();

  const sparedId = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <g clipPath={`url(#${clipPath1})`}>
        <g filter={`url(#${filter1})`}>
          <rect x={4} y={4} width={32} height={32} rx={4} fill="white" />
          <rect x={5} y={5} width={30} height={30} rx={3} stroke={`url(#${sparedId})`} strokeWidth={2} />
        </g>
        <rect x={10} y={10} width={20} height={20} rx={5} fill="black" />
        <path
          d="M25.0799 24.3302C23.3423 24.3302 22.2374 23.4744 22.1441 22.1828L22.1389 22.1102H23.4356L23.4408 22.1621C23.503 22.7897 24.1722 23.2046 25.1214 23.2046C26.0239 23.2046 26.6567 22.7586 26.6567 22.1154V22.1102C26.6567 21.5708 26.2677 21.244 25.3029 21.0365L24.4989 20.8705C22.9688 20.5489 22.3049 19.8331 22.3049 18.718V18.7128C22.3049 17.3745 23.4564 16.472 25.0747 16.472C26.7345 16.472 27.7667 17.3797 27.8601 18.5831L27.8652 18.6505H26.5841L26.5737 18.5883C26.4803 18.0073 25.9253 17.5924 25.0695 17.5976C24.2396 17.5976 23.6483 17.9866 23.6483 18.6246V18.6298C23.6483 19.1588 24.0269 19.4908 24.9606 19.6879L25.7645 19.8591C27.3414 20.191 28.0001 20.8238 28.0001 21.9546V21.9598C28.0001 23.4173 26.8745 24.3302 25.0799 24.3302Z"
          fill="white"
        />
        <path
          d="M17.8864 24.3302C15.6923 24.3302 14.3437 22.8104 14.3437 20.4037V20.3933C14.3437 17.9814 15.7131 16.472 17.8864 16.472C20.0649 16.472 21.4239 17.9814 21.4239 20.3933V20.4037C21.4239 22.8104 20.0701 24.3302 17.8864 24.3302ZM17.8864 23.1735C19.2194 23.1735 20.0597 22.0843 20.0597 20.4037V20.3933C20.0597 18.7024 19.2142 17.6287 17.8864 17.6287C16.5585 17.6287 15.7079 18.7024 15.7079 20.3933V20.4037C15.7079 22.0843 16.5378 23.1735 17.8864 23.1735Z"
          fill="white"
        />
        <path
          d="M12.7729 17.525C12.3475 17.525 12 17.1878 12 16.7625C12 16.3423 12.3475 16 12.7729 16C13.193 16 13.5405 16.3423 13.5405 16.7625C13.5405 17.1878 13.193 17.525 12.7729 17.525ZM12.1245 24.1435V18.4742H13.416V24.1435H12.1245Z"
          fill="white"
        />
        <circle cx={31} cy={31} r={7} fill={`url(#${linearGradient2})`} stroke="white" strokeWidth={2} />
        <rect x={29} y="30.25" width={4} height="1.5" fill="white" />
        <path
          d="M33 28.4828V33.5172C33 33.6953 33.2154 33.7846 33.3414 33.6586L35.8586 31.1414C35.9367 31.0633 35.9367 30.9367 35.8586 30.8586L33.3414 28.3414C33.2154 28.2154 33 28.3047 33 28.4828Z"
          fill="white"
        />
        <path
          d="M29 28.4828V33.5172C29 33.6953 28.7846 33.7846 28.6586 33.6586L26.1414 31.1414C26.0633 31.0633 26.0633 30.9367 26.1414 30.8586L28.6586 28.3414C28.7846 28.2154 29 28.3047 29 28.4828Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id={filter1} x={-1} y={-1} width={42} height={42} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9874_75726" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx={-1} dy={-1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.572549 0 0 0 0 0.92549 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9874_75726" result="effect2_dropShadow_9874_75726" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9874_75726" result="shape" />
        </filter>
        <linearGradient id={linearGradient1} x1={4} y1={11} x2={32} y2={36} gradientUnits="userSpaceOnUse">
          <stop offset="0.208275" stopColor="#AC59FF" />
          <stop offset={1} stopColor="#036DEA" />
        </linearGradient>
        <linearGradient id={linearGradient2} x1="37.4167" y1={38} x2="25.1663" y2="24.8754" gradientUnits="userSpaceOnUse">
          <stop offset="0.107053" stopColor="#AC59FF" />
          <stop offset="0.54456" stopColor="#4B4BFA" />
          <stop offset={1} stopColor="#5594EB" />
        </linearGradient>
        <clipPath id={clipPath1}>
          <rect width={40} height={40} fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export const RippleIOSOffline = () => {
  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width="31" height="31" rx="3.5" fill="white" />
      <rect x="4.5" y="4.5" width="31" height="31" rx="3.5" stroke="#DDDDDD" />
      <rect x="10" y="10" width="20" height="20" rx="5" fill="#B0B0B0" />
      <path
        d="M25.0799 24.3302C23.3423 24.3302 22.2374 23.4744 22.1441 22.1828L22.1389 22.1102H23.4356L23.4408 22.1621C23.503 22.7897 24.1722 23.2046 25.1214 23.2046C26.0239 23.2046 26.6567 22.7586 26.6567 22.1154V22.1102C26.6567 21.5708 26.2677 21.244 25.3029 21.0365L24.4989 20.8705C22.9688 20.5489 22.3049 19.8331 22.3049 18.718V18.7128C22.3049 17.3745 23.4564 16.472 25.0747 16.472C26.7345 16.472 27.7667 17.3797 27.8601 18.5831L27.8652 18.6505H26.5841L26.5737 18.5883C26.4803 18.0073 25.9253 17.5924 25.0695 17.5976C24.2396 17.5976 23.6483 17.9866 23.6483 18.6246V18.6298C23.6483 19.1588 24.0269 19.4908 24.9606 19.6879L25.7645 19.8591C27.3414 20.191 28.0001 20.8238 28.0001 21.9546V21.9598C28.0001 23.4173 26.8745 24.3302 25.0799 24.3302Z"
        fill="white"
      />
      <path
        d="M17.8864 24.3302C15.6923 24.3302 14.3437 22.8104 14.3437 20.4037V20.3933C14.3437 17.9814 15.7131 16.472 17.8864 16.472C20.0649 16.472 21.4239 17.9814 21.4239 20.3933V20.4037C21.4239 22.8104 20.0701 24.3302 17.8864 24.3302ZM17.8864 23.1735C19.2194 23.1735 20.0597 22.0843 20.0597 20.4037V20.3933C20.0597 18.7024 19.2142 17.6287 17.8864 17.6287C16.5585 17.6287 15.7079 18.7024 15.7079 20.3933V20.4037C15.7079 22.0843 16.5378 23.1735 17.8864 23.1735Z"
        fill="white"
      />
      <path
        d="M12.7729 17.525C12.3475 17.525 12 17.1878 12 16.7625C12 16.3423 12.3475 16 12.7729 16C13.193 16 13.5405 16.3423 13.5405 16.7625C13.5405 17.1878 13.193 17.525 12.7729 17.525ZM12.1245 24.1435V18.4742H13.416V24.1435H12.1245Z"
        fill="white"
      />
    </Icon>
  );
};

export const RippleIOSOnline = () => {
  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width="31" height="31" rx="3.5" fill="white" />
      <rect x="4.5" y="4.5" width="31" height="31" rx="3.5" stroke="#DFE4E9" />
      <rect x="10" y="10" width="20" height="20" rx="5" fill="black" />
      <path
        d="M25.0799 24.3302C23.3423 24.3302 22.2374 23.4744 22.1441 22.1828L22.1389 22.1102H23.4356L23.4408 22.1621C23.503 22.7897 24.1722 23.2046 25.1214 23.2046C26.0239 23.2046 26.6567 22.7586 26.6567 22.1154V22.1102C26.6567 21.5708 26.2677 21.244 25.3029 21.0365L24.4989 20.8705C22.9688 20.5489 22.3049 19.8331 22.3049 18.7179V18.7128C22.3049 17.3745 23.4564 16.472 25.0747 16.472C26.7345 16.472 27.7667 17.3797 27.8601 18.5831L27.8652 18.6505H26.5841L26.5737 18.5883C26.4803 18.0073 25.9253 17.5924 25.0695 17.5976C24.2396 17.5976 23.6483 17.9866 23.6483 18.6246V18.6298C23.6483 19.1588 24.0269 19.4908 24.9606 19.6879L25.7645 19.8591C27.3414 20.191 28.0001 20.8238 28.0001 21.9546V21.9598C28.0001 23.4173 26.8745 24.3302 25.0799 24.3302Z"
        fill="white"
      />
      <path
        d="M17.8864 24.3302C15.6923 24.3302 14.3437 22.8104 14.3437 20.4037V20.3933C14.3437 17.9814 15.7131 16.472 17.8864 16.472C20.0649 16.472 21.4239 17.9814 21.4239 20.3933V20.4037C21.4239 22.8104 20.0701 24.3302 17.8864 24.3302ZM17.8864 23.1735C19.2194 23.1735 20.0597 22.0843 20.0597 20.4037V20.3933C20.0597 18.7024 19.2142 17.6287 17.8864 17.6287C16.5585 17.6287 15.7079 18.7024 15.7079 20.3933V20.4037C15.7079 22.0843 16.5378 23.1735 17.8864 23.1735Z"
        fill="white"
      />
      <path
        d="M12.7729 17.525C12.3475 17.525 12 17.1878 12 16.7625C12 16.3423 12.3475 16 12.7729 16C13.193 16 13.5405 16.3423 13.5405 16.7625C13.5405 17.1878 13.193 17.525 12.7729 17.525ZM12.1245 24.1435V18.4742H13.416V24.1435H12.1245Z"
        fill="white"
      />
    </Icon>
  );
};
