import { useEffect, useRef, useState } from 'react';

export default function useActivatedButton(trigger: any, timeout = 600) {
  const isFirstRun = useRef(true);
  const [isActivated, setIsActivated] = useState(false);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setIsActivated(true);

    const timer = setTimeout(() => {
      setIsActivated(false);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [trigger, timeout]);

  return isActivated;
}
