import React from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import type {
  CloseButtonProps,
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlayProps,
  ModalProps,
  TextProps,
} from '@chakra-ui/react';

import { RippleClose } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleBodyText02, RippleHeading05 } from '../RippleText';

export type RippleModalProps = ModalProps;
export const RippleModal = (props: RippleModalProps): React.JSX.Element => {
  return <Modal motionPreset="scale" scrollBehavior="inside" {...props} />;
};

export type RippleModalOverlayProps = ModalOverlayProps;
export const RippleModalOverlay = ModalOverlay;

export type RippleModalContentProps = ModalContentProps;
export const RippleModalContent = ModalContent;

export type RippleModalHeaderProps = ModalHeaderProps;
export const RippleModalHeader = forwardRef<RippleModalHeaderProps, 'header'>(({ children, ...otherProps }, ref): React.JSX.Element => {
  return (
    <ModalHeader ref={ref} {...otherProps}>
      <VStack spacing="8px" alignItems="start">
        {children}
      </VStack>
    </ModalHeader>
  );
});

export const RippleModalTitle = forwardRef<TextProps, 'h5'>((props, ref) => <RippleHeading05 ref={ref} {...props} />);
export const RippleModalSubTitle = forwardRef<TextProps, 'p'>((props, ref) => (
  <RippleBodyText02 ref={ref} color="neutral.200" {...props} />
));

export type RippleModalFooterProps = ModalFooterProps;
export const RippleModalFooter = ModalFooter;

export type RippleModalBodyProps = ModalBodyProps;
export const RippleModalBody = ModalBody;

export type RippleModalCloseButtonProps = CloseButtonProps;
export const RippleModalCloseButton = forwardRef<RippleModalCloseButtonProps, 'button'>((props, ref): React.JSX.Element => {
  return <ModalCloseButton ref={ref} as={RippleIconButton} icon={<RippleClose />} {...props} />;
});
