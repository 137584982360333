import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleClose({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.77503 5.29289C6.38451 4.90237 5.75134 4.90237 5.36082 5.29289L5.29289 5.36082C4.90237 5.75134 4.90237 6.38451 5.29289 6.77503L10.5176 11.9998L5.29729 17.2201C4.90677 17.6106 4.90677 18.2438 5.29729 18.6343L5.36522 18.7022C5.75574 19.0927 6.38891 19.0927 6.77943 18.7022L11.9998 13.4819L17.2179 18.7001C17.6084 19.0906 18.2416 19.0906 18.6321 18.7001L18.7 18.6321C19.0906 18.2416 19.0906 17.6084 18.7 17.2179L13.4819 11.9998L18.7045 6.77719C19.095 6.38667 19.095 5.75351 18.7045 5.36298L18.6365 5.29506C18.246 4.90453 17.6128 4.90453 17.2223 5.29506L11.9998 10.5176L6.77503 5.29289Z"
        fill="currentColor"
      />
    </Icon>
  );
}
