import { useId } from 'react';

export const RippleResultNotFound = () => {
  const fillId1 = useId();
  const fillId2 = useId();
  const fillId3 = useId();
  const fillId4 = useId();
  const fillId5 = useId();
  const filterId1 = useId();
  const filterId2 = useId();
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.09863 0H43.8381C47.183 0 49.8945 2.71155 49.8945 6.05641C49.8945 9.40127 47.183 12.1128 43.8381 12.1128H3.09863V0Z"
        fill="#DEE3E8"
      />
      <path
        d="M6.19824 0H42.3136C45.7627 0 47.4872 0 48.7999 0.68187C49.906 1.25648 50.8079 2.1584 51.3825 3.26456C52.0644 4.57722 52.0644 6.30175 52.0644 9.75082V44.6236C52.0644 48.7625 52.0644 50.8319 51.2462 52.4071C50.5566 53.7345 49.4743 54.8168 48.1469 55.5064C46.5718 56.3246 44.5023 56.3246 40.3634 56.3246H9.86564L6.19824 51V0Z"
        fill={`url(#${fillId1})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19805 6.10158C6.1981 6.08654 6.19813 6.07148 6.19813 6.05641C6.19813 6.04134 6.1981 6.02628 6.19805 6.01124V5.45093H6.18284C6.02739 2.39035 4.70607 0 3.09907 0C1.3875 0 0 2.71155 0 6.05641C0 9.40122 1.38745 12.1127 3.09898 12.1128V12.113H6.19805V6.10158Z"
        fill={`url(#${fillId2})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9577 44.2119H11.0884L9.60742 56.3247H52.9577V56.3207C53.0055 56.3234 53.0536 56.3247 53.1019 56.3247C55.2548 56.3247 57 53.6132 57 50.2683C57 46.9235 55.2548 44.2119 53.1019 44.2119C53.0536 44.2119 53.0055 44.2133 52.9577 44.216V44.2119Z"
        fill={`url(#${fillId3})`}
      />
      <path
        d="M13.326 50.2683C13.326 49.473 13.2579 48.6854 13.1255 47.9506C12.9931 47.2158 12.7991 46.5482 12.5545 45.9858C12.3099 45.4234 12.0195 44.9773 11.6999 44.6729C11.3803 44.3686 11.0378 44.2119 10.6918 44.2119C10.3459 44.2119 10.0034 44.3686 9.68376 44.6729C9.36416 44.9773 9.07377 45.4234 8.82916 45.9858C8.58455 46.5482 8.39052 47.2158 8.25813 47.9506C8.12575 48.6854 8.05762 49.473 8.05762 50.2683L10.6918 50.2683H13.326Z"
        fill={`url(#${fillId4})`}
      />
      <path
        d="M6.19824 50.2683C6.19824 51.0637 6.29043 51.8512 6.46953 52.586C6.64863 53.3208 6.91115 53.9885 7.24209 54.5509C7.57303 55.1132 7.96592 55.5594 8.39831 55.8637C8.83071 56.1681 9.29415 56.3247 9.76217 56.3247C10.2302 56.3247 10.6936 56.1681 11.126 55.8637C11.5584 55.5594 11.9513 55.1132 12.2822 54.5509C12.6132 53.9885 12.8757 53.3208 13.0548 52.586C13.2339 51.8512 13.3261 51.0637 13.3261 50.2683L9.76217 50.2683L6.19824 50.2683Z"
        fill={`url(#${fillId5})`}
      />
      <g>
        <rect x="13.6357" y="29" width="13" height="3" rx="1.5" fill="#D1D4DB" fillOpacity="0.8" />
      </g>
      <g>
        <rect x="13.6357" y="22" width="31.7884" height="3" rx="1.5" fill="#D1D4DB" fillOpacity="0.8" />
      </g>
      <g>
        <rect x="13.6357" y="15" width="31.7884" height="3" rx="1.5" fill="#D1D4DB" fillOpacity="0.8" />
      </g>
      <g>
        <rect x="13.6357" y="8" width="31.7884" height="3" rx="1.5" fill="#D1D4DB" fillOpacity="0.8" />
      </g>
      <g>
        <rect x="13.6357" y="36.3008" width="13" height="3" rx="1.5" fill="#D1D4DB" fillOpacity="0.8" />
      </g>
      <circle cx="41.3748" cy="34.3748" r="9.075" fill="#EEF0F2" />
      <g filter={`url(#${filterId1})`}>
        <path d="M49.625 42.625L60.35 53.35" stroke="#777777" strokeWidth="3.3" strokeLinecap="round" />
      </g>
      <g filter={`url(#${filterId2})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.375 46.75C48.2095 46.75 53.75 41.2095 53.75 34.375C53.75 27.5405 48.2095 22 41.375 22C34.5405 22 29 27.5405 29 34.375C29 41.2095 34.5405 46.75 41.375 46.75ZM41.375 43.45C46.387 43.45 50.45 39.387 50.45 34.375C50.45 29.363 46.387 25.3 41.375 25.3C36.363 25.3 32.3 29.363 32.3 34.375C32.3 39.387 36.363 43.45 41.375 43.45Z"
          fill="#777777"
        />
      </g>
      <defs>
        <filter
          id={filterId1}
          x="47.1496"
          y="40.9751"
          width="15.6754"
          height="15.6749"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.825" />
          <feGaussianBlur stdDeviation="0.4125" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_565_14905" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_565_14905" result="shape" />
        </filter>
        <filter id={filterId2} x="28.175" y="22" width="26.4" height="26.4" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.825" />
          <feGaussianBlur stdDeviation="0.4125" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_565_14905" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_565_14905" result="shape" />
        </filter>
        <linearGradient id={fillId1} x1="29.1313" y1="0" x2="29.1313" y2="56.3246" gradientUnits="userSpaceOnUse">
          <stop offset="0.302083" stopColor="#DFE4E9" />
          <stop offset="1" stopColor="#C1C7CD" />
        </linearGradient>
        <linearGradient id={fillId2} x1="3.09907" y1="0" x2="3.09907" y2="12.1128" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ACAFBB" />
          <stop offset="0.46875" stopColor="#C3C9CF" />
          <stop offset="0.619792" stopColor="#C3C9CF" />
          <stop offset="0.760417" stopColor="#B9BEC7" />
          <stop offset="0.958333" stopColor="#ACAFBB" />
        </linearGradient>
        <linearGradient id={fillId3} x1="33.3037" y1="44.2119" x2="33.3037" y2="56.3247" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D1D7DC" />
          <stop offset="0.208333" stopColor="#D9DFE4" />
          <stop offset="0.359375" stopColor="#DFE4E9" />
          <stop offset="0.572917" stopColor="#DFE4E9" />
          <stop offset="1" stopColor="#D1D7DC" />
        </linearGradient>
        <linearGradient id={fillId4} x1="10.6918" y1="44.2119" x2="10.6918" y2="56.3247" gradientUnits="userSpaceOnUse">
          <stop offset="0.265625" stopColor="#B3B7C1" />
          <stop offset="0.515625" stopColor="#9C9FA9" />
        </linearGradient>
        <linearGradient id={fillId5} x1="9.76217" y1="44.2119" x2="9.76217" y2="56.3247" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C7CDD3" />
          <stop offset="0.713542" stopColor="#C9CFD4" />
          <stop offset="1" stopColor="#ACAFBB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
