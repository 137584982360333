import colors from '@/design/theme/colors';

const styles = {
  parts: ['field'],
  baseStyle: {
    field: {},
  },
  variants: {
    outline: {
      field: {
        color: 'dark.90',
        bg: 'white',
        borderColor: 'neutral.300',
        _placeholder: {
          color: 'dark.40',
        },
        _hover: {
          borderColor: 'blue.100',
        },
        _focus: {
          borderColor: 'blue.100',
          boxShadow: 'none',
        },
        _invalid: {
          borderColor: 'red.100',
          boxShadow: 'none',
          _hover: {
            borderColor: 'red.100',
          },
        },
        _disabled: {
          borderColor: 'dark.40',
          bg: 'neutral.10',
          _placeholder: {
            color: 'dark.40',
          },
          _hover: {
            borderColor: 'dark.40',
          },
        },
      },
    },
    flushed: {
      field: {
        padding: '10px 12px',
        color: 'dark.100',
        borderBottom: '1px solid',
        borderColor: 'neutral.300',
        background: 'white',
        fontWeight: 700,
        _placeholder: {
          color: 'dark.40',
          fontStyle: 'italic',
          fontWeight: 400,
        },
        _hover: {
          background: 'blue.0',
          borderColor: 'neutral.300',
        },
        _focus: {
          background: 'blue.10',
          borderColor: 'blue.100',
        },
        _focusVisible: {
          border: 'none',
          borderColor: 'blue.100',
          boxShadow: `0px 1px 0px 0px ${colors.blue[100]}`,
        },
      },
    },
    editPlaceholder: {
      field: {
        color: 'dark.40',
        border: '1px solid',
        borderRadius: '4px',
        borderColor: 'neutral.300',
        background: 'white',
        _placeholder: {
          color: 'dark.40',
          fontStyle: 'italic',
        },
        _hover: {
          background: 'blue.0',
          borderColor: 'neutral.300',
        },
        _focus: {
          background: 'white',
          borderColor: 'blue.100',
        },
      },
    },
  },
  sizes: {
    xs: {
      field: {
        fontSize: '0.875rem',
        height: '32px',
        borderRadius: '4px',
        px: '12px',
        py: '6px',
      },
    },
    sm: {
      field: {
        fontSize: '0.875rem',
        height: '40px',
        borderRadius: '4px',
        px: '12px',
        py: '10px',
      },
    },
    md: {
      field: {
        fontSize: '0.875rem',
        height: '44px',
        borderRadius: '4px',
        px: '12px',
        py: '12px',
      },
    },
    lg: {
      field: {
        fontSize: '0.875rem',
        height: '48px',
        borderRadius: '4px',
        px: '12px',
        py: '14px',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};

export const rippleInput = styles;
