import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCopy16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M19 7L20 7C20.5523 7 21 7.44771 21 8L21 20C21 20.5523 20.5523 21 20 21L7.99999 21C7.4477 21 6.99999 20.5523 6.99999 20L6.99998 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="16"
      />
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V14V16C17 16.5523 16.5523 17 16 17H14H4C3.44772 17 3 16.5523 3 16V4Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </Icon>
  );
}
