import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

import { ComputerDetail_deprecated } from './getTeamComputerDetail_deprecated';

/**
 * @deprecated Use `getUserComputerDetail` from `@/services/computers/services/getUserComputerDetail` instead.
 */
export function getUserComputerDetail_deprecated(computerId: string) {
  return new Promise<ComputerDetail_deprecated>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ComputerDetail_deprecated }>(`/api/web/v1/users/computers/${computerId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
