import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleHourglass16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        d="M3 1C3 2.82609 3 3.43478 3.96 4.65217C4.92 5.86957 5.4 5.97826 5.4 7.5C5.4 8.71739 4.92 9.52174 3.96 10.7391C3 11.9565 3 13.1739 3 15"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M13 1C13 2.82609 13 3.43478 12.04 4.65217C11.08 5.86957 10.6 5.97826 10.6 7.5C10.6 8.71739 11.08 9.52174 12.04 10.7391C13 11.9565 13 13.1739 13 15"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <rect x="1.5" y="1" width="13" height="1.6" rx="0.8" fill="currentColor" />
      <rect x="1.5" y="13.4" width="13" height="1.6" rx="0.8" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02664 11.5522C5.88192 12.1096 6.34945 12.6 6.92527 12.6H9.07474C9.65055 12.6 10.1181 12.1096 9.97337 11.5522C9.64468 10.2864 8.88485 9.40002 8 9.40002C7.11516 9.40002 6.35533 10.2864 6.02664 11.5522Z"
        fill="currentColor"
      />
      <rect x="7" y="5" width="2" height="1.6" rx="0.8" fill="currentColor" />
    </Icon>
  );
}
