import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleCtrlOptionEsc({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2C13.4477 2 13 2.44772 13 3V10C13 10.5523 13.4477 11 14 11H21C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14ZM14.38 6.5C14.38 4.81579 15.7484 3.5 17.494 3.5C19.2484 3.5 20.6256 4.82456 20.6256 6.5C20.6256 8.18421 19.2484 9.5 17.494 9.5C15.7397 9.5 14.38 8.18421 14.38 6.5ZM19.0818 6.5C19.0818 5.5614 18.4239 4.88597 17.5028 4.88597C16.5642 4.88597 15.9239 5.54386 15.9239 6.5C15.9239 7.46491 16.5554 8.11403 17.5028 8.11403C18.4326 8.11403 19.0818 7.44737 19.0818 6.5ZM3 7C2.44772 7 2 7.44772 2 8V15C2 15.5523 2.44772 16 3 16H10C10.5523 16 11 15.5523 11 15V8C11 7.44772 10.5523 7 10 7H3ZM8.85088 13.5351C8.3421 14.1404 7.50877 14.5 6.62281 14.5C4.85965 14.5 3.5 13.193 3.5 11.5C3.5 9.80702 4.86842 8.5 6.63158 8.5C7.51754 8.5 8.35088 8.85965 8.85965 9.46491C9 9.62281 9.07895 9.80702 9.07895 10C9.07895 10.3772 8.77193 10.6842 8.38596 10.6842C8.17544 10.6842 7.98246 10.5877 7.80702 10.4035C7.48246 10.0526 7.09649 9.88597 6.62281 9.88597C5.69298 9.88597 5.04386 10.5526 5.04386 11.5088C5.04386 12.4649 5.68421 13.114 6.62281 13.114C7.07895 13.114 7.45614 12.9474 7.79824 12.5965C7.97368 12.4123 8.16667 12.3158 8.37719 12.3158C8.76316 12.3158 9.07018 12.6228 9.07018 13C9.07018 13.1667 9 13.3421 8.85088 13.5351ZM13 14C13 13.4477 13.4477 13 14 13H21C21.5523 13 22 13.4477 22 14V21C22 21.5523 21.5523 22 21 22H14C13.4477 22 13 21.5523 13 21V14ZM16.225 20.25H18.8417C19.2167 20.25 19.5 19.975 19.5 19.6C19.5 19.2333 19.225 18.9583 18.8417 18.9583H16.9333V18H18.0833C18.4333 18 18.6917 17.7417 18.6917 17.4C18.6917 17.0583 18.4333 16.8 18.0833 16.8H16.9333V16.0417H18.7417C19.1167 16.0417 19.4 15.7667 19.4 15.3917C19.4 15.025 19.125 14.75 18.7417 14.75H16.225C15.8083 14.75 15.5 15.0583 15.5 15.475V19.525C15.5 19.9417 15.8083 20.25 16.225 20.25Z"
        fill="currentColor"
      />
    </Icon>
  );
}
