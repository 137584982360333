import range from '@/utils/range';

// Copy from @/components/SimpleTable/Pagination/useLocalPagination

type UseLocalPaginationProps = {
  pageIndex: number;
  pageCount: number;
  siblingCount?: number;
  boundaryCount?: number;
};

function useLocalPagination(props: UseLocalPaginationProps) {
  const { pageIndex, pageCount, siblingCount = 1, boundaryCount = 1 } = props;

  // fork from: https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/usePagination/usePagination.js
  const startPages = range(1, Math.min(boundaryCount, pageCount));
  const endPages = range(Math.max(pageCount - boundaryCount + 1, boundaryCount + 1), pageCount);

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      pageIndex - siblingCount,
      // Lower boundary when page is high
      pageCount - boundaryCount - siblingCount * 2 - 1,
    ),
    // Greater than startPages
    boundaryCount + 2,
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      pageIndex + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2,
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : pageCount - 1,
  );

  const pageItemList: Array<number | string | 'start-ellipsis' | 'end-ellipsis'> = [
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2 ? ['start-ellipsis'] : boundaryCount + 1 < pageCount - boundaryCount ? [boundaryCount + 1] : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < pageCount - boundaryCount - 1
      ? ['end-ellipsis']
      : pageCount - boundaryCount > boundaryCount
      ? [pageCount - boundaryCount]
      : []),

    ...endPages,
  ];

  const isFirstPage = pageIndex === 1;
  const isLastPage = pageIndex === pageCount;

  return {
    isFirstPage,
    isLastPage,
    pageItemList,
  };
}

export default useLocalPagination;
