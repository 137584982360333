import { Radio, RadioGroup, RadioProps } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';

export const RippleRadioGroup = RadioGroup;

/**
 * Check Radio in Modal will affect Modal's position,
 * need to define `position: relative` or upgrade Chakra to v2 to resolve this issue
 * // TODO: remove this workaround after upgrade Chakra to v2
 * ref: https://github.com/chakra-ui/chakra-ui/issues/5926,
 *      https://github.com/chakra-ui/chakra-ui/pull/6119/files
 */
const workaroundFixStyle: StyleProps = { position: 'relative' };

export const RippleRadio = (props: Omit<RadioProps, 'size'>) => {
  return <Radio size="lg" spacing="12px" {...workaroundFixStyle} {...props} />;
};
