import { Icon } from '@chakra-ui/react';

import type { RippleIconProps } from './types';

export function RippleWindowsNoBorder({ color = '#0074CD' }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <rect x="4" y="4" width="7.5" height="7.5" fill="currentColor" />
      <rect x="12.5" y="4" width="7.5" height="7.5" fill="currentColor" />
      <rect x="4" y="12.5" width="7.5" height="7.5" fill="currentColor" />
      <rect x="12.5" y="12.5" width="7.5" height="7.5" fill="currentColor" />
    </Icon>
  );
}
